import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { lighten } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { DataGrid, GridOverlay } from '@mui/x-data-grid';
import clsx from 'clsx';

const LoadingOverlay = () => (
    <GridOverlay>
        <div style={{ position: 'absolute', top: 0, width: '100%' }}>
            <LinearProgress />
        </div>
    </GridOverlay>
);

const useStyles = makeStyles((theme) => ({
    customDataGrid: {
        //need this cause we are using a number column type (datagrid justifies content to flex-end on number columns (fail))
        '&.MuiDataGrid-root$numberColLeftAligned .MuiDataGrid-colCellNumeric .MuiDataGrid-colCellTitleContainer': {
            flexDirection: 'row-reverse'
        },
        '&.MuiDataGrid-root$coloredHeader .MuiDataGrid-columnsContainer': {
            backgroundColor: theme.palette.secondary.main
        },
        '&.MuiDataGrid-root$coloredHeader .MuiDataGrid-columnSeparator': {
            color: 'rgba(0,0,0,0.5)'
        },
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none'
        },
        '& .MuiDataGrid-row.Mui-selected:hover': {
            backgroundColor: lighten(theme.palette.secondary.main, 0.65)
        },
        '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: lighten(theme.palette.secondary.main, 0.8)
        },
        '& .MuiDataGrid-row:hover': {
            backgroundColor: lighten(theme.palette.secondary.main, 0.95)
        },
        '&.MuiDataGrid-root$selectPointer .MuiDataGrid-row:hover': {
            cursor: 'pointer'
        }
    },
    selectPointer: {},
    coloredHeader: {},
    numberColLeftAligned: {}
}));

const CustomDataGrid = ({
    className,
    coloredHeader,
    numberColLeftAligned,
    autoHeight,
    disableSelectionOnClick,
    components,
    initialSortModel,
    initialPageSize,
    ...restProps
}) => {
    const classes = useStyles();
    const [sortModel, setSortModel] = React.useState(initialSortModel ?? []);
    const [pageSize, setPageSize] = React.useState(initialPageSize ?? 25);

    return (
        <DataGrid
            className={clsx(
                classes.customDataGrid,
                className,
                !disableSelectionOnClick && classes.selectPointer,
                coloredHeader && classes.coloredHeader,
                numberColLeftAligned && classes.numberColLeftAligned
            )}
            sortModel={restProps.onSortModelChange ? restProps.sortModel : sortModel}
            onSortModelChange={restProps.onSortModelChange ? restProps.onSortModelChange : (model) => setSortModel(model)}
            pageSize={pageSize}
            onPageSizeChange={restProps.onPageSizeChange ? restProps.onPageSizeChange : (newPageSize) => setPageSize(newPageSize)}
            localeText={{ filterOperatorAfter: 'is after' }}
            autoHeight={autoHeight !== undefined ? autoHeight : true}
            components={{ ...components, LoadingOverlay: LoadingOverlay }}
            disableSelectionOnClick={disableSelectionOnClick}
            {...restProps}
        />
    );
};

export default CustomDataGrid;
