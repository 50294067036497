import axios from 'axios';

export function getAgreements(cancelToken) {
    return axios.get('/api/services/app/Setting/GetAgreements', { cancelToken });
}

export function updateAgreements(input) {
    return axios.put('/api/services/app/Setting/UpdateAgreements', input);
}

export function getContacts(cancelToken) {
    return axios.get('/api/services/app/Setting/GetContacts', { cancelToken });
}

export function updateContacts(input) {
    return axios.put('/api/services/app/Setting/UpdateContacts', input);
}

export function updateRegistrationPage(input) {
    return axios.put('/api/services/app/Setting/UpdateRegistrationPage', input);
}
