import React from 'react';
import { navigate } from '@gatsbyjs/reach-router';
import AppBar from '@material-ui/core/AppBar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from '../auth/auth-context';

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1
    },
    appBar: {
        backgroundColor: theme.palette.header.main,
        color: theme.palette.getContrastText(theme.palette.header.main)
    }
}));

export default function HostHeader() {
    const [userMenuAnchor, setUserMenuAnchor] = React.useState(null);
    const isUserMenuOpen = Boolean(userMenuAnchor);
    const { user, logout } = useAuth();
    const classes = useStyles();

    const handleOpenUserMenu = (event) => {
        setUserMenuAnchor(event.currentTarget);
    };

    const handleCloseUserMenu = (event) => {
        setUserMenuAnchor(null);
    };

    return (
        <AppBar position="static" className={classes.appBar}>
            <Toolbar>
                <Typography variant="h6" className={classes.title}>
                    Base Host Admin
                </Typography>
                <Button color="inherit" onClick={handleOpenUserMenu}>
                    {user.name} {user.surname}
                </Button>
                <Menu
                    anchorEl={userMenuAnchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    getContentAnchorEl={null}
                    open={isUserMenuOpen}
                    onClose={handleCloseUserMenu}
                >
                    <MenuItem
                        onClick={() => {
                            setUserMenuAnchor(null);
                            navigate('/change-password');
                        }}
                    >
                        Change Password
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setUserMenuAnchor(null);
                            logout();
                        }}
                    >
                        Logout
                    </MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
}
