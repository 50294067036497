import axios from 'axios';

export function forgotPassword(values) {
    return axios.post('/api/services/app/Account/ForgotPassword', values);
}

export function reactivateAccount(values) {
    return axios.post('/api/services/app/Account/ReactivateAccount', values);
}

export function changePassword(currentPassword, newPassword) {
    return axios.post('/api/services/app/Account/ChangePassword', { currentPassword, newPassword });
}

export function resetPassword(code, newPassword, tenantId) {
    return axios.post('/api/services/app/Account/ResetPassword', { code, newPassword, tenantId });
}

export function resendVerificationEmail(emailAddress, tenantId) {
    return axios.post('/api/services/app/Account/ResendVerificationEmail', { emailAddress, tenantId });
}

export function verify(code, tenantId, disableIntercept) {
    return axios.post(
        '/api/services/app/Account/Verify',
        { code, tenantId },
        disableIntercept ? { headers: { 'disable-intercept': true } } : undefined
    );
}

export function updatePrimaryEmailAddress(emailAddress) {
    return axios.put('/api/services/app/Account/UpdatePrimaryEmailAddress', { emailAddress });
}

export function requestChangEmailAddress(emailAddress) {
    return axios.post('/api/services/app/Account/RequestChangeEmailAddress', { emailAddress });
}

export function changeEmailAddress(userId, emailAddress, token, password) {
    return axios.post('/api/services/app/Account/ChangeEmailAddress', { userId, emailAddress, token, password });
}
