import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import { FinalFormTextField, FinalFormSwitch } from '../../../common/formWrappers';
import { required, email, maxLength, composeValidators } from '../../../common/formValidators';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    buttonWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

export default function EditDialog({ open, handleClose, tenant, handleSave, isSaving }) {
    const classes = useStyles();

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{tenant === null ? `Add Tenant` : `Edit Tenant '${tenant.name}'`}</DialogTitle>
            <DialogContent>
                <Form onSubmit={(values) => handleSave({ ...tenant, ...values })}>
                    {(props) => (
                        <form onSubmit={props.handleSubmit}>
                            <Field
                                name="tenancyName"
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                label="Tenancy Name"
                                id="tenancyName"
                                component={FinalFormTextField}
                                validate={composeValidators(required, maxLength(64))}
                                initialValue={tenant ? tenant.tenancyName : undefined}
                            />
                            <Field
                                name="name"
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                label="Name"
                                id="name"
                                component={FinalFormTextField}
                                validate={composeValidators(required, maxLength(128))}
                                initialValue={tenant ? tenant.name : undefined}
                            />
                            {tenant === null && (
                                <React.Fragment>
                                    <Field
                                        name="adminEmailAddress"
                                        variant="outlined"
                                        margin="dense"
                                        required
                                        fullWidth
                                        label="Admin Email Address"
                                        id="adminEmailAddress"
                                        component={FinalFormTextField}
                                        validate={composeValidators(required, email, maxLength(256))}
                                    />
                                    <Field
                                        name="connectionString"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        label="Connection String"
                                        id="connectionString"
                                        component={FinalFormTextField}
                                        validate={maxLength(1024)}
                                    />
                                </React.Fragment>
                            )}
                            <FormControlLabel
                                control={
                                    <Field
                                        name="isActive"
                                        value="isActive"
                                        component={FinalFormSwitch}
                                        color="primary"
                                        initialValue={tenant ? tenant.isActive : true}
                                    />
                                }
                                label="Active"
                            />
                            <DialogActions>
                                <Button onClick={handleClose} disabled={isSaving}>
                                    Cancel
                                </Button>
                                <div className={classes.buttonWrapper}>
                                    <Button type="submit" disabled={isSaving}>
                                        Save
                                    </Button>
                                    {isSaving && <CircularProgress className={classes.buttonProgress} size={24} />}
                                </div>
                            </DialogActions>
                        </form>
                    )}
                </Form>
            </DialogContent>
        </Dialog>
    );
}
