import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Skeleton from '@material-ui/lab/Skeleton';

import * as ProfileApi from '../../../../../api/profile';
import ReadOnlyProfile from '../../../home/readOnlyProfile';

const ViewProfileDialog = ({ userId, open, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        const load = async () => {
            try {
                setLoading(true);
                const profileData = (await ProfileApi.get(userId)).data.result;
                setData(profileData);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        if (open && userId) {
            load();
        }
    }, [open, userId]);

    return (
        <Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>
            <DialogTitle>View Profile</DialogTitle>
            <DialogContent>
                {loading && (
                    <div>
                        <Skeleton variant="rect" width="100%" height={500} />
                    </div>
                )}
                {data && !loading && <ReadOnlyProfile profile={data} showHeader={false} />}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ViewProfileDialog;
