import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import LeftNavLayout from '../../leftNavLayout';
import Agreements from './agreements';
import Contacts from './contacts';
import RegistrationPage from './registrationPage';

const useStyles = makeStyles((theme) => ({
    appConfigPage: {
        minHeight: 500
    }
}));

const AppConfigPage = () => {
    const classes = useStyles();

    return (
        <LeftNavLayout contentClassName={classes.appConfigPage}>
            <Grid container spacing={2}>
                <Grid item sm={12} md={6}>
                    <Agreements />
                </Grid>
                <Grid item sm={12} md={6}>
                    <RegistrationPage />
                </Grid>
                <Grid item sm={12} md={6}>
                    <Contacts />
                </Grid>
            </Grid>
        </LeftNavLayout>
    );
};

export default AppConfigPage;
