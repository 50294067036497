import React from 'react';
import { Router } from '@gatsbyjs/reach-router';

import TenantPage from './pages/tenants';
import UsersPage from './pages/users';
import ChangePasswordPage from './pages/changePassword';
import AccountVerification from '../anonymous/accountVerification';
import SecureRoute from '../common/secureRoute';

export default function HostMain() {
    return (
        <Router>
            <SecureRoute as={TenantPage} path="/" />
            <SecureRoute as={UsersPage} path="/users/:tenantId" />
            <SecureRoute as={ChangePasswordPage} path="/change-password" />
            <AccountVerification path="/verify" />
        </Router>
    );
}
