import axios from 'axios';

export function getStudentList(academicYearId, cancelToken) {
    return axios.get('/api/services/app/Certification/GetStudentList', { params: { academicYearId }, cancelToken });
}

export function getStudentCertifications(applicationId, cancelToken) {
    return axios.get('/api/services/app/Certification/GetStudentCertifications', { params: { applicationId }, cancelToken });
}

export function createOrUpdate(values) {
    return axios.post('/api/services/app/Certification/CreateOrUpdate', values);
}

export function getApplicationEnrolledCertifications(applicationId) {
    return axios.get('/api/services/app/Certification/GetApplicationEnrolledCertifications', { params: { applicationId } });
}

export function updateAwards(values, release) {
    return axios.put('/api/services/app/Certification/UpdateAwards', { certifications: values, release });
}

export function getCertificationCountForRelease(academicYearId) {
    return axios.get('/api/services/app/Certification/GetCertificationCountForRelease', { params: { academicYearId } });
}

export function releaseCertifications(academicYearId) {
    return axios.post('/api/services/app/Certification/ReleaseCertifications', null, { params: { academicYearId } });
}
