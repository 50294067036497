import { useState, useEffect } from 'react';
import useDebounce from '../../../common/useDebounce';

import * as ReportApi from '../../../api/report';
import * as SearchApi from '../../../api/search';
import b64toBlob from '../../../common/logic/b64toBlob';
import FileSaver from 'file-saver';

const useStudentSearch = ({ searchText, selectedStudentId }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searching, setSearching] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    const debouncedSearchText = useDebounce(searchText, 350);

    const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);

    useEffect(() => {
        const search = async () => {
            try {
                setSearching(true);
                const results = (await SearchApi.searchStudents(debouncedSearchText)).data.result;
                setSearchResults(results);
            } catch {
            } finally {
                setSearching(false);
            }
        };

        if (debouncedSearchText.length >= 3) {
            search();
        } else {
            setSearchResults([]);
        }
    }, [debouncedSearchText]);

    useEffect(() => {
        if (searchResults.length > 0) {
            setAutoCompleteOpen(true);
        }
    }, [searchResults]);

    useEffect(() => {
        if (!autoCompleteOpen) {
            setSearchResults([]);
        }
    }, [autoCompleteOpen]);

    useEffect(() => {
        if (selectedStudentId) {
            (async () => {
                try {
                    setLoading(true);
                    const result = (await ReportApi.getStudentPaymentData(selectedStudentId)).data.result;
                    setData(result.transactions);
                } catch {
                } finally {
                    setLoading(false);
                }
            })();
        } else {
            setData([]);
        }
    }, [selectedStudentId]);

    const handleExportToExcel = async () => {
        try {
            setLoading(true);
            const file = (await ReportApi.runStudentPaymentReport(selectedStudentId)).data.result;
            const blob = b64toBlob(file.data, file.mimeType);
            FileSaver.saveAs(blob, file.fileName);
        } catch {
        } finally {
            setLoading(false);
        }
    };

    return {
        isOpen: autoCompleteOpen,
        setOpen: setAutoCompleteOpen,
        data,
        isLoading: loading,
        setLoading,
        searching,
        searchResults,
        exportToExcel: handleExportToExcel
    };
};

export default useStudentSearch;
