import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
// import withStyles from '@material-ui/styles/withStyles';
import axios from 'axios';
import LeftNavLayout from '../../leftNavLayout';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FilledInput from '@material-ui/core/FilledInput';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { fade } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import FileSaver from 'file-saver';
import InfoIcon from '@material-ui/icons/Info';
import { GridOverlay } from '@mui/x-data-grid';

import { certByEnrolledFormatter, dateFormatterUtc, GridCellExpand } from '../../../common/logic/grid';
import AcademicYearSelector from '../../../common/academicYearSelector';
import CustomDataGrid from '../../../common/customDataGrid';
import * as InstitutionApi from '../../../api/institution';
import * as ReportApi from '../../../api/report';
import { conformedCurrency } from '../../../common/masks';
import ExcelIcon from '../../../common/icons/excelIcon';
import b64toBlob from '../../../common/logic/b64toBlob';
import { useAuth } from '../../../auth/auth-context';
import { ROLE_NAME_INSTITUTION } from '../../../common/logic/consts';

const useStyles = makeStyles((theme) => ({
    certifiedStudentsSummary: {},
    formControl: {
        minWidth: 120,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25)
        }
    },
    selectLabel: {
        color: theme.palette.secondary.contrastText,
        '&.Mui-focused': {
            color: theme.palette.secondary.contrastText,
            fontWeight: 700
        }
    },
    underline: {
        '&:after': {
            borderBottom: `2px solid ${theme.palette.secondary.contrastText}`
        }
    },
    selectInput: {
        color: theme.palette.secondary.contrastText
    },
    leftMargin: {
        marginLeft: theme.spacing(1)
    }
}));

const payoutRenderCell =
    (emptyMessage) =>
    ({ value, colDef }) => {
        if (value.length === 0) {
            return (
                <GridCellExpand width={colDef.width}>
                    <span>{emptyMessage}</span>
                </GridCellExpand>
            );
        }

        return (
            <GridCellExpand width={200}>
                <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 1.3 }}>
                    {value.map((v) => (
                        <div key={`${v.period}`} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>{v.name}:</span>
                            <span style={{ marginLeft: 10 }}>{conformedCurrency(v.totalAmount.toString())}</span>
                        </div>
                    ))}
                </div>
            </GridCellExpand>
        );
    };

const sortModel = [{ field: 'firstName', sort: 'asc' }];

const CertifiedStudentsSummary = () => {
    const classes = useStyles();

    const [selectedYear, setSelectedYear] = useState('');
    const [selectedInstitution, setSelectedInstitution] = useState('');
    const [certified, setCertified] = useState(true);
    const [institutions, setInstitutions] = useState([]);
    const [data, setData] = useState([]);
    const [showInfo, setShowInfo] = useState(true);
    const [loading, setLoading] = useState(false);

    const { user } = useAuth();

    useEffect(() => {
        const tokenSourceInstitutions = axios.CancelToken.source();

        (async () => {
            try {
                setLoading(true);
                const items = (await InstitutionApi.getAll('name', 0, 1000)).data.result;
                const institutions = items.items.map((i) => ({ value: i.id, label: i.name }));
                setInstitutions(institutions);
                setSelectedInstitution(user.institution ? user.institution.id : institutions[0].value);
            } catch {
            } finally {
                setLoading(false);
            }
        })();

        return () => {
            tokenSourceInstitutions.cancel();
        };
    }, []);

    const handleExportToExcel = async () => {
        try {
            setLoading(true);
            const file = (await ReportApi.runCertifiedStudentSummaryReport(selectedYear, selectedInstitution, certified)).data.result;
            const blob = b64toBlob(file.data, file.mimeType);
            FileSaver.saveAs(blob, file.fileName);
        } catch {
        } finally {
            setLoading(false);
        }
    };

    const handleRunReport = async () => {
        try {
            setLoading(true);
            const items = (await ReportApi.getCertifiedStudentSummaryData(selectedYear, selectedInstitution, certified)).data.result;
            setData(items);
            setShowInfo(false);
        } catch {
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        { field: 'firstName', headerName: 'First Name', flex: 1 },
        { field: 'lastName', headerName: 'Last Name', flex: 1 },
        { field: 'institution', headerName: 'Institution', flex: 1 },
        { field: 'initialAwardTime', headerName: 'Initial Award Date', flex: 1, valueFormatter: dateFormatterUtc },
        {
            field: 'numberOfCertifications',
            headerName: 'Certifications/EnrolledTerms',
            flex: 1,
            valueFormatter: certByEnrolledFormatter
        },
        { field: 'termPayouts', headerName: 'Terms', flex: 1, renderCell: payoutRenderCell('No Terms') },
        { field: 'race', headerName: 'Race', flex: 1 },
        { field: 'citizenshipStatus', headerName: 'Citizenship Status', flex: 1 },
        { field: 'birthDate', headerName: 'DOB', flex: 1, valueFormatter: dateFormatterUtc },
        { field: 'age', headerName: 'Age', flex: 1 },
        { field: 'gender', headerName: 'Gender', flex: 1 }
    ];

    return (
        <LeftNavLayout>
            <Box p={1} color="secondary.contrastText" bgcolor="secondary.main">
                <Toolbar variant="dense">
                    <Box display="flex" flex={1} alignItems="center" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                            <Typography variant="h6">Certified Students Summary</Typography>
                            <AcademicYearSelector
                                className={classes.leftMargin}
                                inTableHeader
                                value={selectedYear}
                                onChange={(val) => {
                                    setSelectedYear(val);
                                    setData([]);
                                    setShowInfo(true);
                                }}
                            />
                            {user.roleName !== ROLE_NAME_INSTITUTION && (
                                <FormControl variant="filled" className={clsx(classes.formControl, classes.leftMargin)} margin="dense">
                                    <InputLabel shrink id="select-institution-label" className={classes.selectLabel}>
                                        Institution
                                    </InputLabel>
                                    <Select
                                        id="select-institution"
                                        labelid="select-institution-label"
                                        value={selectedInstitution}
                                        displayEmpty
                                        onChange={(evt) => {
                                            setSelectedInstitution(evt.target.value);
                                            setData([]);
                                            setShowInfo(true);
                                        }}
                                        input={<FilledInput classes={{ underline: classes.underline, input: classes.selectInput }} />}
                                    >
                                        {institutions.map((institution) => (
                                            <MenuItem key={institution.value} value={institution.value}>
                                                {institution.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                            <FormControlLabel
                                className={classes.leftMargin}
                                control={
                                    <Checkbox
                                        checked={certified}
                                        onChange={(e) => {
                                            setCertified(e.target.checked);
                                            setData([]);
                                            setShowInfo(true);
                                        }}
                                        name="certified"
                                        className={classes.certifiedCheckbox}
                                        color="primary"
                                    />
                                }
                                label="Certified"
                            />
                            <Button variant="contained" color="primary" className={classes.leftMargin} onClick={handleRunReport} disabled={loading}>
                                Run Report
                            </Button>
                        </Box>
                        <Tooltip arrow title={data.length === 0 ? 'No Data' : ''}>
                            <div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleExportToExcel}
                                    startIcon={<ExcelIcon />}
                                    disabled={loading || data.length === 0}
                                >
                                    Export to Excel
                                </Button>
                            </div>
                        </Tooltip>
                    </Box>
                </Toolbar>
            </Box>
            <CustomDataGrid
                getRowId={(row) => row.userId}
                disableColumnMenu
                disableColumnFilter
                initialSortModel={sortModel}
                disableSelectionOnClick
                rows={data}
                rowHeight={100}
                columns={columns}
                initialPageSize={25}
                rowsPerPageOptions={[25, 50, 100]}
                loading={loading}
                components={{
                    NoRowsOverlay: () => (
                        <GridOverlay>
                            {showInfo ? (
                                <>
                                    <InfoIcon color="primary" fontSize="small" />
                                    <Typography variant="body1" style={{ marginLeft: 10 }}>
                                        Run Report To See Data
                                    </Typography>
                                </>
                            ) : !loading ? (
                                <Typography variant="body1">No Rows</Typography>
                            ) : null}
                        </GridOverlay>
                    )
                }}
            />
        </LeftNavLayout>
    );
};

export default CertifiedStudentsSummary;
