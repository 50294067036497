import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { navigate } from '@gatsbyjs/reach-router';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import { green } from '@material-ui/core/colors';
import { Form, Field } from 'react-final-form';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FinalFormTextField } from '../../common/formWrappers';
import { required, email, composeValidators } from '../../common/formValidators';
import * as AccountApi from '../../api/account';
import Copyright from '../copyright';
import { useAuth } from '../../auth/auth-context';
import { isDev } from '../../common/logic/environment';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    wrapper: {
        position: 'relative',
        margin: theme.spacing(1)
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    caption: {
        marginTop: 5,
        marginBottom: theme.spacing(2)
    }
}));

export default function AccountInactiveForm() {
    const [loading, setIsLoading] = useState();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { login } = useAuth();

    async function save(values) {
        try {
            setIsLoading(true);
            await AccountApi.reactivateAccount(values);
            await login(values.emailAddress, values.password, values.tenantId);
            enqueueSnackbar('Account has been reactivated!', { variant: 'success' });
            navigate('/');
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Typography variant="h1">Account Inactive</Typography>
                <Typography variant="body1" className={classes.caption}>
                    You have deactivated your account. Do you want to reactivate? Please enter your credentials to confirm.
                </Typography>
                <Form onSubmit={values => save(values)}>
                    {props => (
                        <form className={classes.form} noValidate onSubmit={props.handleSubmit}>
                            <Field
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                name="emailAddress"
                                label="Email Address"
                                type="text"
                                id="emailAddress"
                                autoComplete="off"
                                component={FinalFormTextField}
                                validate={composeValidators(required, email)}
                            />
                            <Field
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                id="password"
                                maxLength={32}
                                autoComplete="off"
                                password
                                component={FinalFormTextField}
                                validate={required}
                            />
                            {isDev() && (
                                <Field
                                    name="tenantId"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    label="Tenant Id"
                                    id="tenantId"
                                    initialValue="1"
                                    component={FinalFormTextField}
                                />
                            )}
                            <div className={classes.wrapper}>
                                <Button type="submit" fullWidth variant="contained" color="primary" disabled={loading}>
                                    Reactivate Account
                                </Button>
                                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                        </form>
                    )}
                </Form>
            </div>
            <Copyright />
        </Container>
    );
}
