import React from 'react';
import { Router } from '@gatsbyjs/reach-router';

import SecureRoute from '../common/secureRoute';
import HomePage from './pages/home';
import DebugPage from './admin/debugPage';
import UsersPage from './admin/users';
import RolesPage from './admin/roles';
import ThemePage from './admin/theme';
import EligibleStudentsPage from './admin/eligibleStudents';
import InstitutionsPage from './admin/institutions';
import RegisterPage from '../anonymous/registerPage';
import ResetPasswordPage from '../anonymous/resetPassword';
import ForgotPasswordPage from '../anonymous/forgotPassword';
import AccountVerification from '../anonymous/accountVerification';
import CompleteAccountSetupPage from '../anonymous/completeAccountSetupPage';
import ChangeEmailAddressPage from '../anonymous/changeEmailAddress';
import GrantSettingsPage from './admin/grantSettings';
import StudentApplicationsPage from './pages/studentApplications';
import StudentHome from './pages/home/studentHome';
import ApplicantsPage from './pages/applicants';
import ApplicantPage from './pages/applicant';
import AppConfigPage from './admin/appConfig';
import JobsPage from './admin/jobs';
import CertificationsPage from './pages/scholarship/certifications';
import CertifyPage from './pages/scholarship/certifications/certifyPage';
import FinancialsPage from './pages/scholarship/financials';
import {
    PERMISSION_ELIGIBLESTUDENTS,
    PERMISSION_APPCONFIG,
    PERMISSION_GRANTSETTINGS_EDIT,
    PERMISSION_INSTITUTIONS,
    PERMISSION_PAGES_ROLES,
    PERMISSION_PAGES_USERS,
    PERMISSION_THEMES,
    PERMISSION_MANAGE_JOBS,
    PERMISSION_PAGES_APPLICANTS,
    PERMISSION_REPORTS
} from '../common/logic/permissions';
import ReportsHome from './pages/reports';
import CertifiedStudentsSummary from './pages/reports/certifiedStudentsSummary';
import GrantSummary from './pages/reports/grantSummary';
import SchoolGrantSummary from './pages/reports/schoolGrantSummary';
import StudentPaymentHistory from './pages/reports/studentPaymentHistory';
import NotFoundPage from '../anonymous/notFoundPage';

export default function TenantMain() {
    return (
        <Router>
            <SecureRoute as={HomePage} path="/" />
            <SecureRoute as={StudentHome} path="/profile" />
            <SecureRoute as={ApplicantsPage} path="/applicants" permissionSet={[PERMISSION_PAGES_APPLICANTS]} />
            <SecureRoute as={ApplicantPage} path="/applicants/:applicationId" />
            <SecureRoute as={StudentApplicationsPage} path="/applications" />
            <SecureRoute as={JobsPage} path="/admin/jobs" permissionSet={[PERMISSION_MANAGE_JOBS]} />
            <SecureRoute as={UsersPage} path="/admin/users" permissionSet={[PERMISSION_PAGES_USERS]} />
            <SecureRoute as={RolesPage} path="/admin/roles" permissionSet={[PERMISSION_PAGES_ROLES]} />
            <SecureRoute as={ThemePage} path="/admin/theme" permissionSet={[PERMISSION_THEMES]} />
            <SecureRoute as={DebugPage} path="/admin/debug" />
            <SecureRoute as={InstitutionsPage} path="/admin/institutions" permissionSet={[PERMISSION_INSTITUTIONS]} />
            <SecureRoute as={EligibleStudentsPage} path="/admin/eligible-students" permissionSet={[PERMISSION_ELIGIBLESTUDENTS]} />
            <SecureRoute as={GrantSettingsPage} path="/admin/grant-settings" permissionSet={[PERMISSION_GRANTSETTINGS_EDIT]} />
            <SecureRoute as={AppConfigPage} path="/admin/app-config" permissionSet={[PERMISSION_APPCONFIG]} />

            <SecureRoute as={CertificationsPage} path="/scholarship/certify" />
            <SecureRoute as={FinancialsPage} path="/financials" />
            <SecureRoute as={CertifyPage} path="/scholarship/certify/:applicationId" />

            <SecureRoute as={ReportsHome} path="/reports" permissionSet={[PERMISSION_REPORTS]} />
            <SecureRoute as={CertifiedStudentsSummary} path="/reports/certified-students-summary" permissionSet={[PERMISSION_REPORTS]} />
            <SecureRoute as={GrantSummary} path="/reports/grant-summary" permissionSet={[PERMISSION_REPORTS]} />
            <SecureRoute as={SchoolGrantSummary} path="/reports/school-grant-summary" permissionSet={[PERMISSION_REPORTS]} />
            <SecureRoute as={StudentPaymentHistory} path="/reports/student-payment-history" permissionSet={[PERMISSION_REPORTS]} />

            <CompleteAccountSetupPage path="/complete-account-setup" />
            <ResetPasswordPage path="/reset-password" />
            <AccountVerification path="/verify" />
            <RegisterPage path="/register" />
            <ForgotPasswordPage path="/forgot-password" />
            <ChangeEmailAddressPage path="/change-email" />

            <NotFoundPage default />
        </Router>
    );
}
