import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import Providers from './Providers';
import * as serviceWorker from './serviceWorker';
import CacheBuster from './cacheBuster';
import PageLoader from './common/pageLoader';

ReactDOM.render(
    <Providers>
        <CacheBuster>
            {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                if (loading) return <PageLoader />;
                if (!loading && !isLatestVersion) {
                    // You can decide how and when you want to force reload
                    refreshCacheAndReload();
                }

                return <App />;
            }}
        </CacheBuster>
    </Providers>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
