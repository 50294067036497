import React, { useState, useEffect } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

import * as VersionApi from '../api/version';

const useStyles = makeStyles(theme => ({
    footer: {
        top: 'auto',
        bottom: 0
    }
}));

export default function HostFooter() {
    const [version, setVersion] = useState('');
    const classes = useStyles();

    useEffect(() => {
        async function fetchData() {
            const ver = (await VersionApi.getAppVersion()).data.result;
            setVersion(ver);
        }
        fetchData();
    }, [version]);

    return (
        <AppBar position="fixed" className={classes.footer}>
            <Toolbar>
                <span>Version {version}</span>
            </Toolbar>
        </AppBar>
    );
}
