import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import LockIcon from '@material-ui/icons/Lock';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { useSnackbar } from 'notistack';
import FilledInput from '@material-ui/core/FilledInput';

import { conformedPhone } from '../../../common/masks';
import * as UserApi from '../../../api/user';
import * as RoleApi from '../../../api/role';
import { useAuth } from '../../../auth/auth-context';
import EditDialog from './editDialog';
import AdminChangePasswordDialog from './adminChangePasswordDialog';
import { resendVerificationEmail } from '../../../api/account';
import ToolbarSearchInput from '../../../common/toolbarSearchInput';
import CustomDataGrid from '../../../common/customDataGrid';

const useStyles = makeStyles((theme) => ({
    leftIcon: {
        marginRight: theme.spacing(1)
    },
    formControl: {
        minWidth: 120,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25)
        }
    },
    underline: {
        '&:after': {
            borderBottom: `2px solid ${theme.palette.secondary.contrastText}`
        }
    },
    selectInput: {
        color: theme.palette.secondary.contrastText
    },
    selectLabel: {
        color: theme.palette.secondary.contrastText,
        '&.Mui-focused': {
            color: theme.palette.secondary.contrastText,
            fontWeight: 700
        }
    },
    menuButton: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
            backgroundColor: lighten(theme.palette.primary.main, 0.5),
            color: theme.palette.getContrastText(lighten(theme.palette.primary.main, 0.5))
        }
    }
}));

const sortModel = [{ field: 'name', sort: 'asc' }];

export default function UserList() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showReset, setShowReset] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [roles, setRoles] = useState([]);
    const { user: currentUser, tenant, impersonate } = useAuth();
    const classes = useStyles();
    const [searchText, setSearchText] = useState('');
    const [filterData, setFilterData] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const [menuAnchor, setMenuAnchor] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const tokenSourceUsers = axios.CancelToken.source();
        const tokenSourceRoles = axios.CancelToken.source();

        async function fetchData() {
            setIsLoading(true);
            try {
                const items = (await UserApi.getAll('', null, 0, 10000, tokenSourceUsers.token)).data.result.items;
                const roleData = (await RoleApi.getAll('', 0, 10000, tokenSourceRoles.token)).data.result.items;
                const roles = roleData.map((role) => ({ label: role.name, value: role.normalizedName }));
                setData(items);
                setRoles(roles);
            } catch (error) {
            } finally {
                setIsLoading(false);
            }
        }
        fetchData();

        return () => {
            tokenSourceUsers.cancel();
            tokenSourceRoles.cancel();
        };
    }, [showEdit]);

    useEffect(() => {
        const newData = data
            .filter(
                (d) =>
                    d.name.toLowerCase().includes(searchText.toLowerCase()) ||
                    d.surname.toLowerCase().includes(searchText.toLowerCase()) ||
                    d.emailAddress.toLowerCase().includes(searchText.toLowerCase()) ||
                    (d.phoneNumber && d.phoneNumber.includes(searchText))
            )
            .filter((d) => selectedRole === '' || d.roleNames === selectedRole);
        setFilterData(newData);
    }, [data, searchText, selectedRole]);

    async function handleChangePassword({ adminPassword, id, newPassword }) {
        try {
            setIsSaving(true);
            await UserApi.changePassword(adminPassword, id, newPassword);
            enqueueSnackbar('Password changed successfully.', { variant: 'success' });
            setShowReset(false);
            setSelectedUser(null);
        } finally {
            setIsSaving(false);
        }
    }

    const handleResendVerification = async () => {
        const emailAddress = selectedUser.emailAddress;

        try {
            handleCloseMenu();
            setIsSaving(true);
            await resendVerificationEmail(emailAddress);
            enqueueSnackbar(`A new verification email has been sent to ${emailAddress}.`, { variant: 'success' });
        } catch {
            enqueueSnackbar(`Failed to send verification email to ${emailAddress}.`, { variant: 'error' });
        } finally {
            setIsSaving(false);
        }
    };

    const handleShowEdit = () => {
        setMenuAnchor(null);
        setShowEdit(true);
    };

    const handleShowChangePassword = () => {
        setMenuAnchor(null);
        setShowReset(true);
    };

    const handleImpersonate = () => {
        setMenuAnchor(null);
        impersonate(tenant.id, selectedUser.id);
    };

    const handleCloseMenu = () => {
        setMenuAnchor(null);
        setSelectedUser(null);
    };

    const handleOpenMenu = (e, row) => {
        setMenuAnchor(e.currentTarget);
        setSelectedUser(row);
    };

    async function saveUser(user) {
        setIsSaving(true);
        try {
            if (user.id) {
                await UserApi.update(user);
            } else {
                await UserApi.create(user);
            }
            if (user.id === currentUser.id) {
                window.location.reload(true);
            }
            if (user.id) {
                enqueueSnackbar('User updated successfully.', { variant: 'success' });
            } else {
                enqueueSnackbar('User saved successfully.', { variant: 'success' });
            }
            setShowEdit(false);
            setSelectedUser(null);
        } catch (error) {
        } finally {
            setIsSaving(false);
        }
    }

    const columns = [
        { field: 'name', headerName: 'First Name', flex: 1 },
        { field: 'surname', headerName: 'Last Name', flex: 1 },
        { field: 'emailAddress', headerName: 'Email', flex: 1 },
        { field: 'phoneNumber', headerName: 'Mobile', flex: 1, valueFormatter: ({ value }) => (value ? conformedPhone(value) : '') },
        { field: 'roleNames', headerName: 'Role', flex: 1 },
        { field: 'isActive', headerName: 'Active', renderCell: ({ row }) => (row.isActive ? <DoneIcon color="primary" /> : ' '), width: 100 },
        {
            field: 'actions',
            headerName: 'Action',
            width: 100,
            sortable: false,
            renderCell: ({ row }) => (
                <IconButton className={classes.menuButton} onClick={(e) => handleOpenMenu(e, row)}>
                    <MoreVertIcon />
                </IconButton>
            )
        }
    ];

    return (
        <div>
            <Box p={1} color="secondary.contrastText" bgcolor="secondary.main">
                <Toolbar variant="dense">
                    <Box display="flex" flex={1} alignItems="center" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                            <Typography variant="h6">Users</Typography>
                            <ToolbarSearchInput value={searchText} onChange={(val) => setSearchText(val)} />
                            <FormControl variant="filled" className={classes.formControl} margin="dense">
                                <InputLabel shrink id="select-role-label" className={classes.selectLabel}>
                                    Role
                                </InputLabel>
                                <Select
                                    classes={{ root: classes.searchRoot }}
                                    id="select-role"
                                    labelid="select-role-label"
                                    value={selectedRole}
                                    displayEmpty
                                    onChange={(evt) => setSelectedRole(evt.target.value)}
                                    input={<FilledInput classes={{ underline: classes.underline, input: classes.selectInput }} />}
                                >
                                    <MenuItem value="">All Roles</MenuItem>
                                    {roles.map((role) => (
                                        <MenuItem key={role.value} value={role.value}>
                                            {role.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <IconButton
                            color="inherit"
                            onClick={() => {
                                setSelectedUser(null);
                                setShowEdit(true);
                            }}
                        >
                            <AddIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </Box>
            <CustomDataGrid
                disableColumnMenu
                disableColumnFilter
                initialSortModel={sortModel}
                disableSelectionOnClick
                rows={filterData}
                rowHeight={75}
                columns={columns}
                initialPageSize={25}
                loading={isLoading}
                rowsPerPageOptions={[25, 50, 100]}
            />
            {showEdit && (
                <EditDialog
                    open={showEdit}
                    handleClose={() => (isSaving ? undefined : setShowEdit(false))}
                    user={selectedUser}
                    isSaving={isSaving}
                    handleSave={saveUser}
                    roles={roles}
                />
            )}
            <AdminChangePasswordDialog
                open={showReset}
                handleClose={() => (isSaving ? undefined : setShowReset(false))}
                id={selectedUser ? selectedUser.id : undefined}
                isSaving={isSaving}
                handleSave={handleChangePassword}
                userName={selectedUser ? selectedUser.fullName : ''}
            />
            {selectedUser && (
                <Menu id="simple-menu" anchorEl={menuAnchor} keepMounted open={Boolean(menuAnchor)} onClose={handleCloseMenu}>
                    <MenuItem onClick={handleShowEdit}>
                        <CreateIcon className={classes.leftIcon} fontSize="small" color="action" />
                        Edit
                    </MenuItem>
                    {selectedUser.userName !== currentUser.userName && (
                        <MenuItem onClick={handleImpersonate}>
                            <SupervisorAccount className={classes.leftIcon} fontSize="small" color="action" />
                            Impersonate
                        </MenuItem>
                    )}
                    {selectedUser.userName !== currentUser.userName && (
                        <MenuItem onClick={handleShowChangePassword}>
                            <LockIcon className={classes.leftIcon} fontSize="small" color="action" />
                            Change Password
                        </MenuItem>
                    )}
                    {!selectedUser.isEmailConfirmed && (
                        <MenuItem onClick={handleResendVerification}>
                            <VerifiedUserIcon className={classes.leftIcon} fontSize="small" color="action" />
                            Resend Verification
                        </MenuItem>
                    )}
                </Menu>
            )}
        </div>
    );
}
