import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'flex-start',
        padding: theme.spacing(4),
        width: 1024,
        alignItems: 'center'
    },
    icon: {
        marginRight: theme.spacing(1)
    }
}));

export default function AccessDeniedPage() {
    const classes = useStyles();
    return (
        <Paper className={classes.root}>
            <ErrorOutlineIcon className={classes.icon} />
            <Typography variant="body1">Access Denied</Typography>
        </Paper>
    );
}
