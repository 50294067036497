import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { green } from '@material-ui/core/colors';

import { FinalFormTextField, FinalFormSwitch, FinalFormDropdown, WhenFieldChanges, Condition } from '../../../common/formWrappers';
import { required, email, maxLength, composeValidators, exactLength } from '../../../common/formValidators';
import FinalFormRadioGroup from '../../../common/formWrappers/radioGroup';
import { ROLE_NAME_ADMIN, ROLE_NAME_FINANCE, ROLE_NAME_INSTITUTION, ROLE_NAME_STUDENT } from '../../../common/logic/consts';
import { PhoneMaskInput } from '../../../common/masks';
import * as InstitutionApi from '../../../api/institution';
import { useAuth } from '../../../auth/auth-context';
import { OnChange } from 'react-final-form-listeners';

const useStyles = makeStyles((theme) => ({
    buttonWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

const matchesEmail = (email) => (value) => value !== email ? 'Emails must match' : undefined;

export default function EditDialog({ user, open, handleClose, handleSave, isSaving, roles }) {
    const classes = useStyles();
    const { user: currentUser } = useAuth();
    const [institutions, setInstitutions] = useState([]);

    useEffect(() => {
        const tokenSourceInstitutions = axios.CancelToken.source();

        (async () => {
            try {
                const items = (await InstitutionApi.getAll('name', 0, 1000)).data.result;
                const institutions = items.items.map((i) => ({ value: i.id, label: i.name }));
                setInstitutions(institutions);
            } catch {}
        })();

        return () => {
            tokenSourceInstitutions.cancel();
        };
    }, []);

    return (
        <Dialog open={open} onClose={handleClose} disableBackdropClick disableEscapeKeyDown>
            <DialogTitle>{!user ? 'Add User' : `Edit User '${user.name} ${user.surname}'`}</DialogTitle>
            <DialogContent>
                <Form onSubmit={(values) => handleSave(values)} initialValues={user} mutators={{ ...arrayMutators }}>
                    {(props) => {
                        return (
                            <form noValidate onSubmit={props.handleSubmit}>
                                <WhenFieldChanges
                                    field="roleNames"
                                    becomes={[ROLE_NAME_ADMIN, ROLE_NAME_STUDENT, ROLE_NAME_FINANCE]}
                                    set="institutionId"
                                    to={undefined}
                                />
                                <Field
                                    component={FinalFormRadioGroup}
                                    label="Role"
                                    name="roleNames"
                                    id="role"
                                    choices={roles}
                                    row
                                    defaultValue={user ? undefined : ROLE_NAME_ADMIN}
                                    required
                                    disabled={user && user.rolesName === ROLE_NAME_ADMIN && user.id === currentUser.id}
                                    validate={required}
                                />
                                <Field
                                    name="name"
                                    variant="outlined"
                                    margin="dense"
                                    required
                                    fullWidth
                                    label="First Name"
                                    id="name"
                                    component={FinalFormTextField}
                                    validate={composeValidators(required, maxLength(64))}
                                />
                                <Field
                                    name="surname"
                                    variant="outlined"
                                    margin="dense"
                                    required
                                    fullWidth
                                    label="Last Name"
                                    id="surname"
                                    component={FinalFormTextField}
                                    validate={composeValidators(required, maxLength(64))}
                                />
                                <Field
                                    name="emailAddress"
                                    variant="outlined"
                                    margin="dense"
                                    required
                                    fullWidth
                                    label="Email"
                                    id="emailAddress"
                                    component={FinalFormTextField}
                                    validate={composeValidators(required, email, maxLength(256))}
                                />
                                <Field
                                    name="confirmEmailAddress"
                                    variant="outlined"
                                    margin="dense"
                                    required={
                                        (props.initialValues && props.values.emailAddress !== props.initialValues.emailAddress) ||
                                        !props.initialValues
                                    }
                                    fullWidth
                                    label="Confirm Email"
                                    id="confirmEmailAddress"
                                    component={FinalFormTextField}
                                    validate={
                                        (props.initialValues && props.values.emailAddress !== props.initialValues.emailAddress) ||
                                        !props.initialValues
                                            ? composeValidators(required, email, maxLength(256), matchesEmail(props.values.emailAddress))
                                            : undefined
                                    }
                                    key={props.values.emailAddress ? props.values.emailAddress.length : 0}
                                    //validate={composeValidators(required, email, maxLength(256), matchesEmail(props.values.emailAddress))}
                                />
                                <Field
                                    name="phoneNumber"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    label="Mobile Number"
                                    id="phoneNumber"
                                    component={FinalFormTextField}
                                    inputComponent={PhoneMaskInput}
                                    required={props.values && props.values.roleNames === ROLE_NAME_STUDENT}
                                    validate={composeValidators(required, exactLength(14, 'Invalid phone number'))}
                                />
                                <OnChange name="emailAddress">
                                    {(value) => {
                                        props.form.change('confirmEmailAddress', '');
                                    }}
                                </OnChange>
                                <Condition when="roleNames" is={ROLE_NAME_INSTITUTION}>
                                    <Field
                                        name="institutionId"
                                        variant="outlined"
                                        margin="dense"
                                        required
                                        fullWidth
                                        placeholder="Select an Institution..."
                                        label="Institution"
                                        id="institutionId"
                                        component={FinalFormDropdown}
                                        choices={institutions}
                                        validate={required}
                                    />
                                </Condition>
                                <FormControlLabel
                                    control={
                                        <Field
                                            name="isActive"
                                            value="isActive"
                                            component={FinalFormSwitch}
                                            color="primary"
                                            defaultValue={true}
                                            disabled={user && user.userName === 'admin'}
                                        />
                                    }
                                    label="Active"
                                />
                                <DialogActions>
                                    <Button onClick={handleClose} disabled={isSaving}>
                                        Cancel
                                    </Button>
                                    <div className={classes.buttonWrapper}>
                                        <Button type="submit" disabled={isSaving}>
                                            Save
                                        </Button>
                                        {isSaving && <CircularProgress className={classes.buttonProgress} size={24} />}
                                    </div>
                                </DialogActions>
                            </form>
                        );
                    }}
                </Form>
            </DialogContent>
        </Dialog>
    );
}
