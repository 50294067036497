import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { navigate } from '@gatsbyjs/reach-router';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import green from '@material-ui/core/colors/green';
import { Form, Field } from 'react-final-form';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FinalFormTextField } from '../formWrappers';
import { required } from '../formValidators';
import * as AccountApi from '../../api/account';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    wrapper: {
        position: 'relative',
        margin: theme.spacing(1)
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

export default function ChangePasswordPage() {
    const [loading, setIsLoading] = useState();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    async function save(values) {
        try {
            setIsLoading(true);
            await AccountApi.changePassword(values.currentPassword, values.newPassword);
            enqueueSnackbar('Password changed!', { variant: 'success' });
            navigate('/');
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Change Password
                </Typography>
                <Form onSubmit={(values) => save(values)}>
                    {(props) => (
                        <form className={classes.form} noValidate onSubmit={props.handleSubmit}>
                            <Field
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                name="currentPassword"
                                label="Current Password"
                                id="currentPassword"
                                maxLength={32}
                                autoComplete="off"
                                password
                                component={FinalFormTextField}
                                validate={required}
                            />
                            <Field
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                name="newPassword"
                                label="New Password"
                                id="newPassword"
                                maxLength={32}
                                autoComplete="off"
                                password
                                component={FinalFormTextField}
                                validate={required}
                            />
                            <Field
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                name="confirmPassword"
                                label="Confirm Password"
                                id="confirmPassword"
                                maxLength={32}
                                autoComplete="off"
                                password
                                component={FinalFormTextField}
                                validate={required}
                            />
                            <div className={classes.wrapper}>
                                <Button type="submit" fullWidth variant="contained" color="primary" disabled={loading}>
                                    Change Password
                                </Button>
                                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                        </form>
                    )}
                </Form>
            </div>
        </Container>
    );
}
