import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';

import { useAuth } from './auth/auth-context';
import { useIdleTimer } from 'react-idle-timer';

export default function useLogoutWhenIdle(idleTimeInMinutes) {
    const { user, logout } = useAuth();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const idleTimeInMS = idleTimeInMinutes * 60 * 1000;

    function clearTimeoutAction(key) {
        return (
            <Button color="default" variant="text" size="small" onClick={() => closeSnackbar(key)}>
                Dismiss
            </Button>
        );
    }

    const [snackbarKeys, setSnackbarKeys] = useState([]);

    const handleOnIdle = () => {
        localStorage.removeItem('trilix_idleTimeout');
        const key = enqueueSnackbar('You have been logged out due to inactivity', {
            variant: 'warning',
            persist: true,
            action: clearTimeoutAction
        });
        setSnackbarKeys((prev) => {
            const keys = [...prev];
            keys.push(key);

            return keys;
        });
        logout();
    };

    const { reset, pause, getRemainingTime } = useIdleTimer({
        timeout: idleTimeInMS,
        throttle: 250,
        onIdle: handleOnIdle,
        onAction: (e) => {
            if (e.type === 'visibilitychange' && e.target.hidden) {
                localStorage.setItem('trilix_idleTimeout', new Date().getTime() + getRemainingTime());
            }

            if (e.type === 'visibilitychange' && !e.target.hidden) {
                const now = new Date().getTime();
                const temp = localStorage.getItem('trilix_idleTimeout');
                if (temp < now) {
                    handleOnIdle();
                }
            }
        }
    });

    const closeOpenSnackbars = () => {
        snackbarKeys.forEach((key) => closeSnackbar(key));
    };

    useEffect(() => {
        if (user) {
            closeOpenSnackbars();
            reset();
        } else {
            pause();
        }
    }, [user, logout]);
}
