import axios from 'axios';

export function getAll(keyword = '', skipCount = 0, maxResultCount = 1000) {
    return axios.get('/api/services/app/Role/GetAll', { params: { keyword, skipCount, maxResultCount } });
}

export function getAllPermissions() {
    return axios.get('/api/services/app/Role/GetAllPermissions');
}

export function create(values) {
    return axios.post('/api/services/app/Role/Create', values);
}

export function update(values) {
    return axios.put('/api/services/app/Role/Update', values);
}

export function deleteRole(id) {
    return axios.delete('/api/services/app/Role/Delete', { params: { id } });
}
