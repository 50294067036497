import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import * as InstitutionApi from '../../../api/institution';
import * as ApplicationApi from '../../../api/applications';
import * as ProfileApi from '../../../api/profile';
import * as YearApi from '../../../api/year';
import { OTHER_INSTITUTION_VALUE } from '../../../common/logic/consts';
import { formatProfile } from '../home/profile';

const useApplicantData = (applicationId) => {
    const [loading, setLoading] = useState(false);
    const [applicantData, setApplicantData] = useState(null);
    const [institutions, setInstitutions] = useState([]);
    const [profile, setProfile] = useState(null);
    const [years, setYears] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    const loadData = useCallback(async () => {
        if (!applicationId) {
            return;
        }

        try {
            setLoading(true);
            const yearItems = (await YearApi.getAll(false)).data.result;
            const application = (await ApplicationApi.adminGet(applicationId)).data.result;
            const institutionItems = (await InstitutionApi.getAll('name', 0, 100)).data.result.items;
            const profileData = (await ProfileApi.get(application.userId)).data.result;
            const institutionOptions = institutionItems.map((i) => ({ label: i.name, value: i.id, active: i.isActive }));
            institutionOptions.push({ active: true, label: 'Other', value: OTHER_INSTITUTION_VALUE });

            setInstitutions(institutionOptions);
            setYears(yearItems);
            setApplicantData(application);
            setProfile(formatProfile(profileData));
            setLoading(false);
        } catch (error) {
            console.error(error);
            if (axios.isCancel(error)) {
                return;
            }
            setLoading(false);
        }
    }, [applicationId]);

    useEffect(() => {
        loadData();
    }, [applicationId]);

    const saveApplication = async (values) => {
        try {
            setLoading(true);
            await ApplicationApi.adminUpdate(values);
            const application = (await ApplicationApi.adminGet(applicationId)).data.result;
            setApplicantData(application);
            enqueueSnackbar('Application updated', { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
        } catch (ex) {
            enqueueSnackbar('Failed to update application', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
        } finally {
            setLoading(false);
        }
    };

    const saveProfile = async (values) => {
        try {
            setLoading(true);
            await ProfileApi.createOrUpdate(values);
            const application = (await ApplicationApi.adminGet(applicationId)).data.result;
            setApplicantData(application);
            enqueueSnackbar('Profile updated', { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
        } catch (ex) {
            enqueueSnackbar('Failed to update profile', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
        } finally {
            setLoading(false);
        }
    };

    return {
        loading,
        applicantData,
        institutions,
        profile,
        years,
        saveApplication,
        saveProfile,
        loadData
    };
};

export default useApplicantData;
