import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { ColorPickerType } from './colorPickerType';
import ColorPicker from './colorPicker';
import { AppThemeContext } from '../../../theme/theme-context';
import Switch from '@material-ui/core/Switch';
import { ThemeType } from './editor';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    colorContainer: {
        marginRight: 10
    },
    darkSwitch: {
        '&$checked': { color: '#555555' },
        '&$checked + $darkSwitchTrack': { backgroundColor: '#000000' }
    },
    darkSwitchTrack: {},
    checked: {},
    controlPanel: {
        position: 'relative',
        padding: '0 10px 10px',
        minHeight: '400px'
    },
    fab: { position: 'absolute', bottom: 10, right: 10 },
    fabLoading: { position: 'absolute', bottom: 20, right: 20 },
    swatchContainer: {
        padding: '5px',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1)',
        display: 'inline-block',
        cursor: 'pointer'
    },
    swatch: {
        width: '100px',
        height: '40px',
        borderRadius: '2px'
    }
}));

export default function ThemeControl({
    colorPickerAnchor,
    colorType,
    themeType,
    headerColor,
    primaryColor,
    secondaryColor,
    onColorClick,
    onColorClose,
    onChangeColor,
    onChangeThemeType
}) {
    const classes = useStyles();
    const { loading, setTheme } = useContext(AppThemeContext);
    const { enqueueSnackbar } = useSnackbar();

    return (
        <Grid container direction="column" alignItems="flex-start" className={classes.controlPanel}>
            <h1>Settings</h1>
            <Grid container alignItems="center" spacing={1} direction="row">
                <Grid item>
                    <Grid container alignItems="center">
                        <Grid item>Light</Grid>
                        <Grid item>
                            <Switch
                                checked={themeType === ThemeType.Dark}
                                onChange={() => onChangeThemeType(themeType === ThemeType.Dark ? ThemeType.Light : ThemeType.Dark)}
                                classes={{ colorSecondary: classes.darkSwitch, checked: classes.checked, track: classes.darkSwitchTrack }}
                            />
                        </Grid>
                        <Grid item>Dark</Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.colorContainer}>
                    <div className={classes.swatchContainer} onClick={(e) => onColorClick(ColorPickerType.headerColor, e)}>
                        <span>Header Color</span>
                        <div
                            className={classes.swatch}
                            style={{
                                background: headerColor
                            }}
                        />
                    </div>
                    <ColorPicker
                        showColorPicker={colorType === ColorPickerType.headerColor}
                        color={headerColor}
                        onClose={onColorClose}
                        onChange={onChangeColor}
                        colorPickerAnchor={colorPickerAnchor}
                    />
                </Grid>
                <Grid item className={classes.colorContainer}>
                    <div className={classes.swatchContainer} onClick={(e) => onColorClick(ColorPickerType.primaryColor, e)}>
                        <span>Primary Color</span>
                        <div
                            className={classes.swatch}
                            style={{
                                background: primaryColor
                            }}
                        />
                    </div>
                    <ColorPicker
                        showColorPicker={colorType === ColorPickerType.primaryColor}
                        color={primaryColor}
                        onClose={onColorClose}
                        onChange={onChangeColor}
                        colorPickerAnchor={colorPickerAnchor}
                    />
                </Grid>
                <Grid item className={classes.colorContainer}>
                    <div className={classes.swatchContainer} onClick={(e) => onColorClick(ColorPickerType.secondaryColor, e)}>
                        <span>Secondary Color</span>
                        <div
                            className={classes.swatch}
                            style={{
                                background: secondaryColor
                            }}
                        />
                    </div>
                    <ColorPicker
                        showColorPicker={colorType === ColorPickerType.secondaryColor}
                        color={secondaryColor}
                        onClose={onColorClose}
                        onChange={onChangeColor}
                        colorPickerAnchor={colorPickerAnchor}
                    />
                </Grid>
            </Grid>
            {loading ? (
                <CircularProgress className={classes.fabLoading} />
            ) : (
                <Fab
                    color="primary"
                    aria-label="save"
                    disabled={loading}
                    className={classes.fab}
                    onClick={async () => {
                        await setTheme({ themeType, primaryColor, secondaryColor, headerColor });
                        enqueueSnackbar('Theme Saved', { variant: 'success' });
                    }}
                >
                    <SaveIcon />
                </Fab>
            )}
        </Grid>
    );
}
