import { getContrastText } from '../common/logic/colorHelper';
import { DEFAULT_CONTRAST_THRESHOLD } from '../common/logic/consts';

const DefaultTheme = ({ themeType = 'light', primaryColor, secondaryColor, headerColor }) => ({
    palette: {
        type: themeType,
        header: {
            main: headerColor,
            contrastText: getContrastText(headerColor, DEFAULT_CONTRAST_THRESHOLD)
        },
        primary: {
            main: primaryColor,
            contrastText: getContrastText(primaryColor, DEFAULT_CONTRAST_THRESHOLD)
        },
        secondary: {
            main: secondaryColor,
            contrastText: getContrastText(secondaryColor, DEFAULT_CONTRAST_THRESHOLD)
        },
        success: {
            main: '#2ac458'
        },
        error: {
            main: '#f44336'
        },
        warning: {
            main: '#f4a936'
        },
        info: {
            main: '#1d98c9'
        },
        inputDisabled: {
            main: '#efefef'
        }
    },
    typography: {
        h4: {
            fontWeight: 500,
            fontSize: '1.15rem'
        },
        h6: {
            fontSize: '1.5rem'
        }
    },
    spacing: 10,
    overrides: {
        MuiFormLabel: {
            root: {
                fontSize: '0.8rem',
                fontWeight: '500',
                color: 'rgba(0, 0, 0, 0.87)'
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: '0.725rem'
            }
        },
        MuiTypography: {
            colorTextSecondary: {
                color: 'rgba(0, 0, 0, 0.60)'
            }
        }
    }
});

export default DefaultTheme;
