import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import makeStyles from '@material-ui/styles/makeStyles';
import { Form, Field } from 'react-final-form';

import { FinalFormTextField } from '../../../../common/formWrappers';
import { email, composeValidators } from '../../../../common/formValidators';
import LoadingButton from '../../../../common/loadingButton';

const useStyles = makeStyles((theme) => ({
    changeEmailDialog: {}
}));

const ChangeEmailDialog = ({ open, onClose, onOk, loading, initialEmailAddress }) => {
    const classes = useStyles();

    const emailSame = (value) => {
        if (value.toLowerCase() === initialEmailAddress.toLowerCase()) {
            return 'Email cannot be the same as previous email';
        }

        return undefined;
    };

    return (
        <Dialog className={classes.changeEmailDialog} open={open} title="Update Email" onClose={onClose} maxWidth="sm" fullWidth>
            <Form onSubmit={(values) => onOk(values)}>
                {(props) => (
                    <form noValidate onSubmit={props.handleSubmit} className={classes.form}>
                        <DialogContent>
                            <Field
                                margin="dense"
                                fullWidth
                                name="emailAddress"
                                label="Email Address"
                                placeholder="Email Address"
                                type="text"
                                id="emailAddress"
                                initialValue={initialEmailAddress}
                                autoComplete="off"
                                component={FinalFormTextField}
                                validate={composeValidators(email, emailSame)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose} color="primary" variant="outlined" disabled={loading}>
                                Cancel
                            </Button>
                            <LoadingButton type="submit" color="primary" variant="contained" loading={loading}>
                                Update
                            </LoadingButton>
                        </DialogActions>
                    </form>
                )}
            </Form>
        </Dialog>
    );
};

export default ChangeEmailDialog;
