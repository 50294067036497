import React from 'react';
import MaskedInput, { conformToMask } from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const defaultCurrencyMaskOptions = {
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 7, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false
};

const PhoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
const SsnMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
const ZipMask = (val) => {
    val = val.split('-').join('');
    if (val.length > 5) {
        return [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }
    return [/\d/, /\d/, /\d/, /\d/, /\d/];
};
const DateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

const conformedCurrency = (value, maskOptions) =>
    conformToMask(value, createNumberMask({ ...defaultCurrencyMaskOptions, ...maskOptions })).conformedValue;
const conformedPhone = (phoneNumber) => conformToMask(phoneNumber, PhoneMask, { guide: false }).conformedValue;
const conformedZip = (zip) => conformToMask(zip, ZipMask, { guide: false }).conformedValue;
const conformedSsn = (ssn) => conformToMask(ssn, SsnMask, { guide: false }).conformedValue;

const parseCurrency = (value) => {
    return value.replace(/[^0-9.-]+/g, '');
};

const PhoneMaskInput = (props) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={PhoneMask}
            guide={false}
            placeholder="(123) 456-7890"
        />
    );
};

const ZipMaskInput = (props) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={ZipMask}
            guide={false}
            placeholder="00000-0000"
        />
    );
};

const SsnMaskInput = (props) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={SsnMask}
            guide={false}
            placeholder="000-00-0000"
        />
    );
};

const DateMaskInput = (props) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={DateMask}
            guide={false}
            placeholder="02/22/2010"
        />
    );
};

const CurrencyMaskInput = ({ maskOptions, ...inputProps }) => {
    const { inputRef, ...other } = inputProps;
    const currencyMask = createNumberMask({
        ...defaultCurrencyMaskOptions,
        ...maskOptions
    });

    return (
        <MaskedInput
            mask={currencyMask}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            {...other}
        />
    );
};

export {
    PhoneMaskInput,
    ZipMaskInput,
    SsnMaskInput,
    conformedPhone,
    conformedZip,
    conformedSsn,
    conformedCurrency,
    parseCurrency,
    DateMaskInput,
    CurrencyMaskInput
};
