import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';

import enumHelper from '../../../../../common/logic/enumHelper';

const useStyles = makeStyles((theme) => ({
    flagSelect: {},
    label: {
        marginBottom: theme.spacing(1)
    },
    required: {
        color: 'red'
    },
    disabled: {
        backgroundColor: '#efefef'
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
    },
    transformOrigin: {
        vertical: 'bottom',
        horizontal: 'center'
    },
    variant: 'menu',
    getContentAnchorEl: null,
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
            width: 325
        }
    }
};

const FlagSelect = ({ enumDefinition, label, required, disabled, meta, placeholder, input: { value, onChange, ...otherInput }, ...otherProps }) => {
    const classes = useStyles();

    const enumValues = enumHelper.getSelectOptionsList(enumDefinition);
    const valueArray = [];
    enumValues.forEach((val) => {
        if ((value & val.value) === val.value) {
            valueArray.push(val.value);
        }
    });

    const handleChange = (event) => {
        let newValue = 0;
        event.target.value.forEach((val) => {
            newValue |= val;
        });

        onChange(newValue);
    };

    return (
        <FormControl className={classes.flagSelect} error={meta.error && meta.touched} disabled={disabled}>
            {label && (
                <FormLabel required={required} className={classes.label} classes={{ asterisk: classes.required }} disabled={disabled}>
                    {label}
                </FormLabel>
            )}
            <Select
                className={disabled ? classes.disabled : undefined}
                multiple
                displayEmpty
                renderValue={(items) => {
                    return placeholder !== undefined && items.length === 0
                        ? placeholder
                        : items.length === 1
                        ? enumValues.find((e) => e.value === items[0]).label
                        : `(${items.length}) items selected`;
                }}
                value={valueArray}
                onChange={handleChange}
                input={<OutlinedInput placeholder={placeholder} />}
                MenuProps={MenuProps}
                disabled={disabled}
                {...otherProps}
                {...otherInput}
            >
                {enumValues.map((val) => (
                    <MenuItem key={val.value} value={val.value}>
                        <Checkbox color="primary" checked={valueArray.indexOf(val.value) > -1} />
                        <ListItemText primary={val.label} />
                    </MenuItem>
                ))}
            </Select>
            {meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
    );
};

export default FlagSelect;
