import React from 'react';
import { makeStyles } from '@material-ui/styles';
import TenantHeader from './tenantHeader';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    content: {
        marginTop: 58,
        display: 'flex',
        flexDirection: 'column'
    }
}));

export default function TenantLayout({ children }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <TenantHeader />
            <div className={classes.content}>{children}</div>
        </div>
    );
}
