import React from 'react';
import Paper from '@material-ui/core/Paper';

import HostLayout from '../../hostLayout';
import TenantList from './list';

export default function TenantPage() {
    return (
        <HostLayout>
            <Paper>
                <TenantList />
            </Paper>
        </HostLayout>
    );
}
