import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import makeStyles from '@material-ui/styles/makeStyles';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import { Field, Form } from 'react-final-form';
import moment from 'moment';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { useSnackbar } from 'notistack';

import { FinalFormCheckbox, FinalFormDropdown, FinalFormTextField } from '../../../../../common/formWrappers';
import { composeValidators, isDate, required } from '../../../../../common/formValidators';
import LoadingButton from '../../../../../common/loadingButton';
import { DateMaskInput } from '../../../../../common/masks';
import { renderAmount } from '../common';
import { FormHelperText, LinearProgress, TableContainer } from '@material-ui/core';
import enumHelper from '../../../../../common/logic/enumHelper';
import { PaymentType, State, TransactionType } from '../../../../../common/logic/enums';
import * as TransactionApi from '../../../../../api/transactions';

const useStyles = makeStyles((theme) => ({
    submitDialog: {},
    tableHead: {
        backgroundColor: theme.palette.secondary.main,
        '& .MuiTableCell-stickyHeader': {
            backgroundColor: theme.palette.secondary.main
        }
    },
    checkbox: {
        '&.Mui-checked.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.26)'
        },
        '&.Mui-checked': {
            color: theme.palette.success.main
        }
    },
    noMargin: {
        margin: 0
    },
    entryDate: {
        color: theme.palette.success.main
    },
    formLabel: {
        marginBottom: theme.spacing(1)
    },
    transactionsErrorLabel: {
        color: theme.palette.error.main
    },
    transactionTableError: {
        border: `1px solid ${theme.palette.error.main}`
    }
}));

const SubmitDialog = ({ open, type, onClose, onSave, selectedInstitution, selectedYear, transaction, refund, isSaving }) => {
    const classes = useStyles();
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const tokenSource = axios.CancelToken.source();
        if (!transaction && !refund) {
            (async () => {
                try {
                    setLoading(true);
                    const response = await TransactionApi.getAll(selectedInstitution.id, selectedYear, true, tokenSource.token);
                    const data = response.data.result.items.map((t) => ({ ...t, selected: true }));
                    setTransactions(data);
                } catch (ex) {
                    enqueueSnackbar('Error fetching transactions', { variant: 'error' });
                    console.error(ex);
                } finally {
                    setLoading(false);
                }
            })();
        }
        return () => {
            tokenSource.cancel();
        };
    }, [selectedInstitution]);

    const handleSelectAll = ({ form, values }) => {
        const allSelected = getSelectAll(values);
        for (let i = 0; i < values.transactions.length; i++) {
            form.change(`transactions[${i}].selected`, !allSelected);
        }
    };

    const getSelectAll = (values) => {
        if (values.transactions.length === 0) {
            return false;
        }

        if (values.transactions.some((i) => !i.selected)) {
            return false;
        }

        return true;
    };

    const handleValidateTransactions = (values) => {
        if (!values.some((t) => t.selected)) {
            return 'At least one transaction is required';
        }
    };

    const initialValues = useMemo(() => {
        if (transaction) {
            return {
                ...transaction,
                paymentDate: moment(transaction.paymentDate).utc().format('MM/DD/YYYY'),
                transactions: [...transaction.transactions.map((t) => ({ ...t, selected: true }))]
            };
        } else if (refund) {
            return {
                ...refund,
                paymentDate: moment(refund.paymentDate).utc().format('MM/DD/YYYY'),
                transactions: [...refund.transactions.map((t) => ({ ...t, selected: true }))]
            };
        } else {
            return { paymentDate: moment().format('MM/DD/YYYY'), transactions };
        }
    }, [transaction, refund, transactions]);

    const transactionTypeText = useMemo(() => {
        return type === TransactionType.PendingRefund || type === TransactionType.Refund
            ? enumHelper.getDisplayName(TransactionType, TransactionType.Refund)
            : type === TransactionType.Payment || type === TransactionType.Update
            ? enumHelper.getDisplayName(TransactionType, TransactionType.Payment)
            : 'Unknown';
    }, [type]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth disableBackdropClick>
            <DialogTitle>
                {Boolean(refund) ? 'Receive Refund' : type === TransactionType.Update ? 'View Transaction' : `Submit ${transactionTypeText}`}
            </DialogTitle>
            <DialogContent>
                <Form onSubmit={(values) => onSave({ ...values, type })} initialValues={initialValues} mutators={{ ...arrayMutators }}>
                    {(props) => (
                        <form onSubmit={props.handleSubmit} noValidate>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <FormLabel>Entry Date</FormLabel>
                                    <Typography className={classes.entryDate} variant="body2">
                                        {transaction || refund ? moment(props.values.creationTime).format('M/D/YYYY') : moment().format('M/D/YYYY')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Field
                                        name="paymentDate"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        required
                                        label={`${transactionTypeText} Date`}
                                        id="paymentDate"
                                        component={FinalFormTextField}
                                        validate={composeValidators(required, isDate)}
                                        inputComponent={DateMaskInput}
                                        // disabled={Boolean(transaction) || Boolean(refund)}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Field
                                        name="paymentType"
                                        margin="dense"
                                        fullWidth
                                        required
                                        label={`${transactionTypeText} Type`}
                                        id="paymentType"
                                        choices={enumHelper.getSelectOptionsList(PaymentType).filter((p) => p.value !== PaymentType.None)}
                                        component={FinalFormDropdown}
                                        validate={required}
                                        disabled={Boolean(transaction) || Boolean(refund)}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Field
                                        name="paymentId"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        required
                                        label="Payment ID"
                                        maxLength={25}
                                        id="paymentId"
                                        component={FinalFormTextField}
                                        validate={required}
                                        disabled={Boolean(transaction) || Boolean(refund)}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormLabel>Total</FormLabel>
                                    <Typography variant="body1" style={{ lineHeight: '40px' }}>
                                        {renderAmount({
                                            value: props.values.transactions
                                                .filter((t) => t.selected)
                                                .map((t) => t.amount)
                                                .reduce((a, b) => a + b, 0)
                                                .toString()
                                        })}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={5}>
                                    <Field
                                        name="notes"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        maxLength={500}
                                        label="Notes"
                                        id="notes"
                                        component={FinalFormTextField}
                                    />
                                </Grid>
                                {type === TransactionType.Payment && (
                                    <Grid item xs={5}>
                                        <Box display="flex" flexDirection="column">
                                            <FormLabel className={classes.formLabel}>Pay To</FormLabel>
                                            <Typography variant="body1">{selectedInstitution.institutionName}</Typography>
                                            <Typography variant="body2">{selectedInstitution.paymentStreetAddress1}</Typography>
                                            <Typography variant="body2">{selectedInstitution.paymentStreetAddress2}</Typography>
                                            <Typography variant="body2">{`${selectedInstitution.paymentCity}, ${enumHelper.getDisplayName(
                                                State,
                                                selectedInstitution.paymentState
                                            )} ${selectedInstitution.paymentZip}`}</Typography>
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TableContainer style={{ maxHeight: 400 }}>
                                        <FieldArray name="transactions" validate={handleValidateTransactions}>
                                            {({ fields, meta: { error, dirty } }) => {
                                                return (
                                                    <>
                                                        {loading && <LinearProgress />}
                                                        <Table
                                                            size="small"
                                                            padding="none"
                                                            stickyHeader
                                                            className={error && dirty ? classes.transactionTableError : undefined}
                                                        >
                                                            <TableHead className={classes.tableHead}>
                                                                <TableRow>
                                                                    <TableCell style={{ paddingLeft: 10 }}>
                                                                        <Checkbox
                                                                            classes={{ root: classes.checkbox }}
                                                                            style={{ marginLeft: -10 }}
                                                                            onChange={() => handleSelectAll(props)}
                                                                            checked={getSelectAll(props.values)}
                                                                            disabled={Boolean(transaction) || Boolean(refund)}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>Description</TableCell>
                                                                    <TableCell>Amount</TableCell>
                                                                    <TableCell>Transaction Date</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {fields.map((name, index) => {
                                                                    return (
                                                                        <TableRow key={name}>
                                                                            <TableCell style={{ paddingLeft: 10 }}>
                                                                                <Field
                                                                                    className={classes.noMargin}
                                                                                    name={`${name}.selected`}
                                                                                    checkboxClassName={classes.checkbox}
                                                                                    component={FinalFormCheckbox}
                                                                                    disabled={Boolean(transaction) || Boolean(refund)}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell>{props.values.transactions[index].description}</TableCell>
                                                                            <TableCell>
                                                                                {renderAmount({ value: props.values.transactions[index].amount })}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {moment(props.values.transactions[index].date).format('M/D/YYYY')}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    );
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                        {error && dirty && (
                                                            <FormHelperText className={classes.transactionsErrorLabel}>{error}</FormHelperText>
                                                        )}
                                                    </>
                                                );
                                            }}
                                        </FieldArray>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            <DialogActions style={{ marginTop: 30 }}>
                                <Button onClick={onClose} disabled={loading || isSaving} variant="outlined">
                                    Cancel
                                </Button>
                                <LoadingButton loading={loading || isSaving} type="submit" variant="contained" color="primary">
                                    {Boolean(transaction)
                                        ? 'Save'
                                        : Boolean(refund)
                                        ? 'Receive Refund'
                                        : `Submit ${transactionTypeText} ${type === TransactionType.Refund ? 'to DCYF' : ''}`}
                                </LoadingButton>
                            </DialogActions>
                        </form>
                    )}
                </Form>
            </DialogContent>
        </Dialog>
    );
};

export default SubmitDialog;
