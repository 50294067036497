import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import { green } from '@material-ui/core/colors';

import { FinalFormTextField } from '../../../common/formWrappers';
import { required, maxLength, composeValidators } from '../../../common/formValidators';
import PasswordRequirements, { meetsPasswordRequirements, matchesNewPassword } from '../../../common/passwordRequirements';

const useStyles = makeStyles((theme) => ({
    buttonWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    subHead: {
        fontSize: '1rem',
        marginBottom: theme.spacing(1)
    }
}));

export default function AdminChangePasswordDialog({ id, open, handleClose, handleSave, isSaving, userName }) {
    const classes = useStyles();

    return (
        <Dialog open={open} onClose={handleClose} disableBackdropClick disableEscapeKeyDown>
            <DialogTitle>Change Password</DialogTitle>
            <DialogContent>
                <Form onSubmit={(values) => handleSave({ id, ...values })}>
                    {(props) => (
                        <form onSubmit={props.handleSubmit}>
                            <Typography variant="h6" className={classes.subHead}>
                                Please enter your password
                            </Typography>
                            <Field
                                name="adminPassword"
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                label="Your Password"
                                id="adminPassword"
                                password
                                maxLength={32}
                                component={FinalFormTextField}
                                validate={composeValidators(required, maxLength(32))}
                            />
                            <Typography variant="h6" className={classes.subHead}>
                                Change password for {userName}
                            </Typography>
                            <Field
                                name="newPassword"
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                label="New Password"
                                id="newPassword"
                                password
                                maxLength={32}
                                tooltipPlacement="left"
                                tooltipContent={<PasswordRequirements password={props.values.newPassword} />}
                                component={FinalFormTextField}
                                validate={composeValidators(required, maxLength(32), meetsPasswordRequirements)}
                            />
                            <Field
                                name="confirmPassword"
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                label="Confirm Password"
                                id="confirmPassword"
                                password
                                maxLength={32}
                                component={FinalFormTextField}
                                validate={composeValidators(required, maxLength(32), matchesNewPassword(props.values.newPassword))}
                            />
                            <DialogActions>
                                <Button onClick={handleClose} disabled={isSaving}>
                                    Cancel
                                </Button>
                                <div className={classes.buttonWrapper}>
                                    <Button type="submit" disabled={isSaving}>
                                        Save
                                    </Button>
                                    {isSaving && <CircularProgress className={classes.buttonProgress} size={24} />}
                                </div>
                            </DialogActions>
                        </form>
                    )}
                </Form>
            </DialogContent>
        </Dialog>
    );
}
