import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';

const ReadOnlyCheckbox = ({ label, checked }) => {
    return (
        <FormControl>
            <FormLabel>{label}</FormLabel>
            <Box alignSelf="flex-start" display="flex" alignItems="center" justifyContent="flex-start">
                <Checkbox checked={checked} disabled />
            </Box>
        </FormControl>
    );
};

export default ReadOnlyCheckbox;
