import React, { useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import LeftNavLayout from '../../leftNavLayout';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import FileSaver from 'file-saver';
import InfoIcon from '@material-ui/icons/Info';
import { GridOverlay } from '@mui/x-data-grid';

import CustomDataGrid from '../../../common/customDataGrid';
import b64toBlob from '../../../common/logic/b64toBlob';
import AcademicYearSelector from '../../../common/academicYearSelector';
import * as ReportApi from '../../../api/report';
import BackdropLoader from '../../../common/backdropLoader';
import ExcelIcon from '../../../common/icons/excelIcon';
import { conformedCurrency } from '../../../common/masks';
import { useAuth } from '../../../auth/auth-context';

const useStyles = makeStyles((theme) => ({
    schoolGrantSummary: {},
    leftMargin: {
        marginLeft: theme.spacing(1)
    },
    data: {
        border: '1px solid rgba(224, 224, 224)',
        borderRadius: theme.shape.borderRadius
    },
    bold: {
        fontWeight: 'bold'
    }
}));

const sortModel = [{ field: 'school', sort: 'asc' }];

const SchoolGrantSummary = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [showInfo, setShowInfo] = useState(true);
    const [academicYear, setAcademicYear] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const { getPeriodName } = useAuth();

    const columns = [
        { field: 'school', headerName: 'School', flex: 1 },
        { field: 'term', headerName: 'Term', flex: 1, valueFormatter: (params) => getPeriodName(params.row.institutionId, params.value) },
        { field: 'stateGrantsAwarded', headerName: '# of Students awarded State grants', flex: 1 },
        { field: 'federalGrantsAwarded', headerName: '# of Students awarded Federal grants', flex: 1 },
        {
            field: 'stateTotal',
            headerName: 'Total Award Amount - State',
            flex: 1,
            valueFormatter: (params) => conformedCurrency(params.value.toString())
        },
        {
            field: 'federalTotal',
            headerName: 'Total Award Amount - Federal',
            flex: 1,
            valueFormatter: (params) => conformedCurrency(params.value.toString())
        }
    ];

    const getData = async () => {
        try {
            setLoading(true);
            const result = (await ReportApi.getSchoolGrantSummaryData(academicYear)).data.result;
            setData(result);
            setShowInfo(false);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.error(err);
            enqueueSnackbar('There was an error retrieving report data. Please try again later.', { variant: 'error' });
        }
    };

    const getExcel = async () => {
        try {
            setLoading(true);
            const file = (await ReportApi.runSchoolGrantSummaryReport(academicYear)).data.result;
            const blob = b64toBlob(file.data, file.mimeType);
            FileSaver.saveAs(blob, file.fileName);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.error(err);
            enqueueSnackbar('There was an error retrieving report data. Please try again later.', { variant: 'error' });
        }
    };

    return (
        <LeftNavLayout>
            <div className={classes.schoolGrantSummary}>
                <BackdropLoader open={loading} />
                <Box
                    paddingTop={1}
                    paddingBottom={1}
                    paddingLeft={4}
                    paddingRight={4}
                    color="secondary.contrastText"
                    bgcolor="secondary.main"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box display="flex" alignItems="center">
                        <Typography variant="h6">School Grant Summary</Typography>
                        <AcademicYearSelector
                            inTableHeader
                            value={academicYear}
                            onChange={(val) => setAcademicYear(val)}
                            className={classes.leftMargin}
                        />
                        <Button variant="contained" color="primary" className={classes.leftMargin} onClick={getData}>
                            Run Report
                        </Button>
                    </Box>
                    <Button variant="contained" color="primary" startIcon={<ExcelIcon />} onClick={getExcel}>
                        Export to Excel
                    </Button>
                </Box>
                <CustomDataGrid
                    getRowId={(row) => row.rowId}
                    disableColumnMenu
                    disableColumnFilter
                    initialSortModel={sortModel}
                    disableSelectionOnClick
                    rows={data}
                    rowHeight={30}
                    columns={columns}
                    initialPageSize={25}
                    rowsPerPageOptions={[25, 50, 100]}
                    loading={loading}
                    components={{
                        NoRowsOverlay: () => (
                            <GridOverlay>
                                {showInfo ? (
                                    <>
                                        <InfoIcon color="primary" fontSize="small" />
                                        <Typography variant="body1" style={{ marginLeft: 10 }}>
                                            Run Report To See Data
                                        </Typography>
                                    </>
                                ) : !loading ? (
                                    <Typography variant="body1">No Rows</Typography>
                                ) : null}
                            </GridOverlay>
                        )
                    }}
                />
            </div>
        </LeftNavLayout>
    );
};

export default SchoolGrantSummary;
