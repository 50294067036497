import React from 'react';
import ForgotPasswordForm from './forgotPasswordForm';

export default function ForgotPasswordPage({ expired }) {
    return (
        <div>
            <ForgotPasswordForm expired={expired} />
        </div>
    );
}
