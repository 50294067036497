import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Field, Form } from 'react-final-form';
import moment from 'moment';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';

import { FinalFormTextField } from '../../../../../common/formWrappers';
import { composeValidators, isDate, maxLength, required } from '../../../../../common/formValidators';
import LoadingButton from '../../../../../common/loadingButton';
import { CurrencyMaskInput, DateMaskInput, parseCurrency } from '../../../../../common/masks';
import { renderAmount } from '../common';
import { TransactionType } from '../../../../../common/logic/enums';

const useStyles = makeStyles((theme) => ({
    adjustmentDialog: {},
    inputTotalNegative: {
        color: 'red'
    },
    entryDate: {
        color: theme.palette.success.main
    }
}));

const AdjustmentDialog = ({ open, balance, onClose, onSave, isSaving }) => {
    const classes = useStyles();
    const [adjustmentType, setAdjustmentType] = useState(TransactionType.Charge);

    useEffect(() => {
        if (open) {
            setAdjustmentType(TransactionType.Charge);
        }
    }, [open]);

    const getTotal = (amount) => {
        if (!amount) {
            amount = 0;
        }

        let multiplier = 1;
        if (adjustmentType === TransactionType.Credit) {
            multiplier = -1;
        }

        return parseInt(amount * multiplier, 10) + parseInt(balance, 10);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth disableBackdropClick>
            <DialogTitle>Add Adjustment</DialogTitle>
            <DialogContent>
                <Form
                    onSubmit={(values) =>
                        onSave({
                            ...values,
                            type: TransactionType.Adjustment,
                            amount: adjustmentType === TransactionType.Credit ? values.amount * -1 : values.amount
                        })
                    }
                    initialValues={{ paymentDate: moment().format('MM/DD/YYYY'), amount: 0 }}
                >
                    {(props) => (
                        <form onSubmit={props.handleSubmit} noValidate>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <FormLabel>Entry Date</FormLabel>
                                    <Typography className={classes.entryDate} variant="body2">
                                        {moment().format('M/D/YYYY')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Field
                                        name="paymentDate"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        required
                                        label="Adjustment Date"
                                        id="paymentDate"
                                        component={FinalFormTextField}
                                        validate={composeValidators(required, isDate)}
                                        inputComponent={DateMaskInput}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Box display="flex" flexDirection="column">
                                        <FormLabel style={{ marginBottom: 10 }}>Type</FormLabel>
                                        <Select
                                            variant="outlined"
                                            margin="dense"
                                            value={adjustmentType}
                                            onChange={(e) => setAdjustmentType(e.target.value)}
                                        >
                                            <MenuItem value={TransactionType.Charge}>Charge</MenuItem>
                                            <MenuItem value={TransactionType.Credit}>Credit</MenuItem>
                                        </Select>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Field
                                        key={`amount${adjustmentType}`}
                                        name="amount"
                                        margin="dense"
                                        fullWidth
                                        required
                                        label="Adjustment Amount"
                                        id="amount"
                                        placeholder="Amount"
                                        component={FinalFormTextField}
                                        inputClasses={adjustmentType === TransactionType.Credit ? { input: classes.inputTotalNegative } : undefined}
                                        parse={(val) => parseCurrency(val)}
                                        inputComponent={CurrencyMaskInput}
                                        inputProps={
                                            adjustmentType === TransactionType.Credit
                                                ? {
                                                      maskOptions: {
                                                          allowDecimal: false,
                                                          prefix: '($',
                                                          suffix: ')'
                                                      }
                                                  }
                                                : {
                                                      maskOptions: {
                                                          allowDecimal: false
                                                      }
                                                  }
                                        }
                                        validate={required}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormLabel>New Balance (Owed to School)</FormLabel>
                                    <Typography variant="body1" style={{ lineHeight: '40px' }}>
                                        {renderAmount({
                                            value: getTotal(props.values.amount)
                                        })}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={5}>
                                    <Field
                                        name="notes"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        rows={4}
                                        label="Description"
                                        id="notes"
                                        maxLength={50}
                                        component={FinalFormTextField}
                                        validate={maxLength(50)}
                                    />
                                </Grid>
                            </Grid>
                            <DialogActions style={{ marginTop: 30 }}>
                                <Button onClick={onClose} disabled={isSaving} variant="outlined">
                                    Cancel
                                </Button>
                                <LoadingButton loading={isSaving} type="submit" variant="contained" color="primary">
                                    Add Adjustment
                                </LoadingButton>
                            </DialogActions>
                        </form>
                    )}
                </Form>
            </DialogContent>
        </Dialog>
    );
};

export default AdjustmentDialog;
