import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import { green } from '@material-ui/core/colors';

import * as InstitutionApi from '../../../api/institution';
import { State } from '../../../common/logic/state';
import { TermStructure, SchoolType } from '../../../common/logic/enums';
import enumHelper from '../../../common/logic/enumHelper';
import { FinalFormTextField, FinalFormSwitch, FinalFormDropdown, FinalFormCheckbox, WhenFieldChanges } from '../../../common/formWrappers';
import { required, email, url, maxLength, composeValidators, zipCode } from '../../../common/formValidators';
import { ZipMaskInput, PhoneMaskInput } from '../../../common/masks/';

const useStyles = makeStyles((theme) => ({
    h6: {
        marginBottom: theme.spacing(1)
    },
    left: {
        marginRight: theme.spacing(2)
    },
    city: {
        flex: 6,
        marginRight: theme.spacing(1)
    },
    state: {
        flex: 3,
        marginRight: theme.spacing(1)
    },
    zip: {
        minWidth: 120,
        flex: 3
    },
    divider: {
        marginBottom: theme.spacing(1)
    },
    buttonWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    form: {
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column'
    }
}));

export default function EditDialog({ institution, open, onClose }) {
    const classes = useStyles();
    const [saving, setSaving] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const schoolTypes = enumHelper.getSelectOptionsList(SchoolType);
    const termStructures = enumHelper.getSelectOptionsList(TermStructure);
    const stateOptions = enumHelper.getSelectOptionsList(State);

    const save = async (values) => {
        try {
            if (institution) {
                await InstitutionApi.update({ ...values, id: institution.id });
            } else {
                await InstitutionApi.create(values);
            }
            setSaving(false);
            enqueueSnackbar('Institution saved successfully.', { variant: 'success' });
            onClose();
        } catch (error) {
            setSaving(false);
        }
    };

    return (
        <Dialog maxWidth="md" fullWidth open={open} onClose={onClose} disableBackdropClick disableEscapeKeyDown>
            <Form onSubmit={save} initialValues={institution}>
                {({ handleSubmit, values }) => (
                    <form className={classes.form} noValidate onSubmit={handleSubmit}>
                        <DialogTitle>
                            <Box display="flex" justifyContent="space-between">
                                <span>{institution ? `Edit Institution ${institution.name}` : 'Add Institution'}</span>
                                <FormControlLabel
                                    control={
                                        <Field
                                            name="isActive"
                                            value="isActive"
                                            component={FinalFormSwitch}
                                            color="primary"
                                            initialValue={institution ? undefined : true}
                                        />
                                    }
                                    label="Active"
                                />
                            </Box>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Field
                                        component={FinalFormTextField}
                                        name="name"
                                        variant="outlined"
                                        margin="dense"
                                        required
                                        fullWidth
                                        label="Name"
                                        id="name"
                                        maxLength={60}
                                        placeholder="Name"
                                        validate={required}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Field
                                        name="schoolType"
                                        label="School Type"
                                        placeholder="Select School Type..."
                                        id="schoolType"
                                        margin="dense"
                                        required
                                        fullWidth
                                        component={FinalFormDropdown}
                                        choices={schoolTypes}
                                        validate={required}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Field
                                        name="termStructure"
                                        label="Term Structure"
                                        placeholder="Select Term Structure..."
                                        id="termStructure"
                                        margin="dense"
                                        required
                                        fullWidth
                                        component={FinalFormDropdown}
                                        choices={termStructures}
                                        validate={required}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Field
                                        component={FinalFormTextField}
                                        name="mainPhone"
                                        variant="outlined"
                                        margin="dense"
                                        required
                                        fullWidth
                                        label="Main Phone"
                                        id="mainPhone"
                                        placeholder="Main Phone"
                                        validate={required}
                                        inputComponent={PhoneMaskInput}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={FinalFormTextField}
                                        name="mainFax"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        label="Main Fax"
                                        id="mainFax"
                                        placeholder="Main Fax"
                                        inputComponent={PhoneMaskInput}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={FinalFormTextField}
                                        name="federalId"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        required
                                        placeholder="000000"
                                        label="Federal Id"
                                        id="federalId"
                                        maxLength={6}
                                        validate={required}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={5}>
                                    <Field
                                        component={FinalFormTextField}
                                        name="website"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        label="Website"
                                        id="website"
                                        placeholder="Website"
                                        validate={url}
                                    />
                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Typography variant="h6" className={classes.h6}>
                                Key Contacts
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Box flexDirection="column" flex={1}>
                                        <Field
                                            component={FinalFormTextField}
                                            name="keyContact"
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            placeholder="Key Contact"
                                            label="Key Contact"
                                            id="keyContact"
                                        />
                                        <Field
                                            component={FinalFormTextField}
                                            name="keyContactEmail"
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            placeholder="Key Contact Email"
                                            label="Key Contact Email"
                                            id="keyContactEmail"
                                            maxLength={256}
                                            validate={composeValidators(email, maxLength(256))}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box flexDirection="column" flex={1}>
                                        <Field
                                            component={FinalFormTextField}
                                            name="secondaryContact"
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            placeholder="Secondary Contact"
                                            label="Secondary Contact"
                                            id="secondaryContact"
                                        />
                                        <Field
                                            component={FinalFormTextField}
                                            name="secondaryContactEmail"
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            placeholder="Secondary Contact Email"
                                            label="Secondary Contact Email"
                                            id="secondaryContactEmail"
                                            maxLength={256}
                                            validate={composeValidators(email, maxLength(256))}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box display="flex">
                                <Box flex={1} display="flex" flexDirection="column" className={classes.left}>
                                    <Divider className={classes.divider} />
                                    <Typography variant="h6" className={classes.h6}>
                                        Mailing Address
                                    </Typography>
                                    <Field
                                        name="streetAddress1"
                                        label="Street Address 1"
                                        id="streetAddress1"
                                        margin="dense"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        placeholder="Street Address 1"
                                        component={FinalFormTextField}
                                        validate={required}
                                    />
                                    <Field
                                        name="streetAddress2"
                                        label="Street Address 2"
                                        id="streetAddress2"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="Street Address 2"
                                        component={FinalFormTextField}
                                    />
                                    <Box display="flex">
                                        <Field
                                            className={classes.city}
                                            name="city"
                                            label="City"
                                            id="city"
                                            margin="dense"
                                            variant="outlined"
                                            required
                                            placeholder="City"
                                            component={FinalFormTextField}
                                            validate={required}
                                        />
                                        <Field
                                            className={classes.state}
                                            name="state"
                                            label="State"
                                            placeholder="Select State..."
                                            id="state"
                                            margin="dense"
                                            required
                                            component={FinalFormDropdown}
                                            choices={stateOptions}
                                            validate={required}
                                        />
                                        <Field
                                            className={classes.zip}
                                            name="zip"
                                            label="Zip"
                                            id="zip"
                                            margin="dense"
                                            variant="outlined"
                                            required
                                            placeholder="Zip"
                                            component={FinalFormTextField}
                                            validate={composeValidators(required, zipCode)}
                                            inputComponent={ZipMaskInput}
                                        />
                                    </Box>
                                </Box>
                                <Box flex={1} display="flex" flexDirection="column">
                                    <Divider className={classes.divider} />
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        <Typography variant="h6" className={classes.h6}>
                                            Payment Address
                                        </Typography>
                                        <Field name="sameAsMailing" caption="Same As Mailing" size="small" component={FinalFormCheckbox} />
                                    </Box>
                                    <Field
                                        name="paymentStreetAddress1"
                                        label="Street Address 1"
                                        id="paymentStreetAddress1"
                                        margin="dense"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        placeholder="Street Address 1"
                                        disabled={values.sameAsMailing}
                                        component={FinalFormTextField}
                                        validate={required}
                                    />
                                    <Field
                                        name="paymentStreetAddress2"
                                        label="Street Address 2"
                                        id="paymentStreetAddress2"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="Street Address 2"
                                        disabled={values.sameAsMailing}
                                        component={FinalFormTextField}
                                    />
                                    <Box display="flex">
                                        <Field
                                            className={classes.city}
                                            name="paymentCity"
                                            label="City"
                                            id="paymentCity"
                                            margin="dense"
                                            variant="outlined"
                                            required
                                            placeholder="City"
                                            disabled={values.sameAsMailing}
                                            component={FinalFormTextField}
                                            validate={required}
                                        />
                                        <Field
                                            className={classes.state}
                                            name="paymentState"
                                            label="State"
                                            placeholder="Select State..."
                                            id="paymentState"
                                            margin="dense"
                                            required
                                            disabled={values.sameAsMailing}
                                            component={FinalFormDropdown}
                                            choices={stateOptions}
                                            validate={required}
                                        />
                                        <Field
                                            className={classes.zip}
                                            name="paymentZip"
                                            label="Zip"
                                            id="paymentZip"
                                            margin="dense"
                                            variant="outlined"
                                            required
                                            placeholder="Zip"
                                            disabled={values.sameAsMailing}
                                            component={FinalFormTextField}
                                            validate={composeValidators(required, zipCode)}
                                            inputComponent={ZipMaskInput}
                                        />
                                        <WhenFieldChanges
                                            field="sameAsMailing"
                                            becomes={true}
                                            set="paymentStreetAddress1"
                                            to={values.streetAddress1}
                                        />
                                        <WhenFieldChanges
                                            field="sameAsMailing"
                                            becomes={true}
                                            set="paymentStreetAddress2"
                                            to={values.streetAddress2}
                                        />
                                        <WhenFieldChanges field="sameAsMailing" becomes={true} set="paymentCity" to={values.city} />
                                        <WhenFieldChanges field="sameAsMailing" becomes={true} set="paymentState" to={values.state} />
                                        <WhenFieldChanges field="sameAsMailing" becomes={true} set="paymentZip" to={values.zip} />
                                    </Box>
                                </Box>
                            </Box>
                            <Field
                                name="notes"
                                label="Notes"
                                id="notes"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                maxLength={500}
                                rows={3}
                                placeholder="Notes"
                                component={FinalFormTextField}
                                multiline
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose} disabled={saving}>
                                Cancel
                            </Button>
                            <div className={classes.buttonWrapper}>
                                <Button type="submit" disabled={saving} variant="contained" color="primary">
                                    Save
                                </Button>
                                {saving && <CircularProgress className={classes.buttonProgress} size={24} />}
                            </div>
                        </DialogActions>
                    </form>
                )}
            </Form>
        </Dialog>
    );
}
