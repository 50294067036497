import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { FinalFormTextField } from '../../../../common/formWrappers';
import { composeValidators, email, exactLength, required } from '../../../../common/formValidators';
import { Field } from 'react-final-form';
import { PhoneMaskInput } from '../../../../common/masks';

const useStyles = makeStyles((theme) => ({
    contact: {
        display: 'flex',
        flexDirection: 'column',
        '&:first-child': {
            marginBottom: theme.spacing(2)
        }
    },
    label: {
        textTransform: 'capitalize',
        marginBottom: theme.spacing(0.5)
    },
    margin: {
        marginBottom: theme.spacing(0.5)
    },
    required: {
        color: 'red'
    },
    formControl: {
        alignItems: 'flex-start'
    }
}));

const EditContact = ({ label, contact, isRequired }) => {
    const classes = useStyles();

    return (
        <div className={classes.contact}>
            <FormControl className={classes.formControl}>
                <FormLabel required={isRequired} classes={{ asterisk: classes.required }} className={classes.label}>
                    {label} Contact
                </FormLabel>
                <Field
                    className={classes.margin}
                    component={FinalFormTextField}
                    name={`${label.toLowerCase()}.name`}
                    variant="outlined"
                    margin="dense"
                    required={isRequired}
                    id={`${label.toLowerCase()}.name`}
                    placeholder="Name"
                    validate={isRequired ? required : undefined}
                />
                <Field
                    className={classes.margin}
                    component={FinalFormTextField}
                    name={`${label.toLowerCase()}.degree`}
                    variant="outlined"
                    margin="dense"
                    id={`${label.toLowerCase()}.degree`}
                    placeholder="Degree"
                />
                <Field
                    className={classes.margin}
                    component={FinalFormTextField}
                    name={`${label.toLowerCase()}.phoneNumber`}
                    variant="outlined"
                    margin="dense"
                    required={isRequired}
                    id={`${label.toLowerCase()}.phoneNumber`}
                    placeholder="Phone Number"
                    validate={
                        isRequired ? composeValidators(required, exactLength(14, 'Invalid phone number')) : exactLength(14, 'Invalid phone number')
                    }
                    inputComponent={PhoneMaskInput}
                />
                <Field
                    className={classes.margin}
                    component={FinalFormTextField}
                    name={`${label.toLowerCase()}.emailAddress`}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    required={isRequired}
                    id={`${label.toLowerCase()}.emailAddress`}
                    placeholder="Email Address"
                    validate={isRequired ? composeValidators(required, email) : email}
                />
                <Field
                    className={classes.margin}
                    component={FinalFormTextField}
                    name={`${label.toLowerCase()}.jobTitle`}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    required={isRequired}
                    id={`${label.toLowerCase()}.jobTitle`}
                    placeholder="Job Title"
                    validate={isRequired ? required : undefined}
                />
            </FormControl>
        </div>
    );
};

export default EditContact;
