import React, { useState } from 'react';
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    margin: {
        marginBottom: theme.spacing(2)
    },
    label: {
        marginBottom: theme.spacing(1)
    },
    required: {
        color: 'red'
    },
    placeholderText: {
        color: '#cecece'
    },
    paper: {
        maxHeight: 200
    },
    disabled: {
        backgroundColor: theme.palette.inputDisabled.main
    }
}));

const FinalFormDropdown = ({
    input: { name, value, onChange, ...restInput },
    className,
    label,
    placeholder,
    fullWidth,
    meta,
    required,
    choices,
    onSelectChange,
    disabled,
    ...rest
}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const onKeyPress = (e) => {
        if (!open) {
            setOpen(true);
        }
    };

    return (
        <FormControl className={clsx(classes.margin, className)} fullWidth={fullWidth} error={meta.error && meta.touched}>
            {label && (
                <FormLabel required={required} className={classes.label} classes={{ asterisk: classes.required }} disabled={disabled}>
                    {label}
                </FormLabel>
            )}
            <Select
                onKeyPress={onKeyPress}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                displayEmpty
                MenuProps={{ PopoverClasses: { paper: classes.paper } }}
                renderValue={(val) => {
                    try {
                        if (val === null || val === '') {
                            if (placeholder) return placeholder;
                            else return label;
                        } else {
                            if (typeof choices[0].value !== 'string') {
                                const choice = choices.find((c) => c.value.toString() === val.toString());
                                return choice.label;
                            } else {
                                return choices.find((c) => c.value === val).label;
                            }
                        }
                    } catch (ex) {
                        //console.error(ex);
                        return '';
                    }
                }}
                input={
                    <OutlinedInput
                        {...rest}
                        placeholder={label}
                        name={name}
                        error={meta.error && meta.touched}
                        inputProps={{
                            ...restInput,
                            type: 'text',
                            placeholder: label
                        }}
                        onChange={(value) => {
                            onChange(value);
                            onSelectChange && onSelectChange(value);
                        }}
                        required={required}
                        value={value}
                        disabled={disabled}
                        classes={{ disabled: classes.disabled }}
                    />
                }
            >
                {choices.map((choice) => {
                    return (
                        <MenuItem key={choice.value} value={choice.value}>
                            {choice.label}
                        </MenuItem>
                    );
                })}
            </Select>
            {meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
    );
};

export default FinalFormDropdown;

export const FinalFormNativeDropdown = ({
    input: { name, value, ...restInput },
    label,
    placeholder,
    fullWidth,
    meta,
    required,
    choices,
    autoFocus,
    disabled,
    ...rest
}) => {
    const classes = useStyles();
    return (
        <FormControl className={classes.margin} fullWidth={fullWidth} error={meta.error && meta.touched}>
            <FormLabel required={required} className={classes.label} classes={{ asterisk: classes.required }} disabled={disabled}>
                {label}
            </FormLabel>
            <Select
                className={value === '' ? classes.placeholderText : undefined}
                autoFocus={autoFocus}
                native
                value={value}
                input={
                    <OutlinedInput
                        {...rest}
                        placeholder={placeholder}
                        name={name}
                        error={meta.error && meta.touched}
                        inputProps={{
                            ...restInput,
                            type: 'text',
                            placeholder: placeholder
                        }}
                        required={required}
                        value={value}
                        classes={{ disabled: classes.disabled }}
                    />
                }
            >
                <option key="9999" value="" disabled>
                    {placeholder}
                </option>
                {choices.map((choice) => {
                    return (
                        <option key={choice.value} value={choice.value} style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                            {choice.label}
                        </option>
                    );
                })}
            </Select>
            {meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
    );
};
