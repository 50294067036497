function getAuthStorage() {
    const accessToken = localStorage.getItem('accessToken') || '';
    const refreshToken = localStorage.getItem('refreshToken') || '';
    const expiration = localStorage.getItem('expiration') || '';
    const hasLoggedIn = localStorage.getItem('hasLoggedIn') || '';

    let expirationDate = null;
    if (expiration !== '') {
        expirationDate = new Date(expiration);
    }

    return {
        accessToken,
        refreshToken,
        expiration: expirationDate,
        hasLoggedIn
    };
}

function setAuthStorage(accessToken, refreshToken, expiration, hasLoggedIn) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('expiration', expiration.toISOString());
    localStorage.setItem('hasLoggedIn', hasLoggedIn);
}

function clearAuthStorage() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('expiration');
}

export { getAuthStorage, setAuthStorage, clearAuthStorage };
