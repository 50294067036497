import React from 'react';
import clsx from 'clsx';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import makeStyles from '@material-ui/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';
import { fade } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    toolbarSearchInput: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25)
        },
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(3)
    },
    searchIcon: {
        padding: theme.spacing(0, 1),
        alignSelf: 'stretch',
        display: 'flex',
        alignItems: 'center'
    },
    searchRoot: {
        color: 'inherit',
        borderRadius: 4
    },
    searchInput: {
        padding: theme.spacing(1.5, 1, 1.5, 0),
        backgroundColor: '#ffffff'
    },
    adornment: {
        backgroundColor: '#ffffff'
    },
    hidden: {
        visibility: 'hidden'
    },
    clickable: {
        cursor: 'pointer'
    }
}));

const ToolbarSearchInput = ({ value, onChange }) => {
    const classes = useStyles();

    return (
        <div className={classes.toolbarSearchInput}>
            <InputBase
                startAdornment={
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                }
                endAdornment={
                    <div className={clsx(classes.searchIcon, value !== '' && classes.clickable)} onClick={() => onChange('')}>
                        <CloseIcon className={clsx(value === '' && classes.hidden)} />
                    </div>
                }
                value={value}
                onChange={(event) => onChange(event.target.value)}
                placeholder="Search..."
                classes={{
                    root: classes.searchRoot,
                    input: classes.searchInput,
                    adornedStart: classes.adornment,
                    adornedEnd: classes.adornment
                }}
            />
        </div>
    );
};

export default ToolbarSearchInput;
