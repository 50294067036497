import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useAuth } from '../../auth/auth-context';
import { APP_SETTING_NAME_CONTACTS } from '../logic/consts';

const ContactsDisplay = ({ showSecondary = true }) => {
    const { settings } = useAuth();
    const contacts = settings.find((s) => s.name === APP_SETTING_NAME_CONTACTS).value;

    return (
        <>
            <Typography variant="body1">
                {contacts.primary.degree ? `${contacts.primary.name}, ${contacts.primary.degree}` : contacts.primary.name}
            </Typography>
            <Typography variant="body1">
                <a href={`tel:${contacts.primary.phoneNumber}`}>{contacts.primary.phoneNumber}</a>
            </Typography>
            <Typography variant="body1">
                <a href={`mailto:${contacts.primary.emailAddress}`}>{contacts.primary.emailAddress}</a>
            </Typography>
            <Typography variant="body1">{contacts.primary.jobTitle}</Typography>
            {showSecondary && contacts.secondary && (
                <>
                    <Box mb={1} mt={1}>
                        <Typography variant="body2">
                            <strong>OR</strong>
                        </Typography>
                    </Box>
                    <Typography variant="body1">
                        {contacts.secondary.degree ? `${contacts.secondary.name}, ${contacts.secondary.degree}` : contacts.secondary.name}
                    </Typography>
                    <Typography variant="body1">
                        <a href={`tel:${contacts.secondary.phoneNumber}`}>{contacts.secondary.phoneNumber}</a>
                    </Typography>
                    <Typography variant="body1">
                        <a href={`mailto:${contacts.secondary.emailAddress}`}>{contacts.secondary.emailAddress}</a>
                    </Typography>
                    <Typography variant="body1">{contacts.secondary.jobTitle}</Typography>
                </>
            )}
        </>
    );
};

export default ContactsDisplay;
