import axios from 'axios';

export function getGrantSummaryData(academicYearId) {
    return axios.get('/api/services/app/Report/GetGrantSummaryData', { params: { academicYearId } });
}

export function getCertifiedStudentSummaryData(academicYearId, institutionId, certified, cancelToken) {
    return axios.get('/api/services/app/Report/GetCertifiedStudentSummaryData', {
        params: { academicYearId, institutionId, certified },
        cancelToken
    });
}

export function runGrantSummaryReport() {
    return axios.post('/api/services/app/Report/RunGrantSummaryReport');
}

export function runCertifiedStudentSummaryReport(academicYearId, institutionId, certified, cancelToken) {
    return axios.post('/api/services/app/Report/RunCertifiedStudentSummaryReport', { academicYearId, institutionId, certified }, { cancelToken });
}

export function getSchoolGrantSummaryData(academicYearId) {
    return axios.get('/api/services/app/Report/GetSchoolGrantSummaryData', { params: { academicYearId } });
}

export function runSchoolGrantSummaryReport(academicYearId) {
    return axios.post('/api/services/app/Report/RunSchoolGrantSummaryReport', { academicYearId });
}

export function getStudentPaymentData(userId) {
    return axios.get('/api/services/app/Report/GetStudentPaymentData', { params: { userId } });
}

export function runStudentPaymentReport(userId) {
    return axios.post('/api/services/app/Report/RunStudentPaymentReport', null, { params: { userId } });
}
