import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Form, Field } from 'react-final-form';
import { useSnackbar } from 'notistack';

import { FinalFormTextField } from '../formWrappers';
import { composeValidators, maxLength, required } from '../formValidators';
import LoadingButton from '../loadingButton';
import PasswordRequirements, { matchesNewPassword, meetsPasswordRequirements } from '../../common/passwordRequirements';
import * as AccountApi from '../../api/account';

const ChangePasswordDialog = ({ open, onClose }) => {
    const [isSaving, setSaving] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const handleSave = async (values) => {
        try {
            setSaving(true);
            await AccountApi.changePassword(values.currentPassword, values.newPassword);
            enqueueSnackbar('Password updated successfully', { variant: 'success' });
            onClose();
        } catch (ex) {
        } finally {
            setSaving(false);
        }
    };

    return (
        <Dialog open={open} scroll="paper" disableBackdropClick disableEscapeKeyDown>
            <DialogTitle>Change Password</DialogTitle>
            <DialogContent>
                <Form onSubmit={(values) => handleSave(values)}>
                    {(props) => (
                        <form onSubmit={props.handleSubmit}>
                            <Field
                                name="currentPassword"
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                label="Current Password"
                                id="currentPassword"
                                password
                                maxLength={32}
                                component={FinalFormTextField}
                                validate={composeValidators(required, maxLength(32))}
                            />
                            <Field
                                name="newPassword"
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                label="New Password"
                                id="newPassword"
                                password
                                maxLength={32}
                                tooltipPlacement="left"
                                tooltipContent={<PasswordRequirements password={props.values.newPassword} />}
                                component={FinalFormTextField}
                                validate={composeValidators(required, maxLength(32), meetsPasswordRequirements)}
                            />
                            <Field
                                name="confirmPassword"
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                label="Confirm Password"
                                id="confirmPassword"
                                password
                                maxLength={32}
                                component={FinalFormTextField}
                                validate={composeValidators(required, maxLength(32), matchesNewPassword(props.values.newPassword))}
                            />
                            <DialogActions>
                                <Button disabled={isSaving} onClick={onClose}>
                                    Cancel
                                </Button>
                                <LoadingButton type="submit" loading={isSaving} color="primary" variant="contained">
                                    Save
                                </LoadingButton>
                            </DialogActions>
                        </form>
                    )}
                </Form>
            </DialogContent>
        </Dialog>
    );
};

export default ChangePasswordDialog;
