import React, { useState, useMemo } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { navigate } from '@gatsbyjs/reach-router';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Form, Field } from 'react-final-form';
import { FinalFormTextField } from '../../common/formWrappers';
import { required, composeValidators, email } from '../../common/formValidators';
import * as AccountApi from '../../api/account';
import LoadingButton from '../../common/loadingButton';

import Logo from '../../assets/dcyf-logo.png';
import LogoSmall from '../../assets/dcyf-logo-small.png';
import { APP_SETTING_NAME_PASSWORD_EXPIRES_IN_DAYS } from '../../common/logic/consts';
import { useAuth } from '../../auth/auth-context';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(5),
        [theme.breakpoints.only('xs')]: {
            padding: theme.spacing(0, 2),
            marginTop: theme.spacing(2)
        }
    },
    form: {
        maxWidth: '400px',
        marginTop: theme.spacing(1)
    },
    wrapper: {
        position: 'relative',
        margin: theme.spacing(1)
    },
    caption: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(2),
        textAlign: 'center'
    },
    title: {
        textAlign: 'center'
    },
    maxWidth: {
        maxWidth: 400
    },
    logo: {
        maxWidth: 500
    }
}));

export default function ForgotPasswordForm({ expired }) {
    const [loading, setIsLoading] = useState();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.only('xs'));
    const { settings } = useAuth();

    const expireDays = useMemo(() => {
        return settings.find((s) => s.name === APP_SETTING_NAME_PASSWORD_EXPIRES_IN_DAYS)?.value;
    }, [settings]);

    async function save(values) {
        try {
            setIsLoading(true);
            await AccountApi.forgotPassword(values);
            enqueueSnackbar(`Thank you, your password reset information has been sent to ${values.emailAddress}.`, { variant: 'success' });
            navigate('/');
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className={classes.paper}>
            <div>
                <img className={classes.logo} src={isXS ? LogoSmall : Logo} alt="DCYF Logo" />
            </div>

            <div className={classes.maxWidth}>
                {expired ? (
                    <Typography className={classes.title} variant="h3">
                        Password Expired
                    </Typography>
                ) : (
                    <Typography className={classes.title} variant="h3">
                        Forgot / Reset Password
                    </Typography>
                )}
                {expired ? (
                    <Typography variant="body1" className={classes.caption}>
                        {`This system requires you to change your password every ${expireDays} days.  Please enter your email address to begin the password reset process.`}
                    </Typography>
                ) : (
                    <Typography variant="body1" className={classes.caption}>
                        Enter the email you use to login and we’ll help you create a new password.
                    </Typography>
                )}
                <Form onSubmit={(values) => save(values)}>
                    {(props) => (
                        <form className={classes.form} noValidate onSubmit={props.handleSubmit}>
                            <Field
                                margin="dense"
                                required
                                fullWidth
                                name="emailAddress"
                                placeholder="email@address.com"
                                label="Email Address"
                                type="text"
                                id="emailAddress"
                                autoComplete="off"
                                component={FinalFormTextField}
                                validate={composeValidators(required, email)}
                            />
                            <LoadingButton type="submit" fullWidth variant="contained" color="primary" loading={loading}>
                                Reset Password
                            </LoadingButton>
                        </form>
                    )}
                </Form>
            </div>
        </div>
    );
}
