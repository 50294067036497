import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { navigate } from '@gatsbyjs/reach-router';
import makeStyles from '@material-ui/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Form, Field } from 'react-final-form';
import { useSnackbar } from 'notistack';

import { useAuth } from '../../auth/auth-context';
import * as AccountApi from '../../api/account';
import { required, composeValidators, maxLength } from '../../common/formValidators';
import LoadingButton from '../../common/loadingButton';
import { FinalFormTextField } from '../../common/formWrappers';

const useStyles = makeStyles((theme) => ({
    changeEmailAddressPage: {
        margin: 'auto',
        marginTop: theme.spacing(1),
        maxWidth: 500,
        padding: theme.spacing(1)
    },
    title: {
        marginBottom: theme.spacing(1)
    },
    wrapper: {
        display: 'flex',
        flex: 1
    }
}));

const ChangeEmailAddressPage = ({ location }) => {
    const classes = useStyles();
    const qs = queryString.parse(location.search);
    const [isSaving, setIsSaving] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { user, updateUserEmail } = useAuth();

    useEffect(() => {
        if (!qs.code || !qs.email || !qs.userId) {
            navigate('/', { replace: true });
        }
    }, []);

    const handleSave = async (values) => {
        try {
            setIsSaving(true);
            await AccountApi.changeEmailAddress(qs.userId, qs.email, qs.code, values.password);
            enqueueSnackbar('Success! Your email/username has been updated.', { variant: 'success' });
            setIsSaving(false);
            if (user) {
                updateUserEmail(qs.email);
            }
            navigate('/', { replace: true });
        } catch {
            setIsSaving(false);
        }
    };

    return (
        <Paper className={classes.changeEmailAddressPage}>
            <Form onSubmit={handleSave}>
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container justify="center">
                            <Grid item xs={12}>
                                <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
                                    <Typography variant="h5" className={classes.title}>
                                        Change Email Address
                                    </Typography>
                                    <Typography variant="body1">
                                        Please input your password if you would like to confirm changing your email address to{' '}
                                        <strong>{qs.email}</strong>.
                                    </Typography>
                                </Box>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Field
                                            name="password"
                                            margin="dense"
                                            placeholder="Enter password"
                                            required
                                            fullWidth
                                            password
                                            maxLength="32"
                                            label="Password"
                                            id="password"
                                            component={FinalFormTextField}
                                            validate={composeValidators(required, maxLength(32))}
                                        />
                                    </Grid>
                                </Grid>
                                <Box display="flex">
                                    <LoadingButton
                                        wrapperClassName={classes.wrapper}
                                        fullWidth
                                        type="submit"
                                        loading={isSaving}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Save
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        </Paper>
    );
};

export default ChangeEmailAddressPage;
