import axios from 'axios';

export function authenticate(userName, password, tenantId, disableIntercept) {
    return axios.post(
        '/api/TokenAuth/Authenticate',
        { userNameOrEmailAddress: userName, password, tenantId },
        disableIntercept ? { headers: { 'disable-intercept': true } } : undefined
    );
}

export function exchangeRefreshToken(refreshToken, accessToken) {
    return axios.post('/api/TokenAuth/ExchangeRefreshToken', { refreshToken, accessToken });
}

export function impersonate(tenantId, userId) {
    return axios.post('/api/TokenAuth/Impersonate', { tenantId, userId });
}

export function stopImpersonating() {
    return axios.post('/api/TokenAuth/StopImpersonating');
}

export function register(values, disableIntercept) {
    return axios.post('/api/TokenAuth/Register', values, disableIntercept ? { headers: { 'disable-intercept': true } } : undefined);
}

export function resetPassword(code, newPassword, tenantId, accountSetup) {
    return axios.post('/api/TokenAuth/ResetPassword', { code, newPassword, tenantId, accountSetup });
}
