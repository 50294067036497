import React from 'react';
import InstitutionList from './list';
import LeftNavLayout from '../../leftNavLayout';

export default function RolesPage() {
    return (
        <LeftNavLayout>
            <InstitutionList />
        </LeftNavLayout>
    );
}
