import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import TenantLayout from './tenantLayout';
import { Divider, fade, useMediaQuery, useTheme } from '@material-ui/core';
import { useLocation, navigate } from '@gatsbyjs/reach-router';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import clsx from 'clsx';

import { getSecondaryNavItems } from '../common/logic/nav';
import { useAuth } from '../auth/auth-context';

const useStyles = makeStyles((theme) => ({
    leftNavLayout: {
        display: 'flex',
        flexDirection: 'column'
    },
    left: {
        top: 63,
        bottom: 5,
        position: 'fixed',
        width: 250,
        [theme.breakpoints.between('xs', 'lg')]: {
            position: 'initial',
            width: 'fit-content',
            padding: theme.spacing(1, 1, 0)
        }
    },
    right: {
        marginLeft: 260,
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: 5,
        [theme.breakpoints.between('xs', 'lg')]: {
            marginLeft: theme.spacing(1)
        }
    },
    paper: {
        [theme.breakpoints.only('xl')]: {
            marginLeft: theme.spacing(0.5)
        }
    },
    list: {
        margin: theme.spacing(0, 0.5),
        [theme.breakpoints.between('xs', 'lg')]: {
            margin: 0,
            paddingLeft: 8,
            paddingRight: 8,
            display: 'flex'
        }
    },
    itemRoot: {
        '&$selected': {
            backgroundColor: fade(theme.palette.primary.main, 0.2),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: fade(theme.palette.primary.main, 0.3)
            }
        },
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.2)
        },
        [theme.breakpoints.between('xs', 'lg')]: {
            width: 'unset'
        }
    },
    ripple: {
        color: theme.palette.primary.main
    },
    selected: {},
    rightPaper: {
        padding: theme.spacing(2)
    }
}));

function ListItemLink(props) {
    return <ListItem button onClick={() => navigate(props.to)} {...props} />;
}

const LeftNavLayout = ({ contentClassName, children }) => {
    const classes = useStyles();
    const { nav } = useAuth();
    const { pathname } = useLocation();

    const navItems = getSecondaryNavItems(nav, pathname);

    const theme = useTheme();
    const isNotLargeScreen = useMediaQuery(theme.breakpoints.between('xs', 'lg'));

    return (
        <TenantLayout>
            <div className={classes.leftNavLayout}>
                <div className={classes.left}>
                    <Paper className={classes.paper}>
                        <List component="nav" className={classes.list}>
                            {navItems.map((item, index) => {
                                return (
                                    <React.Fragment key={item.label}>
                                        <ListItemLink
                                            to={item.to}
                                            TouchRippleProps={{ classes: { ripple: classes.ripple } }}
                                            button
                                            selected={pathname.startsWith(item.to)}
                                            classes={{ root: classes.itemRoot, selected: classes.selected, button: classes.itemButton }}
                                        >
                                            <ListItemText primary={item.label} />
                                        </ListItemLink>
                                        {isNotLargeScreen && navItems.length - 1 !== index ? <Divider orientation="vertical" flexItem /> : null}
                                    </React.Fragment>
                                );
                            })}
                        </List>
                    </Paper>
                </div>
                <div className={classes.right}>
                    <Paper className={clsx(classes.rightPaper, contentClassName)}>{children}</Paper>
                </div>
            </div>
        </TenantLayout>
    );
};

export default LeftNavLayout;
