import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import { Typography, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

import { useAuth } from '../../../../../auth/auth-context';
import { EnrollmentStatus, Grade, ReviewCondition } from '../../../../../common/logic/enums';
import enumHelper from '../../../../../common/logic/enumHelper';
import { FinalFormDropdown, FinalFormTextField, FinalFormCheckbox } from '../../../../../common/formWrappers';
import FlagSelect from './flagSelect';
import { CurrencyMaskInput, parseCurrency } from '../../../../../common/masks';
import { required } from '../../../../../common/formValidators';
import { OnChange } from 'react-final-form-listeners';

const useStyles = makeStyles((theme) => ({
    editCertifyYear: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    cell: {
        verticalAlign: 'top'
    },
    textArea: {
        marginBottom: 0
    }
}));

const EditCertifyYear = ({ data, year, onSave, onCancel }) => {
    const { getPeriodName } = useAuth();
    const classes = useStyles();

    const grades = enumHelper.getSelectOptionsList(Grade).filter((g) => g.value > 2);

    const onSubmit = (values) => {
        onSave({ ...values, academicYearId: year });
    };

    return (
        <Paper className={classes.editCertifyYear}>
            <Form onSubmit={onSubmit} initialValues={data} mutators={{ ...arrayMutators }}>
                {({ form, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="h6">{data.institution.name}</Typography>
                            <Box display="flex">
                                <Button color="secondary" variant="contained" onClick={onCancel}>
                                    Cancel
                                </Button>
                                <Box ml={1}>
                                    <Button type="submit" color="primary" variant="contained">
                                        Save &amp; Submit to DCYF
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Term</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Grade</TableCell>
                                    <TableCell>Conditions</TableCell>
                                    <TableCell>Unmet Need</TableCell>
                                    <TableCell>Term Cost</TableCell>
                                    <TableCell>Breakdown of Cost</TableCell>
                                    <TableCell>Certify</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <FieldArray name="certifications">
                                    {({ fields }) =>
                                        fields.map((name, index) => {
                                            const notEnrolled = fields.value[index].enrollmentStatus === EnrollmentStatus.NotEnrolled;
                                            let certifiedDisabled = false;
                                            if (data.certifications[index].certified) {
                                                certifiedDisabled = true;
                                            }

                                            if (!fields.value[index].released) {
                                                certifiedDisabled = true;
                                            }

                                            if (data.certifications[index].reviewConditions === 0 && fields.value[index].reviewConditions !== 0) {
                                                // changed from none to something
                                                certifiedDisabled = true;
                                            }

                                            if (
                                                fields.value[index].reviewConditions !== 0 &&
                                                data.certifications[index].reviewConditions !== fields.value[index].reviewConditions
                                            ) {
                                                // new value is not none and the conditions are different from initial
                                                certifiedDisabled = true;
                                            }

                                            if (data.certifications[index].needsReview && fields.value[index].reviewConditions !== 0) {
                                                certifiedDisabled = true;
                                            }

                                            return (
                                                <TableRow key={name}>
                                                    <TableCell className={classes.cell}>
                                                        {getPeriodName(fields.value[index].institutionId, fields.value[index].period)}
                                                    </TableCell>
                                                    <TableCell className={classes.cell}>
                                                        <Field
                                                            margin="dense"
                                                            name={`${name}.enrollmentStatus`}
                                                            component={FinalFormDropdown}
                                                            choices={enumHelper.getSelectOptionsList(EnrollmentStatus)}
                                                        />
                                                    </TableCell>
                                                    <TableCell className={classes.cell}>
                                                        <Field
                                                            validate={!notEnrolled && required}
                                                            key={notEnrolled ? 1 : 0} // required for dynamic validation to work
                                                            disabled={notEnrolled}
                                                            margin="dense"
                                                            placeholder="Select Grade..."
                                                            name={`${name}.currentGrade`}
                                                            component={FinalFormDropdown}
                                                            choices={grades}
                                                            format={(val) => (val === 0 ? null : val)}
                                                            parse={(val) => (val === null ? 0 : val)}
                                                        />
                                                    </TableCell>
                                                    <TableCell className={classes.cell}>
                                                        <Field
                                                            disabled={notEnrolled}
                                                            style={{ width: 200 }}
                                                            margin="dense"
                                                            name={`${name}.reviewConditions`}
                                                            component={FlagSelect}
                                                            enumDefinition={ReviewCondition}
                                                            format={(val) => (val === 0 ? null : val)}
                                                            parse={(val) => (val === null ? 0 : val)}
                                                        />
                                                    </TableCell>
                                                    <TableCell className={classes.cell}>
                                                        <Field
                                                            validate={!notEnrolled && required}
                                                            key={notEnrolled ? 1 : 0} // required for dynamic validation to work
                                                            disabled={notEnrolled}
                                                            margin="dense"
                                                            name={`${name}.unmetNeed`}
                                                            component={FinalFormTextField}
                                                            inputComponent={CurrencyMaskInput}
                                                            parse={parseCurrency}
                                                            inputProps={{
                                                                maskOptions: {
                                                                    allowDecimal: false
                                                                }
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell className={classes.cell}>
                                                        <Field
                                                            disabled={notEnrolled}
                                                            margin="dense"
                                                            name={`${name}.termCost`}
                                                            component={FinalFormTextField}
                                                            inputComponent={CurrencyMaskInput}
                                                            parse={parseCurrency}
                                                            inputProps={{
                                                                maskOptions: {
                                                                    allowDecimal: false
                                                                }
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell className={classes.cell}>
                                                        <Field
                                                            disabled={notEnrolled}
                                                            className={classes.textArea}
                                                            margin="dense"
                                                            name={`${name}.breakdownOfCost`}
                                                            component={FinalFormTextField}
                                                            multiline
                                                            rows={3}
                                                        />
                                                    </TableCell>
                                                    <TableCell className={classes.cell}>
                                                        <OnChange name={`${name}.reviewConditions`}>
                                                            {(value) => {
                                                                if (data.certifications[index].certified) {
                                                                    return;
                                                                }

                                                                if (value !== 0 && fields.value[index].certified) {
                                                                    form.change(`${name}.certified`, false);
                                                                }
                                                            }}
                                                        </OnChange>
                                                        <Tooltip
                                                            arrow
                                                            placement="left"
                                                            title={
                                                                !fields.value[index].released && !notEnrolled
                                                                    ? 'Record has not been released yet'
                                                                    : ''
                                                            }
                                                        >
                                                            <div>
                                                                <Field
                                                                    name={`${name}.certified`}
                                                                    color="primary"
                                                                    component={FinalFormCheckbox}
                                                                    disabled={certifiedDisabled}
                                                                />
                                                            </div>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    }
                                </FieldArray>
                            </TableBody>
                        </Table>
                    </form>
                )}
            </Form>
        </Paper>
    );
};

export default EditCertifyYear;
