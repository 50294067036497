import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import { useAuth } from '../../../../../auth/auth-context';
import { EnrollmentStatus, Grade } from '../../../../../common/logic/enums';
import enumHelper from '../../../../../common/logic/enumHelper';
import { conformedCurrency } from '../../../../../common/masks';

const useStyles = makeStyles((theme) => ({
    readOnlyCertifyYear: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

const ReadOnlyCertifyYear = ({ data, year, onEdit }) => {
    const classes = useStyles();
    const { user, getPeriodName } = useAuth();
    const canEdit = user.institution?.id === data.institution.id;

    return (
        <Paper className={classes.readOnlyCertifyYear} elevation={2}>
            <Box display="flex" justifyContent="space-between">
                <Typography variant="h6">{data.institution.name}</Typography>
                {canEdit && (
                    <Button startIcon={<EditIcon />} color="primary" onClick={onEdit}>
                        Edit
                    </Button>
                )}
            </Box>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Term</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Grade</TableCell>
                        <TableCell>Unmet Need</TableCell>
                        <TableCell>State Award</TableCell>
                        <TableCell>Federal Award</TableCell>
                        <TableCell>Certified Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.certifications.map((cert) => (
                        <TableRow key={`${cert.applicationId}-${cert.institutionId}-${cert.period}`}>
                            <TableCell>{getPeriodName(cert.institutionId, cert.period)}</TableCell>
                            <TableCell>{enumHelper.getDisplayName(EnrollmentStatus, cert.enrollmentStatus)}</TableCell>
                            <TableCell>{enumHelper.getDisplayName(Grade, cert.currentGrade)}</TableCell>
                            <TableCell>{conformedCurrency(cert.unmetNeed.toString(), { allowDecimal: false })}</TableCell>
                            <TableCell>{conformedCurrency(cert.stateAward.toString(), { allowDecimal: false })}</TableCell>
                            <TableCell>{conformedCurrency(cert.federalAward.toString(), { allowDecimal: false })}</TableCell>
                            <TableCell>
                                {cert.certified ? (
                                    <CheckBoxIcon fontSize="small" color="primary" />
                                ) : (
                                    <CheckBoxOutlineBlankIcon fontSize="small" color="primary" />
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
};

export default ReadOnlyCertifyYear;
