import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import DoneIcon from '@material-ui/icons/Done';
import { Redirect } from '@gatsbyjs/reach-router';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import { makeStyles } from '@material-ui/core/styles';

import * as UserApi from '../../../api/user';
import * as TenantApi from '../../../api/tenant';
import { useAuth } from '../../../auth/auth-context';

const useStyles = makeStyles((theme) => ({
    leftIcon: {
        marginRight: theme.spacing(1)
    }
}));

const isAdmin = (user) => {
    return user.roleNames.includes('ADMIN');
};

export default function TenantsUserList({ tenantId }) {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { impersonateLoading, tenant, impersonate } = useAuth();
    const [tenantName, setTenantName] = useState('');
    const classes = useStyles();

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            const items = (await UserApi.getAllByTenant(tenantId)).data.result.items;
            const tenantData = (await TenantApi.get(tenantId)).data.result;
            setData(items);
            setTenantName(tenantData.name);
            setIsLoading(false);
        }
        fetchData();
    }, [tenantId]);

    return (
        <div>
            {(isLoading || impersonateLoading) && <LinearProgress />}
            {tenant !== null && <Redirect to="/" />}
            <Box p={1} color="secondary.contrastText" bgcolor="secondary.main">
                <Toolbar variant="dense">
                    <Typography className={classes.tableTitle} variant="h6">
                        {tenantName} Users
                    </Typography>
                </Toolbar>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Username</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Active</TableCell>
                        <TableCell>Admin</TableCell>
                        <TableCell>Full Name</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.userName}</TableCell>
                            <TableCell>{row.emailAddress}</TableCell>
                            <TableCell>{row.isActive ? <DoneIcon color="primary" /> : null}</TableCell>
                            <TableCell>{isAdmin(row) ? <DoneIcon color="primary" /> : null}</TableCell>
                            <TableCell>{row.fullName}</TableCell>
                            <TableCell>
                                <Button variant="contained" size="small" color="primary" onClick={() => impersonate(tenantId, row.id)}>
                                    <SupervisorAccount className={classes.leftIcon} fontSize="small" />
                                    Impersonate
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}
