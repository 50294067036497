import { getContrastRatio } from '@material-ui/core';

export function RGBToHex(r, g, b, a) {
    r = r.toString(16);
    g = g.toString(16);
    b = b.toString(16);

    if (r.length === 1) r = '0' + r;
    if (g.length === 1) g = '0' + g;
    if (b.length === 1) b = '0' + b;

    return '#' + r + g + b;
}

export function RGBObjectToHex(rgba) {
    let r = rgba.r.toString(16);
    let g = rgba.g.toString(16);
    let b = rgba.b.toString(16);

    if (r.length === 1) r = '0' + r;
    if (g.length === 1) g = '0' + g;
    if (b.length === 1) b = '0' + b;

    return '#' + r + g + b;
}

export function HextoRGBObject(hexString, alpha) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexString);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
              a: alpha ? alpha : 1
          }
        : null;
}

export function getContrastText(color, contrastThreshold) {
    const contrastText = getContrastRatio(color, '#ffffff') >= contrastThreshold ? '#ffffff' : 'rgba(0,0,0,0.87)';

    if (process.env.NODE_ENV !== 'production') {
        const contrast = getContrastRatio(color, contrastText);
        if (contrast < 3) {
            // console.error(
            //     [
            //         `Material-UI: The contrast ratio of ${contrast}:1 for ${contrastText} on ${color}`,
            //         'falls below the WCAG recommended absolute minimum contrast ratio of 3:1.',
            //         'https://www.w3.org/TR/2008/REC-WCAG20-20081211/#visual-audio-contrast-contrast'
            //     ].join('\n')
            // );
        }
    }

    return contrastText;
}
