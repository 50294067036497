import React from 'react';
import EligibleStudentsList from './list';
import LeftNavLayout from '../../leftNavLayout';

export default function EligibleStudentsPage() {
    return (
        <LeftNavLayout>
            <EligibleStudentsList />
        </LeftNavLayout>
    );
}
