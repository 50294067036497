import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import { FinalFormCheckbox } from '../../../common/formWrappers';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    buttonWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

export default function FeaturesDialog({ open, handleClose, tenant, handleSave, isSaving, features }) {
    const classes = useStyles();

    function formatDataAndSave(values) {
        const data = { tenantId: tenant.id, features: features };

        const entries = Object.entries(values);
        for (let i = 0; i < entries.length; i++) {
            const feature = data.features.find((f) => {
                return f.name === entries[i][0];
            });
            feature.value = entries[i][1];
        }

        handleSave(data);
    }

    return (
        <Dialog open={open} onClose={handleClose} disableBackdropClick disableEscapeKeyDown>
            <DialogTitle>{tenant !== null ? `Edit '${tenant.name}' Features` : ''}</DialogTitle>
            <DialogContent>
                <Form onSubmit={(values) => formatDataAndSave({ ...values })}>
                    {(props) => (
                        <form onSubmit={props.handleSubmit}>
                            {features.map((item, index) => {
                                return (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Field
                                                name={item.name}
                                                component={FinalFormCheckbox}
                                                color="primary"
                                                initialValue={item.value === 'true' ? true : false}
                                            />
                                        }
                                        label={item.displayName}
                                    />
                                );
                            })}
                            <DialogActions>
                                <Button onClick={handleClose} disabled={isSaving}>
                                    Cancel
                                </Button>
                                <div className={classes.buttonWrapper}>
                                    <Button type="submit" disabled={isSaving}>
                                        Save
                                    </Button>
                                    {isSaving && <CircularProgress className={classes.buttonProgress} size={24} />}
                                </div>
                            </DialogActions>
                        </form>
                    )}
                </Form>
            </DialogContent>
        </Dialog>
    );
}
