import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import axios from 'axios';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { navigate } from '@gatsbyjs/reach-router';
import Tooltip from '@material-ui/core/Tooltip';
import { useSnackbar } from 'notistack';

import { ROLE_NAME_ADMIN, ROLE_NAME_FINANCE } from '../../../../common/logic/consts';
import * as AwardApi from '../../../../api/award';
import * as CertificationApi from '../../../../api/certification';
import AcademicYearSelector from '../../../../common/academicYearSelector';
import { conformedCurrency, conformedSsn } from '../../../../common/masks';
import { useAuth } from '../../../../auth/auth-context';
import { maskedSsn } from '../../../../common/logic/string';
import ToolbarSearchInput from '../../../../common/toolbarSearchInput';
import ConfirmDialog from '../../../../common/confirmDialog';
import CustomDataGrid from '../../../../common/customDataGrid';
import { hasPermission, PERMISSION_MANAGE_AWARDS } from '../../../../common/logic/permissions';
import UpdateAwardDialog from './components/updateAwardDialog';
import ConfigAwardDialog from './components/configAwardDialog';
import AwardSummary from './components/awardSummary';
import { certByEnrolledFormatter, dateFormatterUtc, GridCellExpand } from '../../../../common/logic/grid';
import { fade } from '@material-ui/core';

const currencyFormatter = ({ value }) => {
    if (value === null) {
        return '--';
    }

    return conformedCurrency(value.toString());
};

const yesNoFormatter = ({ value }) => {
    if (value === null) {
        return '--';
    }

    return value ? 'Yes' : 'No';
};

const ssnFormatter = ({ value }) => maskedSsn(value);

const useStyles = makeStyles((theme) => ({
    button: {
        marginBottom: theme.spacing(1)
    },
    leftMargin: {
        marginLeft: theme.spacing(1)
    },
    grid: {
        '& .MuiDataGrid-cell.released': {
            backgroundColor: fade(theme.palette.success.main, 0.1)
        }
    }
}));

const sortModel = [{ field: 'lastName', sort: 'desc' }];

const CertificationList = () => {
    const { user, permissions } = useAuth();
    const [loading, setLoading] = useState(true);
    const [selectedYear, setSelectedYear] = useState('');
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    // const [status, setStatus] = useState('All');
    const [search, setSearch] = useState('');
    const [showEditAward, setShowEditAward] = useState(null);
    const [showConfigAward, setShowConfigAward] = useState(false);
    const [showConfirmRelease, setShowConfirmRelease] = useState(false);
    const [totalAwardsForRelease, setTotalAwardsForRelease] = useState(0);
    const [award, setAward] = useState(null);
    const classes = useStyles();
    const canManageAwards = hasPermission(permissions, [PERMISSION_MANAGE_AWARDS]);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const tokenSource = axios.CancelToken.source();

        const load = async () => {
            try {
                setLoading(true);
                const data = (await CertificationApi.getStudentList(selectedYear, tokenSource.token)).data.result;
                const count = (await CertificationApi.getCertificationCountForRelease(selectedYear)).data.result;
                if (user.roleName === ROLE_NAME_ADMIN || user.roleName === ROLE_NAME_FINANCE) {
                    const awardData = (await AwardApi.getAwardBalance(selectedYear)).data.result;
                    setAward(awardData);
                }
                setTotalAwardsForRelease(count);
                setRows(data);
                setLoading(false);
            } catch {}
        };

        if (selectedYear !== '' && showConfigAward === false && showEditAward === null && showConfirmRelease === false) {
            load();
        }

        return () => {
            tokenSource.cancel();
        };
    }, [selectedYear, showConfigAward, showEditAward, showConfirmRelease]);

    useEffect(() => {
        const searchValue = search.toLowerCase();
        setFilteredRows(
            rows.filter(
                (i) =>
                    i.firstName.toLowerCase().includes(searchValue) ||
                    i.lastName.toLowerCase().includes(searchValue) ||
                    i.ssn.includes(searchValue) ||
                    conformedSsn(i.ssn).includes(searchValue)
            )
        );
    }, [rows, search]);

    const handleReleaseClick = async () => {
        try {
            setLoading(true);
            await CertificationApi.releaseCertifications(selectedYear);
            setShowConfirmRelease(false);
            enqueueSnackbar('Awards have been released and Institutions have been notified.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Failed to release awards', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const nameRenderCell = ({ value, colDef }) => {
        return (
            <GridCellExpand width={200}>
                <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 1.3 }}>{value}</div>
            </GridCellExpand>
        );
    };

    const columns = [
        {
            field: 'lastName',
            headerName: 'Last Name',
            flex: 1,
            renderCell: nameRenderCell,
            cellClassName: (params) => (params.row.released ? 'released' : '')
        },
        {
            field: 'firstName',
            headerName: 'First Name',
            flex: 1,
            renderCell: nameRenderCell,
            cellClassName: (params) => (params.row.released ? 'released' : '')
        },
        {
            field: 'birthDate',
            headerName: 'DOB',
            type: 'date',
            flex: 1,
            valueFormatter: dateFormatterUtc,
            cellClassName: (params) => (params.row.released ? 'released' : '')
        },
        {
            field: 'ssn',
            headerName: 'SSN',
            flex: 1,
            valueFormatter: ssnFormatter,
            cellClassName: (params) => (params.row.released ? 'released' : '')
        },
        {
            field: 'numberOfCertifications',
            headerName: 'Certifications/EnrolledTerms',
            flex: 1,
            valueFormatter: certByEnrolledFormatter,
            cellClassName: (params) => (params.row.released ? 'released' : '')
        },
        {
            field: 'totalUnmetNeed',
            headerName: 'Unmet Need',
            flex: 1,
            valueFormatter: currencyFormatter,
            cellClassName: (params) => (params.row.released ? 'released' : '')
        },
        {
            field: 'needsReview',
            headerName: 'Needs Review',
            flex: 1,
            valueFormatter: yesNoFormatter,
            cellClassName: (params) => (params.row.released ? 'released' : '')
        },
        {
            field: 'totalStateAward',
            headerName: 'State Awarded',
            flex: 1,
            valueFormatter: currencyFormatter,
            cellClassName: (params) => (params.row.released ? 'released' : '')
        },
        {
            field: 'totalFederalAward',
            headerName: 'Federal Awarded',
            flex: 1,
            valueFormatter: currencyFormatter,
            cellClassName: (params) => (params.row.released ? 'released' : '')
        },
        {
            field: 'released',
            headerName: 'Released',
            flex: 1,
            valueFormatter: yesNoFormatter,
            cellClassName: (params) => (params.row.released ? 'released' : '')
        },
        {
            field: 'actions',
            headerName: 'Action',
            width: 150,
            sortable: false,
            renderCell: (params) => (
                <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
                    <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => navigate(`/scholarship/certify/${params.row['applicationId']}`)}
                    >
                        Certify
                    </Button>

                    {canManageAwards && (
                        <Button
                            disabled={!params.row.numberOfEnrolledTerms}
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => setShowEditAward(params.row)}
                        >
                            Edit Award
                        </Button>
                    )}
                </Box>
            ),
            cellClassName: (params) => (params.row.released ? 'released' : '')
        }
    ];

    if (user.roleName === ROLE_NAME_ADMIN) {
        columns.splice(4, 0, {
            field: 'institutionName',
            headerName: 'Institution',
            flex: 1,
            renderCell: nameRenderCell,
            cellClassName: (params) => (params.row.released ? 'released' : '')
        });
    }

    return (
        <div>
            {(user.roleName === ROLE_NAME_ADMIN || user.roleName === ROLE_NAME_FINANCE) && (
                <Box mb={1}>
                    <AwardSummary award={award} totalFederal={award?.award?.federalOver} totalState={award?.award?.stateOver} />
                </Box>
            )}

            <Box p={1} display="flex" justifyContent="space-between" color="secondary.contrastText" bgcolor="secondary.main">
                <Toolbar variant="dense">
                    <Box display="flex" flex={1} alignItems="center" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                            <Typography variant="h6">Certification List</Typography>
                            <ToolbarSearchInput value={search} onChange={(val) => setSearch(val)} />
                            <AcademicYearSelector inTableHeader value={selectedYear} onChange={(val) => setSelectedYear(val)} />
                            {canManageAwards && (
                                <>
                                    <Button
                                        className={classes.leftMargin}
                                        color="primary"
                                        variant="contained"
                                        onClick={() => setShowConfigAward(true)}
                                    >
                                        Configure Award
                                    </Button>
                                    <Tooltip
                                        arrow
                                        placement="top"
                                        title={loading ? 'Loading' : totalAwardsForRelease === 0 ? 'No awards for release' : ''}
                                    >
                                        <div>
                                            <Button
                                                className={classes.leftMargin}
                                                color="primary"
                                                variant="contained"
                                                disabled={loading || totalAwardsForRelease === 0}
                                                onClick={() => setShowConfirmRelease(true)}
                                            >
                                                Release Awards for Certification
                                            </Button>
                                        </div>
                                    </Tooltip>
                                </>
                            )}
                        </Box>
                    </Box>
                </Toolbar>
            </Box>
            <CustomDataGrid
                className={classes.grid}
                getRowId={(row) => row.userId}
                disableColumnMenu
                disableColumnFilter
                initialSortModel={sortModel}
                disableSelectionOnClick
                rows={filteredRows}
                rowHeight={100}
                columns={columns}
                initialPageSize={25}
                loading={loading}
                rowsPerPageOptions={[25, 50, 100]}
            />
            {showEditAward !== null && (
                <UpdateAwardDialog
                    open={showEditAward !== null}
                    academicYearId={selectedYear}
                    item={showEditAward}
                    onClose={() => setShowEditAward(null)}
                />
            )}
            <ConfigAwardDialog academicYearId={selectedYear} open={showConfigAward} onClose={() => setShowConfigAward(false)} />
            <ConfirmDialog
                confirmAction={handleReleaseClick}
                open={showConfirmRelease}
                handleClose={() => setShowConfirmRelease(false)}
                title="Release Award(s)?"
                body="Are you sure you are ready to release the award(s)? Once released the institutions will be notified and certification will be enabled."
                confirmActionText="Yes, Release Award(s)"
            />
        </div>
    );
};

export default CertificationList;
