import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import clsx from 'clsx';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { formatProfile } from './profile';

import ChangeEmailDialog from '../../../common/changeEmailDialog';
import * as ProfileApi from '../../../api/profile';
import { useAuth } from '../../../auth/auth-context';
import { navigate } from '@gatsbyjs/reach-router';
import TenantLayout from '../../tenantLayout';
import EditProfileForm from './editProfileForm';
import ReadOnlyProfile from './readOnlyProfile';

const useStyles = makeStyles((theme) => ({
    studentHome: {
        marginLeft: theme.spacing(1),
        maxWidth: 1000,
        padding: theme.spacing(1),
        [theme.breakpoints.only('xs')]: {
            padding: 0,
            margin: theme.spacing(1)
        }
    },
    extraBottomMargin: {
        marginBottom: 58
    }
}));

const StudentHome = () => {
    const classes = useStyles();
    const { user, isImpersonating, updateUserInfo } = useAuth();
    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState(null);
    const [editing, setEditing] = useState(false);
    const [showEmail, setShowEmail] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.only('xs'));

    const extraBottomMargin = isXS && isImpersonating;

    useEffect(() => {
        const tokenSource = axios.CancelToken.source();

        (async () => {
            try {
                setLoading(true);
                const profileData = formatProfile((await ProfileApi.get(user.id, tokenSource.token)).data.result);
                setProfile(profileData);
                if (!profileData.ssn) {
                    setEditing(true);
                }
            } finally {
                setLoading(false);
            }
        })();

        return () => {
            tokenSource.cancel();
        };
    }, [user.id]);

    const handleSave = async (values) => {
        try {
            setLoading(true);
            const response = await ProfileApi.createOrUpdate(values);
            enqueueSnackbar('Profile saved successfully', { variant: 'success' });
            setEditing(false);
            const profileData = formatProfile(response.data.result);
            setProfile(profileData);
            updateUserInfo({ name: profileData.firstName, surname: profileData.lastName });
        } catch (ex) {
            enqueueSnackbar('Failed to save profile', { variant: 'error' });
        } finally {
            setLoading(false);
            if (values.action === 'continue') {
                navigate('/applications');
            }
        }
    };

    return (
        <TenantLayout>
            <Paper className={clsx(classes.studentHome, extraBottomMargin && classes.extraBottomMargin)}>
                {profile && editing ? (
                    <EditProfileForm
                        editEmail={() => setShowEmail(true)}
                        handleSave={handleSave}
                        profile={profile}
                        loading={loading}
                        onCancel={() => setEditing(false)}
                    />
                ) : profile ? (
                    <ReadOnlyProfile profile={profile} loading={loading} onEditClick={() => setEditing(true)} showHeader />
                ) : (
                    <CircularProgress />
                )}
                <ChangeEmailDialog open={showEmail} onClose={() => setShowEmail(false)} />
            </Paper>
        </TenantLayout>
    );
};

export default StudentHome;
