export const StartLogin_Type = 'start login';
export const UpdateEmailConfirmation_Type = 'update email confirmation';
export const UpdateUserInfo_Type = 'update user info';
export const UpdateUserEmail_Type = 'update user email';
export const UpdateSettings_Type = 'update settings';
export const StartImpersonate_Type = 'start impersonate';
export const FailImpersonate_Type = 'fail impersonate';
export const UpdateToken_Type = 'update token';
export const UpdateSession_Type = 'update session';
export const UpdateTenant_Type = 'update tenant';
export const FailLogin_Type = 'fail login';
export const Logout_Type = 'logout';
