/* eslint react-hooks/exhaustive-deps: 0 */

import React, { useEffect, useMemo } from 'react';
import axios from 'axios';
import { navigate } from '@gatsbyjs/reach-router';

import * as AuthApi from '../api/auth';
import * as SessionApi from '../api/session';
import { AuthReducer, initAuthState } from './authReducer';
import { setAuthStorage, clearAuthStorage } from './auth-storage';
import { isDev } from '../common/logic/environment';
import { TermStructure } from '../common/logic/enums';

import * as Actions from './actionTypes';

// Disable axios request caching
axios.defaults.headers = {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0'
};

const AuthStateContext = React.createContext();

function AuthProvider({ children }) {
    const [state, dispatch] = React.useReducer(AuthReducer, null, initAuthState);

    const handleLogin = async (username, password, tenantId = null) => {
        dispatch({ type: Actions.StartLogin_Type });
        try {
            const loginData = (await AuthApi.authenticate(username, password, tenantId)).data.result;
            if (loginData.accountInactive) {
                dispatch({ type: Actions.Logout_Type });
                navigate('/inactive');
                return;
            }
            loadSession(loginData);
            if (tenantId == null) {
                navigate('/');
            }
        } catch (error) {
            dispatch({ type: Actions.FailLogin_Type, error });
        }
    };

    const loadSession = async (loginData) => {
        try {
            const sessionResponse = (await SessionApi.loadSession(loginData.accessToken)).data.result;
            const expirationDate = new Date();
            expirationDate.setSeconds(expirationDate.getSeconds() + loginData.expireInSeconds);

            const tenant = isDev()
                ? !sessionResponse.tenant
                    ? { id: parseInt(localStorage.getItem('dev_tenantId'), 10) } || sessionResponse.tenant
                    : sessionResponse.tenant
                : sessionResponse.tenant;

            // Always set tenant data
            dispatch({
                type: Actions.UpdateTenant_Type,
                tenant
            });

            // Always set settings
            dispatch({
                type: Actions.UpdateSettings_Type,
                settings: sessionResponse.settings
            });

            if (sessionResponse.user === null) {
                handleLogout();
                return;
            }

            setAuthStorage(loginData.accessToken, loginData.refreshToken, expirationDate, true);
            dispatch({
                type: Actions.UpdateToken_Type, // Need to update token before everything else for interceptor
                accessToken: loginData.accessToken
            });

            dispatch({
                type: Actions.UpdateSession_Type,
                accessToken: loginData.accessToken,
                refreshToken: loginData.refreshToken,
                expiration: expirationDate,
                user: sessionResponse.user,
                permissions: sessionResponse.permissions,
                settings: sessionResponse.settings,
                tenant: sessionResponse.tenant,
                isImpersonating: sessionResponse.isImpersonating,
                nav: sessionResponse.nav,
                institutions: sessionResponse.institutions
            });
        } catch (error) {
            dispatch({ type: Actions.FailLogin_Type, error });
        }
    };

    const handleLogout = useMemo(
        () => async () => {
            clearAuthStorage();
            dispatch({ type: Actions.Logout_Type });
        },
        [dispatch]
    );

    const refreshToken = async () => {
        try {
            const loginData = (await AuthApi.exchangeRefreshToken(state.refreshToken, state.accessToken)).data.result;
            loadSession(loginData);
        } catch (error) {
            loadSession({
                accessToken: '',
                refreshToken: '',
                expireInSeconds: 0
            });
        }
    };

    const handleUpdateUserInfo = (info) => {
        dispatch({ type: Actions.UpdateUserInfo_Type, info });
    };

    const handleUpdateUserEmail = (email) => {
        dispatch({ type: Actions.UpdateUserEmail_Type, email });
    };

    const handleConfirmEmail = () => {
        dispatch({
            type: Actions.UpdateEmailConfirmation_Type,
            confirm: true
        });
    };

    const handleImpersonate = async (tenantId, userId) => {
        dispatch({ type: Actions.StartImpersonate_Type });
        try {
            const loginData = (await AuthApi.impersonate(tenantId, userId)).data.result;
            loadSession(loginData);
            navigate('/');
        } catch (error) {
            dispatch({ type: Actions.FailImpersonate_Type, error });
        }
    };

    const handleStopImpersonating = async () => {
        dispatch({ type: Actions.StartImpersonate_Type });
        try {
            const loginData = (await AuthApi.stopImpersonating()).data.result;
            loadSession(loginData);
            navigate('/admin/users');
        } catch (error) {
            dispatch({ type: Actions.FailImpersonate_Type, error });
        }
    };

    const handlePostRegisterLogin = async (loginData) => {
        await loadSession(loginData);
    };

    const getPeriodName = (institutionId, period) => {
        function getOrdinal(number) {
            let j = number % 10,
                k = number % 100;
            if (j === 1 && k !== 11) {
                return number + 'st';
            }
            if (j === 2 && k !== 12) {
                return number + 'nd';
            }
            if (j === 3 && k !== 13) {
                return number + 'rd';
            }
            return number + 'th';
        }

        const institution = state.institutions.find((i) => i.id === institutionId);

        if (!institution) {
            return period;
        }

        switch (institution.termStructure) {
            case TermStructure.Semester:
                return period === 1 ? 'Fall Semester' : period === 2 ? 'Spring Semester' : 'Summer Term';
            case TermStructure.Trimester:
                return period === 4 ? 'Summer Term' : `${getOrdinal(period)} Trimester`;
            case TermStructure.Quarter:
                return period === 5 ? 'Summer Term' : `${getOrdinal(period)} Quarter`;
            case TermStructure.Other:
                return `${getOrdinal(period)} Term`;
            default:
                throw new Error('Unknown term structure');
        }
    };

    useEffect(() => {
        if (state.expiration) {
            const dateDifference = state.expiration - new Date();
            const expireInSeconds = dateDifference / 1000;
            if (expireInSeconds > 0) {
                loadSession({
                    accessToken: state.accessToken,
                    refreshToken: state.refreshToken,
                    expireInSeconds
                });
            } else {
                refreshToken();
            }
        } else {
            loadSession({
                accessToken: '',
                refreshToken: '',
                expireInSeconds: 0
            });
        }
    }, []);

    useEffect(() => {
        const requestInterceptor = axios.interceptors.request.use((config) => {
            config.headers['Cache-Control'] = 'no-store';

            if (state.accessToken && !config.headers['Authorization']) {
                config.headers.Authorization = `Bearer ${state.accessToken}`;
            }

            if (isDev() && !state.user && !config.url.toLowerCase().includes('tokenauth/authenticate')) {
                const devTenantId = parseInt(localStorage.getItem('dev_tenantId'), 10);
                if (devTenantId) {
                    config.headers['Abp.TenantId'] = devTenantId;
                }
            }
            return config;
        });

        return () => {
            axios.interceptors.request.eject(requestInterceptor);
        };
    }, [state.accessToken]);

    useEffect(() => {
        let refreshTimer = 0;
        if (state.expiration !== null) {
            const remainingTimeMinutes = (state.expiration - new Date()) / (1000 * 60);
            if (remainingTimeMinutes <= 5) {
                refreshToken();
            } else {
                refreshTimer = setTimeout(() => {
                    refreshToken();
                }, (remainingTimeMinutes - 5) * 60 * 1000);
            }
        }

        return () => {
            clearTimeout(refreshTimer);
        };
    }, [state.expiration]);

    return (
        <AuthStateContext.Provider
            value={{
                ...state,
                logout: handleLogout,
                login: handleLogin,
                updateUserInfo: handleUpdateUserInfo,
                updateUserEmail: handleUpdateUserEmail,
                confirmEmail: handleConfirmEmail,
                impersonate: handleImpersonate,
                stopImpersonating: handleStopImpersonating,
                postRegisterLogin: handlePostRegisterLogin,
                getPeriodName
            }}
        >
            {children}
        </AuthStateContext.Provider>
    );
}

function useAuth() {
    const context = React.useContext(AuthStateContext);
    if (context === undefined) {
        throw new Error('useAuthState must be used within an AuthProvider');
    }
    return context;
}

export { AuthProvider, useAuth };
