import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';

import LeftNavLayout from '../../leftNavLayout';
import StartCloseYear from './startCloseYear';

const useStyles = makeStyles((theme) => ({
    content: {
        minHeight: 500
    }
}));

const GrantSettingsPage = () => {
    const classes = useStyles();

    return (
        <LeftNavLayout contentClassName={classes.content}>
            <StartCloseYear />
        </LeftNavLayout>
    );
};

export default GrantSettingsPage;
