import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Form, Field } from 'react-final-form';
import { useAuth } from '../../auth/auth-context';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { FinalFormTextField, FinalFormNativeDropdown, FinalFormCheckbox, FinalFormFileUpload } from '../formWrappers';
import { required } from '../formValidators';
import { GroupType } from '../logic/enums';
import { ProfileImage } from '../fileUpload/profileImage';

export default function DebugPage() {
    const { user, tenant } = useAuth();
    const [formValues, setFormValues] = useState({});
    const { enqueueSnackbar } = useSnackbar();

    async function submit(values) {
        try {
            setFormValues(values);
            enqueueSnackbar('Form Submitted!', { variant: 'success' });
        } finally {
        }
    }

    return (
        <div>
            <Paper style={{ padding: '10px' }}>
                <Typography variant="h4">Debug Info</Typography>
                <Typography variant="body1">{`User: ${user.name} ${user.surname} Tenant: ${tenant.id}`}</Typography>

                <Typography variant="h5">Test Form</Typography>
                <Form onSubmit={(values) => submit(values)}>
                    {(props) => (
                        <form onSubmit={props.handleSubmit}>
                            <Field
                                margin="dense"
                                name="firstName"
                                required
                                label="First Name"
                                id="firstName"
                                maxLength={25}
                                component={FinalFormTextField}
                                validate={required}
                            />
                            <Field
                                component={FinalFormNativeDropdown}
                                name="state"
                                label="State"
                                placeholder="Select a state"
                                margin="dense"
                                validate={required}
                                required
                                choices={[
                                    { value: 'MA', label: 'MA' },
                                    { value: 'RI', label: 'RI' }
                                ]}
                            />
                            <Field component={FinalFormCheckbox} name="awesome" label="Awesome" margin="dense" caption={'Are you awesome?'} />
                            <Field
                                component={FinalFormFileUpload}
                                childRender={(props) => <ProfileImage {...props} />}
                                groupType={GroupType.Image}
                                thumbnailWidth={100}
                                thumbnailHeight={100}
                                onUploadComplete={() => enqueueSnackbar('Upload complete', { variant: 'info' })}
                                // dropzoneProps={options.dropzoneProps}
                                name="image"
                                label="Image"
                            />
                            <div>
                                <Button type="submit" color="primary" variant="contained">
                                    Submit
                                </Button>
                            </div>
                        </form>
                    )}
                </Form>
                <Typography variant="body1">{JSON.stringify(formValues)}</Typography>
            </Paper>
        </div>
    );
}
