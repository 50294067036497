/* eslint react-hooks/exhaustive-deps: 0 */

import React, { useEffect } from 'react';
import queryString from 'query-string';
import { navigate } from '@gatsbyjs/reach-router';
import { useSnackbar } from 'notistack';
import { useAuth } from '../../auth/auth-context';

import { isDev } from '../../common/logic/environment';
import * as AccountApi from '../../api/account';

export default function AccountVerification({ location }) {
    const qs = queryString.parse(location.search);
    const { enqueueSnackbar } = useSnackbar();
    const { user, confirmEmail } = useAuth();

    useEffect(() => {
        if (!qs.code) {
            navigate('/', { replace: true });
            return;
        }

        async function verifyAccount() {
            try {
                var result = (await AccountApi.verify(qs.code, isDev() ? 1 : null, true)).data.result;

                if (!result.success) {
                    navigate('/');
                    return;
                }

                confirmEmail();

                if (result.accountAlreadyVerified) {
                    enqueueSnackbar('You have already verified your account.', { variant: 'success' });
                } else {
                    enqueueSnackbar('Email was successfully verified!', { variant: 'success' });
                }
            } catch (ex) {
                let message =
                    "We're sorry but that verification link has expired. Click the Resend Email button to request a new verification email.";
                if (user === null) {
                    message = "We're sorry but that verification link has expired. Login to request a new email to confirm your account.";
                }

                enqueueSnackbar(message, { variant: 'error' });
            }

            navigate('/', { replace: true });
        }

        verifyAccount();
    }, []);

    return <div></div>;
}
