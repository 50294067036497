export const PERMISSION_PAGES_USERS = 'Pages.Users';
export const PERMISSION_PAGES_ROLES = 'Pages.Roles';
export const PERMISSION_IMPERSONATE = 'Impersonate';
export const PERMISSION_THEMES = 'Themes';
export const PERMISSION_FEATURES = 'Features';
export const PERMISSION_TRANSACTIONS_CREATE = 'Transactions.Create';
export const PERMISSION_INSTITUTIONS = 'Institutions';
export const PERMISSION_GRANTSETTINGS_EDIT = 'GrantSettings.Edit';
export const PERMISSION_ELIGIBLESTUDENTS = 'EligibleStudents';
export const PERMISSION_APPCONFIG = 'AppConfig';
export const PERMISSION_PAGES_APPLICANTS = 'Pages.Applicants';
export const PERMISSION_APPLICATIONS_VIEW = 'Applications.View';
export const PERMISSION_APPLICATIONS_EDIT = 'Applications.Edit';
export const PERMISSION_PROFILE_VIEW = 'Profile.View';
export const PERMISSION_PROFILE_EDIT = 'Profile.Edit';
export const PERMISSION_MANAGE_JOBS = 'ManageJobs';
export const PERMISSION_MANAGE_AWARDS = 'Manage.Awards';
export const PERMISSION_REPORTS = 'Reports';

export const hasPermission = (userPermissions, requiredPermissionSet, allRequired = false) => {
    if (requiredPermissionSet.length === 0) {
        return true;
    }

    let permissionsFound = 0;

    for (let i in requiredPermissionSet) {
        if (userPermissions.indexOf(requiredPermissionSet[i]) > -1) {
            if (!allRequired) {
                return true;
            }

            if (++permissionsFound === requiredPermissionSet.length) {
                return true;
            }
        }
    }

    return false;
};
