import React from 'react';
import Login from './login';

export default function LoginPage() {
    return (
        <div>
            <Login />
        </div>
    );
}
