import React, { Fragment } from 'react';

import AddAPhotoOutlinedIcon from '@material-ui/icons/AddAPhotoOutlined';
import LinearProgress from '@material-ui/core/LinearProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    circle: {
        width: 100,
        height: 100,
        borderRadius: 100,
        backgroundColor: theme.palette.grey[500],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden'
    },
    img: {
        width: 100
    },
    iconLarge: { fontSize: '4em' },
    description: { flex: 1, padding: theme.spacing(2), display: 'flex', flexDirection: 'column', justifyContent: 'center' },
    caption: { fontWeight: 600 },
    limit: { color: 'gray' }
}));

export const ProfileImage = ({ captionText, limitText, uploadingFiles, value, response }) => {
    const classes = useStyles();
    // console.log(response);
    return (
        <Fragment>
            <div className={classes.circle}>
                {value ? (
                    <img className={classes.img} src={`/Image/GetThumbnail/${value}`} alt="profile_image" />
                ) : (
                    <AddAPhotoOutlinedIcon fontSize="large" classes={{ fontSizeLarge: classes.iconLarge }} />
                )}
            </div>
            <div className={classes.description}>
                <span className={classes.caption}>{captionText}</span>
                <span className={classes.limit}>{limitText}</span>
                {uploadingFiles.map(file => (
                    <LinearProgress
                        key={file.index}
                        variant="buffer"
                        value={parseInt(file.percentComplete, 10)}
                        valueBuffer={parseInt(file.buffer, 10)}
                        color="secondary"
                    />
                ))}
            </div>
        </Fragment>
    );
};
