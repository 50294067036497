import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import makeStyles from '@material-ui/styles/makeStyles';
import { Form, Field } from 'react-final-form';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';

import * as AwardApi from '../../../../../api/award';
import BackdropLoader from '../../../../../common/backdropLoader';
import { FinalFormTextField } from '../../../../../common/formWrappers';
import { CurrencyMaskInput, conformedCurrency, parseCurrency } from '../../../../../common/masks';
import { required, requiredAllowZero } from '../../../../../common/formValidators';
import AwardSummary from './awardSummary';
import AwardPercent from './awardPercent';
import ConfirmDialog from '../../../../../common/confirmDialog';

const useStyles = makeStyles((theme) => ({
    configAwardDialog: {},
    form: {
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    fundTable: {
        width: 800,
        marginBottom: theme.spacing(1)
    },
    field: {
        marginBottom: 0
    },
    percent: {
        alignSelf: 'flex-end',
        marginBottom: 5,
        marginLeft: theme.spacing(0.5),
        fontSize: '1.5em'
    },
    cell: {
        paddingLeft: theme.spacing(3)
    },
    label: {
        marginBottom: theme.spacing(0.5)
    }
}));

const ConfigAwardDialog = ({ academicYearId, open, onClose }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [award, setAward] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const load = async () => {
            try {
                setLoading(true);
                const awardData = (await AwardApi.getAwardBalance(academicYearId)).data.result;
                awardData.awardType = '1';
                setAward(awardData);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        if (academicYearId && open) {
            load();
        }
    }, [academicYearId, open]);

    const onSave = async (values) => {
        try {
            setSaving(true);
            await AwardApi.applyAwards({
                academicYearId: academicYearId,
                federalFunds: values.award.federalFunds,
                stateFunds: values.award.stateFunds,
                overawardAmount: values.award.overawardAmount,
                coveragePercentage: values.coveragePercentage,
                coverageType: values.awardType,
                federalCap: values.award.federalCap
            });
            setSaving(false);
            enqueueSnackbar('Award configuration saved and applied.', { variant: 'success' });
            onClose();
        } catch (error) {
            console.error(error);
            setSaving(false);
            enqueueSnackbar('Error saving configuration.', { variant: 'error' });
        }
    };

    const calcOver = (amount, percent) => {
        const val1 = parseInt(amount, 10);
        const val2 = parseInt(percent, 10);

        if (isNaN(val1) || isNaN(val2)) {
            return 0;
        }

        return Math.floor(val1 + val1 * (val2 / 100));
    };

    return (
        <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose} disableBackdropClick>
            <BackdropLoader open={loading} />
            <Form onSubmit={onSave} initialValues={award}>
                {({ handleSubmit, values, errors }) => {
                    console.log();
                    return (
                        <form className={classes.form} noValidate onSubmit={handleSubmit}>
                            <DialogTitle>Award Configuration</DialogTitle>
                            <DialogContent>
                                <Box mb={1}>
                                    <AwardSummary
                                        award={award}
                                        totalFederal={calcOver(values?.award?.federalFunds, values?.award?.overawardAmount)}
                                        totalState={calcOver(values?.award?.stateFunds, values?.award?.overawardAmount)}
                                    />
                                </Box>

                                <Table size="small" className={classes.fundTable}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <Field
                                                    className={classes.field}
                                                    margin="dense"
                                                    name="award.stateFunds"
                                                    label="State Funds"
                                                    component={FinalFormTextField}
                                                    inputComponent={CurrencyMaskInput}
                                                    parse={parseCurrency}
                                                    validate={required}
                                                    inputProps={{
                                                        maskOptions: {
                                                            allowDecimal: false
                                                        }
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Field
                                                    className={classes.field}
                                                    margin="dense"
                                                    name="award.federalFunds"
                                                    label="Federal Funds"
                                                    component={FinalFormTextField}
                                                    inputComponent={CurrencyMaskInput}
                                                    parse={parseCurrency}
                                                    validate={required}
                                                    inputProps={{
                                                        maskOptions: {
                                                            allowDecimal: false
                                                        }
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Field
                                                    className={classes.field}
                                                    margin="dense"
                                                    name="award.overawardAmount"
                                                    label="Over-award Percentage"
                                                    component={FinalFormTextField}
                                                    inputComponent={CurrencyMaskInput}
                                                    parse={parseCurrency}
                                                    validate={requiredAllowZero}
                                                    inputProps={{
                                                        maskOptions: {
                                                            allowDecimal: false,
                                                            prefix: '',
                                                            suffix: '%'
                                                        }
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Field
                                                    className={classes.field}
                                                    margin="dense"
                                                    name="award.federalCap"
                                                    label="Federal Award Yearly Cap"
                                                    component={FinalFormTextField}
                                                    inputComponent={CurrencyMaskInput}
                                                    parse={parseCurrency}
                                                    validate={required}
                                                    inputProps={{
                                                        maskOptions: {
                                                            allowDecimal: false
                                                        }
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Box display="flex" flexDirection="column">
                                                    <FormLabel className={classes.label}>State Over-award</FormLabel>
                                                    {conformedCurrency(
                                                        calcOver(values?.award?.stateFunds, values?.award?.overawardAmount).toString(),
                                                        {
                                                            allowDecimal: false,
                                                            allowNegative: true
                                                        }
                                                    )}
                                                </Box>
                                            </TableCell>
                                            <TableCell colSpan={3}>
                                                <Box display="flex" flexDirection="column">
                                                    <FormLabel className={classes.label}>Federal Over-award</FormLabel>
                                                    {conformedCurrency(
                                                        calcOver(values?.award?.federalFunds, values?.award?.overawardAmount).toString(),
                                                        {
                                                            allowDecimal: false,
                                                            allowNegative: true
                                                        }
                                                    )}
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <AwardPercent values={values} academicYearId={academicYearId} />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={onClose} disabled={saving}>
                                    Cancel
                                </Button>
                                <Button
                                    disabled={saving || Boolean(Object.keys(errors).length !== 0)}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setShowConfirm(true)}
                                >
                                    Save
                                </Button>
                            </DialogActions>
                            <ConfirmDialog
                                confirmAction={() => setShowConfirm(false)}
                                open={showConfirm}
                                disablePortal
                                handleClose={() => setShowConfirm(false)}
                                title="Set Award Configuration"
                                body="Are you sure you are ready to set the award? If applicable, award values will be distributed to students and you will need to release the awards in order for the institution to certify. If student award has already been released and is now updating it will immediately be applied as a transaction to the student's account."
                                confirmActionText="Yes, Save Award Configuration"
                                isSaving={saving}
                                isSubmit
                            />
                        </form>
                    );
                }}
            </Form>
        </Dialog>
    );
};

export default ConfigAwardDialog;
