import React, { useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { useLocation, navigate } from '@gatsbyjs/reach-router';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { lighten } from '@material-ui/core/styles';
import { darken, useMediaQuery, useTheme } from '@material-ui/core';

import { getTopNavItems } from '../common/logic/nav';
import { useAuth } from '../auth/auth-context';
import ChangePasswordDialog from '../common/changePassword/dialog';
import ChangeEmailDialog from '../common/changeEmailDialog';

const useStyles = makeStyles((theme) => ({
    appBar: {
        flexDirection: 'row',
        backgroundColor: theme.palette.header.main,
        color: theme.palette.getContrastText(theme.palette.header.main),
        alignItems: 'center',
        padding: theme.spacing(0, 2),
        justifyContent: 'space-between',
        minHeight: 58
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'row-reverse'
        }
    },
    drawer: {
        backgroundColor: lighten(theme.palette.header.main, 0.1),
        color: theme.palette.header.contrastText
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 1),

        justifyContent: 'center'
    },
    drawerHeaderButton: {
        color: theme.palette.header.contrastText
    },
    divider: {
        backgroundColor: darken(theme.palette.header.contrastText, 0.25)
    },
    selectedListItem: {
        '&.Mui-selected': {
            backgroundColor: lighten(theme.palette.header.main, 0.3)
        }
    },
    title: {
        marginRight: theme.spacing(2)
    },
    indicator: {
        backgroundColor: theme.palette.getContrastText(theme.palette.header.main)
    },
    menuButton: {
        marginRight: theme.spacing(2)
    }
}));

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

export default function TenantHeader({ handleToggleMenu, isMenuOpen }) {
    const classes = useStyles();
    const { user, logout, nav } = useAuth();
    const [userMenuAnchor, setUserMenuAnchor] = useState(null);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showChangeEmail, setShowChangeEmail] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const isUserMenuOpen = Boolean(userMenuAnchor);
    const { pathname } = useLocation();
    const navItems = getTopNavItems(nav);
    const navIndex = navItems.findIndex((nav) => pathname.startsWith(nav.lookup));

    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down('md'));

    const handleOpenUserMenu = (event) => {
        setUserMenuAnchor(event.currentTarget);
    };

    const handleCloseUserMenu = (event) => {
        setUserMenuAnchor(null);
    };

    const handleMenuOpen = () => {
        setMobileMenuOpen(true);
    };

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <div className={classes.toolbar}>
                <Typography className={classes.title} variant="h6">
                    {isSM ? 'DCYF Grant App' : 'DCYF Grant Application'}
                </Typography>
                {!isSM && user.isEmailConfirmed ? (
                    <Tabs
                        classes={{ indicator: classes.indicator }}
                        value={navIndex !== -1 ? navIndex : 0}
                        onChange={(event, val) => {
                            const item = navItems[val];
                            navigate(item.to);
                        }}
                    >
                        {navItems.map((link) => (
                            <LinkTab key={link.to} label={link.label} href={link.to} />
                        ))}
                    </Tabs>
                ) : user.isEmailConfirmed ? (
                    <>
                        <IconButton color="inherit" aria-label="open menu" onClick={handleMenuOpen} edge="start" className={classes.menuButton}>
                            <MenuIcon />
                        </IconButton>
                        <Drawer classes={{ paper: classes.drawer }} anchor="left" open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)}>
                            <div className={classes.drawerHeader}>
                                <IconButton className={classes.drawerHeaderButton} onClick={() => setMobileMenuOpen(false)}>
                                    {<ChevronLeftIcon />}
                                </IconButton>
                            </div>
                            <Divider className={classes.divider} />
                            <List disablePadding>
                                {navItems.map((link) => (
                                    <ListItem
                                        classes={{ selected: classes.selectedListItem }}
                                        selected={pathname.startsWith(link.to)}
                                        button
                                        key={link.to}
                                        onClick={() => {
                                            setMobileMenuOpen(false);
                                            navigate(link.to);
                                        }}
                                    >
                                        <ListItemText>{link.label}</ListItemText>
                                    </ListItem>
                                ))}
                            </List>
                        </Drawer>
                    </>
                ) : null}
            </div>
            <Button color="inherit" onClick={handleOpenUserMenu}>
                {isSM ? (
                    <>
                        <AccountCircleIcon />
                        <ArrowDropDownIcon />
                    </>
                ) : (
                    `${user.name} ${user.surname}`
                )}
            </Button>
            <Menu
                MenuListProps={{ disablePadding: true }}
                anchorEl={userMenuAnchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                getContentAnchorEl={null}
                open={isUserMenuOpen}
                onClose={handleCloseUserMenu}
            >
                {user.isEmailConfirmed && (
                    <MenuItem
                        onClick={() => {
                            setUserMenuAnchor(null);
                            setShowChangePassword(true);
                            // navigate('/change-password');
                        }}
                    >
                        Change Password
                    </MenuItem>
                )}
                {user.isEmailConfirmed && (
                    <MenuItem
                        onClick={() => {
                            setUserMenuAnchor(null);
                            setShowChangeEmail(true);
                        }}
                    >
                        Change Email
                    </MenuItem>
                )}
                <MenuItem
                    onClick={() => {
                        setUserMenuAnchor(null);
                        logout();
                    }}
                >
                    Logout
                </MenuItem>
            </Menu>
            <ChangePasswordDialog open={showChangePassword} onClose={() => setShowChangePassword(false)} />
            <ChangeEmailDialog open={showChangeEmail} onClose={() => setShowChangeEmail(false)} />
        </AppBar>
    );
}
