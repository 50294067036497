import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ErrorDialog = ({ isShowing, hide, message, details }) =>
    isShowing ? (
        <Dialog open={isShowing} onClose={hide}>
            {message && details && <DialogTitle>{message}</DialogTitle>}
            <DialogContent>
                <DialogContentText component="div">{details ? details : message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    ) : null;

export default ErrorDialog;
