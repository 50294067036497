import React from 'react';
import SketchPicker from 'react-color/lib/Sketch';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';

export default function ColorPicker({ colorPickerAnchor, color, onChange, showColorPicker, onClose }) {
    return (
        <Popover open={showColorPicker} anchorEl={colorPickerAnchor} anchorOrigin={{ vertical: 'center', horizontal: 'center' }} onClose={onClose}>
            <Paper>
                <SketchPicker color={color} onChange={onChange} presetColors={[]} disableAlpha={true} />
            </Paper>
        </Popover>
    );
}
