import React from 'react';

import List from './list';
import SinglePaperLayout from '../../singlePaperLayout';

const ApplicantsPage = () => {
    return (
        <SinglePaperLayout>
            <List />
        </SinglePaperLayout>
    );
};

export default ApplicantsPage;
