import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    margin: {
        marginBottom: theme.spacing(2)
    },
    label: {
        marginBottom: theme.spacing(1)
    },
    required: {
        color: 'red'
    },
    checked: {
        '&.Mui-checked': {
            color: theme.palette.success.main
        }
    },
    group: {
        '& label': {
            marginBottom: 10
        }
    }
}));

const FinalFormRadioGroup = ({
    meta,
    fullWidth,
    formControlClassName,
    required,
    input: { name, onChange, value },
    label,
    choices,
    row,
    defaultValue,
    onRadioChange,
    disabled
}) => {
    const classes = useStyles();
    return (
        <FormControl
            className={clsx(classes.margin, formControlClassName)}
            fullWidth={fullWidth}
            error={meta.error && meta.touched}
            disabled={disabled}
        >
            {label && (
                <FormLabel required={required} className={classes.label} classes={{ asterisk: classes.required }}>
                    {label}
                </FormLabel>
            )}
            <RadioGroup
                className={classes.group}
                row={row}
                aria-label={name}
                name={name}
                value={value === true ? 'true' : value === false ? 'false' : value}
                onChange={(e) => {
                    onChange(e.target.value);
                    onRadioChange && onRadioChange(e.target.value);
                }}
                defaultValue={defaultValue}
            >
                {choices.map((choice, index) => {
                    let label = choice.label;
                    if (choice.helper) {
                        label = (
                            <div>
                                {choice.label}
                                <div style={{ color: 'red', fontSize: '.75rem' }}>{choice.helper}</div>
                            </div>
                        );
                    }
                    return (
                        <FormControlLabel
                            key={`${name}${index}`}
                            value={choice.value}
                            control={<Radio classes={{ checked: classes.checked }} />}
                            label={label}
                        />
                    );
                })}
            </RadioGroup>
            {meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
    );
};

export default FinalFormRadioGroup;
