export const DEFAULT_CONTRAST_THRESHOLD = 3;

export const ROLE_NAME_ADMIN = 'ADMIN';
export const ROLE_NAME_FINANCE = 'FINANCE';
export const ROLE_NAME_INSTITUTION = 'INSTITUTION';
export const ROLE_NAME_STUDENT = 'STUDENT';

export const OTHER_INSTITUTION_VALUE = '7f5347a3-17f9-44ad-9899-17d2662aaa3d';

export const APP_SETTING_NAME_CONTACTS = 'App.Contacts';
export const APP_SETTING_NAME_AGREEMENTS = 'App.Agreements';
export const APP_SETTING_NAME_REGISTRATION_PAGE = 'App.RegistrationPage';
export const APP_SETTING_NAME_PASSWORD_EXPIRES_IN_DAYS = 'App.PasswordExpiresInDays';
