import { useState } from 'react';

const useErrorDialog = () => {
    const [isShowing, setIsShowing] = useState(false);
    const [message, setMessage] = useState('');
    const [details, setDetails] = useState('');

    function show(message, details) {
        setIsShowing(true);
        setMessage(message);
        setDetails(details);
    }

    function hide() {
        setIsShowing(false);
    }

    return {
        isShowing,
        show,
        hide,
        message,
        details
    };
};

export default useErrorDialog;
