import React from 'react';
import RoleList from './list';
import LeftNavLayout from '../../leftNavLayout';

export default function RolesPage() {
    return (
        <LeftNavLayout>
            <RoleList />
        </LeftNavLayout>
    );
}
