import React, { useState, useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';

import './index.css';

const useStyles = makeStyles((theme) => ({
    //list: { backgroundColor: theme.palette.secondary.main },
    icon: { minWidth: 0, marginRight: 5 },
    text: { marginTop: 8 },
    subHead: { lineHeight: 'unset', padding: '10px 10px 0', color: 'white', fontWeight: 700 },
    item: { paddingTop: 0, paddingBottom: 0 },
    strengthItem: { marginTop: 10, flexDirection: 'column', paddingTop: 0, paddingBottom: 0, alignItems: 'unset' },
    pass: { color: theme.palette.success.main }
}));

const has8Characters = (value) => {
    return value ? value.length >= 8 : false;
};

const hasUpper = (value) => {
    return value ? /[A-Z]+/.test(value) : false;
};

const hasLower = (value) => {
    return value ? /[a-z]+/.test(value) : false;
};

const hasNumeric = (value) => {
    return value ? /[0-9]+/.test(value) : false;
};

const hasSpecial = (value) => {
    return value ? /[~!@#$%^&*()_\-+=]+/.test(value) : false;
};

const meetsPasswordRequirements = (value) => {
    return hasUpper(value) && hasLower(value) && hasNumeric(value) && has8Characters(value) && hasSpecial(value)
        ? undefined
        : 'Must meet requirements';
};

const matchesNewPassword = (newPassword) => (value) => (value !== newPassword ? 'Passwords must match' : undefined);

const PassIcon = ({ className }) => <CheckIcon classes={{ colorAction: className }} color="action" fontSize="small" />;
const FailIcon = () => <CloseIcon color="error" fontSize="small" />;

export default function PasswordRequirements({ password, showStrength }) {
    const classes = useStyles();
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [passwordStrengthClass, setPasswordStrengthClass] = useState('password-complexity-1');
    const [strengthTerm, setStrengthTerm] = useState('');

    useEffect(() => {
        function getPasswordStrength() {
            let strength = 0;

            if (has8Characters(password)) {
                strength += 20;
            }
            if (hasUpper(password)) {
                strength += 10;
            }
            if (hasLower(password)) {
                strength += 10;
            }
            if (hasNumeric(password)) {
                strength += 20;
            }
            if (hasSpecial(password)) {
                strength += 20;
            }
            if (password && strength === 80) {
                let multpleSpecials = ((password || '').match(/[~!@#$%^&*()_\-+=]/g) || []).length;
                if (multpleSpecials && password.length > 10) {
                    strength += 20;
                }
            }

            setPasswordStrength(strength);
            setPasswordStrengthClass(`password-complexity-${strength === 0 ? 1 : strength / 20}`);
            switch (strength / 20) {
                case 1:
                    setStrengthTerm('Bad');
                    break;
                case 2:
                    setStrengthTerm('Fair');
                    break;
                case 3:
                    setStrengthTerm('Good');
                    break;
                case 4:
                    setStrengthTerm('Strong');
                    break;
                case 5:
                    setStrengthTerm('Excellent');
                    break;
                default:
                    setStrengthTerm('');
            }
        }

        getPasswordStrength();
    }, [password]);

    return (
        <List
            className={classes.list}
            subheader={
                <ListSubheader disableSticky className={classes.subHead}>
                    Password Requirements
                </ListSubheader>
            }
        >
            <ListItem alignItems="flex-start" classes={{ root: classes.item }}>
                <ListItemIcon classes={{ root: classes.icon }}>
                    {has8Characters(password) ? <PassIcon className={classes.pass} /> : <FailIcon />}
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'caption' }} classes={{ root: classes.text }}>
                    Be at least 8 characters in length
                </ListItemText>
            </ListItem>
            <ListItem alignItems="flex-start" classes={{ root: classes.item }}>
                <ListItemIcon classes={{ root: classes.icon }}>
                    {hasUpper(password) && hasLower(password) ? <PassIcon className={classes.pass} /> : <FailIcon />}
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'caption' }} classes={{ root: classes.text }}>
                    Contain both upper and lowercase alphabetic characters <br />
                    (e.g. A-Z, a-z)
                </ListItemText>
            </ListItem>
            <ListItem alignItems="flex-start" classes={{ root: classes.item }}>
                <ListItemIcon classes={{ root: classes.icon }}>
                    {hasNumeric(password) ? <PassIcon className={classes.pass} /> : <FailIcon />}
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'caption' }} classes={{ root: classes.text }}>
                    Have at least one numerical character (e.g. 0-9)
                </ListItemText>
            </ListItem>
            <ListItem alignItems="flex-start" classes={{ root: classes.item }}>
                <ListItemIcon classes={{ root: classes.icon }}>
                    {hasSpecial(password) ? <PassIcon className={classes.pass} /> : <FailIcon />}
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'caption' }} classes={{ root: classes.text }}>
                    Have at least one special character <br />
                    (e.g. ~!@#$%^&amp;*()_-+=)
                </ListItemText>
            </ListItem>
            {showStrength && (
                <ListItem classes={{ root: classes.strengthItem }}>
                    <ListItemText>Password Strength</ListItemText>

                    <ListItemText>
                        <LinearProgress variant="determinate" value={passwordStrength} classes={{ barColorPrimary: passwordStrengthClass }} />
                    </ListItemText>
                    <ListItemText>{strengthTerm}</ListItemText>
                </ListItem>
            )}
        </List>
    );
}

export { meetsPasswordRequirements, matchesNewPassword };
