import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'relative'
    },
    loadingComponent: {
        color: theme.palette.success.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

const DefaultLoadingComponent = ({ className }) => <CircularProgress size={24} className={className} />;

const LoadingButton = ({
    className,
    loading,
    loadingComponent: LoadingComponent,
    type,
    onClick,
    variant,
    color,
    fullWidth,
    children,
    disabled,
    style,
    wrapperClassName,
    ...other
}) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.wrapper, wrapperClassName)} style={style}>
            <Button
                {...other}
                className={className}
                disabled={loading || disabled}
                variant={variant}
                color={color}
                fullWidth={fullWidth}
                type={type === 'submit' ? 'submit' : undefined}
                onClick={onClick}
            >
                {children}
            </Button>
            {loading ? LoadingComponent ? LoadingComponent : <DefaultLoadingComponent className={classes.loadingComponent} /> : null}
        </div>
    );
};

export default LoadingButton;
