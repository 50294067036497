import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    margin: {
        marginBottom: theme.spacing(2)
    },
    label: {
        marginBottom: theme.spacing(1)
    },
    required: {
        color: 'red'
    },
    disabled: {
        backgroundColor: theme.palette.inputDisabled.main
    }
}));

const FinalFormTextField = ({
    className,
    tooltipPlacement,
    tooltipContent,
    input: { name, onChange, onBlur, onFocus, value, ...restInput },
    maxLength,
    spellCheck,
    label,
    fullWidth,
    meta,
    password,
    number,
    startAdornment,
    endAdornment,
    required,
    multiline,
    autoFocus,
    onInputChange,
    inputClassName,
    inputProps,
    disabled,
    inputClasses,
    ...rest
}) => {
    const [showTooltip, setShowTooltip] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const classes = useStyles();
    const remainingCharacters = multiline && maxLength ? maxLength - value.length : '';

    const PasswordAdornment = (
        <InputAdornment position="end">
            <IconButton
                style={{ padding: 0 }}
                tabIndex="-1"
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(event) => event.preventDefault()}
            >
                {showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
            </IconButton>
        </InputAdornment>
    );

    const TheInput = (
        <OutlinedInput
            {...rest}
            disabled={disabled}
            multiline={multiline}
            name={name}
            error={meta.error && meta.touched}
            autoFocus={autoFocus}
            inputProps={{
                ...restInput,
                ...inputProps,
                maxLength,
                onFocus: (e) => {
                    tooltipPlacement && tooltipContent && setShowTooltip(true);
                    onFocus(e);
                },
                onBlur: (e) => {
                    tooltipPlacement && tooltipContent && setShowTooltip(false);
                    onBlur(e);
                },
                type: password && !showPassword ? 'password' : number ? 'number' : 'text'
            }}
            required={required}
            onChange={(value) => {
                onChange(value);
                onInputChange && onInputChange(value);
            }}
            value={value}
            spellCheck={spellCheck === undefined ? 'false' : spellCheck}
            startAdornment={startAdornment}
            endAdornment={password ? PasswordAdornment : endAdornment ? endAdornment : null}
            className={inputClassName}
            classes={{ disabled: classes.disabled, ...inputClasses }}
        />
    );

    return (
        <FormControl className={clsx(classes.margin, className)} fullWidth={fullWidth} error={meta.error && meta.touched} disabled={disabled}>
            {label && (
                <FormLabel required={required} className={classes.label} classes={{ asterisk: classes.required }} disabled={disabled}>
                    {label}
                </FormLabel>
            )}
            {tooltipPlacement && tooltipContent ? (
                <Tooltip arrow title={tooltipContent} open={showTooltip} placement={tooltipPlacement} TransitionComponent={Zoom}>
                    {TheInput}
                </Tooltip>
            ) : (
                TheInput
            )}
            {meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
            {remainingCharacters !== '' && <FormHelperText>Characters left: {remainingCharacters}</FormHelperText>}
        </FormControl>
    );
};

export default FinalFormTextField;
