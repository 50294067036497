import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import { fade } from '@material-ui/core';

import useChunkUpload from '../../../../common/fileUpload/useChunkUpload';
import { GroupType } from '../../../../common/logic/enums';

const maxFileUploadSize = 100000000;

const useStyles = makeStyles((theme) => ({
    fileUploader: {
        flex: 1,
        maxWidth: 200
    },
    addItem: {
        cursor: 'pointer',
        position: 'relative',
        backgroundColor: fade(theme.palette.primary.main, 0.1),
        borderColor: theme.palette.primary.main,
        height: 50,
        borderWidth: 1,
        borderStyle: 'solid'
    },
    dragging: {
        backgroundColor: fade(theme.palette.primary.main, 0.5)
    },
    progress: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    },
    addIcon: {
        marginTop: -24,
        marginLeft: -24,
        position: 'absolute',
        left: '50%',
        top: '50%',
        fontSize: 48,
        color: '#8F8F8F'
    }
}));

const FileUploader = ({ onUploadComplete, disabled, tooltipTitle = '', tooltipPlacement = 'right' }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const { onDrop, files } = useChunkUpload(GroupType.File, null, onUploadComplete, null, null);

    const onDropRejected = (rejectedFiles, event) => {
        enqueueSnackbar('Unable to upload file', { variant: 'error' });
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        disabled: disabled,
        onDrop: onDrop,
        maxSize: maxFileUploadSize,
        onDropRejected,
        multiple: false
    });

    return (
        <Tooltip arrow title={tooltipTitle} placement={tooltipPlacement}>
            <div className={classes.fileUploader}>
                {files.length === 0 && (
                    <div {...getRootProps({ className: clsx(classes.addItem, { [classes.dragging]: isDragActive }) })}>
                        <input {...getInputProps()} />
                        <AddIcon className={classes.addIcon} />
                    </div>
                )}
                {files.length === 1 &&
                    files.map((item) => (
                        <div className={classes.addItem} key={item.id}>
                            <LinearProgress
                                variant="determinate"
                                className={classes.progress}
                                value={parseInt(item.percentComplete, 10)}
                                color="primary"
                            />
                        </div>
                    ))}
            </div>
        </Tooltip>
    );
};

export default FileUploader;
