import React from 'react';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/styles/makeStyles';
import { fade } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(-1)
    },
    yearSummary: {
        border: `solid 1px ${theme.palette.info.main}`,
        backgroundColor: fade(theme.palette.info.main, 0.25),
        color: theme.palette.info.main,
        display: 'inline-flex',
        '& ul': {
            margin: theme.spacing(1),
            padding: 0,
            listStyleType: 'none'
        },
        '& $summaryWrapper:nth-child(2)': {
            borderLeft: '1px solid'
        }
    },
    summaryWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    listTitle: {
        fontSize: '1.2em',
        fontWeight: 'bold'
    }
}));

const YearSummary = ({ summary }) => {
    const classes = useStyles();

    if (!summary) {
        return null;
    }

    const { year1, year2 } = summary;

    const renderYear = (year) => {
        if (!year) {
            return null;
        }

        return (
            <div className={classes.summaryWrapper}>
                <span className={classes.listTitle}>{`${year.startYear}-${year.endYear}`} Summary</span>
                <div style={{ display: 'flex' }}>
                    <ul>
                        <li>Submitted: {year.submitted}</li>
                        <li>In Progress: {year.inProgress}</li>
                    </ul>
                    <ul>
                        <li>Accepted: {year.accepted}</li>
                        <li>Rejected: {year.rejected}</li>
                    </ul>
                </div>
            </div>
        );
    };

    return (
        <Paper elevation={0} className={classes.paper}>
            <div className={classes.yearSummary}>
                {renderYear(year1)}
                {renderYear(year2)}
            </div>
        </Paper>
    );
};

export default YearSummary;
