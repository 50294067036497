import React, { useMemo } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import CustomDataGrid from '../../../../common/customDataGrid';
import enumHelper from '../../../../common/logic/enumHelper';
import { renderAmount } from './common';
import { TransactionType } from '../../../../common/logic/enums';
import clsx from 'clsx';
import LoadingButton from '../../../../common/loadingButton';
import { dateFormatterUtc } from '../../../../common/logic/grid';

const useStyles = makeStyles((theme) => ({
    transactionsList: {},
    pending: {
        color: theme.palette.error.main
    }
}));

const TransactionsList = ({
    rows,
    rowCount,
    loading,
    loadingTransactionId,
    onViewTransaction,
    onSortModelChange,
    onPageSizeChange,
    onPageChange,
    pagination,
    initialSortModel
}) => {
    const classes = useStyles();

    const columns = useMemo(
        () => [
            { field: 'description', headerName: 'Description', cellClassName: (params) => clsx(params.row.pending && classes.pending), flex: 2 },
            {
                field: 'type',
                headerName: 'Type',
                cellClassName: (params) => clsx(params.row.pending && classes.pending),
                valueFormatter: ({ value }) => enumHelper.getDisplayName(TransactionType, value),
                flex: 1
            },
            {
                field: 'amount',
                type: 'number',
                headerName: 'Amount',
                renderCell: renderAmount,
                cellClassName: (params) => clsx(params.row.pending && classes.pending),
                flex: 1
            },
            {
                field: 'paymentDate',
                headerName: 'Transaction Date',
                flex: 1,
                cellClassName: (params) => clsx(params.row.pending && classes.pending),
                valueFormatter: dateFormatterUtc
            },
            {
                field: 'creationTime',
                headerName: 'Entry Date',
                flex: 1,
                cellClassName: (params) => clsx(params.row.pending && classes.pending),
                valueFormatter: ({ value }) => moment(value).format('M/D/YYYY')
            },
            {
                field: 'paymentId',
                type: 'string',
                headerName: 'Payment ID',
                cellClassName: (params) => clsx(params.row.pending && classes.pending),
                flex: 1
            },
            {
                field: 'actions',
                headerName: 'Action',
                width: 100,
                sortable: false,
                renderCell: ({ row }) =>
                    row.paymentId && (
                        <LoadingButton
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={() => {
                                onViewTransaction(row.id);
                            }}
                            loading={loadingTransactionId === row.id}
                        >
                            View
                        </LoadingButton>
                    )
            }
        ],
        []
    );

    return (
        <>
            <Box mb={0.5}>
                <Typography variant="h4">Transactions</Typography>
            </Box>
            <CustomDataGrid
                className={classes.transactionsList}
                coloredHeader
                numberColLeftAligned
                columns={columns}
                disableColumnMenu
                disableColumnFilter
                rowHeight={40}
                rows={rows}
                rowCount={rowCount}
                initialSortModel={initialSortModel}
                loading={loading}
                hideFooterSelectedRowCount
                disableSelectionOnClick
                paginationMode="server"
                sortingMode="server"
                onSortModelChange={onSortModelChange}
                onPageSizeChange={onPageSizeChange}
                onPageChange={onPageChange}
                rowsPerPageOptions={[25]}
                page={pagination.page}
                initialPageSize={pagination.pageSize}
            />
        </>
    );
};

export default TransactionsList;
