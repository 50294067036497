import axios from 'axios';

export function getAllByTenant(tenantId, keyword = '', isActive = null, skipCount = 0, maxResultCount = 100) {
    return axios.get('/api/services/app/User/GetAllByTenant', { params: { tenantId, keyword, isActive, skipCount, maxResultCount } });
}

export function getAll(keyword = '', isActive = null, skipCount = 0, maxResultCount = 100, cancelToken) {
    return axios.get('/api/services/app/User/GetAll', { params: { keyword, isActive, skipCount, maxResultCount }, cancelToken });
}

export function create(values) {
    return axios.post('/api/services/app/User/Create', values);
}

export function update(values) {
    return axios.put('/api/services/app/User/Update', values);
}

export function deleteUser(id) {
    return axios.delete('/api/services/app/User/Delete', { params: { id } });
}

export function changePassword(adminPassword, userId, newPassword) {
    return axios.post('/api/services/app/User/ChangePassword', { adminPassword, userId, newPassword });
}
