import React, { useState } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { navigate } from '@gatsbyjs/reach-router';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { Form, Field } from 'react-final-form';
import queryString from 'query-string';

import { useAuth } from '../../auth/auth-context';
import { FinalFormTextField } from '../../common/formWrappers';
import { required, composeValidators, maxLength } from '../../common/formValidators';
import * as AuthApi from '../../api/auth';
import PasswordRequirements, { matchesNewPassword, meetsPasswordRequirements } from '../../common/passwordRequirements';
import LoadingButton from '../../common/loadingButton';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 400
    },
    form: {
        marginTop: theme.spacing(1)
    },
    wrapper: {
        position: 'relative',
        margin: theme.spacing(1)
    },
    caption: {
        marginTop: 5
    }
}));

export default function ResetPasswordForm({ location, accountSetup, successMessage }) {
    const [loading, setIsLoading] = useState();
    const { postRegisterLogin } = useAuth();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const qs = queryString.parse(location.search);
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.only('xs'));

    async function save(values) {
        try {
            setIsLoading(true);
            const loginData = (await AuthApi.resetPassword(qs.code, values.newPassword, values.tenantId, accountSetup)).data.result;
            await postRegisterLogin(loginData);
            enqueueSnackbar(successMessage ? successMessage : 'Thank you, your password has been reset', { variant: 'success' });
            navigate('/');
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className={classes.paper}>
            <Form onSubmit={(values) => save(values)}>
                {(props) => (
                    <form className={classes.form} noValidate onSubmit={props.handleSubmit}>
                        <Field
                            margin="dense"
                            required
                            fullWidth
                            name="newPassword"
                            label="New Password"
                            placeholder="Enter password"
                            type="password"
                            id="newPassword"
                            autoComplete="off"
                            password
                            tooltipPlacement={isXS ? 'top' : 'left'}
                            tooltipContent={<PasswordRequirements password={props.values.newPassword} />}
                            component={FinalFormTextField}
                            validate={composeValidators(required, maxLength(32), meetsPasswordRequirements)}
                        />
                        <Field
                            margin="dense"
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Confirm Password"
                            placeholder="Enter password"
                            type="password"
                            id="confirmPassword"
                            autoComplete="off"
                            password
                            component={FinalFormTextField}
                            validate={composeValidators(required, maxLength(32), matchesNewPassword(props.values.newPassword))}
                        />
                        <LoadingButton type="submit" fullWidth variant="contained" color="primary" loading={loading}>
                            {accountSetup ? 'Complete' : 'Submit'}
                        </LoadingButton>
                    </form>
                )}
            </Form>
        </div>
    );
}
