import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles((theme) => ({
    importStatus: {},
    nested: {
        padding: theme.spacing(0),
        alignItems: 'flex-start'
    },
    liNoFlex: {
        display: 'block'
    },
    errorIcon: {
        minWidth: 'initial',
        margin: theme.spacing(0.2, 0.5, 0, 3)
    },
    error: {
        fontSize: '0.875rem',
        color: theme.palette.error.main
    },
    errorItem: {
        margin: 0,
        flexWrap: 'wrap',
        maxWidth: 200
    }
}));

const ImportStatus = ({ status }) => {
    const classes = useStyles();

    return (
        <List dense className={classes.importStatus} component="div">
            <ListItem component="div">
                <ListItemText primary={`${status.successful} eligible student(s) imported successfully.`} />
            </ListItem>
            <ListItem classes={{ root: classes.liNoFlex }} component="div">
                <ListItemText primary={`${status.errors.length} student(s) skipped due to error in row.`} />
                {status.errors.length > 0 && (
                    <List disablePadding>
                        <ListItem className={classes.nested}>
                            <ListItemIcon className={classes.errorIcon}>
                                <ErrorIcon className={classes.error} />
                            </ListItemIcon>
                            <span style={{ display: 'flex', flexDirection: 'column' }}>
                                <ListItemText className={classes.errorItem} primaryTypographyProps={{ variant: 'body2' }}>
                                    Row number(s) skipped:
                                </ListItemText>
                                <ListItemText className={classes.errorItem} primaryTypographyProps={{ variant: 'body2' }}>
                                    {status.errors.join(', ')}
                                </ListItemText>
                            </span>
                        </ListItem>
                    </List>
                )}
            </ListItem>
            <ListItem component="div">
                <ListItemText primary={`${status.duplicates} student(s) skipped due to duplicate record.`} />
            </ListItem>
        </List>
    );
};

export default ImportStatus;
