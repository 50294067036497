import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import HostHeader from './header';
import HostFooter from './footer';

const useStyles = makeStyles(theme => ({
    content: {
        padding: theme.spacing(2),
        marginBottom: 75
    }
}));

export default function HostLayout({ children }) {
    const classes = useStyles();

    return (
        <div>
            <HostHeader />
            <div className={classes.content}>{children}</div>
            <HostFooter />
        </div>
    );
}
