import axios from 'axios';

export function getAll(cancelToken) {
    return axios.get('/api/services/app/Profile/GetAll', { cancelToken });
}

export function get(userId) {
    return axios.get('/api/services/app/Profile/Get', { params: { userId } });
}

export function createOrUpdate(input) {
    return axios.post('/api/services/app/Profile/CreateOrUpdate', input);
}

export function getProfileExists(cancelToken) {
    return axios.get('/api/services/app/Profile/GetExists', { cancelToken });
}

export function updateDeceased(userId, deceased) {
    return axios.put('/api/services/app/Profile/UpdateDeceased', { userId, deceased });
}
