import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import SettingsIcon from '@material-ui/icons/Settings';
import CreateIcon from '@material-ui/icons/Create';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import Toolbar from '@material-ui/core/Toolbar';
import { navigate } from '@gatsbyjs/reach-router';

import * as TenantApi from '../../../api/tenant';
import * as FeatureApi from '../../../api/feature';
import ConfirmDialog from '../../../common/confirmDialog';
import EditDialog from './editDialog';
import FeaturesDialog from './featuresDialog';

const useStyles = makeStyles((theme) => ({
    leftIcon: {
        marginRight: theme.spacing(1)
    },
    tableTitle: {
        flexGrow: 1
    }
}));

export default function TenantList() {
    const [data, setData] = useState({ tenants: [] });
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [selectedTenant, setSelectedTenant] = useState(null);
    const [features, setFeatures] = useState([]);
    const [showFeatures, setShowFeatures] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);

            const result = (await TenantApi.getAll()).data.result;
            setData({ tenants: result.items });

            setIsLoading(false);
        }

        fetchData();
    }, [selectedTenant, showEdit, showFeatures]);

    async function deleteTenant() {
        try {
            setIsSaving(true);
            await TenantApi.deleteTenant(selectedTenant);
        } catch (error) {
            console.error(error);
        }

        setIsSaving(false);
        setSelectedTenant(null);
        setShowConfirm(false);
    }

    async function saveTenant(tenant) {
        setIsSaving(true);
        try {
            if (tenant.id) {
                await TenantApi.update(tenant);
            } else {
                await TenantApi.create(tenant);
            }
            setShowEdit(false);
            setSelectedTenant(null);
        } catch (error) {
        } finally {
            setIsSaving(false);
        }
    }

    async function loadFeatures(tenantId) {
        try {
            setIsLoading(true);
            const result = (await FeatureApi.getAll(tenantId)).data.result;
            setFeatures(result);
            setIsLoading(false);
            setShowFeatures(true);
        } catch (error) {
        } finally {
            setIsSaving(false);
        }
    }

    async function saveFeatureSetting(data) {
        setIsSaving(true);
        try {
            await FeatureApi.updateFeatures(data);
            setShowFeatures(false);
            setSelectedTenant(null);
        } catch (error) {
        } finally {
            setIsSaving(false);
        }
    }

    return (
        <div>
            {isLoading && <LinearProgress />}
            <Box p={1} color="secondary.contrastText" bgcolor="secondary.main">
                <Toolbar variant="dense">
                    <Typography className={classes.tableTitle} variant="h6">
                        Tenants
                    </Typography>
                    <IconButton
                        color="inherit"
                        onClick={() => {
                            setSelectedTenant(null);
                            setShowEdit(true);
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                </Toolbar>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Tenancy Name</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Active</TableCell>
                        <TableCell>Database Server</TableCell>
                        <TableCell>Database Name</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.tenants.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.id}</TableCell>
                            <TableCell>{row.tenancyName}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.isActive ? <DoneIcon color="primary" /> : null}</TableCell>
                            <TableCell>{row.databaseServer}</TableCell>
                            <TableCell>{row.databaseName}</TableCell>
                            <TableCell>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            color="primary"
                                            onClick={() => {
                                                setSelectedTenant(row);
                                                setShowEdit(true);
                                            }}
                                        >
                                            <CreateIcon className={classes.leftIcon} fontSize="small" />
                                            Edit
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            color="primary"
                                            onClick={() => {
                                                setSelectedTenant(row.id);
                                                setShowConfirm(true);
                                            }}
                                        >
                                            <ClearIcon className={classes.leftIcon} fontSize="small" />
                                            Delete
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" size="small" color="primary" onClick={() => navigate(`/users/${row.id}`)}>
                                            <AccountBoxIcon className={classes.leftIcon} fontSize="small" />
                                            Users
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            color="primary"
                                            onClick={() => {
                                                setSelectedTenant(row);
                                                loadFeatures(row.id);
                                            }}
                                        >
                                            <SettingsIcon className={classes.leftIcon} fontSize="small" />
                                            Features
                                        </Button>
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <ConfirmDialog
                confirmAction={deleteTenant}
                open={showConfirm}
                handleClose={() => (isSaving ? undefined : setShowConfirm(false))}
                title="Delete Tenant?"
                body={`Are you sure you wish to delete tenant id ${selectedTenant}? The database WILL NOT be deleted.`}
                isSaving={isSaving}
            />
            <EditDialog
                open={showEdit}
                handleClose={() => (isSaving ? undefined : setShowEdit(false))}
                tenant={selectedTenant}
                handleSave={saveTenant}
                isSaving={isSaving}
            />
            <FeaturesDialog
                open={showFeatures}
                handleClose={() => (isSaving ? undefined : setShowFeatures(false))}
                tenant={selectedTenant}
                handleSave={saveFeatureSetting}
                isSaving={isSaving}
                features={features}
            />
        </div>
    );
}
