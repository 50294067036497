import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import Tooltip from '@material-ui/core/Tooltip';
import Skeleton from '@material-ui/lab/Skeleton';
import { useSnackbar } from 'notistack';

import LoadingButton from '../../../common/loadingButton';
import * as YearApi from '../../../api/year';
import ConfirmDialog from '../../../common/confirmDialog';

const useStyles = makeStyles((theme) => ({
    startCloseYear: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        padding: theme.spacing(2)
    },
    content: {
        padding: theme.spacing(0, 2, 2)
    },
    actions: {},
    row: {
        display: 'flex',
        '&:first-child': {
            marginBottom: theme.spacing(1)
        }
    },
    currentYearContainer: {
        display: 'flex',
        marginBottom: theme.spacing(2)
    },
    label: {
        fontWeight: 700,
        marginRight: theme.spacing(1)
    },
    closeButton: {
        marginRight: theme.spacing(1)
    }
}));

const StartCloseYear = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [opening, setOpening] = useState(false);
    const [closing, setClosing] = useState(false);
    const [closeYear, setCloseYear] = useState({});
    const [reloadData, setReloadData] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [years, setYears] = useState([]);

    useEffect(() => {
        const tokenSourceAllYears = axios.CancelToken.source();
        (async () => {
            try {
                setLoading(true);
                const response = await YearApi.getAll(null, tokenSourceAllYears.token);
                setYears(response.data.result);
            } catch {
            } finally {
                setLoading(false);
            }
        })();

        return () => {
            tokenSourceAllYears.cancel();
        };
    }, [reloadData]);

    const handleOpenClick = async () => {
        try {
            setOpening(true);
            await YearApi.open();
            enqueueSnackbar('School year has been successfully opened', { variant: 'success' });
            reload();
        } catch (ex) {
            enqueueSnackbar('Failed to open school year', { variant: 'error' });
        } finally {
            setOpening(false);
        }
    };

    const handleCloseClick = async () => {
        try {
            setClosing(true);
            await YearApi.close(closeYear.id);
            enqueueSnackbar('School year has been successfully closed', { variant: 'success' });
            reload();
        } catch (ex) {
            enqueueSnackbar('Failed to close school year', { variant: 'error' });
        } finally {
            setClosing(false);
            setCloseYear({});
        }
    };

    const reload = () => {
        setReloadData((prev) => !prev);
    };

    const openYears = years.filter((y) => !y.closed);
    const canOpenNextYear = openYears.length < 2;
    const yearToOpen = years.length > 0 ? years[0].endYear : new Date().getFullYear();
    const currentOpenYearsLabel = openYears.length !== 0 ? openYears.map((y) => `${y.startYear}-${y.endYear}`).join(', ') : 'None';

    return (
        <Paper className={classes.startCloseYear}>
            <Typography className={classes.title} variant="h6">
                Open / Close Year
            </Typography>
            {!loading ? (
                <div className={classes.content}>
                    <div className={classes.currentYearContainer}>
                        <Typography className={classes.label} variant="body1" component="span">
                            Current Open Year(s):
                        </Typography>
                        <Typography variant="body1" component="span">
                            {currentOpenYearsLabel}
                        </Typography>
                    </div>
                    <div className={classes.actions}>
                        <div className={classes.row}>
                            <Tooltip
                                arrow
                                title={!canOpenNextYear ? 'Only 2 years can be open. Close a year to open the next one.' : ''}
                                placement="right"
                            >
                                <div>
                                    <LoadingButton
                                        variant="contained"
                                        color="primary"
                                        disabled={!canOpenNextYear}
                                        onClick={handleOpenClick}
                                        loading={opening}
                                    >
                                        {`Open New Academic Year (${yearToOpen}-${yearToOpen + 1})`}
                                    </LoadingButton>
                                </div>
                            </Tooltip>
                        </div>

                        <div className={classes.row}>
                            {openYears.map((year) => (
                                <LoadingButton
                                    key={year.id}
                                    className={classes.closeButton}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setCloseYear(year)}
                                    loading={closing}
                                >{`Close Academic Year (${year.startYear}-${year.endYear})`}</LoadingButton>
                            ))}
                        </div>
                    </div>
                </div>
            ) : (
                <LoadingSkeleton />
            )}
            <ConfirmDialog
                confirmAction={handleCloseClick}
                open={Boolean(closeYear.id)}
                handleClose={() => setCloseYear({})}
                title={closeYear.id ? `Close ${closeYear.startYear}-${closeYear.endYear}` : 'Close Year'}
                body={
                    closeYear.id
                        ? `Are you sure you want to close the ${closeYear.startYear}-${closeYear.endYear} academic year? It cannot be reopened.`
                        : 'Are you sure you want to close this academic year?'
                }
                confirmActionText="Close Year"
            />
        </Paper>
    );
};

const LoadingSkeleton = () => (
    <div style={{ padding: '0 20px 20px 20px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Skeleton style={{ marginBottom: 20 }} variant="text" width={200} />
        <Skeleton style={{ marginBottom: 20, borderRadius: 4 }} variant="rect" width={320} height={36} />
        <div style={{ display: 'flex' }}>
            <Skeleton style={{ marginBottom: 20, marginRight: 10, borderRadius: 4 }} variant="rect" width={320} height={36} />
            {/* <Skeleton style={{ marginBottom: 20, borderRadius: 4 }} variant="rect" width={320} height={36} /> */}
        </div>
    </div>
);

export default StartCloseYear;
