import React, { useState, useEffect } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Form, Field } from 'react-final-form';
import Box from '@material-ui/core/Box';
import { useSnackbar } from 'notistack';
import { navigate } from '@gatsbyjs/reach-router';

import { useAuth } from '../../auth/auth-context';
import { FinalFormTextField } from '../../common/formWrappers';
import { required, composeValidators, email } from '../../common/formValidators';
import { isDev } from '../../common/logic/environment';
import TextLink from '../../common/textLink';
import { ErrorCodes } from '../../common/logic/enums';
import LoadingButton from '../../common/loadingButton';

import Logo from '../../assets/dcyf-logo.png';
import LogoSmall from '../../assets/dcyf-logo-small.png';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(5),
        [theme.breakpoints.only('xs')]: {
            padding: theme.spacing(0, 2),
            marginTop: theme.spacing(2)
        }
    },
    title: {
        marginBottom: theme.spacing(2),
        alignItems: 'center'
    },
    form: {
        maxWidth: '400px', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column-reverse'
    },
    wrapper: {
        position: 'relative',
        margin: theme.spacing(1)
    },
    forgotPasswordContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& a': {
            fontWeight: 'bold'
        }
    },
    register: {
        textAlign: 'center',
        color: theme.palette.primary.main,
        marginTop: theme.spacing(4),
        '& a': {
            fontWeight: 'bold'
        }
    },
    lockedOut: {
        color: theme.palette.error.main
    },
    logo: {
        maxWidth: 500
    }
}));

export default function Login() {
    const classes = useStyles();
    const { loading, error, user, login } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const [lockedOut, setLockedOut] = useState(false);
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.only('xs'));

    // const { tenant } = useSessionState();
    const showRegister = true;

    useEffect(() => {
        if (user) {
            navigate('/', { replace: true });
        }

        if (error && error.response && error.response.data && error.response.data.error) {
            const errorData = error.response.data.error;
            if (errorData.code === ErrorCodes.LockedOut) {
                enqueueSnackbar('Too many failed attempts, try again in 5 minutes', { variant: 'error' });
                setLockedOut(true);
            }
        } else {
            setLockedOut(false);
        }
    }, [error, enqueueSnackbar, user]);

    return (
        <div className={classes.paper}>
            <div>
                <img className={classes.logo} src={isXS ? LogoSmall : Logo} alt="DCYF Logo" />
            </div>

            {!lockedOut && (
                <Box display="flex" flexDirection="column" className={classes.title}>
                    <Typography variant="h3">Log In</Typography>
                    <Typography variant="body1">Welcome to the DCYF Grant Application</Typography>
                </Box>
            )}

            {lockedOut && (
                <Box display="flex" flexDirection="column" className={classes.title}>
                    <Typography variant="h3" className={classes.lockedOut}>
                        Locked Out
                    </Typography>
                    <Typography variant="body1">Too many failed attempts. Try again in 5 minutes.</Typography>
                </Box>
            )}

            <Form onSubmit={(values) => login(values.emailAddress, values.password, values.tenantId)}>
                {(props) => (
                    <form className={classes.form} noValidate onSubmit={props.handleSubmit}>
                        <Field
                            name="emailAddress"
                            margin="dense"
                            required
                            fullWidth
                            placeholder="email@address.com"
                            label="Email Address"
                            id="emailAddress"
                            autoComplete="emailAddress"
                            component={FinalFormTextField}
                            maxLength={256}
                            validate={!isDev() ? composeValidators(required, email) : required}
                        />
                        <Field
                            margin="dense"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            id="password"
                            placeholder="Enter password"
                            maxLength={32}
                            autoComplete="current-password"
                            password
                            component={FinalFormTextField}
                            validate={required}
                        />
                        <div className={classes.buttonContainer}>
                            <LoadingButton type="submit" fullWidth variant="contained" color="primary" loading={loading}>
                                Log In
                            </LoadingButton>
                            <div className={classes.forgotPasswordContainer}>
                                <TextLink location="/forgot-password" label="Forgot / Reset Password?" />
                            </div>
                        </div>
                        {showRegister && (
                            <div className={classes.register}>
                                Don't have an account? <TextLink location="/register" label="Register" />
                            </div>
                        )}
                    </form>
                )}
            </Form>
        </div>
    );
}
