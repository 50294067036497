import React from 'react';
import Box from '@material-ui/core/Box';
import green from '@material-ui/core/colors/green';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Form, Field } from 'react-final-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import { fade, useTheme } from '@material-ui/core/styles';
import { useAuth } from '../../auth/auth-context';

import TextLink from '../../common/textLink';
import { FinalFormTextField } from '../../common/formWrappers';
import { required, composeValidators, email, maxLength } from '../../common/formValidators';
import PasswordRequirements, { meetsPasswordRequirements } from '../../common/passwordRequirements';
import TermsDialog from './termsDialog';
import { BasicTooltipText } from '../../common/basicTooltipText';

import Logo from '../../assets/dcyf-logo.png';
import LogoSmall from '../../assets/dcyf-logo-small.png';
import { useMediaQuery } from '@material-ui/core';
import LoadingButton from '../../common/loadingButton';
import { APP_SETTING_NAME_REGISTRATION_PAGE } from '../../common/logic/consts';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        [theme.breakpoints.only('xs')]: {
            padding: theme.spacing(0, 2),
            margin: theme.spacing(1)
        }
    },
    grid: {
        marginTop: theme.spacing(1)
    },
    title: {
        marginBottom: theme.spacing(2),
        alignItems: 'center'
    },
    form: {
        maxWidth: '450px', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    wrapper: {
        position: 'relative',
        margin: theme.spacing(1)
    },
    member: {
        textAlign: 'center',
        color: theme.palette.primary.main,
        marginTop: theme.spacing(4),
        '& a': {
            fontWeight: 'bold'
        }
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    agreementContainer: {
        //marginLeft: 16
    },
    agreeLabel: {
        fontSize: 14
    },
    padding: {
        padding: theme.spacing(1)
    },
    error: {
        padding: theme.spacing(1),
        backgroundColor: fade(theme.palette.error.main, 0.1),
        color: theme.palette.error.main,
        border: `solid 1px ${theme.palette.error.main}`,
        marginBottom: theme.spacing(2),
        alignItems: 'center'
    },
    logo: {
        maxWidth: 500
    }
}));

const TermsCheckbox = ({ input, ...other }) => {
    return <Checkbox name={input.name} inputProps={input} onChange={input.onChange} checked={input.checked} />;
};

export default function RegisterForm({ loading, save, showEmailInUse, showNotEligible }) {
    const classes = useStyles();
    const [showTerms, setShowTerms] = React.useState(false);
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.only('xs'));
    const { settings } = useAuth();
    const registrationPageSetting = settings.find((s) => s.name === APP_SETTING_NAME_REGISTRATION_PAGE)?.value;

    return (
        <div className={classes.paper}>
            <Form onSubmit={(values) => save(values)}>
                {(props) => {
                    return (
                        <form className={classes.form} noValidate onSubmit={props.handleSubmit}>
                            <Grid container justify="center" className={classes.padding}>
                                <Grid item xs={12}>
                                    <Box display="flex" flexDirection="column" className={classes.title}>
                                        <img className={classes.logo} src={isXS ? LogoSmall : Logo} alt="DCYF Logo" />
                                        <Typography variant="h3">Register</Typography>
                                        <Typography variant="h5">DCYF Grant Application</Typography>
                                        <Typography className={classes.caption} variant="caption">
                                            {registrationPageSetting.introductionText}
                                        </Typography>
                                    </Box>
                                    {showEmailInUse && (
                                        <Box display="flex" flexDirection="column" className={classes.error}>
                                            <Typography variant="body1">
                                                Email is already in use. Have you{' '}
                                                <TextLink location="/forgot-password" label="forgotten your password?" />
                                            </Typography>
                                        </Box>
                                    )}
                                    {showNotEligible && (
                                        <Box display="flex" flexDirection="column" className={classes.error}>
                                            <Typography variant="body1">
                                                Based on the information you provided it doesn't look like you qualify for this program.
                                            </Typography>
                                            <Typography variant="body1">If you think this is in error, please contact DCYF.</Typography>
                                        </Box>
                                    )}
                                    <Grid container spacing={1}>
                                        <Grid item sm={6} xs={12}>
                                            <Field
                                                name="firstName"
                                                placeholder="First Name"
                                                margin="dense"
                                                maxLength="50"
                                                required
                                                fullWidth
                                                label="First Name"
                                                id="name"
                                                component={FinalFormTextField}
                                                validate={composeValidators(required, maxLength(50))}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <Field
                                                name="lastName"
                                                placeholder="Last Name"
                                                margin="dense"
                                                maxLength="50"
                                                required
                                                fullWidth
                                                label="Last Name"
                                                id="surname"
                                                component={FinalFormTextField}
                                                validate={composeValidators(required, maxLength(50))}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item sm={12} xs={12}>
                                            <Field
                                                name="emailAddress"
                                                placeholder="email@address.com"
                                                margin="dense"
                                                maxLength="256"
                                                required
                                                fullWidth
                                                label="Email Address"
                                                id="emailAddress"
                                                tooltipPlacement={isXS ? 'top' : 'left'}
                                                tooltipContent={
                                                    <BasicTooltipText>
                                                        Your email will be used to:
                                                        <br />
                                                        1. Verify your email address
                                                        <br />
                                                        2. Update you on your application status
                                                    </BasicTooltipText>
                                                }
                                                component={FinalFormTextField}
                                                validate={composeValidators(required, email, maxLength(256))}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Field
                                        name="password"
                                        margin="dense"
                                        placeholder="Enter password"
                                        required
                                        fullWidth
                                        password
                                        maxLength="32"
                                        label="Password"
                                        id="password"
                                        tooltipPlacement={isXS ? 'top' : 'left'}
                                        tooltipContent={<PasswordRequirements password={props.values.password} />}
                                        component={FinalFormTextField}
                                        validate={composeValidators(required, maxLength(32), meetsPasswordRequirements)}
                                    />
                                    <div className={classes.agreementContainer}>
                                        <FormControlLabel
                                            className={classes.agreeLabel}
                                            label={
                                                <span>
                                                    I agree to the <Link onClick={() => setShowTerms(true)}>Terms &amp; Conditions</Link>
                                                </span>
                                            }
                                            control={<Field name="agree" component={TermsCheckbox} validate={required} type="checkbox" />}
                                        />
                                        <TermsDialog
                                            name="agree"
                                            open={showTerms}
                                            onAccept={() => {
                                                setShowTerms(false);
                                            }}
                                            onReject={() => {
                                                setShowTerms(false);
                                            }}
                                        />
                                    </div>
                                    <div className={classes.wrapper}>
                                        <LoadingButton
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            disabled={!props.valid}
                                            loading={loading}
                                        >
                                            Create Account
                                        </LoadingButton>
                                    </div>
                                    <div className={classes.member}>
                                        Already a member? <TextLink location="/" label="Log in" />
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
}
