import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    backdropLoader: {
        zIndex: theme.zIndex.drawer + 1
    },
    loadingPaper: {
        width: 200,
        height: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

const BackdropLoader = ({ open }) => {
    const classes = useStyles();

    return (
        <Backdrop open={open} className={classes.backdropLoader}>
            <Paper className={classes.loadingPaper}>
                <CircularProgress />
            </Paper>
        </Backdrop>
    );
};

export default BackdropLoader;
