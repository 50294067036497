import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FilledInput from '@material-ui/core/FilledInput';
import { fade } from '@material-ui/core';
import clsx from 'clsx';

import * as YearApi from '../../api/year';

const useStyles = makeStyles((theme) => ({
    academicYearSelector: {
        minWidth: 120
    },
    formControl: {
        minWidth: 120,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25)
        }
    },
    underline: {
        '&:after': {
            borderBottom: `2px solid ${theme.palette.secondary.contrastText}`
        }
    },
    selectInput: {
        color: theme.palette.secondary.contrastText
    },
    selectLabel: {
        color: theme.palette.secondary.contrastText,
        '&.Mui-focused': {
            color: theme.palette.secondary.contrastText,
            fontWeight: 700
        }
    }
}));

const AcademicYearSelector = ({ className, value, onChange, inTableHeader = false }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [years, setYears] = useState([]);

    const getDefaultValue = (options) => {
        if (options.length === 0) {
            return '';
        }

        const savedValue = localStorage.getItem('academicYear');
        let value = options.find((i) => i.id === savedValue);

        if (!value) {
            value = options.find((i) => !i.closed);
        }

        if (value) {
            return value.id;
        }

        return options[0].id;
    };

    useEffect(() => {
        const load = async () => {
            try {
                setLoading(true);
                const result = (await YearApi.getAll()).data.result;
                result.sort((a, b) => b.startYear - a.startYear);
                const defaultValue = getDefaultValue(result);
                setYears(result);
                onChange(defaultValue);
                setLoading(false);
            } catch (err) {
                setLoading(false);
            }
        };

        if (years.length === 0) {
            load();
        }
    }, [onChange]);

    const onSelectChange = (event) => {
        const value = event.target.value;
        localStorage.setItem('academicYear', value);
        onChange(value);
    };

    return (
        <FormControl
            className={clsx(classes.academicYearSelector, className, inTableHeader && classes.formControl)}
            margin={inTableHeader ? 'dense' : undefined}
        >
            <InputLabel
                id="academic-year"
                variant={inTableHeader ? 'filled' : undefined}
                className={inTableHeader ? classes.selectLabel : undefined}
                shrink={inTableHeader ? true : undefined}
            >
                Academic Year
            </InputLabel>
            <Select
                value={value}
                onChange={onSelectChange}
                id="academic-year"
                input={inTableHeader ? <FilledInput classes={{ underline: classes.underline, input: classes.selectInput }} /> : undefined}
            >
                {loading && <MenuItem value="">Loading...</MenuItem>}
                {years.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                        {item.startYear} - {item.endYear} ({item.closed ? 'Closed' : 'Open'})
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default AcademicYearSelector;
