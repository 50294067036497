import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import FileSaver from 'file-saver';
import { Form, Field } from 'react-final-form';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import createDecorator from 'final-form-focus';
import Button from '@material-ui/core/Button';
import { useMediaQuery, useTheme } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import LoadingButton from '../../../common/loadingButton';
import { FinalFormDropdown, FinalFormTextField, FinalFormRadioGroup } from '../../../common/formWrappers';
import { composeValidators, email, exactLength, isDate, maxLength, required, isDateBeforeNow } from '../../../common/formValidators';
import { BasicTooltipText } from '../../../common/basicTooltipText';
import { DateMaskInput, PhoneMaskInput, SsnMaskInput, ZipMaskInput } from '../../../common/masks';
import { Gender, CitizenshipStatus, Race, HispanicOrigin, State } from '../../../common/logic/enums';
import * as DocumentApi from '../../../api/document';
import enumHelper from '../../../common/logic/enumHelper';
import b64toBlob from '../../../common/logic/b64toBlob';

const useStyles = makeStyles((theme) => ({
    padding: {
        padding: theme.spacing(1)
    },
    link: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    }
}));

const focusOnError = createDecorator();

const SimpleCheckbox = ({ input: { name, onChange, value, ...restInput }, label, meta, defaultValue, ...rest }) => (
    <FormControlLabel
        label={label}
        control={<Checkbox />}
        checked={!!value}
        onChange={(event) => onChange(event.target.checked)}
        value={name}
        defaultValue={defaultValue}
        {...rest}
    />
);

const EditProfileForm = ({ editEmail, handleSave, profile, loading, onCancel, isAdmin = false }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.only('xs'));

    const genderOptions = enumHelper.getSelectOptionsList(Gender);
    const raceOptions = enumHelper.getSelectOptionsList(Race);
    const hispanicOptions = enumHelper.getSelectOptionsList(HispanicOrigin);
    const citizenshipOptions = enumHelper.getSelectOptionsList(CitizenshipStatus);
    const stateOptions = enumHelper.getSelectOptionsList(State);

    const handleDownloadWardOfCourt = async () => {
        try {
            const file = (await DocumentApi.getData(profile.wardOfCourtDocumentId)).data.result;
            const blob = b64toBlob(file.data, file.mimeType);
            FileSaver.saveAs(blob, file.fileName);
        } catch {
        } finally {
        }
    };

    return (
        <Form onSubmit={(values) => handleSave(values)} initialValues={profile} decorators={[focusOnError]}>
            {(props) => {
                return (
                    <form noValidate onSubmit={props.handleSubmit}>
                        <Grid container justify="center" className={classes.padding}>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="space-between" mb={1}>
                                    <div>
                                        <Typography variant="h5">{isAdmin ? 'Applicant Profile' : 'Edit Profile'}</Typography>
                                        {profile.wardOfCourtDocumentId && (
                                            <Link className={classes.link} onClick={handleDownloadWardOfCourt}>
                                                Download Ward of Court Letter
                                            </Link>
                                        )}
                                    </div>
                                    {isAdmin && <Field name="deceased" component={SimpleCheckbox} color="primary" label="Deceased" />}
                                </Box>

                                <Grid container spacing={1}>
                                    <Grid item sm={6} xs={12}>
                                        <Field
                                            name="firstName"
                                            placeholder="First Name"
                                            margin="dense"
                                            maxLength="50"
                                            required
                                            fullWidth
                                            label="First Name"
                                            id="firstName"
                                            component={FinalFormTextField}
                                            validate={composeValidators(required, maxLength(50))}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <Field
                                            name="lastName"
                                            placeholder="Last Name"
                                            margin="dense"
                                            maxLength="50"
                                            required
                                            fullWidth
                                            label="Last Name"
                                            id="lastName"
                                            component={FinalFormTextField}
                                            validate={composeValidators(required, maxLength(50))}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item sm={6} xs={12}>
                                        <Field
                                            name="ssn"
                                            placeholder="Social Security #"
                                            margin="dense"
                                            maxLength="11"
                                            required
                                            fullWidth
                                            label="Social Security #"
                                            id="ssn"
                                            component={FinalFormTextField}
                                            validate={composeValidators(required, exactLength(11, 'Invalid Social Security #'))}
                                            inputComponent={SsnMaskInput}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <Field
                                            name="birthDate"
                                            margin="dense"
                                            required
                                            fullWidth
                                            label="Birthday"
                                            id="birthDate"
                                            component={FinalFormTextField}
                                            validate={composeValidators(required, isDate, isDateBeforeNow)}
                                            inputComponent={DateMaskInput}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item sm={6} xs={12}>
                                        <Box display="flex" alignItems="center">
                                            <Field
                                                name="emailAddress"
                                                placeholder="email@address.com"
                                                margin="dense"
                                                maxLength="256"
                                                fullWidth
                                                label="Email Address"
                                                id="emailAddress"
                                                tooltipPlacement="right"
                                                tooltipContent={
                                                    <BasicTooltipText>
                                                        Your email will be used to:
                                                        <br />
                                                        1. Verify your email address
                                                    </BasicTooltipText>
                                                }
                                                component={FinalFormTextField}
                                                disabled={true}
                                            />
                                            {!isAdmin && (
                                                <Button
                                                    onClick={editEmail}
                                                    style={{ marginLeft: 10 }}
                                                    size="small"
                                                    color="primary"
                                                    variant="contained"
                                                >
                                                    Edit
                                                </Button>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <Field
                                            component={FinalFormTextField}
                                            name="phoneNumber"
                                            variant="outlined"
                                            margin="dense"
                                            required
                                            fullWidth
                                            label="Mobile Phone Number"
                                            id="phoneNumber"
                                            placeholder="Main Phone"
                                            validate={required}
                                            inputComponent={PhoneMaskInput}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item sm={6} xs={12}>
                                        <Field
                                            name="address.street1"
                                            label="Street Address 1"
                                            id="street1"
                                            margin="dense"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            placeholder="Street Address 1"
                                            component={FinalFormTextField}
                                            validate={required}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <Field
                                            name="address.street2"
                                            label="Street Address 2"
                                            id="street2"
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="Street Address 2"
                                            component={FinalFormTextField}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item sm={5} xs={12}>
                                        <Field
                                            name="address.city"
                                            label="City"
                                            id="city"
                                            margin="dense"
                                            fullWidth
                                            variant="outlined"
                                            required
                                            placeholder="City"
                                            component={FinalFormTextField}
                                            validate={required}
                                        />
                                    </Grid>
                                    <Grid item sm={3} xs={12}>
                                        <Field
                                            name="address.state"
                                            label="State"
                                            placeholder="Select State..."
                                            id="state"
                                            margin="dense"
                                            fullWidth
                                            required
                                            component={FinalFormDropdown}
                                            choices={stateOptions}
                                            validate={required}
                                        />
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <Field
                                            name="address.zipCode"
                                            label="Zip"
                                            id="zipCode"
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            placeholder="Zip"
                                            component={FinalFormTextField}
                                            validate={required}
                                            inputComponent={ZipMaskInput}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item sm={3} xs={12}>
                                        <Field
                                            name="gender"
                                            label="Gender"
                                            id="gender"
                                            margin="dense"
                                            fullWidth
                                            variant="outlined"
                                            required
                                            placeholder="Select Gender..."
                                            choices={genderOptions}
                                            component={FinalFormDropdown}
                                            validate={required}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item sm={4} xs={12}>
                                        <Field
                                            name="race"
                                            label="Race"
                                            id="race"
                                            margin="dense"
                                            fullWidth
                                            placeholder="Select Race..."
                                            choices={raceOptions}
                                            component={FinalFormDropdown}
                                        />
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <Field
                                            name="hispanicOrigin"
                                            label="Are you of Hispanic Origin?"
                                            id="hispanicOrigin"
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="Select Hispanic Origin..."
                                            choices={hispanicOptions}
                                            component={FinalFormDropdown}
                                        />
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <Field
                                            name="citizenshipStatus"
                                            label="Citizenship Status"
                                            id="citizenshipStatus"
                                            margin="dense"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            placeholder="Select Citizenship Status..."
                                            choices={citizenshipOptions}
                                            component={FinalFormDropdown}
                                            validate={required}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item sm={6} xs={12}>
                                        <Field
                                            name="alternateEmailAddress"
                                            placeholder="email@address.com"
                                            margin="dense"
                                            maxLength="256"
                                            fullWidth
                                            label="Alternate Email Address"
                                            id="alternateEmailAddress"
                                            component={FinalFormTextField}
                                            validate={email}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <Field
                                            component={FinalFormTextField}
                                            name="alternatePhoneNumber"
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            label="Alternate Phone Number"
                                            id="alternatePhoneNumber"
                                            placeholder="Alternate Phone Number"
                                            inputComponent={PhoneMaskInput}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item sm={12} xs={12}>
                                        <Field
                                            name="graduationDate"
                                            margin="dense"
                                            required
                                            fullWidth
                                            label="High School Graduation or Equivalent Degree (eg GED) Date"
                                            id="highSchoolGraduationDate"
                                            component={FinalFormTextField}
                                            validate={composeValidators(required, isDate)}
                                            inputComponent={DateMaskInput}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item sm={12} xs={12}>
                                        <Field
                                            name="inFosterCareOnOrAfterAge16"
                                            margin="dense"
                                            choices={[
                                                { label: 'Yes', value: 'true' },
                                                { label: 'No', value: 'false' }
                                            ]}
                                            required
                                            row
                                            fullWidth
                                            label="Were you in foster care on or after your 16th birthday?"
                                            id="inFosterCareOnOrAfterAge16"
                                            component={FinalFormRadioGroup}
                                            validate={required}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} direction={isXS ? 'column-reverse' : 'row'} justify="flex-end">
                                    {!isAdmin && (
                                        <>
                                            <Grid item xs={isXS ? 12 : undefined}>
                                                <Button variant="outlined" color="primary" fullWidth onClick={onCancel} disabled={loading}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={isXS ? 12 : undefined}>
                                                <LoadingButton type="submit" fullWidth variant="contained" color="primary" loading={loading}>
                                                    Save
                                                </LoadingButton>
                                            </Grid>
                                            <Grid item xs={isXS ? 12 : undefined}>
                                                <LoadingButton
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    loading={loading}
                                                    onClick={() => props.form.change('action', 'continue')}
                                                >
                                                    Save &amp; Continue to Application
                                                </LoadingButton>
                                            </Grid>
                                        </>
                                    )}
                                    {isAdmin && (
                                        <Grid item xs={isXS ? 12 : undefined}>
                                            <LoadingButton type="submit" fullWidth variant="contained" color="primary" loading={loading}>
                                                Save Profile
                                            </LoadingButton>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                );
            }}
        </Form>
    );
};

export default EditProfileForm;
