import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { Typography } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import Box from '@material-ui/core/Box';
import { fade } from '@material-ui/core/styles';

import { conformedCurrency } from '../../../../../common/masks';

const useStyles = makeStyles((theme) => ({
    awardSummary: {
        padding: theme.spacing(1),
        backgroundColor: fade(theme.palette.secondary.main, 0.5),
        color: theme.palette.secondary.contrastText,
        border: `solid 1px ${theme.palette.secondary.contrastText}`,
        borderRadius: theme.shape.borderRadius,
        display: 'flex'
    }
}));

const AwardSummary = ({ award, totalFederal, totalState }) => {
    const classes = useStyles();

    return (
        <div className={classes.awardSummary}>
            <Box mr={2}>
                <Box mb={1}>
                    <FormLabel>Students Awaiting Institution Review</FormLabel>
                    <Typography variant="body2">{award?.used?.amountAwaitingInstitution}</Typography>
                </Box>
                <Box>
                    <FormLabel>Students With Conditions to Review</FormLabel>
                    <Typography variant="body2">{award?.used?.amountWithConditions}</Typography>
                </Box>
            </Box>
            <Box mr={2}>
                <Box mb={1}>
                    <FormLabel>Students Awaiting Awards</FormLabel>
                    <Typography variant="body2">{award?.used?.amountAwaitingAwards}</Typography>
                </Box>
                <Box>
                    <FormLabel>Students Awarded</FormLabel>
                    <Typography variant="body2">{award?.used?.amountAwarded}</Typography>
                </Box>
            </Box>
            <Box mr={2}>
                <Box mb={1}>
                    <FormLabel>Total Unmet Need</FormLabel>
                    <Typography variant="body2">
                        {conformedCurrency(award?.used?.totalUnmetNeed.toString(), { allowDecimal: false, allowNegative: true })}
                    </Typography>
                </Box>
                <Box>
                    <FormLabel>Remaining Unmet Need</FormLabel>
                    <Typography variant="body2">
                        {conformedCurrency((award?.used?.totalUnmetNeed - award?.used?.federalUsed - award?.used?.stateUsed).toString(), {
                            allowDecimal: false,
                            allowNegative: true
                        })}
                    </Typography>
                </Box>
            </Box>
            <Box mr={2}>
                <Box mb={1}>
                    <FormLabel>State Awarded</FormLabel>
                    <Typography variant="body2">
                        {conformedCurrency(award?.used?.stateUsed.toString(), { allowDecimal: false, allowNegative: true })}
                    </Typography>
                </Box>
                <Box>
                    <FormLabel>Remaining State with Over-award</FormLabel>
                    <Typography variant="body2">
                        {conformedCurrency((totalState - award?.used?.stateUsed).toString(), { allowDecimal: false, allowNegative: true })}
                    </Typography>
                </Box>
            </Box>
            <Box mr={2}>
                <Box mb={1}>
                    <FormLabel>Federal Awarded</FormLabel>
                    <Typography variant="body2">
                        {conformedCurrency(award?.used?.federalUsed.toString(), { allowDecimal: false, allowNegative: true })}
                    </Typography>
                </Box>
                <Box>
                    <FormLabel>Remaining Federal with Over-award</FormLabel>
                    <Typography variant="body2">
                        {conformedCurrency((totalFederal - award?.used?.federalUsed).toString(), { allowDecimal: false, allowNegative: true })}
                    </Typography>
                </Box>
            </Box>
        </div>
    );
};

export default AwardSummary;
