import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import queryString from 'query-string';

import ResetPasswordForm from '../resetPassword/resetPasswordForm';
import Logo from '../../assets/dcyf-logo.png';
import LogoSmall from '../../assets/dcyf-logo-small.png';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(5),
        [theme.breakpoints.only('xs')]: {
            padding: theme.spacing(0, 2),
            marginTop: theme.spacing(2)
        }
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    subtitle: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3)
    },
    logo: {
        maxWidth: 500
    }
}));

export default function CompleteAccountSetupPage({ location }) {
    const classes = useStyles();
    const qs = queryString.parse(location.search);
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.only('xs'));

    return (
        <div className={classes.paper}>
            <div>
                <img className={classes.logo} src={isXS ? LogoSmall : Logo} alt="DCYF Logo" />
            </div>
            <Grid container justify="center">
                <Grid item xs={12} className={classes.container}>
                    <Typography variant="h3" style={{ textTransform: 'capitalize', textAlign: 'center' }}>
                        {`${qs.role} Account setup`.toLowerCase()}
                    </Typography>
                    <Typography variant="body1" className={classes.subtitle}>
                        Please enter your password below to complete your account setup.
                    </Typography>
                    <ResetPasswordForm location={location} accountSetup={true} successMessage="Password saved successfully!" />
                </Grid>
            </Grid>
        </div>
    );
}
