import React from 'react';
import LeftNavLayout from '../../leftNavLayout';

import JobsList from './list';

export default function JobsPage() {
    return (
        <LeftNavLayout>
            <JobsList />
        </LeftNavLayout>
    );
}
