import axios from 'axios';

export function create(input) {
    return axios.post('/api/services/app/Application/Create', input);
}

export function getByUser(academicYearClosed, userId, includePayouts, cancelToken) {
    return axios.get('/api/services/app/Application/GetByUser', { params: { academicYearClosed, userId, includePayouts }, cancelToken });
}

export function update(input) {
    return axios.put('/api/services/app/Application/Update', input);
}

export function updateCollegeId(applicationId, collegeId) {
    return axios.put('/api/services/app/Application/UpdateCollegeId', { applicationId, collegeId });
}

export function submit(input) {
    return axios.post('/api/services/app/Application/Submit', input);
}

export function getByAcademicYear(academicYear, cancelToken) {
    return axios.get('/api/services/app/Application/GetByAcademicYear', { params: { academicYear }, cancelToken });
}

export function getSummary(cancelToken) {
    return axios.get('/api/services/app/Application/GetSummary', { cancelToken });
}

export function adminGet(applicationId, cancelToken) {
    return axios.post('/api/services/app/Application/AdminGet', { id: applicationId }, { cancelToken });
}

export function adminUpdate(values, cancelToken) {
    return axios.post('/api/services/app/Application/AdminUpdate', values, { cancelToken });
}

export function approveReject(values) {
    return axios.post('/api/services/app/Application/ApproveReject', values);
}

export function getApplicationProfileSummary(applicationId) {
    return axios.get('/api/services/app/Application/GetApplicationProfileSummary', { params: { id: applicationId } });
}

export function exportApplicants(academicYear, status) {
    return axios.post('/api/services/app/Application/ExportApplicants', { academicYear, status });
}
