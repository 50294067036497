import React, { useMemo, useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import createDecorator from 'final-form-focus';
import { Field, Form } from 'react-final-form';
import FileSaver from 'file-saver';
import { useSnackbar } from 'notistack';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import Box from '@material-ui/core/Box';

import ApplicationStatusChip from '../../../common/applicationStatusChip';
import b64toBlob from '../../../common/logic/b64toBlob';
import { FinalFormTextField, FinalFormDropdown, Condition, FinalFormCheckbox, WhenFieldChanges } from '../../../common/formWrappers';
import enumHelper from '../../../common/logic/enumHelper';
import { Grade, EnrollmentStatus, ApplicationStatus, AgreementType } from '../../../common/logic/enums';
import { OTHER_INSTITUTION_VALUE } from '../../../common/logic/consts';
import LoadingButton from '../../../common/loadingButton';
import * as DocumentApi from '../../../api/document';
import { APP_SETTING_NAME_AGREEMENTS } from '../../../common/logic/consts';

import ContentWrapper from './contentWrapper';
import { useAuth } from '../../../auth/auth-context';
import ConfirmDialog from '../../../common/confirmDialog';

const useStyles = makeStyles((theme) => ({
    agreementLink: {
        marginLeft: theme.spacing(0.5),
        cursor: 'pointer'
    },
    noMargin: {
        margin: 0
    },
    subtext: {
        fontSize: '0.75rem',
        marginBottom: theme.spacing(0.5)
    }
}));

const focusOnError = createDecorator();

const EditForm = ({ handleSave, applicationToEdit, institutions, loading, onCancel, isAdmin = false }) => {
    const classes = useStyles();
    const { settings } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const [hasDownloaded, setHasDownloaded] = useState({ [AgreementType.Funds]: false, [AgreementType.Records]: false, [AgreementType.FAQS]: false });
    const [showYearClosedWarning, setShowYearClosedWarning] = useState(false);
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.only('xs'));

    const agreements = settings.find((s) => s.name === APP_SETTING_NAME_AGREEMENTS).value;

    const gradeOptions = useMemo(() => enumHelper.getSelectOptionsList(Grade));
    const enrollmentOptions = useMemo(() => enumHelper.getSelectOptionsList(EnrollmentStatus));

    const handleDownloadAgreement = async (type) => {
        let agreement;
        setHasDownloaded((prev) => ({ ...prev, [type]: true }));
        switch (type) {
            case AgreementType.Funds:
                agreement = agreements.funds;
                break;
            case AgreementType.Records:
                agreement = agreements.records;
                break;
            case AgreementType.FAQS:
                agreement = agreements.faqs;
                break;
            default:
                console.error('unsupported agreement type');
                break;
        }

        try {
            const file = (await DocumentApi.getData(agreement.id)).data.result;
            const blob = b64toBlob(file.data, file.mimeType);
            FileSaver.saveAs(blob, file.fileName);
        } catch (ex) {
            enqueueSnackbar('Error downloading document', { variant: 'error' });
            console.error(ex);
        }
    };

    const handleSubmitValidation = (values, state) => {
        if (values.submitting) {
            if (
                (state.name === 'selectedInstitutionId' ||
                    state.name === 'enrollmentStatus' ||
                    state.name === 'useOfFunds' ||
                    state.name === 'accessToRecords' ||
                    state.name === 'hasReadFAQs') &&
                !values[state.name]
            ) {
                return 'This field is required';
            }

            if (state.name === 'otherInstitution' && values.selectedInstitutionId === OTHER_INSTITUTION_VALUE && !values[state.name]) {
                return 'This field is required';
            }
        }

        return undefined;
    };

    return (
        <Form onSubmit={(values) => handleSave(values)} initialValues={applicationToEdit} decorators={[focusOnError]}>
            {(props) => {
                if (props.errors) {
                    const theErrors = Object.keys(props.errors);
                    if (theErrors.length > 0) {
                        document.getElementById(theErrors[0]).scrollIntoView(false);
                    }
                }
                return (
                    <form className={classes.form} noValidate onSubmit={props.handleSubmit}>
                        <ContentWrapper applicationToEdit={applicationToEdit}>
                            <Grid container spacing={1}>
                                <Grid item sm={4} xs={12}>
                                    <Field
                                        name="currentGrade"
                                        placeholder="Select Current Grade..."
                                        margin="dense"
                                        fullWidth
                                        label="Select Current Grade"
                                        choices={gradeOptions}
                                        id="currentGrade"
                                        component={FinalFormDropdown}
                                    />
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <WhenFieldChanges
                                        field="selectedInstitutionId"
                                        becomes={institutions.filter((i) => i.value === props.values.selectedInstitutionId).map((i) => i.value)}
                                        set="useOfFunds"
                                        to={false}
                                        also={() => setHasDownloaded((prev) => ({ ...prev, [AgreementType.Funds]: false }))}
                                    />
                                    <WhenFieldChanges
                                        field="selectedInstitutionId"
                                        becomes={institutions.filter((i) => i.value === props.values.selectedInstitutionId).map((i) => i.value)}
                                        set="accessToRecords"
                                        to={false}
                                        also={() => setHasDownloaded((prev) => ({ ...prev, [AgreementType.Records]: false }))}
                                    />
                                    <WhenFieldChanges
                                        field="selectedInstitutionId"
                                        becomes={institutions.filter((i) => i.value === props.values.selectedInstitutionId).map((i) => i.value)}
                                        set="hasReadFAQs"
                                        to={false}
                                        also={() => setHasDownloaded((prev) => ({ ...prev, [AgreementType.FAQS]: false }))}
                                    />
                                    <Field
                                        name="selectedInstitutionId"
                                        placeholder="Select Institution..."
                                        margin="dense"
                                        fullWidth
                                        required
                                        label="School Attending"
                                        id="selectedInstitutionId"
                                        choices={institutions.filter((i) => i.value === props.values.selectedInstitutionId || i.active)}
                                        component={FinalFormDropdown}
                                        validate={(value, values, state) => handleSubmitValidation(values, state)}
                                    />
                                </Grid>

                                <Grid item sm={4} xs={12}>
                                    <Condition when="selectedInstitutionId" is={OTHER_INSTITUTION_VALUE}>
                                        <Field
                                            name="otherInstitution"
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            required
                                            maxLength="100"
                                            label="Other"
                                            id="otherInstitution"
                                            component={FinalFormTextField}
                                            validate={(value, values, state) => handleSubmitValidation(values, state)}
                                        />
                                    </Condition>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item sm={6} xs={12}>
                                    <Field
                                        name="schoolIdentificationNumber"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        maxLength={20}
                                        label="School ID Number"
                                        placeholder="School ID Number"
                                        id="schoolIdentificationNumber"
                                        tooltipContent="Identification number provided to you by your school."
                                        tooltipPlacement="left"
                                        component={FinalFormTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item sm={6} xs={12}>
                                    <Field
                                        name="enrollmentStatus"
                                        placeholder="Select Enrollment Status..."
                                        margin="dense"
                                        fullWidth
                                        required
                                        label="Enrollment Status"
                                        choices={enrollmentOptions}
                                        id="enrollmentStatus"
                                        component={FinalFormDropdown}
                                        validate={(value, values, state) => handleSubmitValidation(values, state)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item sm={12} xs={12}>
                                    <Typography variant="body2">
                                        <strong>I agree to the following:</strong>
                                    </Typography>
                                    <Typography className={classes.subtext} variant="body2">
                                        (Note: You must download and review each agreement before checkbox is enabled.)
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item sm={4} xs={12}>
                                    <Field
                                        name="useOfFunds"
                                        placeholder="Use of Funds"
                                        margin="dense"
                                        required
                                        fullWidth
                                        labelClassName={classes.noMargin}
                                        disabled={!isAdmin && !hasDownloaded[AgreementType.Funds]}
                                        tooltipContent={!hasDownloaded[AgreementType.Funds] ? 'Please download first' : ''}
                                        tooltipPlacement="bottom"
                                        label={
                                            <>
                                                <span>Use of Funds Agreement</span>
                                                <Link className={classes.agreementLink} onClick={() => handleDownloadAgreement(AgreementType.Funds)}>
                                                    (download)
                                                </Link>
                                            </>
                                        }
                                        id="useOfFunds"
                                        component={FinalFormCheckbox}
                                        validate={(value, values, state) => handleSubmitValidation(values, state)}
                                    />
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <Field
                                        name="accessToRecords"
                                        placeholder="Access to Records"
                                        margin="dense"
                                        required
                                        fullWidth
                                        labelClassName={classes.noMargin}
                                        disabled={!isAdmin && !hasDownloaded[AgreementType.Records]}
                                        tooltipContent={!hasDownloaded[AgreementType.Records] ? 'Please download first' : ''}
                                        tooltipPlacement="bottom"
                                        label={
                                            <>
                                                <span>Access to Records Agreement</span>
                                                <Link
                                                    className={classes.agreementLink}
                                                    onClick={() => handleDownloadAgreement(AgreementType.Records)}
                                                >
                                                    (download)
                                                </Link>
                                            </>
                                        }
                                        id="accessToRecords"
                                        component={FinalFormCheckbox}
                                        validate={(value, values, state) => handleSubmitValidation(values, state)}
                                    />
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <Field
                                        name="hasReadFAQs"
                                        placeholder="Has read FAQs"
                                        margin="dense"
                                        required
                                        fullWidth
                                        labelClassName={classes.noMargin}
                                        disabled={!isAdmin && !hasDownloaded[AgreementType.FAQS]}
                                        tooltipContent={!hasDownloaded[AgreementType.FAQS] ? 'Please download first' : ''}
                                        tooltipPlacement="bottom"
                                        label={
                                            <>
                                                <span>Reading the FAQs</span>
                                                <Link className={classes.agreementLink} onClick={() => handleDownloadAgreement(AgreementType.FAQS)}>
                                                    (download)
                                                </Link>
                                            </>
                                        }
                                        id="hasReadFAQs"
                                        component={FinalFormCheckbox}
                                        validate={(value, values, state) => handleSubmitValidation(values, state)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} direction={isXS ? 'column-reverse' : 'row'} justify="flex-end">
                                {!isAdmin && (
                                    <>
                                        <Grid item xs={isXS ? 12 : undefined}>
                                            <Button variant="outlined" color="primary" fullWidth onClick={onCancel}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        {applicationToEdit && applicationToEdit.applicationStatus === ApplicationStatus.InProgress && (
                                            <Grid item xs={isXS ? 12 : undefined}>
                                                <LoadingButton
                                                    type="submit"
                                                    onClick={() => props.form.change('submitting', false)}
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    loading={loading}
                                                >
                                                    Save
                                                </LoadingButton>
                                            </Grid>
                                        )}
                                        <Grid item xs={isXS ? 12 : undefined}>
                                            <LoadingButton
                                                type="submit"
                                                onClick={() => props.form.change('submitting', true)}
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                loading={loading}
                                            >
                                                Save &amp; Submit
                                            </LoadingButton>
                                        </Grid>
                                    </>
                                )}
                                {isAdmin && (
                                    <Grid item xs={12}>
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <ApplicationStatusChip status={applicationToEdit?.applicationStatus} />
                                            {applicationToEdit?.academicYear.closed ? (
                                                <Button variant="contained" color="primary" onClick={() => setShowYearClosedWarning(true)}>
                                                    Save Application
                                                </Button>
                                            ) : (
                                                <LoadingButton
                                                    type="submit"
                                                    onClick={() => props.form.change('submitting', true)}
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    loading={loading}
                                                >
                                                    Save Application
                                                </LoadingButton>
                                            )}
                                        </Box>
                                        <ConfirmDialog
                                            open={showYearClosedWarning}
                                            handleClose={() => setShowYearClosedWarning(false)}
                                            disablePortal
                                            confirmAction={() => {
                                                props.form.change('submitting', true);
                                                setShowYearClosedWarning(false);
                                            }}
                                            isSubmit
                                            title="Edit Application"
                                            body={`The Academic Year for this application is currently closed.  Are you sure you wish to edit this application?`}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </ContentWrapper>
                    </form>
                );
            }}
        </Form>
    );
};

export default EditForm;
