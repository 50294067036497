import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import BackButton from '../../../common/backButton';

import HostLayout from '../../hostLayout';
import TenantsUserList from './list';

const useStyles = makeStyles(theme => ({
    actions: {
        paddingBottom: theme.spacing(1)
    }
}));

export default function UsersPage({ tenantId }) {
    const classes = useStyles();
    return (
        <HostLayout>
            <div className={classes.actions}>
                <BackButton location="/" />
            </div>
            <Paper>
                <TenantsUserList tenantId={parseInt(tenantId, 10)} />
            </Paper>
        </HostLayout>
    );
}
