/* eslint react-hooks/exhaustive-deps: 0 */

import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import axios from 'axios';

import deepCopyObject from '../logic/deepCopyObject';

const maxChunkSizeInBytes = 10000000;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flex: 1,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#fff',
        cursor: 'pointer'
    },
    dragActive: { borderWidth: 1, borderStyle: 'solid', borderColor: theme.palette.success.main },
    circle: {
        width: 100,
        height: 100,
        borderRadius: 100,
        backgroundColor: theme.palette.grey[500],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    square: {
        width: 250,
        height: 150,
        backgroundColor: theme.palette.grey[500],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    iconLarge: { fontSize: '4em' },
    description: { flex: 1, padding: theme.spacing(2), display: 'flex', flexDirection: 'column', justifyContent: 'center' },
    caption: { fontWeight: 600 },
    limit: { color: 'gray' }
}));

const ChunkFileUpload = ({
    captionText,
    limitText,
    groupingId,
    groupType,
    thumbnailWidth,
    thumbnailHeight,
    onUploadComplete,
    dropzoneProps,
    value,
    tooltipContent,
    tooltipPlacement,
    showTooltip,
    children,
    className,
    ...props
}) => {
    let fIndex = 0;
    const classes = useStyles(props);
    const [uploadingFiles, setUploadingFiles] = useState([]);
    const [currentChunk, setCurrentChunk] = useState(0);
    const [response, setResponse] = useState(null);
    const uploadingFilesRef = React.useRef(uploadingFiles); //need this to reference state inside of eventHandler when using hooks (if using a component, this isn't needed)
    const currentChunkRef = React.useRef(currentChunk);
    const { enqueueSnackbar } = useSnackbar();

    // useEffect(() => {
    //     loadData();
    // }, []);

    // const loadData = async () => {
    //     if (value) {
    //         const response = await get(value);
    //         setPreview(response.data.result.thumbnail);
    //     }
    // };

    const updateUploadingFiles = data => {
        uploadingFilesRef.current = data;
        setUploadingFiles(data);
    };

    const removeUploadingFile = fileIndex => {
        let uploadingFilesCopy = deepCopyObject(uploadingFiles);
        uploadingFilesCopy = uploadingFiles.filter(f => f.index !== fileIndex);
        updateUploadingFiles(uploadingFilesCopy);
    };

    const uploadFile = (file, fileIndex) => {
        uploadChunks(file, fileIndex);
    };

    const uploadChunks = async (file, fileIndex) => {
        let blocks = [];
        let offset = 0;
        let index = 0;
        while (offset < file.size) {
            var start = offset;
            var end = Math.min(offset + maxChunkSizeInBytes, file.size);

            blocks.push({
                name: file.name,
                index: index,
                start: start,
                end: end
            });

            offset = end;
            index++;
        }

        const document = (
            await axios.post('/api/services/app/Documents/StartUpload', {
                fileName: file.name,
                groupingId,
                groupType
            })
        ).data.result;

        const axiosConfig = {
            onUploadProgress: function(progressEvent) {
                const uploadingFilesCopy = deepCopyObject(uploadingFilesRef.current);
                const item = uploadingFilesCopy.find(item => item.index === fileIndex);
                if (item) {
                    const currentChunkBytes = currentChunkRef.current * maxChunkSizeInBytes;
                    const value = ((currentChunkBytes / file.size) * 100).toFixed(0);
                    const buffer = (((currentChunkBytes + progressEvent.loaded) / file.size) * 100).toFixed(0);

                    item.buffer = buffer;
                    item.percentComplete = value;
                    updateUploadingFiles(uploadingFilesCopy);
                }
            }
        };

        for (let i = 0; i < blocks.length; i++) {
            const block = blocks[i];
            // load blob based on the start and end index for each chunks
            var blob = file.slice(block.start, block.end);
            // put the file name, index and blob into a temporary from
            var fd = new FormData();
            fd.append('chunkIndex', block.index);
            fd.append(document.id, blob);

            currentChunkRef.current = block.index;
            setCurrentChunk(block.index);

            // post the form to backend service (asp.net mvc controller action)
            await axios.post('/api/services/app/Documents/UploadChunk', fd, axiosConfig);
        }

        const uploadingFilesCopy = deepCopyObject(uploadingFilesRef.current);
        const item = uploadingFilesCopy.find(item => item.index === fileIndex);
        if (item) {
            item.buffer = 100;
            item.percentComplete = 100;
            updateUploadingFiles(uploadingFilesCopy);
        }

        // call the complete upload
        const response = await axios.post('/api/services/app/Documents/CompleteUpload', {
            numberOfChunks: blocks.length,
            documentId: document.id,
            thumbnailWidth,
            thumbnailHeight
        });

        removeUploadingFile(fileIndex);
        if (onUploadComplete) {
            onUploadComplete(response.data.result);
        }

        setResponse(response.data.result);
    };

    const onDropRejected = (rejectedFiles, event) => {
        if (rejectedFiles.filter(f => f.size >= dropzoneProps.maxSize).length > 0) {
            enqueueSnackbar('File size is too big. Please select a file less than 5MB.', { variant: 'error' });
        } else {
            enqueueSnackbar('File type is not supported.  Please upload a jpg or png.', { variant: 'error' });
        }
    };

    const onDrop = acceptedFiles => {
        const uploadingFilesCopy = deepCopyObject(uploadingFiles);

        acceptedFiles.forEach(file => {
            uploadingFilesCopy.push({ index: fIndex, percentComplete: 0, buffer: 0, fileName: file.name });
            uploadFile(file, fIndex++);
        });

        updateUploadingFiles(uploadingFilesCopy);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, ...dropzoneProps, onDropRejected });

    const TheFileUpload = (
        <div {...getRootProps({ className: isDragActive ? clsx(classes.root, classes.dragActive, className) : clsx(classes.root, className) })}>
            <input {...getInputProps({ style: { display: 'none' } })} />
            {children({ captionText, limitText, response, uploadingFiles, value })}
        </div>
    );

    return tooltipContent && tooltipPlacement ? (
        <Tooltip arrow title={tooltipContent} open={showTooltip} placement={tooltipPlacement} TransitionComponent={Zoom}>
            {TheFileUpload}
        </Tooltip>
    ) : (
        TheFileUpload
    );
};

export default ChunkFileUpload;
