import axios from 'axios';

export function getAll() {
    return axios.get('/api/services/app/Tenant/GetAll', {
        params: {
            keyword: '',
            isActive: undefined,
            skipCount: 0,
            maxResultCount: 2000
        }
    });
}

export function get(id) {
    return axios.get('/api/services/app/Tenant/Get', { params: { id } });
}

export function create(values) {
    return axios.post('/api/services/app/Tenant/Create', values);
}

export function update(values) {
    return axios.put('/api/services/app/Tenant/Update', values);
}

export function deleteTenant(id) {
    return axios.delete('/api/services/app/Tenant/Delete', { params: { id } });
}
