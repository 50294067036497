import React, { useState, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CreateIcon from '@material-ui/icons/Create';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

import * as RoleApi from '../../../api/role';
import EditDialog from './editDialog';

const useStyles = makeStyles((theme) => ({
    leftIcon: {
        marginRight: theme.spacing(1)
    },
    tableTitle: {
        flexGrow: 1
    }
}));

export default function RoleList() {
    const [roles, setRoles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);
    const [permissions, setPermissions] = useState([]);

    const classes = useStyles();

    useEffect(() => {
        async function loadData() {
            try {
                setIsLoading(true);
                const roles = (await RoleApi.getAll()).data.result.items;
                const permissionData = (await RoleApi.getAllPermissions()).data.result.items;
                const permissions = permissionData.map((perm) => ({ label: perm.displayName, value: perm.name }));
                setRoles(roles);
                setPermissions(permissions);
            } catch (error) {
            } finally {
                setIsLoading(false);
            }
        }
        loadData();
    }, [selectedRole, showEdit]);

    async function saveRole(role) {
        try {
            setIsSaving(true);
            if (role.id) {
                await RoleApi.update(role);
            } else {
                await RoleApi.create(role);
            }
            setShowEdit(false);
            setSelectedRole(null);
        } catch (error) {
        } finally {
            setIsSaving(false);
        }
    }

    return (
        <div>
            {isLoading && <LinearProgress />}
            <Box p={1} color="secondary.contrastText" bgcolor="secondary.main">
                <Toolbar variant="dense">
                    <Typography className={classes.tableTitle} variant="h6">
                        Roles
                    </Typography>
                    <IconButton
                        color="inherit"
                        onClick={() => {
                            setSelectedRole(null);
                            setShowEdit(true);
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                </Toolbar>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Display Name</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Permissions</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {roles.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.displayName}</TableCell>
                            <TableCell>{row.description}</TableCell>
                            <TableCell>
                                <Grid container spacing={1}>
                                    {row.grantedPermissions.map((item) => (
                                        <Grid item key={item}>
                                            <Chip label={item} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </TableCell>
                            <TableCell>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        {row.name === 'Admin' && (
                                            <Typography component="div" variant="body2">
                                                <Box color="error.main">Cannot Edit Admin</Box>
                                            </Typography>
                                        )}
                                        {row.name !== 'Admin' && (
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="primary"
                                                onClick={() => {
                                                    setSelectedRole(row);
                                                    setShowEdit(true);
                                                }}
                                            >
                                                <CreateIcon className={classes.leftIcon} fontSize="small" />
                                                Edit
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <EditDialog
                open={showEdit}
                handleClose={() => (isSaving ? undefined : setShowEdit(false))}
                role={selectedRole}
                isSaving={isSaving}
                handleSave={saveRole}
                permissions={permissions}
            />
        </div>
    );
}
