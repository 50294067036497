import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

const useStyles = makeStyles((theme) => ({
    approveReject: {
        backgroundColor: 'white',
        position: 'fixed',
        border: 'solid 1px rgba(0, 0, 0, 0.23)',
        borderTopRightRadius: theme.shape.borderRadius,
        borderLeft: 'none',
        borderBottom: 'none',
        padding: theme.spacing(2),
        boxShadow: theme.shadows[5],
        left: 0,
        bottom: 0
    },
    button: {
        height: 48
    }
}));

const ApproveReject = ({ onApprove }) => {
    const classes = useStyles();

    return (
        <div className={classes.approveReject}>
            <Box display="flex">
                <Button startIcon={<ThumbUpIcon />} onClick={onApprove} color="primary" variant="contained" className={classes.button}>
                    Accept / Reject
                </Button>
            </Box>
        </div>
    );
};

export default ApproveReject;
