import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

import enumHelper from '../../../common/logic/enumHelper';
import { ApplicationStatus } from '../../../common/logic/enums';

const useStyles = makeStyles((theme) => ({
    padding: {
        padding: theme.spacing(1)
    },
    title: {
        marginBottom: theme.spacing(1)
    },
    applicationStatusAccepted: {
        marginLeft: theme.spacing(1),
        color: theme.palette.success.main
    },
    applicationStatusSubmitted: {
        marginLeft: theme.spacing(1),
        color: theme.palette.info.main
    },
    applicationStatusRejected: {
        marginLeft: theme.spacing(1),
        color: theme.palette.error.main
    },
    applicationStatusOpen: {
        marginLeft: theme.spacing(1),
        color: theme.palette.warning.main
    }
}));

const ContentWrapper = ({ children, applicationToEdit }) => {
    const classes = useStyles();

    return (
        <Grid container justify="center" className={classes.padding}>
            <Grid item xs={12}>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={
                        applicationToEdit && applicationToEdit.applicationStatus !== ApplicationStatus.InProgress ? 'space-between' : undefined
                    }
                    className={classes.title}
                >
                    <Typography variant="h5">
                        {applicationToEdit
                            ? `${applicationToEdit.academicYear.startYear}-${applicationToEdit.academicYear.endYear} Application`
                            : 'Applications'}
                    </Typography>
                    {applicationToEdit && applicationToEdit.applicationStatus !== ApplicationStatus.InProgress && (
                        <Typography
                            variant="body1"
                            className={
                                applicationToEdit.applicationStatus === ApplicationStatus.InProgress
                                    ? classes.applicationStatusOpen
                                    : applicationToEdit.applicationStatus === ApplicationStatus.Submitted
                                    ? classes.applicationStatusSubmitted
                                    : applicationToEdit.applicationStatus === ApplicationStatus.Accepted
                                    ? classes.applicationStatusAccepted
                                    : classes.applicationStatusRejected
                            }
                        >
                            {applicationToEdit.applicationStatus !== ApplicationStatus.InProgress &&
                                `${enumHelper.getDisplayName(ApplicationStatus, applicationToEdit.applicationStatus)} on ${
                                    applicationToEdit.applicationStatus === ApplicationStatus.Submitted
                                        ? moment(applicationToEdit.submittedDate).format('MM/DD/YYYY h:mm a')
                                        : applicationToEdit.applicationStatus === ApplicationStatus.Accepted
                                        ? moment(applicationToEdit.approvedDate).format('MM/DD/YYYY h:mm a')
                                        : moment(applicationToEdit.rejectedDate).format('MM/DD/YYYY h:mm a')
                                }`}
                        </Typography>
                    )}
                </Box>
                {children}
            </Grid>
        </Grid>
    );
};

export default ContentWrapper;
