import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';

import JobListRow from './row';
import ErrorDialog from '../../../common/errorDialog/errorDialog';
import useJobsList from './useJobsList';

const useStyles = makeStyles((theme) => ({
    leftIcon: {
        marginRight: theme.spacing(1)
    },
    tableTitle: {
        flexGrow: 1
    },
    h6: {
        fontSize: 14
    }
}));

export default function JobsList() {
    const classes = useStyles();
    const { errorData, isLoading, data, runJob } = useJobsList();

    return (
        <div>
            {isLoading && <LinearProgress />}
            <Box p={1} color="secondary.contrastText" bgcolor="secondary.main">
                <Toolbar variant="dense">
                    <Typography className={classes.tableTitle} variant="h6">
                        Jobs
                    </Typography>
                </Toolbar>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Group</TableCell>
                        <TableCell>Key</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <JobListRow row={row} key={row.key} runJob={runJob} />
                    ))}
                </TableBody>
            </Table>
            <ErrorDialog {...errorData} />
        </div>
    );
}
