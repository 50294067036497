import React from 'react';
import { navigate } from '@gatsbyjs/reach-router';
import Link from '@material-ui/core/Link';

export default function TextLink({ location, label, ...other }) {
    return (
        <Link
            {...other}
            href={location}
            onClick={(e) => {
                e.preventDefault();
                navigate(location);
            }}
        >
            {label}
        </Link>
    );
}
