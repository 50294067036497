import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ChunkFileUpload from '../fileUpload/chunk';

const useStyles = makeStyles(theme => ({
    margin: {
        marginBottom: theme.spacing(2)
    },
    label: {
        marginBottom: theme.spacing(1)
    },
    required: {
        color: 'red'
    }
}));

const FinalFormFileUpload = ({
    input: { name, value, ...restInput },
    label,
    fullWidth,
    meta,
    required,
    onUploadComplete,
    dropzoneProps,
    tooltipContent,
    tooltipPlacement,
    childRender,
    ...rest
}) => {
    const classes = useStyles();
    return (
        <FormControl className={classes.margin} fullWidth={fullWidth} error={meta.error && meta.touched}>
            <FormLabel required={required} className={classes.label} classes={{ asterisk: classes.required }}>
                {label}
            </FormLabel>
            <ChunkFileUpload
                captionText={`Upload a ${label}`}
                limitText="Max upload file size 5MB"
                onUploadComplete={onUploadComplete}
                dropzoneProps={dropzoneProps}
                value={value}
                tooltipContent={tooltipContent}
                tooltipPlacement={tooltipPlacement}
                showTooltip={value ? false : true}
                {...rest}
            >
                {childRender}
            </ChunkFileUpload>
            {meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
    );
};

export default FinalFormFileUpload;
