import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Logo from '../../assets/dcyf-logo.png';
import LogoSmall from '../../assets/dcyf-logo-small.png';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(5),
        [theme.breakpoints.only('xs')]: {
            padding: theme.spacing(0, 2),
            marginTop: theme.spacing(2)
        }
    },
    title: {
        marginBottom: theme.spacing(2),
        alignItems: 'center'
    },
    wrapper: {
        position: 'relative',
        margin: theme.spacing(1)
    },
    logo: {
        maxWidth: 500
    }
}));

export default function NotFoundPage() {
    const classes = useStyles();
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.only('xs'));

    return (
        <div className={classes.paper}>
            <div>
                <img className={classes.logo} src={isXS ? LogoSmall : Logo} alt="DCYF Logo" />
            </div>
            <Typography variant="h3">Page Not Found</Typography>
            <Typography variant="body1">The page you requested could not be found. Please verify the URL and try again.</Typography>
        </div>
    );
}