export default function deepCopyObject(o) {
    var output, v, key;
    if (o === null) return null;
    output = Array.isArray(o) ? [] : {};
    for (key in o) {
        v = o[key];
        output[key] = v === null ? null : typeof v === 'object' ? deepCopyObject(v) : v;
    }
    return output;
}
