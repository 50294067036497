import React, { useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Grow from '@material-ui/core/Grow';
import SearchIcon from '@material-ui/icons/Search';
import InfoIcon from '@material-ui/icons/Info';
import LeftNavLayout from '../../leftNavLayout';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import { fade } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import clsx from 'clsx';

import CustomDataGrid from '../../../common/customDataGrid';
import ExcelIcon from '../../../common/icons/excelIcon';
import useStudentSearch from './useStudentSearch';
import enumHelper from '../../../common/logic/enumHelper';
import { TransactionType } from '../../../common/logic/enums';
import { dateFormatterUtc } from '../../../common/logic/grid';
import { renderAmount } from '../scholarship/financials/common';
import { useAuth } from '../../../auth/auth-context';
import { maskedSsn } from '../../../common/logic/string';
import { GridOverlay } from '@mui/x-data-grid';

const useStyles = makeStyles((theme) => ({
    certifiedStudentsSummary: {},
    formControl: {
        minWidth: 120,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25)
        }
    },
    selectLabel: {
        color: theme.palette.secondary.contrastText,
        '&.Mui-focused': {
            color: theme.palette.secondary.contrastText,
            fontWeight: 700
        }
    },
    underline: {
        '&:after': {
            borderBottom: `2px solid ${theme.palette.secondary.contrastText}`
        }
    },
    selectInput: {
        color: theme.palette.secondary.contrastText
    },
    leftMargin: {
        marginLeft: theme.spacing(1)
    },
    paper: {
        position: 'absolute',
        overflow: 'auto',
        maxHeight: 250,
        zIndex: 100
    },
    list: {
        backgroundColor: '#ffffff',
        zIndex: 1
    },
    inputWrapper: {
        backgroundColor: '#fff',
        minHeight: 49,
        width: 240,
        // border: `1px solid #a0b4c8`,
        borderRadius: 4,
        // padding: 2,
        display: 'flex',
        alignItems: 'center',
        '&$focused': {
            // padding: 1,
            // borderWidth: 2,
            // borderColor: theme.palette.primary.main
        }
    },
    focused: {},
    input: {
        height: 30,
        boxSizing: 'border-box',
        padding: theme.spacing(1.5, 1, 1.5, 0),
        // flexGrow: 1,
        border: 0,
        margin: 0,
        outline: 0,
        fontFamily: 'Roboto',
        fontSize: 16,
        borderRadius: 4,
        '&::placeholder': {
            fontFamily: 'Roboto',
            fontSize: 16,
            color: 'rgba(0,0,0,0.4)'
        },
        '&$focused': {}
    },
    chip: {
        maxWidth: 195,
        display: 'flex',
        justifyContent: 'space-between'
    },
    hideInput: {
        display: 'none'
    }
}));

const sortModel = [{ field: 'fullName', sort: 'asc' }];

const StudentPaymentHistory = () => {
    const classes = useStyles();
    const { getPeriodName } = useAuth();

    const [searchText, setSearchText] = useState('');
    const [selectedStudent, setSelectedStudent] = useState(null);

    const { isOpen, setOpen, data, isLoading, searching, searchResults, exportToExcel } = useStudentSearch({
        searchText,
        selectedStudentId: selectedStudent?.id
    });

    const columns = [
        { field: 'fullName', headerName: 'Name', flex: 1 },
        { field: 'institution', headerName: 'Institution', flex: 1, valueGetter: ({ row }) => row.institution.name },
        {
            field: 'academicYearId',
            headerName: 'Year',
            flex: 1,
            valueFormatter: ({ row }) => `${row.academicYear.startYear}-${row.academicYear.endYear}`
        },
        { field: 'period', headerName: 'Term', flex: 1, valueFormatter: ({ row }) => getPeriodName(row.institutionId, row.period) },
        { field: 'paymentDate', headerName: 'Payment Date', flex: 1, valueFormatter: dateFormatterUtc },
        {
            field: 'type',
            headerName: 'Payment Type',
            flex: 1,
            valueFormatter: ({ value }) => enumHelper.getDisplayName(TransactionType, value)
        },
        { field: 'paymentId', headerName: 'Payment ID', flex: 1 },
        { field: 'amount', headerName: 'Amount', flex: 1, renderCell: renderAmount }
    ];

    const handleInputChange = (val, reason) => {
        if (reason === 'input') {
            setSearchText(val);
        }

        if (reason === 'reset') {
            setOpen(false);
            setSearchText('');
        }
    };

    const handleStudentSelected = (val) => {
        setSelectedStudent(val);
        setOpen(false);
    };

    const { getRootProps, getInputProps, getListboxProps, value, focused, setAnchorEl } = useAutocomplete({
        open: isOpen,
        id: 'student-search',
        defaultValue: [],
        // disableCloseOnSelect: true,
        onOpen: () => setOpen(true),
        onClose: () => setOpen(false),
        options: searchResults,
        value: selectedStudent,
        onChange: (e, val) => handleStudentSelected(val),
        onInputChange: (e, val, reason) => handleInputChange(val, reason),
        getOptionSelected: (option, value) => option.option === value.option,
        getOptionLabel: (option) => `${option.fullName} ${maskedSsn(option.ssn)}`
    });

    return (
        <LeftNavLayout>
            <Box p={1} color="secondary.contrastText" bgcolor="secondary.main">
                <Toolbar variant="dense">
                    <Box display="flex" flex={1} alignItems="center" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                            <Typography variant="h6">Student Payment History</Typography>

                            <FormControl variant="filled" className={clsx(classes.formControl, classes.leftMargin)} margin="dense">
                                <div {...getRootProps()}>
                                    <div ref={setAnchorEl} className={clsx(classes.inputWrapper, focused ? classes.focused : undefined)}>
                                        <Box p={0.5} display="flex" justifyContent="center">
                                            <SearchIcon />
                                        </Box>
                                        {value && (
                                            <Chip
                                                className={classes.chip}
                                                classes={{ label: classes.chipLabel }}
                                                label={value.fullName}
                                                onDelete={() => setSelectedStudent(null)}
                                                color="primary"
                                            />
                                        )}
                                        <input
                                            style={searching ? { width: 166 } : { width: '100%' }}
                                            className={clsx(
                                                classes.input,
                                                focused ? classes.focused : undefined,
                                                value ? classes.hideInput : undefined
                                            )}
                                            {...getInputProps()}
                                            placeholder="Search Students..."
                                        />

                                        {searching && (
                                            <Box p={0.5} display="flex" justifyContent="center">
                                                <CircularProgress size={24} />
                                            </Box>
                                        )}
                                    </div>
                                    <Grow in={searchResults.length > 0}>
                                        <Paper className={clsx(classes.paper)} {...getListboxProps()}>
                                            <List className={clsx(classes.list)} dense>
                                                {searchResults.map((option, index) => {
                                                    return (
                                                        <ListItem key={option.id} onClick={() => setSelectedStudent(option)} dense button>
                                                            <ListItemText
                                                                id={option.id}
                                                                primary={
                                                                    <span>
                                                                        <strong>{option.fullName}</strong>
                                                                        {` (${maskedSsn(option.ssn)})`}
                                                                    </span>
                                                                }
                                                            />
                                                        </ListItem>
                                                    );
                                                })}
                                            </List>
                                        </Paper>
                                    </Grow>
                                </div>
                            </FormControl>
                        </Box>
                        <Tooltip arrow title={data.length === 0 ? 'No Data' : ''}>
                            <div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={exportToExcel}
                                    startIcon={<ExcelIcon />}
                                    disabled={isLoading || data.length === 0}
                                >
                                    Export to Excel
                                </Button>
                            </div>
                        </Tooltip>
                    </Box>
                </Toolbar>
            </Box>
            <CustomDataGrid
                disableColumnMenu
                disableColumnFilter
                initialSortModel={sortModel}
                disableSelectionOnClick
                rows={data}
                rowHeight={75}
                columns={columns}
                initialPageSize={25}
                rowsPerPageOptions={[25, 50, 100]}
                loading={isLoading}
                components={{
                    NoRowsOverlay: () => (
                        <GridOverlay className={classes.root}>
                            {!selectedStudent ? (
                                <>
                                    <InfoIcon color="primary" fontSize="small" />
                                    <Typography variant="body1" style={{ marginLeft: 10 }}>
                                        Search for a student...
                                    </Typography>
                                </>
                            ) : !isLoading ? (
                                <Typography variant="body1">No Rows</Typography>
                            ) : null}
                        </GridOverlay>
                    )
                }}
            />
        </LeftNavLayout>
    );
};

export default StudentPaymentHistory;
