import React from 'react';
import { conformedCurrency } from '../../../../common/masks';

export const renderAmount = ({ value }) => {
    let formattedValue = conformedCurrency(value.toString());
    let negative = false;
    if (value < 0) {
        formattedValue = `(${conformedCurrency(value.toString())})`;
        negative = true;
    }

    return <span style={negative ? { color: 'red', fontWeight: 500 } : undefined}>{formattedValue}</span>;
};
