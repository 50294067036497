import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';

const enumHelper = {
    getDisplayName(enumDefinition, value) {
        const valueInt = parseInt(value, 10);
        let associatedKey = '';

        for (const name of Object.keys(enumDefinition)) {
            if (enumDefinition[name] === valueInt) {
                associatedKey = name;
                break;
            }
        }

        return enumDefinition._displayNames && enumDefinition._displayNames[value] ? enumDefinition._displayNames[value] : associatedKey;
    },
    getBitwiseDisplayNames(enumDefinition, value) {
        const retVal = [];

        const allValues = Object.values(enumDefinition);
        if (typeof allValues[allValues.length - 1] === 'object') {
            allValues.pop();
        }

        allValues.forEach((val) => {
            if ((value & val) === val) {
                retVal.push(this.getDisplayName(enumDefinition, val));
            }
        });

        return retVal;
    },
    getSelectOptionsList(enumDefinition) {
        let output = [];
        for (let property in enumDefinition) {
            if (enumDefinition.hasOwnProperty(property) && !property.startsWith('_')) {
                output.push({
                    value: enumDefinition[property],
                    label: this.getDisplayName(enumDefinition, enumDefinition[property])
                });
            }
        }
        return output;
    },
    getMenuItemsForSelect(enumDefinition, extraOptions) {
        const items = this.getSelectOptionsList(enumDefinition);

        if (extraOptions) {
            extraOptions.reverse().forEach((item) => {
                items.unshift(item);
            });
        }

        return items.map((item) => (
            <MenuItem key={item.value} value={item.value}>
                {item.label}
            </MenuItem>
        ));
    }
};

export default enumHelper;
