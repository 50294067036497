import React from 'react';
import UsersList from './list';
import LeftNavLayout from '../../leftNavLayout';

export default function UsersPage() {
    return (
        <LeftNavLayout>
            <UsersList />
        </LeftNavLayout>
    );
}
