import axios from 'axios';

export function getAll(closed, cancelToken) {
    return axios.get('/api/services/app/AcademicYear/GetAll', { params: { closed }, cancelToken });
}

export function close(id) {
    return axios.post('/api/services/app/AcademicYear/Close', { id });
}

export function open() {
    return axios.post('/api/services/app/AcademicYear/Open');
}
