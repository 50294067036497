import deepCopyObject from '../common/logic/deepCopyObject';
import * as Actions from './actionTypes';
import { getAuthStorage } from './auth-storage';

const initialState = {
    loading: false,
    loggingIn: false,
    user: null,
    permissions: [],
    settings: [],
    accessToken: '',
    refreshToken: '',
    expiration: null,
    error: null,
    tenant: null,
    impersonateLoading: false,
    isImpersonating: false,
    hasLoggedInBefore: false,
    nav: null,
    institutions: []
};

export const initAuthState = () => {
    const storedData = getAuthStorage();
    return {
        ...initialState,
        loading: true,
        accessToken: storedData.accessToken,
        refreshToken: storedData.refreshToken,
        expiration: storedData.expiration,
        hasLoggedInBefore: storedData.hasLoggedIn
    };
};

export const AuthReducer = (state, action) => {
    const user = deepCopyObject(state.user);

    switch (action.type) {
        case Actions.StartLogin_Type: {
            return {
                ...state,
                loading: true,
                loggingIn: true,
                error: null
            };
        }
        case Actions.UpdateSettings_Type:
            return {
                ...state,
                settings: action.settings
            };
        case Actions.UpdateUserInfo_Type:
            user.name = action.info.name;
            user.surname = action.info.surname;
            return {
                ...state,
                user
            };
        case Actions.UpdateUserEmail_Type:
            user.emailAddress = action.email;
            return {
                ...state,
                user
            };
        case Actions.UpdateEmailConfirmation_Type:
            if (user) {
                user.isEmailConfirmed = action.confirm;
            }
            return {
                ...state,
                user
            };
        case Actions.UpdateTenant_Type:
            return {
                ...state,
                tenant: action.tenant
            };
        case Actions.StartImpersonate_Type: {
            return {
                ...state,
                impersonateLoading: true,
                error: null
            };
        }
        case Actions.FailImpersonate_Type: {
            return {
                ...state,
                impersonateLoading: false,
                error: action.error
            };
        }
        case Actions.UpdateToken_Type: {
            return {
                ...state,
                accessToken: action.accessToken
            };
        }
        case Actions.UpdateSession_Type: {
            return {
                ...state,
                accessToken: action.accessToken,
                refreshToken: action.refreshToken,
                expiration: action.expiration,
                user: action.user,
                permissions: action.permissions,
                settings: action.settings,
                tenant: action.tenant,
                isImpersonating: action.isImpersonating,
                impersonateLoading: false,
                hasLoggedInBefore: true,
                loggingIn: false,
                loading: false,
                nav: action.nav,
                institutions: action.institutions
            };
        }
        case Actions.FailLogin_Type: {
            return {
                ...state,
                user: null,
                permissions: [],
                settings: state.settings.filter((s) => s.name.startsWith('App.')),
                accessToken: '',
                refrshToken: '',
                expiration: null,
                loading: false,
                loggingIn: false,
                isImpersonating: false,
                impersonateLoading: false,
                error: action.error
            };
        }
        case Actions.Logout_Type: {
            return {
                ...state,
                user: null,
                permissions: [],
                settings: state.settings.filter((s) => s.name.startsWith('App.')),
                accessToken: '',
                refrshToken: '',
                expiration: null,
                loading: false,
                loggingIn: false,
                isImpersonating: false,
                impersonateLoading: false
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
};
