import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import FileSaver from 'file-saver';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSnackbar } from 'notistack';
import Link from '@material-ui/core/Link';

import { State, Gender, CitizenshipStatus, Race, HispanicOrigin } from '../../../common/logic/enums';
import enumHelper from '../../../common/logic/enumHelper';
import LoadingButton from '../../../common/loadingButton';
import { conformedPhone, conformedSsn, conformedZip } from '../../../common/masks';
import * as ProfileApi from '../../../api/profile';
import * as DocumentApi from '../../../api/document';
import b64toBlob from '../../../common/logic/b64toBlob';

const useStyles = makeStyles((theme) => ({
    padding: {
        padding: theme.spacing(1)
    },
    viewOnly: {
        '& .MuiGrid-container': {
            marginTop: 10,
            marginBottom: 10
        }
    },
    answer: {
        fontWeight: 700
    },
    link: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    }
}));

const ReadOnlyProfile = ({ profile, loading, onEditClick, showHeader = true }) => {
    const classes = useStyles();
    const [savingDeceased, setSavingDeceased] = useState(false);
    const [deceased, setDeceased] = useState(profile.deceased);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setDeceased(profile.deceased);
    }, [profile]);

    const handleDownloadWardOfCourt = async () => {
        try {
            const file = (await DocumentApi.getData(profile.wardOfCourtDocumentId)).data.result;
            const blob = b64toBlob(file.data, file.mimeType);
            FileSaver.saveAs(blob, file.fileName);
        } catch {
        } finally {
        }
    };

    const updateDeceased = async (evt) => {
        try {
            setSavingDeceased(true);
            setDeceased(evt.target.checked);
            await ProfileApi.updateDeceased(profile.userId, evt.target.checked);
            setSavingDeceased(false);
            enqueueSnackbar('Profile updated', { variant: 'success' });
        } catch (err) {
            console.error(err);
            setSavingDeceased(false);
            enqueueSnackbar('Failed to update profile', { variant: 'error' });
        }
    };

    return (
        <Grid container justify="center" className={classes.padding}>
            <Grid item xs={12} className={classes.viewOnly}>
                {showHeader && (
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <div>
                            <Typography variant="h5">Profile</Typography>
                            {profile.wardOfCourtDocumentId && (
                                <Link className={classes.link} onClick={handleDownloadWardOfCourt}>
                                    Download Ward of Court Letter
                                </Link>
                            )}
                        </div>
                        <LoadingButton fullWidth variant="contained" color="primary" loading={loading} onClick={onEditClick}>
                            Edit
                        </LoadingButton>
                    </Box>
                )}
                {!showHeader && (
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="h5">Applicant Profile</Typography>
                        <Box display="flex" alignItems="center">
                            <FormControlLabel
                                label="Deceased"
                                control={<Checkbox />}
                                checked={!!deceased}
                                value="deceased"
                                onChange={updateDeceased}
                            />
                            {savingDeceased && <CircularProgress size="1.5em" color="primary" />}
                        </Box>
                    </Box>
                )}
                <Grid container spacing={1}>
                    <Grid item sm={6} xs={12}>
                        <Typography variant="body1">First Name</Typography>
                        <Typography variant="body1" className={classes.answer}>
                            {profile.firstName}
                        </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Typography variant="body1">Last Name</Typography>
                        <Typography variant="body1" className={classes.answer}>
                            {profile.lastName}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={1}>
                    <Grid item sm={6} xs={12}>
                        <Typography variant="body1">Social Security #</Typography>
                        <Typography variant="body1" className={classes.answer}>
                            {profile.ssn ? conformedSsn(profile.ssn) : 'Not specified'}
                        </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Typography variant="body1">Birthday</Typography>
                        <Typography variant="body1" className={classes.answer}>
                            {profile.birthDate ? moment.utc(profile.birthDate).format('MM/DD/YYYY') : 'Not specified'}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={1}>
                    <Grid item sm={6} xs={12}>
                        <Typography variant="body1">Email Address</Typography>
                        <Typography variant="body1" className={classes.answer}>
                            {profile.emailAddress}
                        </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Typography variant="body1">Mobile Phone Number</Typography>
                        <Typography variant="body1" className={classes.answer}>
                            {profile.phoneNumber ? conformedPhone(profile.phoneNumber) : 'Not specified'}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={1}>
                    <Grid item sm={6} xs={12}>
                        <Typography variant="body1">Street Address 1</Typography>
                        <Typography variant="body1" className={classes.answer}>
                            {profile.address && profile.address.street1 ? profile.address.street1 : 'Not specified'}
                        </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Typography variant="body1">Street Address 2</Typography>
                        <Typography variant="body1" className={classes.answer}>
                            {profile.address && profile.address.street2 ? profile.address.street2 : 'Not specified'}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item sm={5} xs={12}>
                        <Typography variant="body1">City</Typography>
                        <Typography variant="body1" className={classes.answer}>
                            {profile.address && profile.address.city ? profile.address.city : 'Not specified'}
                        </Typography>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <Typography variant="body1">State</Typography>
                        <Typography variant="body1" className={classes.answer}>
                            {profile.address && profile.address.state ? enumHelper.getDisplayName(State, profile.address.state) : 'Not specified'}
                        </Typography>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Typography variant="body1">Zip</Typography>
                        <Typography variant="body1" className={classes.answer}>
                            {profile.address && profile.address.zipCode ? conformedZip(profile.address.zipCode) : 'Not specified'}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={1}>
                    <Grid item sm={3} xs={12}>
                        <Typography variant="body1">Gender</Typography>
                        <Typography variant="body1" className={classes.answer}>
                            {profile.gender ? enumHelper.getDisplayName(Gender, profile.gender) : 'Not specified'}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={1}>
                    <Grid item sm={4} xs={12}>
                        <Typography variant="body1">Race</Typography>
                        <Typography variant="body1" className={classes.answer}>
                            {profile.race ? enumHelper.getDisplayName(Race, profile.race) : 'Not specified'}
                        </Typography>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Typography variant="body1">Are you of Hispanic Origin?</Typography>
                        <Typography variant="body1" className={classes.answer}>
                            {profile.hispanicOrigin ? enumHelper.getDisplayName(HispanicOrigin, profile.hispanicOrigin) : 'Not specified'}
                        </Typography>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Typography variant="body1">Citizenship Status</Typography>
                        <Typography variant="body1" className={classes.answer}>
                            {profile.citizenshipStatus ? enumHelper.getDisplayName(CitizenshipStatus, profile.citizenshipStatus) : 'Not specified'}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={1}>
                    <Grid item sm={6} xs={12}>
                        <Typography variant="body1">Alternate Email Address</Typography>
                        <Typography variant="body1" className={classes.answer}>
                            {profile.alternateEmailAddress || 'Not specified'}
                        </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Typography variant="body1">Alternate Phone Number</Typography>
                        <Typography variant="body1" className={classes.answer}>
                            {profile.alternatePhoneNumber || 'Not specified'}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={1}>
                    <Grid item sm={12} xs={12}>
                        <Typography variant="body1">High School Graduation or Equivalent Degree (eg GED) Date</Typography>
                        <Typography variant="body1" className={classes.answer}>
                            {profile.graduationDate ? moment.utc(profile.graduationDate).format('MM/DD/YYYY') : 'Not specified'}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={1}>
                    <Grid item sm={12} xs={12}>
                        <Typography variant="body1">Were you in foster care on or after your 16th birthday?</Typography>
                        <Typography variant="body1" className={classes.answer}>
                            {profile.inFosterCareOnOrAfterAge16 === true
                                ? 'Yes'
                                : profile.inFosterCareOnOrAfterAge16 === false
                                ? 'No'
                                : 'Not specified'}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ReadOnlyProfile;
