import React from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/styles/makeStyles';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { lighten, fade } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';

const useStyles = makeStyles((theme) => ({
    simpleDropzone: {
        cursor: 'pointer',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: fade(theme.palette.primary.main, 0.1),
        borderColor: theme.palette.primary.main,
        borderWidth: 1,
        borderStyle: 'solid',
        '&:focus': {
            outline: 'none',
            boxShadow: `inset 0 0 2px 2px ${fade(theme.palette.primary.main, 0.7)}`
        },
        height: 50,
        marginBottom: theme.spacing(2)
    },
    dragging: {
        backgroundColor: fade(theme.palette.primary.main, 0.5)
    },
    disabled: {
        cursor: 'no-drop',
        backgroundColor: lighten(theme.palette.text.disabled, 0.8),
        borderColor: theme.palette.text.disabled
    },
    iconDisabled: {
        color: theme.palette.text.disabled
    },
    backdrop: {
        position: 'absolute',
        zIndex: theme.zIndex.drawer + 1
    }
}));

const SimpleDropzone = ({ label, disabled, ...otherProps }) => {
    const classes = useStyles();
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ disabled, ...otherProps });

    return (
        <div
            {...getRootProps({
                className: clsx(classes.simpleDropzone, isDragActive && classes.dragging, disabled && classes.disabled)
            })}
        >
            <input {...getInputProps()} />
            <AddIcon className={clsx(classes.addIcon, disabled && classes.iconDisabled)} />
            <Typography variant="body1">{label}</Typography>
            <Backdrop open={disabled} className={classes.backdrop}>
                <CircularProgress color="secondary" />
            </Backdrop>
        </div>
    );
};

export default SimpleDropzone;
