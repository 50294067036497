import { Redirect } from '@gatsbyjs/reach-router';
import React from 'react';
import { useAuth } from '../../../auth/auth-context';
import { ROLE_NAME_ADMIN, ROLE_NAME_FINANCE, ROLE_NAME_INSTITUTION, ROLE_NAME_STUDENT } from '../../../common/logic/consts';
import TenantLayout from '../../tenantLayout';

export default function HomePage() {
    const { user } = useAuth();

    switch (user.roleName) {
        case ROLE_NAME_STUDENT:
            return <Redirect to="/profile" noThrow={true} />;
        case ROLE_NAME_FINANCE:
            return <Redirect to="/financials" noThrow={true} />;
        case ROLE_NAME_INSTITUTION:
            return <Redirect to="/scholarship/certify" noThrow={true} />;
        case ROLE_NAME_ADMIN:
            return <Redirect to="/admin/users" noThrow={true} />;
        default:
            return <TenantLayout>Home</TenantLayout>;
    }
}
