import React from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
    margin: {
        marginBottom: theme.spacing(2)
    },
    label: {
        marginBottom: theme.spacing(1)
    },
    required: {
        color: theme.palette.error.main
    }
}));

const FinalFormCheckboxGroup = ({ fields, options, meta, label, disabled, className, required, highlight }) => {
    const classes = useStyles();

    const toggle = (event, option) => {
        if (event.target.checked) fields.push(option.value);
        else {
            const index = fields.value.indexOf(option.value);
            if (index !== -1) {
                fields.remove(index);
            }
        }
    };

    const hasValue = (value) => fields.value && fields.value.some((item) => item === value);

    return (
        <FormControl className={clsx(classes.margin, className)} error={meta.error && meta.touched} disabled={disabled}>
            <FormLabel required={required} className={classes.label} classes={{ asterisk: classes.required }}>
                {label}
            </FormLabel>
            {options.map((option) => (
                <FormControlLabel
                    classes={{ label: highlight && hasValue(option.value) && classes.required }}
                    key={option.value}
                    onChange={(event) => toggle(event, option)}
                    value={option.value}
                    checked={hasValue(option.value)}
                    control={<Checkbox />}
                    label={option.label}
                />
            ))}
            {meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
    );
};

export default FinalFormCheckboxGroup;
