import React from 'react';
import AccountInactiveForm from './accountInactiveForm';

export default function AccountInactivePage() {
    return (
        <div>
            <AccountInactiveForm />
        </div>
    );
}
