import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { useField } from 'react-final-form';

import { useAuth } from '../../auth/auth-context';
import { APP_SETTING_NAME_CONTACTS } from '../../common/logic/consts';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
}));

const DialogTitle = ({ children, onClose }) => {
    const classes = useStyles();

    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" onClick={onClose} className={classes.closeButton}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};

export default function TermsDialog({ name, open, onAccept, onReject }) {
    const field = useField(name);
    const { settings } = useAuth();
    const { primary } = settings.find((s) => s.name === APP_SETTING_NAME_CONTACTS).value;

    const onAcceptClick = () => {
        field.input.onChange(true);
        onAccept();
    };

    const onRejectClick = () => {
        field.input.onChange(false);
        onReject();
    };

    return (
        <Dialog open={open} scroll="paper" maxWidth="md" fullWidth disableBackdropClick disableEscapeKeyDown>
            <DialogTitle>Agreement, Privacy Policy &amp; Cookie Policy</DialogTitle>
            <DialogContent dividers>
                <Typography variant="h6" color="primary">
                    About
                </Typography>
                <DialogContentText>
                    The DCYF Post-Secondary Tuition Assistance Program Grant Application is an online application that is maintained by the Rhode
                    Island Department of Children Youth and Families (RIDCYF).
                </DialogContentText>
                <DialogContentText>
                    This policy defines the data that DCYF of Rhode Island collects in the application and how it is used.
                </DialogContentText>
                <Typography variant="h6" color="primary">
                    Terms of Use
                </Typography>
                <DialogContentText>
                    As a condition of use, you promise not to use this application for any purpose that is unlawful or prohibited by these Terms, or
                    any other purpose not reasonably intended by this service. You agree not to use the Application to abuse, harass, threaten,
                    impersonate, or intimidate any person; to post or transmit any content that is libelous, defamatory, obscene, pornographic,
                    abusive, offensive, profane, or that infringes any copyright or the right of any person.
                </DialogContentText>
                <DialogContentText>
                    All copyright, trademarks, design rights, patents and other intellectual property rights in and on the RIDCYF Application belong
                    to RIDCYF and/or third parties. Nothing in the Terms grants you a right or license to use any trademark, design right, or
                    copyright owned or controlled by RIDCYF of Rhode Island or any other third party.
                </DialogContentText>
                <DialogContentText>
                    All content you submit, upload, or otherwise make available in the application will be viewable by RIDCYF staff members and the
                    school(s) you plan to attend or are attending. RIDCYF Staff members may terminate your access to all or any parts of the
                    Application at any time, with or without cause, with or without notice.
                </DialogContentText>
                <DialogContentText>
                    By agreeing to these Terms, you are certifying acceptance of these terms and that all of the information you are providing in the
                    registration process, profile completions, and application is true and accurate.
                </DialogContentText>
                <Typography variant="h6" color="primary">
                    Privacy Policy
                </Typography>
                <Typography variant="subtitle1">
                    <strong>PERSONAL DATA</strong>
                </Typography>
                <DialogContentText>
                    When creating an account for the DCYF Grant Application, you are required to provide your first name, last name, and email
                    address. Your name identifies you to RIDCYF staff and the school(s) you plan to attend or are attending.
                </DialogContentText>
                <DialogContentText>
                    The RIDCYF Application will use your email to verify your account and will send you automatic email notifications when your
                    application is updated.
                </DialogContentText>
                <DialogContentText>
                    Once an account is created, you will be asked to fill out a profile and application which will collect pertinent data to determine
                    eligibility for the RIDCYF Post-Secondary Tuition Assistance Grant Program.. Required data includes: Social Security Number,
                    Birthday, Mobile Phone Number, Street Address, City, State, Zip, Gender, Citizenship Status, High School Graduation Date, Foster
                    Care status, Current Grade, School Attending, and Enrollment Status.
                </DialogContentText>
                <DialogContentText>
                    The RIDCYF Grant Application uses cookies to improve your experience. Cookies are small data files that store information about
                    how you use the RIDCYF Grant Application. Cookies are used on pages within the RIDCYF Grant Application to ensure that the user
                    hits the same server with subsequent requests. The cookies do NOT store any personal information created in your profile or
                    application. You can disable cookies within your browser settings.
                </DialogContentText>
                <Typography variant="subtitle1">
                    <strong>SHARING</strong>
                </Typography>
                <DialogContentText>
                    As part of the application submission process, you are required to read and agree to the “Access to Records” agreement. This
                    agreement gives access of your profile and application information to the school that you indicate you will be attending or are
                    attending in your application.
                </DialogContentText>
                <DialogContentText>
                    If your school changes after the award or certification process has occurred, both your current and previous school will have
                    access to your profile and application information. If change occurs before the award or certification process, the previous
                    school will lose access to your profile and application information.
                </DialogContentText>
                <DialogContentText>
                    RIDCYF does not share your personal information with any third parties for the purposes of marketing, advertising, or selling.
                </DialogContentText>
                <Typography variant="subtitle1">
                    <strong>RETENTION</strong>
                </Typography>
                <DialogContentText>
                    Your profile and application information are retained indefinitely and RIDCYF staff users and the users at the school(s) you
                    attended will have access to both.
                </DialogContentText>
                <Typography variant="subtitle1">
                    <strong>YOUR RIGHTS</strong>
                </Typography>
                <DialogContentText>
                    You may request a file containing all personal data collected for your account through the DCYF application. Such requests may be
                    made to DCYF of RI by email at DCYF.ESSA@dcyf.ri.gov.
                </DialogContentText>
                <Typography variant="subtitle1">
                    <strong>CONTACT INFORMATION</strong>
                </Typography>
                <DialogContentText>
                    Questions about this privacy policy, or how data is used and shared, can be directed to {primary?.emailAddress}.
                </DialogContentText>
                <DialogContentText>Written inquiries can be sent to the following address:</DialogContentText>
                <DialogContentText>
                    RI Department of Children, Youth and Families
                    <br />
                    Attn: {primary?.name}
                    <br />
                    101 Friendship Street
                    <br />
                    Providence, RI 02903
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onRejectClick} color="primary" variant="outlined">
                    Reject
                </Button>
                <Button onClick={onAcceptClick} color="primary" variant="contained">
                    Accept
                </Button>
            </DialogActions>
        </Dialog>
    );
}
