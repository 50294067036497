import React, { useState, useEffect } from 'react';
import axios from 'axios';
import makeStyles from '@material-ui/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import DescriptionIcon from '@material-ui/icons/Description';
import FormLabel from '@material-ui/core/FormLabel';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import { useSnackbar } from 'notistack';
import FileSaver from 'file-saver';
import Link from '@material-ui/core/Link';

import * as SettingApi from '../../../../api/setting';
import * as DocumentApi from '../../../../api/document';
import FileUploader from './fileUploader';
import b64toBlob from '../../../../common/logic/b64toBlob';
import { AgreementType } from '../../../../common/logic/enums';

const useStyles = makeStyles((theme) => ({
    agreements: {
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        padding: theme.spacing(2)
    },
    content: {
        padding: theme.spacing(0, 2, 2)
    },
    bottomMargin: {
        marginBottom: theme.spacing(1)
    },
    file: {
        marginLeft: theme.spacing(1)
    },
    link: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    fileIcon: {
        cursor: 'pointer',
        height: 20,
        width: 20,
        marginRight: theme.spacing(0.5)
    },
    saveButton: {
        marginTop: theme.spacing(1)
    }
}));

const AgreementsSkeleton = () => {
    return [1, 2, 3].map((i) => (
        <Box key={i} display="flex" flexDirection="column" style={{ marginBottom: 10 }}>
            <Skeleton variant="text" width={50} height={20} style={{ marginBottom: 5 }} />
            <Box display="flex">
                <Skeleton variant="rect" width={200} height={50} style={{ marginRight: 10 }} />
                <Box display="flex" alignItems="center">
                    <Skeleton variant="rect" width={20} height={20} style={{ marginRight: 5 }} />
                    <Skeleton variant="text" width={50} height={14} />
                </Box>
            </Box>
        </Box>
    ));
};

const Agreements = () => {
    const classes = useStyles();
    const [agreements, setAgreements] = useState({});
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const tokenSource = axios.CancelToken.source();
        (async () => {
            try {
                setLoading(true);
                const data = (await SettingApi.getAgreements(tokenSource.token)).data.result;
                setAgreements(data);
            } catch (ex) {
                enqueueSnackbar('Error fetching agreements', { variant: 'error' });
                console.error(ex);
            } finally {
                setLoading(false);
            }
        })();

        return () => {
            tokenSource.cancel();
        };
    }, []);

    const handleUploadComplete = async (document, type) => {
        const newAgreements = { ...agreements };
        switch (type) {
            case AgreementType.Funds:
                newAgreements.funds = document;
                break;
            case AgreementType.Records:
                newAgreements.records = document;
                break;
            case AgreementType.FAQS:
                newAgreements.faqs = document;
                break;
            default:
                console.error('unsupported agreement type');
                break;
        }

        try {
            setSaving(true);
            await SettingApi.updateAgreements(newAgreements);
            enqueueSnackbar('Changes saved successfully', { variant: 'success' });
            setAgreements(newAgreements);
        } catch (ex) {
            enqueueSnackbar('Error saving agreements', { variant: 'error' });
            console.error(ex);
        } finally {
            setSaving(false);
        }
    };

    const handleDownloadClick = async (item) => {
        try {
            const file = (await DocumentApi.getData(item.id)).data.result;
            const blob = b64toBlob(file.data, file.mimeType);
            FileSaver.saveAs(blob, file.fileName);
        } catch (ex) {
            enqueueSnackbar('Error downloading document', { variant: 'error' });
            console.error(ex);
        }
    };

    return (
        <Paper className={classes.agreements}>
            <Typography className={classes.title} variant="h6">
                Agreements
            </Typography>
            <div className={classes.content}>
                {loading ? (
                    <AgreementsSkeleton />
                ) : (
                    <>
                        <Box className={classes.bottomMargin} display="flex" flexDirection="column">
                            <FormLabel className={classes.bottomMargin}>Use of Funds Agreement</FormLabel>
                            <Box display="flex">
                                <FileUploader
                                    disabled={saving}
                                    onUploadComplete={(document) => handleUploadComplete(document, AgreementType.Funds)}
                                    tooltipTitle="Click here to upload a file, or drag and drop your file here"
                                />
                                {!saving && agreements.funds && (
                                    <Box display="flex" alignItems="center" className={classes.file}>
                                        <Link className={classes.link} onClick={() => handleDownloadClick(agreements.funds)}>
                                            <DescriptionIcon className={classes.fileIcon} />
                                            <Typography variant="caption">{agreements.funds.fileName}</Typography>
                                        </Link>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        <Box className={classes.bottomMargin} display="flex" flexDirection="column">
                            <FormLabel className={classes.bottomMargin}>Access to Records Agreement</FormLabel>
                            <Box display="flex">
                                <FileUploader
                                    disabled={saving}
                                    onUploadComplete={(document) => handleUploadComplete(document, AgreementType.Records)}
                                    tooltipTitle="Click here to upload a file, or drag and drop your file here"
                                />
                                {!saving && agreements.records && (
                                    <Box display="flex" alignItems="center" className={classes.file}>
                                        <Link className={classes.link} onClick={() => handleDownloadClick(agreements.records)}>
                                            <DescriptionIcon className={classes.fileIcon} />
                                            <Typography variant="caption">{agreements.records.fileName}</Typography>
                                        </Link>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        <Box className={classes.bottomMargin} display="flex" flexDirection="column">
                            <FormLabel className={classes.bottomMargin}>FAQs</FormLabel>
                            <Box display="flex">
                                <FileUploader
                                    disabled={saving}
                                    onUploadComplete={(document) => handleUploadComplete(document, AgreementType.FAQS)}
                                    tooltipTitle="Click here to upload a file, or drag and drop your file here"
                                />
                                {!saving && agreements.faqs && (
                                    <Box display="flex" alignItems="center" className={classes.file}>
                                        <Link className={classes.link} onClick={() => handleDownloadClick(agreements.faqs)}>
                                            <DescriptionIcon className={classes.fileIcon} />
                                            <Typography variant="caption">{agreements.faqs.fileName}</Typography>
                                        </Link>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </>
                )}
            </div>
        </Paper>
    );
};

export default Agreements;
