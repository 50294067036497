import moment from 'moment';

export const required = (value) => {
    if (value && Array.isArray(value) && value.length === 0) {
        return 'This field is required';
    }

    return value ? undefined : 'This field is required';
};
export const requiredAllowZero = (value) => {
    return value === undefined || value === null || value === '' ? 'This field is required' : undefined;
};
export const email = (value) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Please enter a valid email address' : undefined;
export const mustBeNumber = (value) => (isNaN(value) ? 'Must be a number' : undefined);
export const minValue = (min) => (value) => (isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`);
export const maxValue = (max) => (value) => (isNaN(value) || value <= max ? undefined : `Should be less than ${max}`);
export const exactLength = (length, message) => (value) =>
    value && value.length !== length ? (message ? message : `Must be exactly ${length} characters`) : undefined;
export const minLength = (min) => (value) =>
    value && value.length ? (value.length < min ? `Must be ${min} characters or more` : undefined) : undefined;
export const maxLength = (max) => (value) =>
    value && value.length ? (value.length > max ? `Must be ${max} characters or less` : undefined) : undefined;
export const alphaNumeric = (value) => (value && /[^a-zA-Z\d\s-]/.test(value) ? 'Alphanumeric characters only' : undefined);
export const url = (value) => {
    const test = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?/gi.test(value);
    return value && !test ? 'Please enter a valid url' : undefined;
};
export const zipCode = (value) => (value && value.length !== 5 && value.length !== 10 ? 'Please enter a valid zip code' : undefined);
export const isDate = (value) =>
    (value && !moment(value).isValid()) || (value.length !== 10 && value.length !== 8) ? `Please enter a valid date` : undefined;
export const isDateBeforeNow = (value) =>
    value && moment(value).isValid() && moment(value).isSameOrAfter(moment()) ? 'Date cannot be in the future' : undefined;

export const composeValidators = (...validators) => (value) => validators.reduce((error, validator) => error || validator(value), undefined);
