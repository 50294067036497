import axios from 'axios';

export function remove(id) {
    return axios.delete('/api/services/app/EligibleStudent/Delete', { params: { id } });
}

export function getAll() {
    return axios.get('/api/services/app/EligibleStudent/GetAll');
}

export function importFile(documentId) {
    return axios.post('/api/services/app/EligibleStudent/Import', { id: documentId });
}

export function getTemplate() {
    return axios.get('/api/services/app/EligibleStudent/GetTemplate');
}
