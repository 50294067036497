import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import useErrorDialog from '../../../common/errorDialog/useErrorDialog';
import * as JobApi from '../../../api/job';

const useJobsList = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const errorData = useErrorDialog();
    const { enqueueSnackbar } = useSnackbar();

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const result = (await JobApi.getJobs()).data.result;
            setData(result.jobs);
            setIsLoading(false);
        } catch {
            setIsLoading(false);
        }
    };
    useEffect(fetchData, []);

    const runJob = async (group, key) => {
        await JobApi.runJob(group, key);
        enqueueSnackbar(`Running instance of ${key}`, { variant: 'success' });
        fetchData();
    };

    return {
        data,
        isLoading,
        errorData,
        runJob
    };
};

export default useJobsList;
