import React, { useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { conformedSsn } from '../masks';

const useStyles = makeStyles((theme) => ({
    socialSecurityToggle: {
        display: 'flex',
        alignItems: 'center'
    },
    ssn: {
        textAlign: 'justify',
        width: 96
    }
}));

const SocialSecurityToggle = ({ value }) => {
    const classes = useStyles();
    const [show, setShow] = useState(false);

    return (
        <div className={classes.socialSecurityToggle}>
            <Typography variant="body1" className={classes.ssn}>
                {show ? conformedSsn(value) : 'XXX-XX-' + value.slice(5)}
            </Typography>
            <IconButton color="primary" aria-label="show social security number" size="small" onClick={() => setShow(!show)}>
                {show ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
        </div>
    );
};

export default SocialSecurityToggle;
