import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

import { GroupType } from '../logic/enums';

const maxChunkSizeInBytes = 10000000;
const imageExtensions = ['gif', 'jpeg', 'jpg', 'png', 'bmp'];

function useChunkUpload(groupType, groupingId, onComplete, onBeginUpload, onCancel, tenantId = null) {
    const [files, setFiles] = useState([]);

    const removeFile = (id) => {
        setFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
    };

    const uploadFile = (file, id) => {
        uploadChunks(file, id);
    };

    const uploadChunks = async (file, id) => {
        let blocks = [];
        let offset = 0;
        let index = 0;
        while (offset < file.size) {
            var start = offset;
            var end = Math.min(offset + maxChunkSizeInBytes, file.size);

            blocks.push({
                name: file.name,
                index: index,
                start: start,
                end: end
            });

            offset = end;
            index++;
        }

        let docType = groupType;
        if (groupType === GroupType.Video) {
            const extension = file.name.split('.').pop();
            if (imageExtensions.includes(extension)) {
                docType = GroupType.Image;
            }
        }

        const document = (
            await axios.post('/api/services/app/Documents/StartUpload', {
                fileName: file.name,
                groupType: docType,
                groupingId,
                tenantId
            })
        ).data.result;

        const axiosConfig = {
            onUploadProgress: function (progressEvent) {
                setFiles((prevFiles) => {
                    const newFiles = [...prevFiles];
                    const item = newFiles.find((item) => item.id === id);

                    if (item) {
                        const block = blocks[item.currentChunk];
                        const bytesUploaded = block.start + progressEvent.loaded;
                        item.percentComplete = Math.round((bytesUploaded / file.size) * 100);
                        //const currentChunkBytes = item.currentChunk * maxChunkSizeInBytes;
                        //const value = ((currentChunkBytes / file.size) * 100).toFixed(0);
                        //const buffer = (((currentChunkBytes + progressEvent.loaded) / file.size) * 100).toFixed(0);
                    }

                    return newFiles;
                });
            }
        };

        for (let i = 0; i < blocks.length; i++) {
            const block = blocks[i];
            // load blob based on the start and end index for each chunks
            var blob = file.slice(block.start, block.end);
            // put the file name, index and blob into a temporary from
            var fd = new FormData();
            fd.append('chunkIndex', block.index);
            fd.append(document.id, blob);

            const source = axios.CancelToken.source();

            setFiles((prevFiles) => {
                const item = prevFiles.find((item) => item.id === id);
                if (item) {
                    item.currentChunk = block.index;
                    item.cancel = () => source.cancel();
                }

                return [...prevFiles];
            });

            axiosConfig.cancelToken = source.token;

            // post the form to backend service (asp.net mvc controller action)
            try {
                await axios.post('/api/services/app/Documents/UploadChunk', fd, axiosConfig);
            } catch (error) {
                if (axios.isCancel(error)) {
                    removeFile(id);
                    if (onCancel) {
                        onCancel(document.id);
                    }
                    return;
                }
            }
        }

        setFiles((prevFiles) => {
            const item = prevFiles.find((item) => item.id === id);
            if (item) {
                item.percentComplete = 100.0;
            }

            return [...prevFiles];
        });

        // call the complete upload
        const response = await axios.post('/api/services/app/Documents/CompleteUpload', {
            numberOfChunks: blocks.length,
            documentId: document.id
        });

        removeFile(id);

        if (onComplete) {
            onComplete(
                {
                    ...response.data.result,
                    size: file.size,
                    fileName: file.name,
                    contentType: file.type
                },
                docType
            );
        }
    };

    const onDrop = (droppedFiles) => {
        if (onBeginUpload) {
            onBeginUpload(droppedFiles);
        }

        const newFiles = [...files];

        droppedFiles.forEach((file) => {
            const id = uuidv4();
            newFiles.push({ id, percentComplete: 0, fileName: file.name, currentChunk: 0 });
            uploadFile(file, id);
        });

        setFiles(newFiles);
    };

    return { onDrop, files };
}

export default useChunkUpload;
