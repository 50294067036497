import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import TenantLayout from './tenantLayout';

const useStyles = makeStyles((theme) => ({
    singlePaperLayout: {
        padding: theme.spacing(0, 1, 1)
    },
    paper: {
        padding: theme.spacing(2)
    }
}));

const SinglePaperLayout = ({ children }) => {
    const classes = useStyles();

    return (
        <TenantLayout>
            <div className={classes.singlePaperLayout}>
                <Paper className={classes.paper}>{children}</Paper>
            </div>
        </TenantLayout>
    );
};

export default SinglePaperLayout;
