import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import clsx from 'clsx';
import moment from 'moment';

import TenantLayout from '../../tenantLayout';
import * as ApplicationsApi from '../../../api/applications';
import * as YearApi from '../../../api/year';
import * as InstitutionApi from '../../../api/institution';
import * as ProfileApi from '../../../api/profile';
import { useSnackbar } from 'notistack';
import enumHelper from '../../../common/logic/enumHelper';
import { ApplicationStatus } from '../../../common/logic/enums';
import { OTHER_INSTITUTION_VALUE } from '../../../common/logic/consts';
import { useAuth } from '../../../auth/auth-context';
import { Alert } from '@material-ui/lab';
import TextLink from '../../../common/textLink';

import EditForm from './editForm';
import ContentWrapper from './contentWrapper';
import ContactsDisplay from '../../../common/contactsDisplay';
import PayoutsCard from './payoutsCard';

const useStyles = makeStyles((theme) => ({
    studentApplicationsPage: {
        marginLeft: theme.spacing(1),
        maxWidth: 1000,
        padding: theme.spacing(1),
        [theme.breakpoints.only('xs')]: {
            padding: 0,
            margin: theme.spacing(1)
        }
    },
    extraBottomMargin: {
        marginBottom: 58
    },
    applicationStatusAccepted: {
        marginLeft: theme.spacing(1),
        color: theme.palette.success.main
    },
    applicationStatusSubmitted: {
        marginLeft: theme.spacing(1),
        color: theme.palette.info.main
    },
    applicationStatusRejected: {
        marginLeft: theme.spacing(1),
        color: theme.palette.error.main
    },
    applicationStatusOpen: {
        marginLeft: theme.spacing(1),
        color: theme.palette.warning.main
    },
    alert: {
        margin: theme.spacing(0.5, 0)
    }
}));

const StudentApplicationsPage = () => {
    const classes = useStyles();
    const { isImpersonating } = useAuth();
    const [loading, setLoading] = useState(true);
    const [applicationToEdit, setApplicationToEdit] = useState(null);
    const [applications, setApplications] = useState([]);
    const [lastApplication, setLastApplication] = useState(null);
    const [institutions, setInstitutions] = useState([]);
    const [years, setYears] = useState([]);
    const [profileExists, setProfileExists] = useState(false);
    const [reloadData, setReloadData] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.only('xs'));

    const extraBottomMargin = isXS && isImpersonating;

    useEffect(() => {
        const tokenSource = axios.CancelToken.source();
        const tokenSourceYears = axios.CancelToken.source();
        const tokenSourceInstitutions = axios.CancelToken.source();
        const tokenSourceProfile = axios.CancelToken.source();

        (async () => {
            try {
                setLoading(true);
                const response = await ApplicationsApi.getByUser(null, null, true, tokenSource.token);
                const yearsResponse = await YearApi.getAll(false, tokenSourceYears.token);
                const institutionsResponse = await InstitutionApi.getAll('name', 0, 100, tokenSourceInstitutions.token);
                const profileResponse = await ProfileApi.getProfileExists(tokenSourceProfile.token);
                setProfileExists(profileResponse.data.result);
                setApplications(response.data.result.filter((a) => !a.academicYear.closed));
                setLastApplication(response.data.result.length > 0 ? response.data.result[0] : null);
                setYears(yearsResponse.data.result);
                const institutionOptions = institutionsResponse.data.result.items.map((i) => ({ label: i.name, value: i.id, active: i.isActive }));
                institutionOptions.push({ active: true, label: 'Other', value: OTHER_INSTITUTION_VALUE });
                setInstitutions(institutionOptions);
            } finally {
                setLoading(false);
            }
        })();

        return () => {
            tokenSource.cancel();
            tokenSourceYears.cancel();
            tokenSourceInstitutions.cancel();
            tokenSourceProfile.cancel();
        };
    }, [reloadData]);

    const handleSave = async (values) => {
        try {
            setLoading(true);
            let toast = 'Application saved successfully';
            if (values.submitting) {
                await ApplicationsApi.submit(values);
                toast = 'Application submitted successfully';
            } else {
                if (values.id) {
                    await ApplicationsApi.update(values);
                } else {
                    await ApplicationsApi.create(values);
                }
            }
            enqueueSnackbar(toast, { variant: 'success' });
            setApplicationToEdit(null);
            setReloadData((prev) => !prev);
        } catch (ex) {
            enqueueSnackbar(`Failed to ${values.submitting ? 'submit' : 'save'} application`, { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <TenantLayout>
            <Paper className={clsx(classes.studentApplicationsPage, extraBottomMargin && classes.extraBottomMargin)}>
                {loading ? (
                    <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems={!isXS ? 'flex-start' : undefined} p={1}>
                        <Typography variant="h5" style={{ marginBottom: 10 }}>
                            Applications
                        </Typography>
                        <Skeleton variant="rect" height={36} width={!isXS ? 300 : undefined} style={{ marginBottom: 10, borderRadius: 4 }} />
                    </Box>
                ) : years.length === 0 ? (
                    <ContentWrapper applicationToEdit={applicationToEdit}>
                        <Typography variant="body1">Application submission is currently closed.</Typography>
                        <Typography variant="body1">Please contact DCYF if you need further assistance.</Typography>
                        <Box mb={1} mt={1}>
                            <Divider />
                        </Box>
                        <ContactsDisplay />
                    </ContentWrapper>
                ) : applicationToEdit ? (
                    <EditForm
                        handleSave={handleSave}
                        applicationToEdit={applicationToEdit}
                        institutions={institutions}
                        loading={loading}
                        onCancel={() => setApplicationToEdit(null)}
                    />
                ) : (
                    <ContentWrapper applicationToEdit={applicationToEdit}>
                        <Grid container spacing={1}>
                            {!profileExists && (
                                <Alert style={{ marginBottom: 10 }} severity="warning">
                                    Please fill out your <TextLink label="profile" location="/profile" /> information before starting application.
                                </Alert>
                            )}
                            {years.map((year) => {
                                const application = applications.find((app) => app.academicYearId === year.id);
                                if (application) {
                                    return (
                                        <Grid
                                            key={year.id}
                                            item
                                            sm={12}
                                            xs={12}
                                            container
                                            // alignItems={isXS ? 'stretch' : 'center'}
                                            // justify={isXS ? 'center' : undefined}
                                            // direction={isXS ? 'column' : undefined}
                                            alignItems="stretch"
                                            justify="center"
                                            direction="column"
                                        >
                                            <Button
                                                style={!isXS ? { alignSelf: 'flex-start' } : undefined}
                                                // fullWidth={isXS}
                                                color="primary"
                                                variant="contained"
                                                disabled={!profileExists}
                                                onClick={() => setApplicationToEdit(application)}
                                            >{`Update Application for ${year.startYear}-${year.endYear}`}</Button>
                                            <Alert
                                                className={classes.alert}
                                                severity={
                                                    application.applicationStatus === ApplicationStatus.InProgress
                                                        ? 'warning'
                                                        : application.applicationStatus === ApplicationStatus.Submitted
                                                        ? 'info'
                                                        : application.applicationStatus === ApplicationStatus.Accepted
                                                        ? 'success'
                                                        : 'error'
                                                }
                                            >
                                                {application.applicationStatus === ApplicationStatus.InProgress && 'Not Yet Submitted'}

                                                {application.applicationStatus !== ApplicationStatus.InProgress &&
                                                    `${enumHelper.getDisplayName(ApplicationStatus, application.applicationStatus)} on ${
                                                        application.applicationStatus === ApplicationStatus.Submitted
                                                            ? moment(application.submittedDate).format('MM/DD/YYYY h:mm a')
                                                            : application.applicationStatus === ApplicationStatus.Accepted
                                                            ? moment(application.approvedDate).format('MM/DD/YYYY h:mm a')
                                                            : moment(application.rejectedDate).format('MM/DD/YYYY h:mm a')
                                                    }`}
                                            </Alert>
                                            {/* <Typography
                                                variant="body1"
                                                className={
                                                    application.applicationStatus === ApplicationStatus.InProgress
                                                        ? classes.applicationStatusOpen
                                                        : application.applicationStatus === ApplicationStatus.Submitted
                                                        ? classes.applicationStatusSubmitted
                                                        : application.applicationStatus === ApplicationStatus.Accepted
                                                        ? classes.applicationStatusAccepted
                                                        : classes.applicationStatusRejected
                                                }
                                                style={isXS ? { marginTop: 5, marginLeft: 0, alignSelf: 'center' } : undefined}
                                            >
                                                {application.applicationStatus === ApplicationStatus.InProgress && 'Not Yet Submitted'}

                                                {application.applicationStatus !== ApplicationStatus.InProgress &&
                                                    `${enumHelper.getDisplayName(ApplicationStatus, application.applicationStatus)} on ${
                                                        application.applicationStatus === ApplicationStatus.Submitted
                                                            ? moment(application.submittedDate).format('MM/DD/YYYY h:mm a')
                                                            : application.applicationStatus === ApplicationStatus.Accepted
                                                            ? moment(application.approvedDate).format('MM/DD/YYYY h:mm a')
                                                            : moment(application.rejectedDate).format('MM/DD/YYYY h:mm a')
                                                    }`}
                                            </Typography> */}
                                            <Grid item xs={12}>
                                                <PayoutsCard payouts={application.payouts} />
                                            </Grid>
                                        </Grid>
                                    );
                                } else {
                                    let appToEdit = {
                                        academicYearId: year.id,
                                        academicYear: year,
                                        applicationStatus: ApplicationStatus.InProgress
                                    };
                                    if (lastApplication) {
                                        appToEdit.enrollmentStatus = lastApplication.enrollmentStatus;
                                        appToEdit.selectedInstitutionId = lastApplication.selectedInstitutionId;
                                        appToEdit.otherInstitution = lastApplication.otherInstitution;
                                        appToEdit.schoolIdentificationNumber = lastApplication.schoolIdentificationNumber;
                                    }

                                    return (
                                        <Grid key={year.id} item sm={12} xs={12}>
                                            <Button
                                                fullWidth={isXS}
                                                color="primary"
                                                variant="contained"
                                                disabled={!profileExists}
                                                onClick={() => setApplicationToEdit(appToEdit)}
                                            >{`Start Application for ${year.startYear}-${year.endYear}`}</Button>
                                        </Grid>
                                    );
                                }
                            })}
                        </Grid>
                    </ContentWrapper>
                )}
            </Paper>
        </TenantLayout>
    );
};

export default StudentApplicationsPage;
