import { isDev } from './environment';

export function getTopNavItems(nav) {
    if (!nav) return [];

    const dev = isDev();

    return nav.menus.MainMenu.items
        .filter((item) => item.isVisible || dev)
        .map((item) => ({
            label: item.displayName,
            lookup: item.url,
            to: item.items.length > 0 ? item.items[0].url : item.url
        }));
}

export function getSecondaryNavItems(nav, pathname) {
    if (!nav) return [];

    const dev = isDev();

    var topNav = nav.menus.MainMenu.items.find((item) => pathname.startsWith(item.url));
    if (topNav) {
        return topNav.items
            .filter((item) => item.isVisible || dev)
            .map((item) => ({
                label: item.displayName,
                to: item.url
            }));
    }

    return [];
}
