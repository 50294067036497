import React, { useState, useEffect, useCallback } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Box from '@material-ui/core/Box';
import { Field } from 'react-final-form';
import { CircularProgress, Typography } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import { fade } from '@material-ui/core/styles';
import _ from 'lodash';

import * as AwardApi from '../../../../../api/award';
import { FinalFormTextField, FinalFormRadioGroup } from '../../../../../common/formWrappers';
import { CurrencyMaskInput, conformedCurrency, parseCurrency } from '../../../../../common/masks';

const useStyles = makeStyles((theme) => ({
    awardPercent: {
        backgroundColor: fade(theme.palette.primary.main, 0.3),
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        border: `solid 1px ${theme.palette.primary.main}`,
        display: 'flex'
    },
    field: {
        marginBottom: 0
    },
    progress: {
        alignSelf: 'flex-end',
        marginLeft: theme.spacing(1)
    },
    normal: {
        fontWeight: 'normal'
    },
    bold: {
        fontWeight: 'bold'
    }
}));

const choices = [
    { label: 'Apply Only to Students NOT Awarded Yet', value: '1' },
    { label: 'Apply Only to REMAINING Unmet Need (Including Awarded Students)', value: '2' },
    {
        label: 'Redistributed to ALL Unmet Need (Including Awarded Students)',
        value: '3',
        helper: 'Note: This will redistribute award values to ALL awarded and unawarded students including awards that have been released.'
    }
];

const AwardPercent = ({ values, academicYearId }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    const calcAward = useCallback(
        _.debounce(async (values) => {
            // Perform debounce stuff
            if (!values.award || !values.coveragePercentage || !values.award.federalFunds || !values.award.stateFunds) {
                return;
            }

            setLoading(true);

            const result = (
                await AwardApi.calculateAward({
                    academicYearId: academicYearId,
                    federalFunds: values.award.federalFunds,
                    stateFunds: values.award.stateFunds,
                    overawardAmount: values.award.overawardAmount,
                    coveragePercentage: values.coveragePercentage,
                    coverageType: values.awardType,
                    federalCap: values.award.federalCap
                })
            ).data.result;
            setData(result);
            setLoading(false);
        }, 500),
        []
    );

    useEffect(() => {
        calcAward(values);
    }, [values]);

    const getCurrency = (value) => {
        return conformedCurrency(value ? value.toString() : '0', { allowDecimal: false, allowNegative: true });
    };

    return (
        <div className={classes.awardPercent}>
            <Box width={400} mr={2}>
                <Box display="flex">
                    <Field
                        className={classes.field}
                        margin="dense"
                        name="coveragePercentage"
                        label="Coverage Percentage"
                        component={FinalFormTextField}
                        inputComponent={CurrencyMaskInput}
                        parse={parseCurrency}
                        inputProps={{
                            maskOptions: {
                                allowDecimal: false,
                                prefix: '',
                                suffix: '%'
                            }
                        }}
                    />
                    {loading && <CircularProgress className={classes.progress} />}
                </Box>
                <Field name="awardType" component={FinalFormRadioGroup} choices={choices} />
            </Box>
            <Box mr={2}>
                <Box mb={1}>
                    <FormLabel className={classes.normal}>Unmet Need</FormLabel>
                    <Typography variant="body2" className={classes.bold}>
                        {getCurrency(data?.unmetNeed)}
                    </Typography>
                </Box>
                <Box mb={1}>
                    <FormLabel className={classes.normal}>Unmet Need Covered</FormLabel>
                    <Typography variant="body2" className={classes.bold}>
                        {getCurrency(data?.unmetNeedCovered)}
                    </Typography>
                </Box>
                <Box>
                    <FormLabel className={classes.normal}>Students</FormLabel>
                    <Typography variant="body2" className={classes.bold}>
                        {data?.students || '0'}
                    </Typography>
                </Box>
            </Box>
            <Box mr={2}>
                <Box mb={1}>
                    <FormLabel className={classes.normal}>State Used</FormLabel>
                    <Typography variant="body2" className={classes.bold}>
                        {getCurrency(data?.stateUsed)}
                    </Typography>
                </Box>
                <Box mb={1}>
                    <FormLabel className={classes.normal}>Remaining State</FormLabel>
                    <Typography variant="body2" className={classes.bold}>
                        {getCurrency(data?.remainingState)}
                    </Typography>
                </Box>
                <Box>
                    <FormLabel className={classes.normal}>Actual Remaining State</FormLabel>
                    <Typography variant="body2" className={classes.bold}>
                        {getCurrency(data?.actualRemainingState)}
                    </Typography>
                </Box>
            </Box>
            <Box mr={2}>
                <Box mb={1}>
                    <FormLabel className={classes.normal}>Federal Used</FormLabel>
                    <Typography variant="body2" className={classes.bold}>
                        {getCurrency(data?.federalUsed)}
                    </Typography>
                </Box>
                <Box mb={1}>
                    <FormLabel className={classes.normal}>Remaining Federal</FormLabel>
                    <Typography variant="body2" className={classes.bold}>
                        {getCurrency(data?.remainingFederal)}
                    </Typography>
                </Box>
                <Box>
                    <FormLabel className={classes.normal}>Actual Remaining Federal</FormLabel>
                    <Typography variant="body2" className={classes.bold}>
                        {getCurrency(data?.actualRemainingFederal)}
                    </Typography>
                </Box>
            </Box>
        </div>
    );
};

export default AwardPercent;
