import React, { useEffect } from 'react';
import axios from 'axios';

import { useAuth } from '../../auth/auth-context';
import { ErrorDialog, useErrorDialog } from '../errorDialog';
import { ErrorCodes } from '../logic/enums';

const ignoredErrorCodes = [ErrorCodes.LockedOut, ErrorCodes.EmailInUse];

const ErrorInterceptor = () => {
    const { isShowing, show, hide, message, details } = useErrorDialog();
    const { accessToken } = useAuth();

    useEffect(() => {
        const responseInterceptor = axios.interceptors.response.use(
            (response) => response,
            (error) => {
                const headers = error.config.headers;
                if (!headers['disable-intercept']) {
                    if (!error.response.data.error) {
                        return Promise.reject(error);
                    }

                    const errorCode = error.response.data.error.code;
                    if (errorCode !== 0 && ignoredErrorCodes.includes(errorCode)) {
                        return Promise.reject(error);
                    }

                    const validationErrors = error?.response?.data?.error?.validationErrors;
                    if (validationErrors?.length > 0) {
                        const validationMessage = (
                            <div>
                                <div>The following fields had validation errors:</div>
                                <ul>
                                    {validationErrors.map((i, index) => (
                                        <li key={index}>{i.members.join()}</li>
                                    ))}
                                </ul>
                            </div>
                        );
                        show('Field Validation Error', validationMessage);
                        return Promise.reject(error);
                    }

                    const message = error.response.data.error.message;
                    let details = error.response.data.error.details || null;
                    show(message, details);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axios.interceptors.response.eject(responseInterceptor);
        };
    }, [accessToken, show]);

    return <ErrorDialog isShowing={isShowing} hide={hide} message={message} details={details} />;
};

export default ErrorInterceptor;
