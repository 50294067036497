import axios from 'axios';

export function getAll(tenantId) {
    return axios.get('/api/services/app/Feature/GetAll', { params: { tenantId } });
}

export function updateFeature(feature) {
    return axios.put('/api/services/app/Feature/UpdateBooleanFeature', feature);
}

export function updateFeatures(data) {
    return axios.put('/api/services/app/Feature/UpdateFeatures', data);
}
