import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Chip from '@material-ui/core/Chip';
import clsx from 'clsx';
import CreateIcon from '@material-ui/icons/Create';
import PublishIcon from '@material-ui/icons/Publish';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

import { ApplicationStatus } from '../logic/enums';
import enumHelper from '../logic/enumHelper';

const useStyles = makeStyles((theme) => ({
    applicationStatusChip: {},
    accepted: {
        color: theme.palette.success.contrastText,
        backgroundColor: theme.palette.success.main
    },
    rejected: {
        color: theme.palette.error.contrastText,
        backgroundColor: theme.palette.error.main
    },
    inProgress: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main
    }
}));

const ApplicationStatusChip = ({ className, status }) => {
    const classes = useStyles();

    const getStyle = (status) => {
        switch (status) {
            case ApplicationStatus.Submitted:
                return { class: undefined, icon: <PublishIcon /> };
            case ApplicationStatus.Rejected:
                return { class: classes.rejected, icon: <ThumbDownIcon /> };
            case ApplicationStatus.InProgress:
                return { class: classes.inProgress, icon: <CreateIcon /> };
            case ApplicationStatus.Accepted:
                return { class: classes.accepted, icon: <ThumbUpIcon /> };
            default:
                return {};
        }
    };

    const style = getStyle(status);

    return (
        <Chip
            icon={style.icon}
            className={clsx(style.class, className)}
            color="primary"
            label={enumHelper.getDisplayName(ApplicationStatus, status)}
        />
    );
};

export default ApplicationStatusChip;
