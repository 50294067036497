import React, { useState, Fragment } from 'react';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

import enumHelper from '../../../common/logic/enumHelper';
import { TriggerState } from '../../../common/logic/enums';

const useStyles = makeStyles((theme) => ({
    jobListRow: {
        '& > *': {
            borderBottom: 'unset'
        }
    },
    leftIcon: {
        marginRight: theme.spacing(1)
    }
}));

const formatDate = (date) => {
    const momentDate = moment(date);
    if (momentDate.isValid()) {
        return momentDate.format('MM/DD/YYYY h:mm:ss a');
    }

    return '';
};

const JobListRow = ({ row, runJob }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    return (
        <Fragment>
            <TableRow key={row.id} className={classes.jobListRow}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{row.group}</TableCell>
                <TableCell>{row.key}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>
                    <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        onClick={() => {
                            runJob(row.group, row.key);
                        }}
                    >
                        <PlayCircleOutlineIcon className={classes.leftIcon} fontSize="small" />
                        Run
                    </Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" className={classes.h6}>
                                Triggers
                            </Typography>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Cron Expression</TableCell>
                                        <TableCell>State</TableCell>
                                        <TableCell>Next Fire Time</TableCell>
                                        <TableCell>Prev Fire Time</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.triggers.map((trigger) => (
                                        <TableRow key={trigger.name}>
                                            <TableCell>{trigger.type}</TableCell>
                                            <TableCell>{trigger.cronExpression}</TableCell>
                                            <TableCell>{enumHelper.getDisplayName(TriggerState, trigger.state)}</TableCell>
                                            <TableCell>{formatDate(trigger.nextFireTime)}</TableCell>
                                            <TableCell>{formatDate(trigger.prevFireTime)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
};

export default JobListRow;
