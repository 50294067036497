import React, { useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Form, Field } from 'react-final-form';
import { useSnackbar } from 'notistack';

import * as SettingApi from '../../../../api/setting';
import LoadingButton from '../../../../common/loadingButton';
import { FinalFormTextField } from '../../../../common/formWrappers';
import { useAuth } from '../../../../auth/auth-context';
import { APP_SETTING_NAME_REGISTRATION_PAGE } from '../../../../common/logic/consts';

const useStyles = makeStyles((theme) => ({
    registrationPage: {
        padding: theme.spacing(2)
    }
}));

const RegistrationPage = () => {
    const classes = useStyles();
    const [saving, setSaving] = useState(false);
    const [editing, setEditing] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { settings } = useAuth();
    const registrationPageSetting = settings.find((s) => s.name === APP_SETTING_NAME_REGISTRATION_PAGE)?.value;

    const onSave = async (values) => {
        if (editing) {
            try {
                setSaving(true);
                await SettingApi.updateRegistrationPage(values);
                enqueueSnackbar('Successfully saved registration page settings.', { variant: 'success' });
                setEditing(false);
                setSaving(false);
            } catch (ex) {
                enqueueSnackbar('Error saving registration page settings.', { variant: 'error' });
                console.error(ex);
                setSaving(false);
            }
        } else {
            setEditing(true);
        }
    };

    const handleCancel = (form) => {
        setEditing(false);
        form.reset();
    };

    return (
        <Paper className={classes.registrationPage}>
            <Form onSubmit={onSave} initialValues={registrationPageSetting}>
                {({ handleSubmit, form }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Typography variant="h6">Registration Page</Typography>
                            <Box display="flex">
                                {editing && (
                                    <Box mr={1}>
                                        <LoadingButton color="primary" variant="outlined" loading={saving} onClick={() => handleCancel(form)}>
                                            Cancel
                                        </LoadingButton>
                                    </Box>
                                )}
                                <LoadingButton color="primary" variant="contained" type="submit" loading={saving}>
                                    {editing ? 'Save' : 'Edit'}
                                </LoadingButton>
                            </Box>
                        </Box>
                        <Box mt={1.5}>
                            {editing && (
                                <Field
                                    name="introductionText"
                                    fullWidth
                                    rows={5}
                                    component={FinalFormTextField}
                                    multiline
                                    label="Introduction Text"
                                />
                            )}
                            {!editing && (
                                <>
                                    <FormLabel>Introduction Text</FormLabel>
                                    <Typography variant="body1">{registrationPageSetting.introductionText}</Typography>
                                </>
                            )}
                        </Box>
                    </form>
                )}
            </Form>
        </Paper>
    );
};

export default RegistrationPage;
