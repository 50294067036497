import moment from 'moment';
import { conformedSsn } from '../../../common/masks';

export const formatProfile = (profileData) => {
    const data = { ...profileData };
    data.birthDate = data.birthDate ? moment.utc(data.birthDate).format('MM/DD/YYYY') : '';
    data.ssn = profileData.ssn ? conformedSsn(data.ssn) : '';
    data.graduationDate = data.graduationDate ? moment.utc(data.graduationDate).format('MM/DD/YYYY') : '';
    data.inFosterCareOnOrAfterAge16 =
        data.inFosterCareOnOrAfterAge16 === true ? 'true' : data.inFosterCareOnOrAfterAge16 === false ? 'false' : undefined;

    return data;
};
