import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import green from '@material-ui/core/colors/green';
import { Form, Field } from 'react-final-form';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { useSnackbar } from 'notistack';

import { useAuth } from '../../auth/auth-context';
import { FinalFormTextField } from '../../common/formWrappers';
import { required, composeValidators, email } from '../../common/formValidators';
import { isDev } from '../../common/logic/environment';
import { ErrorCodes } from '../../common/logic/enums';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    title: {
        marginBottom: theme.spacing(2),
        alignItems: 'center'
    },
    form: {
        maxWidth: '400px', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    wrapper: {
        position: 'relative',
        margin: theme.spacing(1)
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    forgotPasswordContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& a': {
            fontWeight: 'bold'
        }
    },
    register: {
        textAlign: 'center',
        color: theme.palette.primary.main,
        marginTop: theme.spacing(4),
        '& a': {
            fontWeight: 'bold'
        }
    },
    lockedOut: {
        color: theme.palette.error.main
    }
}));

export default function Login() {
    const classes = useStyles();
    const { loading, error, login } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const [lockedOut, setLockedOut] = useState(false);

    useEffect(() => {
        if (error && error.response && error.response.data && error.response.data.error) {
            const errorData = error.response.data.error;
            if (errorData.code === ErrorCodes.LockedOut) {
                enqueueSnackbar('Too many failed attempts, try again in 5 minutes', { variant: 'error' });
                setLockedOut(true);
            }
        } else {
            setLockedOut(false);
        }
    }, [error, enqueueSnackbar]);

    return (
        <div className={classes.paper}>
            {!lockedOut && (
                <Box display="flex" flexDirection="column" className={classes.title}>
                    <Typography variant="h1">Admin Login</Typography>
                </Box>
            )}

            {lockedOut && (
                <Box display="flex" flexDirection="column" className={classes.title}>
                    <Typography variant="h1" className={classes.lockedOut}>
                        Locked Out
                    </Typography>
                    <Typography variant="body1">Too many failed attempts. Try again in 5 minutes.</Typography>
                </Box>
            )}

            <Form onSubmit={values => login(values.emailAddress, values.password, values.tenantId)}>
                {props => (
                    <form className={classes.form} noValidate onSubmit={props.handleSubmit}>
                        <Field
                            name="emailAddress"
                            margin="dense"
                            required
                            fullWidth
                            placeholder="email@address.com"
                            label="Email Address"
                            id="emailAddress"
                            autoComplete="emailAddress"
                            component={FinalFormTextField}
                            maxLength={256}
                            validate={!isDev() ? composeValidators(required, email) : required}
                        />
                        <Field
                            margin="dense"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            id="password"
                            placeholder="Enter password"
                            maxLength={32}
                            autoComplete="current-password"
                            password
                            component={FinalFormTextField}
                            validate={required}
                        />
                        {isDev() && (
                            <Field
                                name="tenantId"
                                margin="dense"
                                fullWidth
                                label="Tenant Id"
                                id="tenantId"
                                initialValue="1"
                                component={FinalFormTextField}
                            />
                        )}
                        <div className={classes.wrapper}>
                            <Button type="submit" fullWidth variant="contained" color="primary" disabled={loading}>
                                Log In
                            </Button>
                            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </form>
                )}
            </Form>
        </div>
    );
}
