import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import ResetPasswordForm from './resetPasswordForm';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(5)
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    title: {
        marginBottom: theme.spacing(2),
        alignItems: 'center'
    },
    subtitle: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3)
    },
    maxWidth: {
        maxWidth: 400
    }
}));

export default function ResetPasswordPage({ location, expired }) {
    const classes = useStyles();

    return (
        <div className={classes.paper}>
            <div className={classes.maxWidth}>
                <Box display="flex" flexDirection="column" className={classes.title}>
                    <Typography variant="h3">{expired ? 'Password Expired' : 'Password Reset'}</Typography>
                </Box>
                <ResetPasswordForm location={location} expired={expired} />
            </div>
        </div>
    );
}
