import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import CustomDataGrid from '../../../../common/customDataGrid';
import { renderAmount } from './common';

const useStyles = makeStyles((theme) => ({
    institutionsList: {
        '& .MuiDataGrid-window': {
            overflowX: 'hidden'
        }
    }
}));

const sortModel = [{ field: 'institutionName', sort: 'asc' }];

const InstitutionsList = ({ data, loading, onRowSelected, selectedInstitutionId }) => {
    const classes = useStyles();

    const columns = [
        { field: 'institutionName', headerName: 'Institution', flex: 3 },
        {
            field: 'amount',
            type: 'number',
            renderHeader: () => (
                <div
                    className="MuiDataGrid-colCellTitle"
                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', lineHeight: 1, height: 56, fontWeight: 500 }}
                >
                    <span>Balance Owed</span>
                    <span>to Institution</span>
                </div>
            ),
            renderCell: renderAmount,
            sortComparator: (v1, v2, param1, param2) => {
                return param1.api.getCellValue(param1.id, 'amount') - param2.api.getCellValue(param2.id, 'amount');
            },
            flex: 1
        }
    ];

    return (
        <div>
            <Box mb={0.5}>
                <Typography variant="h4">Institutions</Typography>
            </Box>
            <CustomDataGrid
                selectionModel={selectedInstitutionId ? [selectedInstitutionId] : []}
                className={classes.institutionsList}
                coloredHeader
                numberColLeftAligned
                columns={columns}
                disableColumnMenu
                disableColumnFilter
                rows={data}
                initialSortModel={sortModel}
                loading={loading}
                rowsPerPageOptions={[5]}
                initialPageSize={5}
                rowHeight={35}
                hideFooterSelectedRowCount
                onRowClick={(params) => {
                    onRowSelected(params);
                }}
            />
        </div>
    );
};

export default InstitutionsList;
