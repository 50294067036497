import React from 'react';

import { useAuth } from '../../auth/auth-context';

import HostLoginPage from '../../anonymous/hostLoginPage';
import LoginPage from '../../anonymous/loginPage';
import AccountInactivePage from '../../anonymous/accountInactive';
import NotVerifiedPage from '../../tenant/pages/notVerified';
import AccessDeniedPage from '../../tenant/pages/accessDenied';
import { hasPermission } from '../logic/permissions';
import ForgotPasswordPage from '../../anonymous/forgotPassword';

export default function SecureRoute({ as: Component, permissionSet = [], allRequired = false, children, ...props }) {
    const { user, tenant, permissions } = useAuth();
    const isLoggedIn = user !== null;
    const isInactive = isLoggedIn ? user.accountDeactivatedByUser : false;
    const hasVerifiedEmail = isLoggedIn ? user.isEmailConfirmed : false;
    const isHost = tenant === null;

    if (isHost) {
        return isLoggedIn ? <Component {...props}>{children}</Component> : <HostLoginPage />;
    }

    if (!isLoggedIn) {
        //return hasLoggedInBefore ? <LoginPage /> : <RegisterPage />;
        return <LoginPage />;
    }

    if (isInactive) {
        return <AccountInactivePage />;
    }

    if (!hasVerifiedEmail) {
        return <NotVerifiedPage />;
    }

    if (!hasPermission(permissions, permissionSet, allRequired)) {
        return <AccessDeniedPage />;
    }

    if (user.passwordExpired) {
        return <ForgotPasswordPage expired />;
    }

    return <Component {...props}>{children}</Component>;
}
