import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

function isOverflown(element) {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

export const dateFormatter = ({ value }) => {
    if (!value) {
        return '';
    }

    return moment(value).format('MM/DD/YYYY');
};

export const dateFormatterUtc = ({ value }) => {
    if (!value) {
        return '';
    }

    return moment(value).utc().format('MM/DD/YYYY');
};

export const certByEnrolledFormatter = ({ row }) => {
    if (row.numberOfEnrolledTerms === null || row.numberOfCertifications === null) {
        return '--';
    }

    return `${row.numberOfCertifications}/${row.numberOfEnrolledTerms}`;
};

const useGridCellExpandStyles = makeStyles(() => ({
    root: {
        alignItems: 'center',
        lineHeight: '24px',
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        '& .cellValue': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    }
}));

export const GridCellExpand = React.memo(function GridCellExpand(props) {
    const { width } = props;
    const wrapper = React.useRef(null);
    const cellDiv = React.useRef(null);
    const cellValue = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useGridCellExpandStyles();
    const [showFullCell, setShowFullCell] = React.useState(false);
    const [showPopper, setShowPopper] = React.useState(false);

    const handleMouseEnter = () => {
        const isCurrentlyOverflown = isOverflown(cellValue.current);
        setShowPopper(isCurrentlyOverflown);
        setAnchorEl(cellDiv.current);
        setShowFullCell(true);
    };

    const handleMouseLeave = () => {
        setShowFullCell(false);
    };

    React.useEffect(() => {
        if (!showFullCell) {
            return undefined;
        }

        function handleKeyDown(nativeEvent) {
            // IE11, Edge (prior to using Bink?) use 'Esc'
            if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
                setShowFullCell(false);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [setShowFullCell, showFullCell]);

    return (
        <div ref={wrapper} className={classes.root} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div
                ref={cellDiv}
                style={{
                    height: 1,
                    width,
                    display: 'block',
                    position: 'absolute',
                    top: 0
                }}
            />
            <div ref={cellValue} className="cellValue">
                {props.children}
            </div>
            {showPopper && (
                <Popper open={showFullCell && anchorEl !== null} anchorEl={anchorEl} style={{ width, marginLeft: -17 }}>
                    <Paper
                        elevation={3}
                        style={{
                            minHeight: wrapper.current.offsetHeight - 3,
                            padding: 10,
                            display: 'flex',
                            alignItems: 'center',
                            overflowWrap: 'anywhere'
                        }}
                    >
                        {props.children}
                    </Paper>
                </Popper>
            )}
        </div>
    );
});
