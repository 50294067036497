import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { green } from '@material-ui/core/colors';

import { FinalFormTextField, FinalFormCheckboxGroup } from '../../../common/formWrappers';
import { required, maxLength, composeValidators } from '../../../common/formValidators';

const useStyles = makeStyles((theme) => ({
    buttonWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

export default function EditDialog({ role, open, handleClose, handleSave, isSaving, permissions }) {
    const classes = useStyles();

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{role === null ? 'Add Role' : `Edit Role '${role.name}'`}</DialogTitle>
            <DialogContent>
                <Form onSubmit={(values) => handleSave({ ...role, ...values })} mutators={{ ...arrayMutators }}>
                    {(props) => (
                        <form onSubmit={props.handleSubmit}>
                            <Field
                                name="name"
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                label="Name"
                                id="name"
                                component={FinalFormTextField}
                                validate={composeValidators(required, maxLength(32))}
                                initialValue={role ? role.name : undefined}
                            />
                            <Field
                                name="displayName"
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                label="Display Name"
                                id="displayName"
                                component={FinalFormTextField}
                                validate={composeValidators(required, maxLength(64))}
                                initialValue={role ? role.displayName : undefined}
                            />
                            <Field
                                name="description"
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                label="Description"
                                id="description"
                                component={FinalFormTextField}
                                validate={composeValidators(maxLength(5000))}
                                initialValue={role ? role.description : undefined}
                            />
                            <div>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Assign Permissions</FormLabel>
                                    <FieldArray
                                        name="grantedPermissions"
                                        component={FinalFormCheckboxGroup}
                                        options={permissions}
                                        initialValue={role ? role.grantedPermissions : undefined}
                                    />
                                </FormControl>
                            </div>
                            <DialogActions>
                                <Button onClick={handleClose} disabled={isSaving}>
                                    Cancel
                                </Button>
                                <div className={classes.buttonWrapper}>
                                    <Button type="submit" disabled={isSaving}>
                                        Save
                                    </Button>
                                    {isSaving && <CircularProgress className={classes.buttonProgress} size={24} />}
                                </div>
                            </DialogActions>
                        </form>
                    )}
                </Form>
            </DialogContent>
        </Dialog>
    );
}
