export { State } from './state';

export const ErrorCodes = Object.freeze({
    EmailInUse: 10001,
    LoginFailed: 10002,
    LockedOut: 10003,
    NotEligible: 10004
});

export const GroupType = Object.freeze({
    Image: 0,
    Video: 1,
    Pdf: 2,
    File: 3
});

export const TriggerState = Object.freeze({
    // Used for Jobs List
    Normal: 0,
    Paused: 1,
    Complete: 2,
    Error: 3,
    Blocked: 4,
    None: 5
});

export const SchoolType = Object.freeze({
    FourYear: 1,
    TwoYear: 2,
    _displayNames: {
        1: 'Four Year',
        2: 'Two Year'
    }
});

export const TermStructure = Object.freeze({
    Semester: 1,
    Trimester: 2,
    Quarter: 3,
    Other: 4
});

export const Gender = Object.freeze({
    Female: 1,
    Male: 2,
    NonBinary: 3,
    Transgender: 4,
    TransgenderFemale: 5,
    TransgenderMale: 6,
    ChooseNotToIdentify: 99,
    _displayNames: {
        1: 'Female',
        2: 'Male',
        3: 'Non-Binary',
        4: 'Transgender',
        5: 'Transgender Female',
        6: 'Transgender Male',
        99: 'Choose Not to Identify'
    }
});

export const Race = Object.freeze({
    AmericanIndianOrAlaskanNative: 1,
    Asian: 2,
    BlackOrAfricanAmerican: 3,
    NativeHawaiianOrOtherPacificIslander: 4,
    White: 5,
    ChooseNotToIdentify: 99,
    _displayNames: {
        1: 'American Indian or Alaskan Native',
        2: 'Asian',
        3: 'Black or African American',
        4: 'Native Hawaiian or Other Pacific Islander',
        5: 'White',
        99: 'Choose Not to Identify'
    }
});

export const HispanicOrigin = Object.freeze({
    Yes: 1,
    No: 2,
    UnableToDetermine: 3,
    DeclineToDisclose: 4,
    _displayNames: {
        1: 'Yes',
        2: 'No',
        3: 'Unable to Determine',
        4: 'Decline to Disclose'
    }
});

export const CitizenshipStatus = Object.freeze({
    USCitizen: 4,
    LegalAlien: 1,
    NaturalizedUSCitizen: 2,
    SpecialImmigrationStatus: 3,
    UndocumentedPerson: 5,
    _displayNames: {
        1: 'Legal Alien',
        2: 'Naturalized US Citizen',
        3: 'Special Immigration Status',
        4: 'US Citizen',
        5: 'Undocumented Person'
    }
});

export const Grade = Object.freeze({
    HighSchoolSenior: 1,
    GEDOrEquivalentDegree: 2,
    TradeSchoolCertificationProgram: 3,
    Freshman: 4,
    Sophomore: 5,
    Junior: 6,
    Senior: 7,
    GraduateStudent: 8,
    _displayNames: {
        1: 'High School Senior',
        2: 'GED or Equivalent Degree',
        3: 'Trade School Certification Program',
        4: 'College Freshman',
        5: 'College Sophomore',
        6: 'College Junior',
        7: 'College Senior',
        8: 'Graduate Student'
    }
});

export const EnrollmentStatus = Object.freeze({
    FullTime: 1,
    ThreeQuarterTime: 2,
    HalfTime: 3,
    LessThanHalfTime: 4,
    NotEnrolled: 5,
    _displayNames: {
        1: 'Full-time',
        2: 'Three-quarter time',
        3: 'Half-time',
        4: 'Less than half-time',
        5: 'Not enrolled'
    }
});

export const ApplicationStatus = Object.freeze({
    InProgress: 1,
    Submitted: 2,
    Accepted: 3,
    Rejected: 4,
    _displayNames: {
        1: 'In Progress'
    }
});

export const AgreementType = Object.freeze({
    Funds: 0,
    Records: 1,
    FAQS: 2
});

export const CertificationStatus = Object.freeze({
    Pending: 0,
    Certified: 1,
    Ineligible: 2,
    _displayNames: {
        0: 'Pending Certification'
    }
});

export const ReviewCondition = Object.freeze({
    NoHighSchoolDiplomaGED: 1,
    NoUnmetNeed: 2,
    ProgramIneligible: 4,
    NotMatriculated: 8,
    LeaveOfAbasence: 16,
    LoanDefault: 32,
    RefundOwed: 64,
    SelectiveService: 128,
    DrugConviction: 256,
    SAP: 512,
    _displayNames: {
        1: 'No High School Diploma or GED',
        2: 'No Unmet Need',
        4: 'Program Ineligible',
        8: 'Not Matriculated',
        16: 'Leave of Absence',
        32: 'Loan Default',
        64: 'Refund Owed',
        128: 'Selective Service',
        256: 'Drug Conviction',
        512: 'SAP'
    }
});

export const TransactionType = Object.freeze({
    Charge: 0, // Positive Transaction Amount
    Credit: 1, // Negative Transaction Amount
    Payment: 2, // Negative Transaction Amount
    Refund: 3, // Positive Transaction Amount
    PendingRefund: 4, // Positive Transaction Amount
    Adjustment: 5, // Pos or Neg Transaction Amount
    Update: 9999, // update notes
    _displayNames: {
        4: 'Pending Refund'
    }
});

export const PaymentType = Object.freeze({
    None: 0,
    Check: 1,
    ACH: 2,
    JournalEntry: 3,
    _displayNames: {
        3: 'Journal Entry'
    }
});
