import React from 'react';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

const useStyles = makeStyles((theme) => ({
    previewPanel: {
        padding: '0 10px 10px',
        minHeight: '400px'
    },
    appBar: {
        marginBottom: 10,
        backgroundColor: theme.palette.header.main,
        color: theme.palette.getContrastText(theme.palette.header.main)
    },
    appBarTitle: {
        flexGrow: 1
    },
    radioGroup: {
        flexDirection: 'row'
    }
}));

const ThemePreview = () => {
    const classes = useStyles();
    const theme = useTheme();

    console.log(theme.palette.header.main);

    return (
        <Grid container className={classes.previewPanel} direction="column" justify="flex-start">
            <h1>Preview</h1>
            <Grid container justify="space-between">
                <AppBar position="static" className={classes.appBar}>
                    <Toolbar>
                        <IconButton color="inherit" edge="start">
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.appBarTitle}>
                            Preview
                        </Typography>
                        <Button color="inherit">Preview User</Button>
                    </Toolbar>
                </AppBar>
                <TextField label="Preview" variant="outlined" />
                <Switch />
                <RadioGroup className={classes.ragioGroup}>
                    <Radio color="primary" />
                    <Radio color="secondary" />
                </RadioGroup>
                <Link href="#">This is a link</Link>
            </Grid>
        </Grid>
    );
};

export default ThemePreview;
