import React, { useState, useEffect } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';

import DefaultTheme from './defaultTheme';

import { updateTheme, getTheme } from '../api/theme';
import ThemeProvider from '@material-ui/styles/ThemeProvider';

export const AppThemeContext = React.createContext();
export function getDefaultThemeWithOptions(themeSetting) {
    //trilix colors
    const themeType = 'light';
    const primaryColor = '#223c4d';
    const secondaryColor = '#d7df21';
    const headerColor = '#7bbec6';

    let theme = null;
    if (themeSetting) {
        theme = DefaultTheme({
            themeType: themeSetting.themeType,
            primaryColor: themeSetting.primaryColor,
            secondaryColor: themeSetting.secondaryColor,
            headerColor: themeSetting.headerColor
        });
    } else {
        theme = DefaultTheme({ themeType, primaryColor, secondaryColor, headerColor });
    }

    return createMuiTheme(theme);
}

function AppThemeProvider({ children }) {
    const [themeData, setThemeData] = useState({ themeType: 'light', headerColor: '#ffffff', primaryColor: '#ffffff', secondaryColor: '#ffffff' });

    const [fetching, setFetching] = useState(true);
    const [loading, setLoading] = useState(false);

    async function handleSave(data) {
        try {
            setLoading(true);
            await updateTheme(data);
            setThemeData(data);
        } finally {
            setLoading(false);
        }
    }

    async function fetchTheme() {
        try {
            setFetching(true);
            const t = (await getTheme()).data.result;
            setThemeData(t);
        } finally {
            setFetching(false);
        }
    }

    useEffect(() => {
        fetchTheme();
    }, []);

    const t = getDefaultThemeWithOptions(themeData);
    window.theme = t;
    return (
        <ThemeProvider theme={t}>
            <AppThemeContext.Provider
                value={{
                    loading: loading,
                    fetching: fetching,
                    theme: t,
                    setTheme: handleSave,
                    fetchTheme: fetchTheme
                }}
            >
                {children}
            </AppThemeContext.Provider>
        </ThemeProvider>
    );
}

export { AppThemeProvider };
