import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';

import ConfirmDialog from '../../../../../common/confirmDialog';
import BackdropLoader from '../../../../../common/backdropLoader';
import * as CertificationApi from '../../../../../api/certification';
import * as ApplicationApi from '../../../../../api/applications';
import ReadOnlyCertifyYear from './readOnlyCertifyYear';
import EditCertifyYear from './editCertifyYear';
import { useAuth } from '../../../../../auth/auth-context';
import { EnrollmentStatus } from '../../../../../common/logic/enums';

const useStyles = makeStyles((theme) => ({
    certifyStudent: {},
    select: {
        minWidth: 200
    }
}));

const CertifyStudent = ({ applicationId }) => {
    const classes = useStyles();
    const { user } = useAuth();
    const [confirm, setConfirm] = useState({ show: false, academicYearId: '', institutionId: '' });
    const [loading, setLoading] = useState(false);
    const [applications, setApplications] = useState([]);
    const [selectedYear, setSelectedYear] = useState('');
    const [canAddYear, setCanAddYear] = useState(false);
    const [currentApplication, setCurrentApplication] = useState([]);
    const [data, setData] = useState([]);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const load = async () => {
            try {
                setLoading(true);
                const certifications = (await CertificationApi.getStudentCertifications(applicationId)).data.result;
                const appData = (await ApplicationApi.adminGet(applicationId)).data.result;
                const allApps = (await ApplicationApi.getByUser(null, appData.userId, false)).data.result;
                setCurrentApplication(appData);
                setSelectedYear(appData.academicYear);
                setData(certifications);
                setLoading(false);
                setApplications(allApps);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        if (applicationId) {
            load();
        }
    }, [applicationId]);

    useEffect(() => {
        setCanAddYear(
            !Boolean(
                data.find(
                    (c) =>
                        c.academicYear.id === currentApplication.academicYearId &&
                        Boolean(c.institutions.find((i) => i.institution.id === currentApplication.selectedInstitutionId))
                )
            )
        );
    }, [data, currentApplication]);

    const addYear = () => {
        const selectedApplication = applications.find((a) => a.academicYear.id === selectedYear.id);

        setData((current) => {
            let year = current.find((y) => y.academicYear.id === selectedYear.id);
            if (!year) {
                year = {
                    academicYear: {
                        ...selectedYear
                    },
                    institutions: []
                };
                const index = current.findIndex((i) => i.academicYear.startYear < selectedYear.startYear);
                if (index >= 0) {
                    current.splice(index, 0, year);
                } else {
                    current.push(year);
                }
            }

            const newInst = {
                isEditing: true,
                isNew: true,
                institution: {
                    ...user.institution
                },
                certifications: []
            };

            for (let i = 0; i <= user.institution.termStructure + 1; i++) {
                newInst.certifications.push({
                    period: i + 1,
                    institutionId: user.institution.id,
                    applicationId: selectedApplication.id,
                    enrollmentStatus: EnrollmentStatus.NotEnrolled,
                    currentGrade: 0,
                    reviewConditions: 0
                });
            }

            year.institutions.push(newInst);

            return [...current];
        });
    };

    const toggleEdit = (academicYearId, institutionId, editValue, confirm = false) => {
        if (editValue === true && confirm === false) {
            const academicYear = data.find((i) => i.academicYear.id === academicYearId);
            if (academicYear.academicYear.closed) {
                setConfirm({ show: true, academicYearId, institutionId });
                return;
            }
        }

        setData((curData) => {
            const academicYear = curData.find((i) => i.academicYear.id === academicYearId);
            const inst = academicYear.institutions.find((i) => i.institution.id === institutionId);
            if (inst.isNew && editValue === false) {
                academicYear.institutions = academicYear.institutions.filter((i) => i.institution.id !== institutionId);
                if (academicYear.institutions.length === 0) {
                    curData = curData.filter((i) => i.academicYear.id !== academicYearId);
                }
            } else {
                inst.isEditing = editValue;
            }

            return [...curData];
        });
    };

    const confirmEdit = () => {
        setConfirm((prev) => ({ ...prev, show: false }));
        toggleEdit(confirm.academicYearId, confirm.institutionId, true, true);
    };

    const handleSave = async (values) => {
        try {
            setLoading(true);
            const returnedCerts = (await CertificationApi.createOrUpdate(values.certifications)).data.result;
            setData((curData) => {
                const academicYear = curData.find((i) => i.academicYear.id === values.academicYearId);
                const inst = academicYear.institutions.find((i) => i.institution.id === values.institution.id);
                inst.isNew = false;
                inst.isEditing = false;
                inst.certifications = returnedCerts;
                return [...curData];
            });
            enqueueSnackbar('Status updated successfully and sent to DCYF for review.', { variant: 'success' });
            setLoading(false);
        } catch (err) {
            console.error(err);
            enqueueSnackbar('Error submitting data.', { variant: 'error' });
            setLoading(false);
        }
    };

    if (loading) {
        return <BackdropLoader open />;
    }

    return (
        <div className={classes.certifyStudent}>
            {canAddYear && user.institution?.id && (
                <Box mt={2} mb={2} display="flex" alignItems="center">
                    <Button color="primary" variant="contained" onClick={addYear}>
                        {`Add (${selectedYear.startYear}-${selectedYear.endYear}) Year`}
                    </Button>
                </Box>
            )}
            {data.map((year) => (
                <div key={year.academicYear.id}>
                    <Typography variant="h6" color="primary">
                        {year.academicYear.startYear} - {year.academicYear.endYear} Academic Year {year.academicYear.closed ? '(Closed)' : '(Open)'}
                    </Typography>
                    {year.institutions.map((institution) =>
                        institution.isEditing ? (
                            <EditCertifyYear
                                onSave={handleSave}
                                onCancel={() => toggleEdit(year.academicYear.id, institution.institution.id, false)}
                                key={`${year.academicYear.id}_${institution.institution.id}`}
                                data={institution}
                                year={year.academicYear.id}
                            />
                        ) : (
                            <ReadOnlyCertifyYear
                                key={`${year.academicYear.id}_${institution.institution.id}`}
                                data={institution}
                                year={year.academicYear.id}
                                onEdit={() => toggleEdit(year.academicYear.id, institution.institution.id, true)}
                            />
                        )
                    )}
                </div>
            ))}
            <ConfirmDialog
                open={confirm.show}
                handleClose={() => setConfirm((prev) => ({ ...prev, show: false }))}
                title="Confirm?"
                body="This academic year has been closed. Are you sure you want to proceed?"
                confirmActionText="Yes"
                confirmAction={confirmEdit}
            />
        </div>
    );
};

export default CertifyStudent;
