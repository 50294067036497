import React from 'react';
import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from '@gatsbyjs/reach-router';

const useStyles = makeStyles((theme) => ({
    leftIcon: {
        marginRight: theme.spacing(1)
    }
}));

export default function BackButton({ location, label = 'Back' }) {
    const classes = useStyles();

    return (
        <Button variant="contained" size="small" color="primary" onClick={() => navigate(location)}>
            <ArrowBack className={classes.leftIcon} fontSize="small" />
            {label}
        </Button>
    );
}
