import React, { useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import BackButton from '../../../common/backButton';
import TenantLayout from '../../tenantLayout';
import useApplicantData from './useApplicantData';
import { useAuth } from '../../../auth/auth-context';
import EditApplicationForm from '../studentApplications/editForm';
import BackdropLoader from '../../../common/backdropLoader';
import { hasPermission, PERMISSION_APPLICATIONS_EDIT } from '../../../common/logic/permissions';
import ReadOnlyApplication from './readOnlyApplication';
import ReadOnlyProfile from '../home/readOnlyProfile';
import EditProfileForm from '../home/editProfileForm';
import ApproveReject from './approveReject';
import EligibilityDialog from './eligibilityDialog';

const useStyles = makeStyles((theme) => ({
    applicantPage: {
        margin: 0,
        width: '100%'
    }
}));

const ApplicantPage = ({ applicationId }) => {
    const classes = useStyles();
    const [showEligibility, setShowEligibility] = useState(false);
    const { permissions } = useAuth();
    const { loading, applicantData, institutions, profile, years, saveApplication, saveProfile, loadData } = useApplicantData(applicationId);
    const academicYear = applicantData && years.length > 0 ? years.find((y) => y.id === applicantData.academicYearId) : null;
    const academicYearClosed = academicYear ? academicYear.closed : false;
    const canEdit = hasPermission(permissions, [PERMISSION_APPLICATIONS_EDIT]);

    const renderApplication = () => {
        if (!applicantData) {
            return null;
        }

        if (!academicYearClosed && canEdit) {
            return (
                <EditApplicationForm
                    institutions={institutions}
                    handleSave={saveApplication}
                    applicationToEdit={applicantData}
                    loading={loading}
                    isAdmin
                />
            );
        } else {
            return <ReadOnlyApplication institutions={institutions} application={applicantData} />;
        }
    };

    const renderProfile = () => {
        if (!profile) {
            return null;
        }

        if (canEdit) {
            return <EditProfileForm profile={profile} loading={loading} handleSave={saveProfile} isAdmin />;
        } else {
            return <ReadOnlyProfile profile={profile} loading={loading} showHeader={false} />;
        }
    };

    const onCloseEligibilityDialog = (reload = true) => {
        setShowEligibility(false);
        if (reload) {
            loadData();
        }
    };

    return (
        <TenantLayout>
            <BackdropLoader open={loading} />
            <Box paddingLeft={1} paddingTop={1}>
                <BackButton location="/applicants" label="Back to Applicants" />
            </Box>
            <Grid container spacing={2} className={classes.applicantPage}>
                <Grid item xs={12} sm={6}>
                    <Paper>{renderApplication()}</Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Paper>{renderProfile()}</Paper>
                </Grid>
            </Grid>
            {!academicYearClosed && canEdit && <ApproveReject onApprove={() => setShowEligibility(true)} />}
            <EligibilityDialog application={applicantData} open={showEligibility} onClose={onCloseEligibilityDialog} />
        </TenantLayout>
    );
};

export default ApplicantPage;
