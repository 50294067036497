import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { navigate } from '@gatsbyjs/reach-router';

import RegisterForm from './form';
import { register } from '../../api/auth';
import { useAuth } from '../../auth/auth-context';
import { ErrorCodes } from '../../common/logic/enums';

export default function RegisterPage() {
    const [loading, setLoading] = useState(false);
    const [showEmailInUse, setShowEmailInUse] = useState(false);
    const [showNotEligible, setShowNotEligible] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { postRegisterLogin } = useAuth();

    async function save(values) {
        try {
            setLoading(true);
            setShowNotEligible(false);
            setShowEmailInUse(false);
            const loginData = (await register(values, true)).data.result;
            postRegisterLogin(loginData);
            navigate('/');
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.code === ErrorCodes.EmailInUse) {
                    enqueueSnackbar('Email is already in use.', { variant: 'error' });
                    setShowEmailInUse(true);
                } else if (err.response.data.error.code === ErrorCodes.NotEligible) {
                    setShowNotEligible(true);
                }
            } else {
                console.error(err.response.data);
            }
        } finally {
            setLoading(false);
        }
    }

    return (
        <div>
            <RegisterForm save={save} loading={loading} showEmailInUse={showEmailInUse} showNotEligible={showNotEligible} />
        </div>
    );
}
