import React from 'react';
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from './auth/auth-context';
import { AppThemeProvider } from './theme/theme-context';

export default function Providers({ children }) {
    return (
        <AppThemeProvider>
            <SnackbarProvider maxSnack={3}>
                <AuthProvider>{children}</AuthProvider>
            </SnackbarProvider>
        </AppThemeProvider>
    );
}
