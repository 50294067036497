import React, { useState, useMemo } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { Form, Field } from 'react-final-form';
import { green } from '@material-ui/core/colors';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import HelpIcon from '@material-ui/icons/Help';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { useSnackbar } from 'notistack';
import Tooltip from '@material-ui/core/Tooltip';

import { OTHER_INSTITUTION_VALUE } from '../../../common/logic/consts';
import * as ApplicationApi from '../../../api/applications';
import ApproveButton from './approveButton';
import RejectButton from './rejectButton';
import { required } from '../../../common/formValidators';
import { FinalFormRadioGroup, FinalFormCheckboxGroup, FinalFormTextField, FinalFormCheckbox, Condition } from '../../../common/formWrappers';

const useStyles = makeStyles((theme) => ({
    eligibilityDialog: {},
    form: {
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    buttonWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    bold: {
        fontWeight: 700
    },
    removeMargin: {
        marginBottom: 0
    },
    mb: {
        marginBottom: theme.spacing(1)
    },
    icon: {
        color: theme.palette.warning.main
    },
    listIcon: {
        minWidth: 40
    }
}));

const eligibleValues = [
    { value: 'true', label: 'Eligible' },
    { value: 'false', label: 'Not eligible' }
];

const notEligibleOptions = [
    { label: 'NOT In foster care for two consecutive years on or after 16th birthday', value: 'notFoster' },
    { label: 'NOT Participating as of 21st birthday', value: 'notParticipating' },
    { label: 'NOT Age 23 or younger within the academic year', value: 'notAge' },
    { label: 'NOT attending CCRI, RIC, or URI FT', value: 'notAttending' }
];

const chafeeNotEligibleOptions = [
    { label: 'NOT Citizen or permanent resident', value: 'notCitizen' },
    { label: 'NOT In foster care on or after 16th birthday', value: 'notFoster' },
    { label: 'NOT Participating as of 21st birthday', value: 'notParticipating' },
    { label: 'NOT Age 23 or younger within the academic year', value: 'notAge' },
    { label: 'NOT Met Significant Academic Progress (SAP) requirement as reported by school', value: 'notSAP' },
    { label: 'NOT At least PT student at any school', value: 'notPT' },
    { label: 'NOT Within 5 year cap', value: 'notFive' }
];

const mapDtoToFederalEligibleCond = (dto) => {
    let cond = [];

    if (!dto) {
        return cond;
    }

    if (dto.citizen) {
        cond.push('notCitizen');
    }
    if (dto.foster) {
        cond.push('notFoster');
    }
    if (dto.participating) {
        cond.push('notParticipating');
    }
    if (dto.age) {
        cond.push('notAge');
    }
    if (dto.sap) {
        cond.push('notSAP');
    }
    if (dto.pt) {
        cond.push('notPT');
    }
    if (dto.five) {
        cond.push('notFive');
    }

    return cond;
};

const mapDtoToStateEligibleCond = (dto) => {
    let cond = [];

    if (!dto) {
        return cond;
    }

    if (dto.foster) {
        cond.push('notFoster');
    }
    if (dto.participating) {
        cond.push('notParticipating');
    }
    if (dto.age) {
        cond.push('notAge');
    }
    if (dto.attending) {
        cond.push('notAttending');
    }

    return cond;
};

const mapStateEligibleCondToDto = (cond) => {
    let dto = {
        foster: false,
        participating: false,
        age: false,
        attending: false
    };

    if (cond.includes('notFoster')) {
        dto.foster = true;
    }
    if (cond.includes('notParticipating')) {
        dto.participating = true;
    }
    if (cond.includes('notAge')) {
        dto.age = true;
    }
    if (cond.includes('notAttending')) {
        dto.attending = true;
    }

    return dto;
};

const mapFederalEligibleCondToDto = (cond) => {
    let dto = {
        citizen: false,
        foster: false,
        participating: false,
        age: false,
        sap: false,
        pt: false,
        five: false
    };

    if (cond.includes('notCitizen')) {
        dto.citizen = true;
    }
    if (cond.includes('notFoster')) {
        dto.foster = true;
    }
    if (cond.includes('notParticipating')) {
        dto.participating = true;
    }
    if (cond.includes('notAge')) {
        dto.age = true;
    }
    if (cond.includes('notSAP')) {
        dto.sap = true;
    }
    if (cond.includes('notPT')) {
        dto.pt = true;
    }
    if (cond.includes('notFive')) {
        dto.five = true;
    }

    return dto;
};

const AcceptCheckbox = ({ meta, input: { name, onChange, value, ...restInput } }) => (
    <FormControl error={Boolean(meta.touched && meta.error)}>
        <FormControlLabel
            control={<Checkbox checked={!!value} onChange={(evt) => onChange(evt.target.checked)} />}
            label="Applicant meets the following criteria:"
        />
        {meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
);

const getEligibleValue = (status) => {
    return status === null ? 'Unknown' : status === true ? 'Eligible' : 'Not Eligible';
};

const getEligibleBoolean = (eligible, adminEligible) => {
    if (eligible !== null) {
        return eligible ? 'true' : 'false';
    }

    if (adminEligible !== null) {
        return adminEligible ? 'true' : 'false';
    }

    return 'true';
};

const EligibilityDialog = ({ application, open, onClose }) => {
    const classes = useStyles();
    const [saving, setSaving] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const renderSaveButton = (stateEligible, federalEligible) => {
        if (stateEligible === 'false' && federalEligible === 'false') {
            return (
                <RejectButton startIcon={<ThumbDownIcon />} type="submit" disabled={saving} height="auto" variant="contained">
                    Reject
                </RejectButton>
            );
        }

        const otherSelected = application.selectedInstitutionId === OTHER_INSTITUTION_VALUE;

        return (
            <Tooltip
                arrow
                placement="top"
                title={
                    otherSelected
                        ? '"Other" was selected for school. Add school to the Institutions list in the Admin section and then change selection for the student.'
                        : ''
                }
            >
                <div>
                    <ApproveButton startIcon={<ThumbUpIcon />} type="submit" disabled={saving || otherSelected} height="auto" variant="contained">
                        Accept
                    </ApproveButton>
                </div>
            </Tooltip>
        );
    };

    const initialValues = useMemo(
        () => ({
            applicationId: application?.id,
            notes: application?.notes,
            stateEligible: getEligibleBoolean(application?.stateEligible, application?.adminStateEligible),
            federalEligible: getEligibleBoolean(application?.federalEligible, application?.adminFederalEligible),
            federalNotEligible: mapDtoToFederalEligibleCond(application?.federalNotEligible),
            stateNotEligible: mapDtoToStateEligibleCond(application?.stateNotEligible),
            stateConfirm: true,
            federalConfirm: true,
            generateWardOfCourt: false
        }),
        [application]
    );

    const save = async (values) => {
        try {
            setSaving(true);
            const dto = {
                ...values,
                federalNotEligible: mapFederalEligibleCondToDto(values.federalNotEligible),
                stateNotEligible: mapStateEligibleCondToDto(values.stateNotEligible)
            };
            await ApplicationApi.approveReject(dto);
            enqueueSnackbar("Student's status updated successfully.", {
                variant: 'success',
                anchorOrigin: { horizontal: 'right', vertical: 'top' }
            });
            setSaving(false);
            onClose();
        } catch (err) {
            console.error(err);
            setSaving(false);
        }
    };

    return (
        <Dialog maxWidth="md" fullWidth open={open} onClose={onClose} disableBackdropClick disableEscapeKeyDown>
            <Form initialValues={initialValues} onSubmit={save} mutators={{ ...arrayMutators }}>
                {({ handleSubmit, values }) => (
                    <form className={classes.form} noValidate onSubmit={handleSubmit}>
                        <DialogTitle>Application Eligibility</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Box mb={1}>
                                        <Typography color="primary" variant="subtitle1">
                                            DCYF Higher Ed Grant Program (State)
                                        </Typography>
                                    </Box>
                                    <Box mb={2}>
                                        <FormLabel>Eligible Status</FormLabel>
                                        <Typography variant="body1">{getEligibleValue(application?.adminStateEligible)}</Typography>
                                    </Box>
                                    <Field
                                        formControlClassName={classes.removeMargin}
                                        required
                                        name="stateEligible"
                                        row
                                        label="Eligibility"
                                        component={FinalFormRadioGroup}
                                        choices={eligibleValues}
                                        validate={required}
                                    />
                                    {values.stateEligible === 'true' && (
                                        <Box mb={1}>
                                            <Field name="stateConfirm" component={AcceptCheckbox} validate={required} />
                                            <List dense>
                                                <ListItem>
                                                    <ListItemIcon className={classes.listIcon}>
                                                        <HelpIcon className={classes.icon} />
                                                    </ListItemIcon>
                                                    <ListItemText primary="In foster care for two consecutive years on or after 16th birthday" />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon className={classes.listIcon}>
                                                        <HelpIcon className={classes.icon} />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Participating as of 21st birthday" />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon className={classes.listIcon}>
                                                        <HelpIcon className={classes.icon} />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Age 23 or younger in academic year" />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon className={classes.listIcon}>
                                                        <HelpIcon className={classes.icon} />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Must be attending CCRI, RIC, or URI FT" />
                                                </ListItem>
                                            </List>
                                        </Box>
                                    )}
                                    {values.stateEligible === 'false' && (
                                        <Box mt={1} mb={1}>
                                            <FieldArray
                                                required
                                                label="Select all that apply"
                                                name="stateNotEligible"
                                                component={FinalFormCheckboxGroup}
                                                options={notEligibleOptions}
                                                validate={required}
                                                highlight
                                            />
                                        </Box>
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    <Box mb={1}>
                                        <Typography color="primary" variant="subtitle1">
                                            Chafee Program (Federal)
                                        </Typography>
                                    </Box>
                                    <Box mb={2}>
                                        <FormLabel>Eligible Status</FormLabel>
                                        <Typography variant="body1">{getEligibleValue(application?.adminFederalEligible)}</Typography>
                                    </Box>
                                    <Field
                                        formControlClassName={classes.removeMargin}
                                        required
                                        name="federalEligible"
                                        row
                                        label="Eligibility"
                                        component={FinalFormRadioGroup}
                                        choices={eligibleValues}
                                        validate={required}
                                    />
                                    {values.federalEligible === 'true' && (
                                        <Box mb={1}>
                                            <Field name="federalConfirm" component={AcceptCheckbox} validate={required} />
                                            <List dense>
                                                <ListItem>
                                                    <ListItemIcon className={classes.listIcon}>
                                                        <HelpIcon className={classes.icon} />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Citizen or permanent resident" />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon className={classes.listIcon}>
                                                        <HelpIcon className={classes.icon} />
                                                    </ListItemIcon>
                                                    <ListItemText primary="In foster case on or after 16th birthday" />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon className={classes.listIcon}>
                                                        <HelpIcon className={classes.icon} />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Participating as of 21st birthday" />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon className={classes.listIcon}>
                                                        <HelpIcon className={classes.icon} />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Age 23 or younger within the academic year" />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon className={classes.listIcon}>
                                                        <HelpIcon className={classes.icon} />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Has met Significant Academic Progress (SAP) requirement as reported by school" />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon className={classes.listIcon}>
                                                        <HelpIcon className={classes.icon} />
                                                    </ListItemIcon>
                                                    <ListItemText primary="At least PT student at any school" />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon className={classes.listIcon}>
                                                        <HelpIcon className={classes.icon} />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Has not hit 5 year cap" />
                                                </ListItem>
                                            </List>
                                        </Box>
                                    )}
                                    {values.federalEligible === 'false' && (
                                        <Box mt={1} mb={1}>
                                            <FieldArray
                                                required
                                                label="Select all that apply"
                                                name="federalNotEligible"
                                                component={FinalFormCheckboxGroup}
                                                options={chafeeNotEligibleOptions}
                                                validate={required}
                                                highlight
                                            />
                                        </Box>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Condition when="stateEligible" is="false">
                                        <Condition when="federalEligible" is="false">
                                            <Field
                                                label="Ward of Court Letter"
                                                name="generateWardOfCourt"
                                                component={FinalFormCheckbox}
                                                caption={<Typography variant="body1">Provide student Ward of Court Letter</Typography>}
                                            />
                                        </Condition>
                                    </Condition>
                                    <Field name="notes" rows={3} fullWidth label="Notes" maxLength={500} component={FinalFormTextField} multiline />
                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => onClose(false)} disabled={saving} variant="outlined">
                                Cancel
                            </Button>
                            <div className={classes.buttonWrapper}>
                                {renderSaveButton(values.stateEligible, values.federalEligible)}
                                {saving && <CircularProgress className={classes.buttonProgress} size={24} />}
                            </div>
                        </DialogActions>
                    </form>
                )}
            </Form>
        </Dialog>
    );
};

export default EligibilityDialog;
