import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { Form, Field } from 'react-final-form';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { green } from '@material-ui/core/colors';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { useSnackbar } from 'notistack';
import { OnChange } from 'react-final-form-listeners';
import Typography from '@material-ui/core/Typography';

import { conformedCurrency, CurrencyMaskInput, parseCurrency } from '../../../../../common/masks';
import * as AwardApi from '../../../../../api/award';
import { getApplicationEnrolledCertifications, updateAwards } from '../../../../../api/certification';
import { FinalFormCheckbox, FinalFormTextField, WhenFieldChanges } from '../../../../../common/formWrappers';
import { useAuth } from '../../../../../auth/auth-context';
import BackdropLoader from '../../../../../common/backdropLoader';
import LoadingButton from '../../../../../common/loadingButton';
import enumHelper from '../../../../../common/logic/enumHelper';
import { EnrollmentStatus, ReviewCondition } from '../../../../../common/logic/enums';
import ConfirmDialog from '../../../../../common/confirmDialog';
import { requiredAllowZero } from '../../../../../common/formValidators';
import { fade } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    updateAwardDialog: {},
    form: {
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    buttonWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    field: {
        marginBottom: 0
        // width: 125
    },
    noMargin: {
        margin: 0
    },
    released: {
        backgroundColor: fade(theme.palette.success.main, 0.1),
        '& input': {
            backgroundColor: '#ffffff'
        }
    },
    lastRow: {
        '& .MuiTableCell-root': {
            border: 'none'
        }
    }
}));

const UpdateAwardDialog = ({ academicYearId, item, open, onClose }) => {
    const { getPeriodName } = useAuth();
    const [balance, setBalance] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showConfirmRelease, setShowConfirmRelease] = useState(false);
    const [saving, setSaving] = useState(false);
    const [data, setData] = useState({ certifications: [] });
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const onSave = async (values, toastMessage, toastError) => {
        const anyMeetConfirmCriteria = values.certifications.some(
            (v) => (!v.stateEligible && v.stateAward) || (!v.federalEligible && v.federalAward)
        );

        if (!showConfirm && anyMeetConfirmCriteria) {
            setShowConfirm(true);
            return;
        }

        try {
            setSaving(true);
            await updateAwards(values.certifications, toastMessage ? true : false);
            setSaving(false);
            enqueueSnackbar(toastMessage ? toastMessage : 'Changes saved successfully', { variant: 'success' });
            onClose();
            setShowConfirm(false);
        } catch (error) {
            console.error(error);
            setSaving(false);
            enqueueSnackbar(toastError ? toastError : 'There was an error when attempting to save awards', { variant: 'error' });
        }
    };

    const getRemainingFederalBalance = (values) => {
        if (!balance) {
            return '';
        }

        const fedAmt = values.certifications.reduce((sum, item) => {
            let amt = parseInt(item.federalAward, 10);
            return sum + (isNaN(amt) ? 0 : amt);
        }, 0);

        return balance.award.federalFunds - balance.federalAwardUsed - fedAmt;
    };

    const getRemainingStateBalance = (values) => {
        if (!balance) {
            return '';
        }

        const fedAmt = values.certifications.reduce((sum, item) => {
            let amt = parseInt(item.stateAward, 10);
            return sum + (isNaN(amt) ? 0 : amt);
        }, 0);

        return balance.award.stateFunds - balance.stateAwardUsed - fedAmt;
    };

    const getRemainingUnmet = (value) => {
        const fed = value.federalAward === '' ? 0 : parseInt(value.federalAward, 10);
        const state = value.stateAward === '' ? 0 : parseInt(value.stateAward, 10);

        return value.unmetNeed - fed - state;
    };

    const handleReleaseClick = async (values) => {
        try {
            await onSave(
                values,
                'Award has been released and Institution has been notified.',
                'There was an error when attempting to release award(s)'
            );
        } finally {
            setShowConfirmRelease(false);
        }
    };

    useEffect(() => {
        const load = async () => {
            try {
                setLoading(true);
                const certs = (await getApplicationEnrolledCertifications(item.applicationId)).data.result;
                const award = (await AwardApi.getAwardBalance(academicYearId)).data.result;

                const fedAmt = certs.reduce((sum, item) => sum + item.federalAward, 0);
                const stateAmt = certs.reduce((sum, item) => sum + item.stateAward, 0);
                award.federalAwardUsed = award.used.federalUsed - fedAmt;
                award.stateAwardUsed = award.used.stateUsed - stateAmt;

                setBalance(award);
                setData({ certifications: certs, totalFederalAward: fedAmt, totalStateAward: stateAmt });
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        if (item !== null) {
            load();
        }
    }, [item]);

    return (
        <Dialog maxWidth="xl" fullWidth open={open} onClose={onClose} disableBackdropClick>
            <BackdropLoader open={loading} />
            <Form onSubmit={(values) => onSave(values)} mutators={{ ...arrayMutators }} initialValues={data}>
                {({ active, form, handleSubmit, values }) => (
                    <form className={classes.form} noValidate onSubmit={handleSubmit}>
                        <DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }} disableTypography>
                            <Typography variant="h6">
                                Edit Award for {item?.firstName} {item?.lastName} {`(${item?.academicStartYear}-${item?.academicEndYear})`}
                            </Typography>
                            {values.certifications.some((c) => !c.released) ? (
                                <Tooltip
                                    title={
                                        values.certifications.some((c) => !c.released && c.needsReview)
                                            ? 'Review conditions before releasing award.'
                                            : ''
                                    }
                                >
                                    <div>
                                        <LoadingButton
                                            variant="contained"
                                            color="primary"
                                            loading={saving}
                                            onClick={() => setShowConfirmRelease(true)}
                                            disabled={values.certifications.some((c) => !c.released && c.needsReview)}
                                        >
                                            Release Award
                                        </LoadingButton>
                                    </div>
                                </Tooltip>
                            ) : null}
                        </DialogTitle>
                        <DialogContent>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ width: 130 }}>Term Name</TableCell>
                                        <TableCell style={{ width: 125 }}>Status</TableCell>
                                        <TableCell>Conditions for Review</TableCell>
                                        <TableCell style={{ width: 125 }}>Unmet Need</TableCell>
                                        <TableCell style={{ width: 125 }}>Term Cost</TableCell>
                                        <TableCell>Breakdown of Cost</TableCell>
                                        <TableCell style={{ width: 125 }}>State Award</TableCell>
                                        <TableCell style={{ width: 125 }}>Federal Award</TableCell>
                                        <TableCell style={{ width: 125 }}>Remaining Unmet</TableCell>
                                        <TableCell style={{ width: 125 }}>Reviewed</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <FieldArray name="certifications">
                                        {({ fields }) =>
                                            fields.map((name, index) => {
                                                const applicationStateEligible = fields.value[index].applicationEligibility.stateEligible;
                                                const applicationFederalEligible = fields.value[index].applicationEligibility.federalEligible;
                                                return (
                                                    <TableRow key={name} className={data.certifications[index].released ? classes.released : ''}>
                                                        <TableCell style={{ maxWidth: 200, textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                                            <span>
                                                                <strong>{fields.value[index].institutionName}</strong> -{' '}
                                                                {getPeriodName(fields.value[index].institutionId, fields.value[index].period)}
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            {enumHelper.getDisplayName(EnrollmentStatus, fields.value[index].enrollmentStatus)}
                                                        </TableCell>
                                                        <TableCell>
                                                            <ul style={{ paddingInlineStart: 15 }}>
                                                                {enumHelper
                                                                    .getBitwiseDisplayNames(ReviewCondition, fields.value[index].reviewConditions)
                                                                    .map((v) => (
                                                                        <li key={v}>{v}</li>
                                                                    ))}
                                                            </ul>
                                                        </TableCell>
                                                        <TableCell>
                                                            {conformedCurrency(fields.value[index].unmetNeed.toString(), { allowDecimal: false })}
                                                        </TableCell>
                                                        <TableCell>
                                                            {conformedCurrency(fields.value[index].termCost.toString(), { allowDecimal: false })}
                                                        </TableCell>
                                                        <TableCell>{fields.value[index].breakdownOfCost}</TableCell>
                                                        <TableCell>
                                                            <WhenFieldChanges
                                                                field={`${name}.federalEligible`}
                                                                becomes={[false]}
                                                                set={`${name}.federalAward`}
                                                                to={0}
                                                            />
                                                            <WhenFieldChanges
                                                                field={`${name}.stateEligible`}
                                                                becomes={[false]}
                                                                set={`${name}.stateAward`}
                                                                to={0}
                                                            />
                                                            {fields.value[index].reviewConditions !== 0 && (
                                                                <Field
                                                                    className={classes.noMargin}
                                                                    name={`${name}.stateEligible`}
                                                                    component={FinalFormCheckbox}
                                                                    caption={<Typography variant="body2">Eligible?</Typography>}
                                                                    disabled={!applicationStateEligible}
                                                                />
                                                            )}
                                                            <Field
                                                                className={classes.field}
                                                                margin="dense"
                                                                name={`${name}.stateAward`}
                                                                component={FinalFormTextField}
                                                                inputComponent={CurrencyMaskInput}
                                                                parse={parseCurrency}
                                                                inputProps={{
                                                                    maskOptions: {
                                                                        allowDecimal: false
                                                                    }
                                                                }}
                                                                validate={requiredAllowZero}
                                                                disabled={!applicationStateEligible}
                                                            />
                                                            <OnChange name={`${name}.stateAward`}>
                                                                {(value) => {
                                                                    if (active !== `${name}.stateAward`) {
                                                                        return;
                                                                    }

                                                                    if (!value) {
                                                                        form.change('totalStateAward', 0);
                                                                    }

                                                                    let total = 0;
                                                                    for (let i = 0; i < values.certifications.length; i++) {
                                                                        let newVal = 0;
                                                                        if (index === i) {
                                                                            newVal += parseInt(value, 10);
                                                                        } else {
                                                                            newVal += parseInt(values.certifications[i].stateAward, 10);
                                                                        }
                                                                        total += isNaN(newVal) ? 0 : newVal;
                                                                    }

                                                                    form.change('totalStateAward', total);
                                                                }}
                                                            </OnChange>
                                                        </TableCell>
                                                        <TableCell>
                                                            {fields.value[index].reviewConditions !== 0 && (
                                                                <Field
                                                                    className={classes.noMargin}
                                                                    name={`${name}.federalEligible`}
                                                                    component={FinalFormCheckbox}
                                                                    caption={<Typography variant="body2">Eligible?</Typography>}
                                                                    disabled={!applicationFederalEligible}
                                                                />
                                                            )}
                                                            <Field
                                                                className={classes.field}
                                                                margin="dense"
                                                                name={`${name}.federalAward`}
                                                                component={FinalFormTextField}
                                                                inputComponent={CurrencyMaskInput}
                                                                parse={parseCurrency}
                                                                inputProps={{
                                                                    maskOptions: {
                                                                        allowDecimal: false
                                                                    }
                                                                }}
                                                                validate={requiredAllowZero}
                                                                disabled={!applicationFederalEligible}
                                                            />
                                                            <OnChange name={`${name}.federalAward`}>
                                                                {(value) => {
                                                                    if (active !== `${name}.federalAward`) {
                                                                        return;
                                                                    }

                                                                    let total = 0;
                                                                    for (let i = 0; i < values.certifications.length; i++) {
                                                                        let newVal = 0;
                                                                        if (index === i) {
                                                                            newVal += parseInt(value, 10);
                                                                        } else {
                                                                            newVal += parseInt(values.certifications[i].federalAward, 10);
                                                                        }
                                                                        total += isNaN(newVal) ? 0 : newVal;
                                                                    }

                                                                    form.change('totalFederalAward', total);
                                                                }}
                                                            </OnChange>
                                                        </TableCell>
                                                        <TableCell>
                                                            {conformedCurrency(getRemainingUnmet(fields.value[index]).toString(), {
                                                                allowDecimal: false,
                                                                allowNegative: true
                                                            })}
                                                        </TableCell>
                                                        <TableCell>
                                                            {fields.value[index].reviewConditions !== 0 && (
                                                                <Field
                                                                    name={`${name}.needsReview`}
                                                                    component={FinalFormCheckbox}
                                                                    format={(val) => (val === true ? false : true)}
                                                                    parse={(val) => (val === true ? false : true)}
                                                                />
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        }
                                    </FieldArray>
                                    <TableRow>
                                        <TableCell colSpan={6} align="right">
                                            <strong>Total Award</strong>
                                        </TableCell>
                                        <TableCell>
                                            <Field
                                                className={classes.field}
                                                margin="dense"
                                                name="totalStateAward"
                                                component={FinalFormTextField}
                                                inputComponent={CurrencyMaskInput}
                                                parse={parseCurrency}
                                                inputProps={{
                                                    maskOptions: {
                                                        allowDecimal: false
                                                    }
                                                }}
                                                disabled={
                                                    data.certifications.length !== 0
                                                        ? !data.certifications[0].applicationEligibility.stateEligible
                                                        : true
                                                }
                                            />
                                            <OnChange name="totalStateAward">
                                                {(value) => {
                                                    if (value === undefined || value === null) {
                                                        return;
                                                    }

                                                    if (active !== 'totalStateAward') {
                                                        return;
                                                    }

                                                    const fieldCount = values.certifications.reduce((acc, item) => {
                                                        if (item.reviewConditions !== 0 && !item.stateEligible) {
                                                            return acc;
                                                        }

                                                        return acc + 1;
                                                    }, 0);
                                                    const intValue = parseInt(value, 10);
                                                    const valuePerField = isNaN(intValue) ? 0 : Math.floor(intValue / fieldCount);

                                                    for (let i = 0; i < values.certifications.length; i++) {
                                                        if (
                                                            values.certifications[i].reviewConditions === 0 ||
                                                            values.certifications[i].stateEligible
                                                        ) {
                                                            form.change(`certifications[${i}].stateAward`, valuePerField);
                                                        }
                                                    }
                                                }}
                                            </OnChange>
                                        </TableCell>
                                        <TableCell>
                                            <Field
                                                className={classes.field}
                                                margin="dense"
                                                name="totalFederalAward"
                                                component={FinalFormTextField}
                                                inputComponent={CurrencyMaskInput}
                                                parse={parseCurrency}
                                                inputProps={{
                                                    maskOptions: {
                                                        allowDecimal: false
                                                    }
                                                }}
                                                disabled={
                                                    data.certifications.length !== 0
                                                        ? !data.certifications[0].applicationEligibility.federalEligible
                                                        : true
                                                }
                                            />
                                            <OnChange name="totalFederalAward">
                                                {(value) => {
                                                    if (value === undefined || value === null) {
                                                        return;
                                                    }

                                                    if (active !== 'totalFederalAward') {
                                                        return;
                                                    }

                                                    const fieldCount = values.certifications.reduce((acc, item) => {
                                                        if (item.reviewConditions !== 0 && !item.federalEligible) {
                                                            return acc;
                                                        }

                                                        return acc + 1;
                                                    }, 0);
                                                    const intValue = parseInt(value, 10);
                                                    const valuePerField = isNaN(intValue) ? 0 : Math.floor(intValue / fieldCount);

                                                    for (let i = 0; i < values.certifications.length; i++) {
                                                        if (
                                                            values.certifications[i].reviewConditions === 0 ||
                                                            values.certifications[i].federalEligible
                                                        ) {
                                                            form.change(`certifications[${i}].federalAward`, valuePerField);
                                                        }
                                                    }
                                                }}
                                            </OnChange>
                                        </TableCell>
                                        <TableCell colSpan={2}></TableCell>
                                    </TableRow>
                                    <TableRow className={classes.lastRow}>
                                        <TableCell colSpan={6} align="right">
                                            <strong>Remaining Balance</strong>
                                        </TableCell>
                                        <TableCell>
                                            {conformedCurrency(getRemainingStateBalance(values).toString(), {
                                                allowDecimal: false,
                                                allowNegative: true
                                            })}
                                        </TableCell>
                                        <TableCell>
                                            {conformedCurrency(getRemainingFederalBalance(values).toString(), {
                                                allowDecimal: false,
                                                allowNegative: true
                                            })}
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose} disabled={saving}>
                                Cancel
                            </Button>
                            <LoadingButton type="submit" variant="contained" color="primary" loading={saving}>
                                Save
                            </LoadingButton>
                        </DialogActions>
                        <ConfirmDialog
                            confirmAction={() => onSave(values)}
                            open={showConfirm}
                            handleClose={() => setShowConfirm(false)}
                            title="Are you sure?"
                            body="You have award amount(s) set with an ineligible designation, this will result in a zero award amount.  Are you sure you wish to save?"
                            confirmActionText="Yes, Save"
                        />
                        <ConfirmDialog
                            confirmAction={() => handleReleaseClick(values)}
                            open={showConfirmRelease}
                            handleClose={() => setShowConfirmRelease(false)}
                            title="Release Award(s)?"
                            body="Are you sure you are ready to release the award(s)? Once released the institutions will be notified and certification will be enabled."
                            confirmActionText="Yes, Release Award(s)"
                        />
                    </form>
                )}
            </Form>
        </Dialog>
    );
};

export default UpdateAwardDialog;
