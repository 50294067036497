import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CustomDataGrid from '../../../../common/customDataGrid';
import { renderAmount } from './common';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    refundsList: {}
}));

const sortModel = [{ field: 'paymentId', sort: 'asc' }];

const RefundsList = ({ data, loading, onShowRefundReceived }) => {
    const classes = useStyles();

    const columns = [
        {
            field: 'amount',
            type: 'number',
            headerName: 'Amount',
            renderCell: renderAmount,
            flex: 1
        },
        { field: 'paymentId', type: 'string', headerName: 'Payment ID', flex: 1 },
        {
            field: 'actions',
            headerName: 'Action',
            width: 160,
            sortable: false,
            renderCell: ({ row }) => (
                <Button variant="contained" color="primary" size="small" onClick={() => onShowRefundReceived(row)}>
                    Receive Refund
                </Button>
            )
        }
    ];

    return data.length > 0 ? (
        <div>
            <Box mb={0.5}>
                <Typography variant="h4">Refunds</Typography>
            </Box>
            <CustomDataGrid
                className={classes.refundsList}
                coloredHeader
                numberColLeftAligned
                columns={columns}
                disableColumnMenu
                disableColumnFilter
                rows={data}
                initialSortModel={sortModel}
                loading={loading}
                initialPageSize={5}
                rowsPerPageOptions={[5]}
                rowHeight={35}
                hideFooterSelectedRowCount
                disableSelectionOnClick
            />
        </div>
    ) : null;
};

export default RefundsList;
