import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import FileSaver from 'file-saver';

import b64toBlob from '../../../common/logic/b64toBlob';
import ImportStatus from './importStatus';
import SimpleDropzone from '../../../common/simpleDropzone';
import { ErrorDialog } from '../../../common/errorDialog';
import ConfirmDialog from '../../../common/confirmDialog';
import { GroupType } from '../../../common/logic/enums';
import useChunkUpload from '../../../common/fileUpload/useChunkUpload';
import * as EligibleStudentApi from '../../../api/eligibleStudent';
import ToolbarSearchInput from '../../../common/toolbarSearchInput';
import { maskedSsn } from '../../../common/logic/string';
import CustomDataGrid from '../../../common/customDataGrid';
import { dateFormatterUtc } from '../../../common/logic/grid';

const useStyles = makeStyles((theme) => ({
    eligibleStudents: {
        padding: theme.spacing(0, 2)
    },
    tableTitle: {
        flexGrow: 1
    }
}));

const eligibilityFormatter = ({ value }) => (value ? 'Yes' : 'No');

const ssnFormatter = ({ value }) => maskedSsn(value);

const sortModel = [{ field: 'firstName', sort: 'asc' }];

export default function EligibleStudentsList() {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [importing, setImporting] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [importStatus, setImportStatus] = useState(null);
    const [selectedStudent, setSelectedStudent] = useState({ firstName: '', lastName: '' });
    const [searchText, setSearchText] = useState('');
    const classes = useStyles();

    const onUploadComplete = async (document) => {
        try {
            setImporting(true);
            const data = (await EligibleStudentApi.importFile(document.id)).data.result;
            setImportStatus(data);
            setShowErrorDialog(true);
            await loadData();
        } finally {
            setImporting(false);
        }
    };

    const getTemplate = async () => {
        try {
            setIsLoading(true);
            const template = (await EligibleStudentApi.getTemplate()).data.result;
            const blob = b64toBlob(template.data, template.mimeType);
            FileSaver.saveAs(blob, template.fileName);
        } finally {
            setIsLoading(false);
        }
    };

    const loadData = async () => {
        setIsLoading(true);
        try {
            const items = (await EligibleStudentApi.getAll()).data.result.items;
            setData(items);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        const formattedSocial = searchText.replace(/[^0-9]/g, '');
        const newData = data.filter(
            (d) =>
                d.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
                d.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
                (formattedSocial && d.ssn.includes(formattedSocial))
        );
        setFilteredData(newData);
    }, [data, searchText]);

    const deleteStudent = async () => {
        setShowConfirm(false);
        await EligibleStudentApi.remove(selectedStudent.id);
        await loadData();
        enqueueSnackbar('Student removed successfully.', { variant: 'success' });
    };

    const onDropRejected = () => {
        enqueueSnackbar('File type is not supported. Please only choose a single Excel (.xlsx) file.');
    };
    const { onDrop, files } = useChunkUpload(GroupType.Pdf, null, onUploadComplete, null, null);
    const uploadDisabled = Boolean(files.length > 0 || importing);

    const columns = [
        { field: 'firstName', headerName: 'First Name', flex: 1 },
        { field: 'lastName', headerName: 'Last Name', flex: 1 },
        { field: 'ssn', headerName: 'SSN', flex: 1, valueFormatter: ssnFormatter },
        { field: 'birthDate', headerName: 'Birthday', flex: 1, valueFormatter: dateFormatterUtc },
        { field: 'stateEligible', headerName: 'State Eligible?', flex: 1, valueFormatter: eligibilityFormatter },
        { field: 'federalEligible', headerName: 'Federal Eligible?', flex: 1, valueFormatter: eligibilityFormatter },
        {
            field: 'actions',
            headerName: 'Action',
            width: 125,
            sortable: false,
            renderCell: ({ row }) => (
                <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => {
                        setSelectedStudent(row);
                        setShowConfirm(true);
                    }}
                    startIcon={<DeleteIcon className={classes.leftIcon} fontSize="small" />}
                >
                    Delete
                </Button>
            )
        }
    ];

    return (
        <div className={classes.eligibleStudents}>
            <div>
                <Box display="flex" justifyContent="space-between" marginBottom={1}>
                    <Typography variant="h6">Import Eligible Students</Typography>
                    <Button color="primary" variant="contained" onClick={getTemplate}>
                        Download Template
                    </Button>
                </Box>
                <SimpleDropzone
                    onDrop={onDrop}
                    onDropRejected={onDropRejected}
                    accept=".xlsx"
                    maxFiles={1}
                    disabled={uploadDisabled}
                    label="Drag Excel (.xlsx) file here to upload, or click to select file"
                />
            </div>
            <Box p={1} color="secondary.contrastText" bgcolor="secondary.main">
                <Toolbar variant="dense">
                    <Box display="flex" alignItems="center">
                        <Typography className={classes.tableTitle} variant="h6">
                            Eligible Students
                        </Typography>
                        <ToolbarSearchInput value={searchText} onChange={(val) => setSearchText(val)} />
                    </Box>
                </Toolbar>
            </Box>
            <CustomDataGrid
                disableColumnMenu
                disableColumnFilter
                initialSortModel={sortModel}
                disableSelectionOnClick
                rows={filteredData}
                rowHeight={75}
                columns={columns}
                initialPageSize={25}
                rowsPerPageOptions={[25, 50, 100]}
                loading={isLoading}
            />
            <ConfirmDialog
                confirmAction={deleteStudent}
                open={showConfirm}
                handleClose={() => setShowConfirm(false)}
                title="Delete Eligible Student?"
                body={`Are you sure you wish to delete ${selectedStudent.firstName} ${selectedStudent.lastName}?`}
            />
            {importStatus && (
                <ErrorDialog
                    message="Import Complete"
                    isShowing={showErrorDialog}
                    hide={() => setShowErrorDialog(false)}
                    details={<ImportStatus status={importStatus} />}
                />
            )}
        </div>
    );
}
