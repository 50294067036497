import React, { useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Form, Field } from 'react-final-form';
import { useSnackbar } from 'notistack';

import { FinalFormTextField } from '../formWrappers';
import { composeValidators, email, maxLength, required } from '../formValidators';
import LoadingButton from '../loadingButton';
import * as AccountApi from '../../api/account';

const useStyles = makeStyles((theme) => ({
    changeEmailDialog: {},
    form: {
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column'
    }
}));

const ChangeEmailDialog = ({ open, onClose }) => {
    const [isSaving, setSaving] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const handleSave = async (values) => {
        try {
            setSaving(true);
            await AccountApi.requestChangEmailAddress(values.emailAddress);
            enqueueSnackbar(`Email sent to ${values.emailAddress} with a link to verify your new email address.`, { variant: 'success' });
            onClose();
        } catch (ex) {
        } finally {
            setSaving(false);
        }
    };

    return (
        <Dialog open={open} scroll="paper" maxWidth="sm" fullWidth disableBackdropClick>
            <DialogTitle>Change Email</DialogTitle>
            <Form onSubmit={handleSave}>
                {({ handleSubmit }) => (
                    <form className={classes.form} noValidate onSubmit={handleSubmit}>
                        <DialogContent>
                            <Field
                                name="emailAddress"
                                placeholder="email@address.com"
                                margin="dense"
                                maxLength="256"
                                required
                                fullWidth
                                label="New Email Address"
                                id="emailAddress"
                                component={FinalFormTextField}
                                validate={composeValidators(required, email, maxLength(256))}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isSaving} onClick={onClose} color="primary">
                                Cancel
                            </Button>
                            <LoadingButton type="submit" loading={isSaving} variant="contained" color="primary">
                                Save
                            </LoadingButton>
                        </DialogActions>
                    </form>
                )}
            </Form>
        </Dialog>
    );
};

export default ChangeEmailDialog;
