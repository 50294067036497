import withStyles from '@material-ui/styles/withStyles';
import { fade } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const RejectButton = withStyles((theme) => ({
    outlined: {
        color: theme.palette.error.main,
        border: `1px solid ${fade(theme.palette.error.main, 0.5)}`,
        '&:hover': {
            border: `1px solid ${theme.palette.error.main}`,
            backgroundColor: fade(theme.palette.error.main, theme.palette.action.hoverOpacity)
        },
        height: (props) => props.height,
        padding: (props) => props.padding
    },
    contained: {
        color: theme.palette.error.contrastText,
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.dark,
            '@media (hover: none)': {
                backgroundColor: theme.palette.error.main
            }
        }
    }
}))(Button);

export default RejectButton;
