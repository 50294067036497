import React, { useContext } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import { useAuth } from './auth/auth-context';
import HostMain from './host/main';
import TenantMain from './tenant';
import ErrorInterceptor from './common/errorInterceptor';
import PageLoader from './common/pageLoader';
import { AppThemeContext } from './theme/theme-context';
import useLogoutWhenIdle from './useLogoutWhenIdle';

function App() {
    const { loading, loggingIn, tenant, isImpersonating, impersonateLoading, stopImpersonating } = useAuth();

    const { fetching } = useContext(AppThemeContext);

    const isLoading = loading && !loggingIn;
    const isHost = tenant === null;

    function renderSecuredPage() {
        return isHost ? <HostMain /> : <TenantMain />;
    }

    function snackbarAction() {
        return (
            <Button color="secondary" size="small" onClick={() => stopImpersonating()} variant="contained">
                Stop Impersonating
            </Button>
        );
    }

    useLogoutWhenIdle(15);

    return fetching ? (
        <PageLoader />
    ) : (
        <div className="App">
            <React.Fragment>
                <CssBaseline />
                {isLoading || impersonateLoading ? <PageLoader /> : renderSecuredPage()}
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={isImpersonating}>
                    <SnackbarContent message="Currently Impersonating" action={snackbarAction()} />
                </Snackbar>
                <ErrorInterceptor />
            </React.Fragment>
        </div>
    );
}

export default App;
