import React, { useState } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { useSnackbar } from 'notistack';

import { useAuth } from '../../../auth/auth-context';
import { resendVerificationEmail, updatePrimaryEmailAddress } from '../../../api/account';
import ChangeEmailDialog from './dialogs/changeEmail';

import Logo from '../../../assets/dcyf-logo.png';
import LogoSmall from '../../../assets/dcyf-logo-small.png';
import TenantLayout from '../../tenantLayout';
import { APP_SETTING_NAME_CONTACTS } from '../../../common/logic/consts';

const useStyles = makeStyles((theme) => ({
    logo: {
        maxWidth: 500
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(6, 6, 4),
        marginTop: theme.spacing(4),
        width: 750,
        marginBottom: theme.spacing(2),
        [theme.breakpoints.only('xs')]: {
            padding: theme.spacing(1),
            margin: theme.spacing(1, 1, 0)
        }
    },
    bodyText: {
        padding: theme.spacing(4),
        [theme.breakpoints.only('xs')]: {
            padding: theme.spacing(1)
        }
    },
    text: {
        fontWeight: 300,
        fontSize: 24,
        marginBottom: theme.spacing(3)
    },
    center: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    copyright: {
        marginTop: theme.spacing(3)
    }
}));

export default function NotVerifiedPage() {
    const classes = useStyles();
    const { user, settings, updateUserEmail } = useAuth();
    const [loading, setLoading] = useState(false);
    const [showUpdateEmail, setShowUpdateEmail] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.only('xs'));
    const contactSetting = settings.find((s) => s.name === APP_SETTING_NAME_CONTACTS).value;

    const resendVerification = async () => {
        try {
            setLoading(true);
            const emailAddress = user.emailAddress;
            await resendVerificationEmail(emailAddress);
            enqueueSnackbar(`A new verification email has been sent.`, { variant: 'success' });
        } finally {
            setLoading(false);
        }
    };

    const handleUpdateEmail = async (values) => {
        try {
            setLoading(true);
            await updatePrimaryEmailAddress(values.emailAddress);
            updateUserEmail(values.emailAddress);
            setShowUpdateEmail(false);
            enqueueSnackbar(`A new verification email has been sent to ${values.emailAddress.toLowerCase()}`, { variant: 'success' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <TenantLayout>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Paper className={classes.paper}>
                    <div style={{ alignSelf: 'center' }}>
                        <img className={classes.logo} src={isXS ? LogoSmall : Logo} alt="DCYF Logo" />
                    </div>
                    <Typography variant="h5" style={{ alignSelf: 'center' }}>
                        Welcome!
                    </Typography>
                    <div className={classes.bodyText}>
                        <Typography variant="body1" className={classes.text}>
                            Thanks for signing up! We sent an email to <strong>{user.emailAddress}</strong> that contains a link to complete your
                            verification. Please take a quick moment to verify your email in order to continue to the application.
                        </Typography>
                        <Typography variant="body1" className={classes.text}>
                            Check your Spam or Junk folders if you can’t find the email. We can also resend it! If you still need assistance, please
                            call: {contactSetting?.primary?.phoneNumber}
                        </Typography>
                    </div>
                    <div className={classes.center}>
                        <Button variant="outlined" color="primary" onClick={() => setShowUpdateEmail(true)} disabled={loading}>
                            Update Email
                        </Button>
                        <Button variant="outlined" color="primary" onClick={resendVerification} disabled={loading}>
                            Resend Email
                        </Button>
                    </div>

                    {showUpdateEmail && (
                        <ChangeEmailDialog
                            open={showUpdateEmail}
                            onClose={() => setShowUpdateEmail(false)}
                            onOk={handleUpdateEmail}
                            loading={loading}
                            initialEmailAddress={user.emailAddress}
                        />
                    )}
                </Paper>
            </div>
        </TenantLayout>
    );
}
