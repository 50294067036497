import React from 'react';
import { Field, FormSpy } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

const WhenFieldChanges = ({ field, becomes, set, to, also }) => (
    <Field name={set} subscription={{}}>
        {(
            // No subscription. We only use Field to get to the change function
            { input: { onChange } }
        ) => (
            <FormSpy subscription={{}}>
                {({ form }) => (
                    <OnChange name={field}>
                        {(value) => {
                            if (becomes === undefined) {
                                onChange(to);
                                if (also) {
                                    also();
                                }
                            } else if (becomes.includes && becomes.includes(value)) {
                                onChange(to);
                                if (also) {
                                    also();
                                }
                            } else if (value === becomes) {
                                onChange(to);
                                if (also) {
                                    also();
                                }
                            }
                        }}
                    </OnChange>
                )}
            </FormSpy>
        )}
    </Field>
);

export default WhenFieldChanges;
