import React from 'react';
import Debug from '../../../common/debugPage';
import LeftNavLayout from '../../leftNavLayout';

export default function DebugPage() {
    return (
        <LeftNavLayout>
            <Debug />
        </LeftNavLayout>
    );
}
