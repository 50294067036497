import React, { useEffect, useMemo, useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import SinglePaperLayout from '../../../singlePaperLayout';
import TransactionsList from './transactionsList';
import InstitutionsList from './institutionsList';
import RefundsList from './refundsList';
import useFinancials from './useFinancials';
import Button from '@material-ui/core/Button';
import AcademicYearSelector from '../../../../common/academicYearSelector';
import { useAuth } from '../../../../auth/auth-context';
import { ROLE_NAME_FINANCE, ROLE_NAME_INSTITUTION } from '../../../../common/logic/consts';
import SubmitDialog from './dialogs/submit';
import { TransactionType } from '../../../../common/logic/enums';
import AdjustmentDialog from './dialogs/adjustment';

const INITIAL_TRANSACTION_PAGINATION = { page: 0, pageSize: 25 };
const DEFAULT_TRANSACTIONS_SORTING = { field: 'creationTime', sort: 'desc' };

const useStyles = makeStyles((theme) => ({
    button: {
        marginBottom: theme.spacing(1)
    }
}));

const FinancialsPage = () => {
    const classes = useStyles();
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedInstitutionId, setSelectedInstitutionId] = useState(null);
    const [transactionToView, setTransactionToView] = useState(null);
    const [transactionsPagination, setTransactionPagination] = useState(INITIAL_TRANSACTION_PAGINATION);
    const [sortModel, setSortModel] = useState([DEFAULT_TRANSACTIONS_SORTING]);
    const [showAdjustment, setShowAdjustment] = useState(false);
    const [refundToReceive, setRefundToReceive] = useState(null);
    const [showSubmitPaymentOrRefund, setShowSubmitPaymentOrRefund] = useState(false);
    const { user } = useAuth();

    const sortModelString = useMemo(() => {
        if (!sortModel || sortModel.length === 0) {
            return `${DEFAULT_TRANSACTIONS_SORTING.field} ${DEFAULT_TRANSACTIONS_SORTING.sort}`;
        } else {
            return `${sortModel[0].field} ${sortModel[0].sort}`;
        }
    }, [sortModel]);

    const {
        loading,
        saving,
        reload,
        getParentTransaction,
        createOrUpdatePaymentRefund,
        data: { transactions, institutionBalances, refunds, transactionsRowCount }
    } = useFinancials({
        selectedInstitutionId,
        selectedYear,
        transactionsPageNumber: transactionsPagination.page,
        transactionsResultsPerPage: transactionsPagination.pageSize,
        transactionsSorting: sortModelString
    });

    useEffect(() => {
        if (institutionBalances.length === 1) {
            setSelectedInstitutionId(institutionBalances[0].id);
        } else {
            setSelectedInstitutionId(null);
        }
    }, [selectedYear, institutionBalances]);

    const handleInstitutionRowSelected = (params) => {
        setSelectedInstitutionId(params.row.id);
        setTransactionPagination((prev) => ({ ...prev, page: 0 }));
    };

    const handleShowRefundReceived = (refund) => {
        setRefundToReceive(refund);
        setShowSubmitPaymentOrRefund(true);
    };

    const handleSubmitPaymentOrRefund = async (values) => {
        const input = { ...values, institutionId: selectedInstitutionId, academicYearId: selectedYear };
        input.transactions = values.transactions.filter((t) => t.selected).map((t) => t.id);

        try {
            await createOrUpdatePaymentRefund(input);
            setShowSubmitPaymentOrRefund(false);
            reload();
        } catch {}
    };

    const handleAddAdjustment = async (values) => {
        try {
            await createOrUpdatePaymentRefund({ ...values, institutionId: selectedInstitutionId, academicYearId: selectedYear });
            setShowAdjustment(false);
            reload();
        } catch {}
    };

    const handleViewTransaction = async (id) => {
        const transaction = await getParentTransaction(id);
        setTransactionToView(transaction);
        setShowSubmitPaymentOrRefund(true);
    };

    const handleCloseSubmitPaymentOrRefund = () => {
        setTransactionToView(null);
        setRefundToReceive(null);
        setShowSubmitPaymentOrRefund(false);
    };

    const getSubmitDialogType = () => {
        if (Boolean(transactionToView)) {
            return TransactionType.Update;
        } else if (Boolean(refundToReceive)) {
            return TransactionType.Refund;
        } else if (user.roleName === ROLE_NAME_INSTITUTION) {
            return TransactionType.PendingRefund;
        } else {
            return TransactionType.Payment;
        }
    };

    const submitPaymentTooltip =
        selectedInstitutionId && institutionBalances.find((i) => i.id === selectedInstitutionId)?.amount <= 0
            ? 'No balance due'
            : !selectedInstitutionId
            ? 'Select an institution'
            : '';

    const submitRefundTooltip =
        selectedInstitutionId && institutionBalances.find((i) => i.id === selectedInstitutionId)?.amount >= 0
            ? 'No refund due'
            : refunds.length > 0
            ? 'Already a pending refund'
            : !selectedInstitutionId
            ? 'Select an institution'
            : '';

    const anyLoading = loading.institutions || loading.refunds || loading.transactions;

    return (
        <SinglePaperLayout>
            <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography variant="h6">Financials</Typography>
                <AcademicYearSelector value={selectedYear} onChange={(val) => setSelectedYear(val)} />
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={5} xl={4}>
                    <InstitutionsList
                        data={institutionBalances}
                        loading={loading.institutions}
                        onRowSelected={handleInstitutionRowSelected}
                        selectedInstitutionId={selectedInstitutionId}
                    />
                </Grid>

                <Grid item xs={2}>
                    <Box display="flex" flexDirection="column" justifyContent="center" mt={3}>
                        {(user.roleName === ROLE_NAME_FINANCE || user.roleName === ROLE_NAME_INSTITUTION) && (
                            <Tooltip arrow title={user.roleName === ROLE_NAME_FINANCE ? submitPaymentTooltip : submitRefundTooltip} placement="top">
                                <div>
                                    <Button
                                        className={classes.button}
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={() => setShowSubmitPaymentOrRefund(true)}
                                        disabled={
                                            anyLoading ||
                                            (user.roleName === ROLE_NAME_FINANCE ? Boolean(submitPaymentTooltip) : Boolean(submitRefundTooltip))
                                        }
                                    >
                                        {`Submit a ${user.roleName === ROLE_NAME_FINANCE ? 'Payment' : 'Refund'}`}
                                    </Button>
                                </div>
                            </Tooltip>
                        )}
                        {user.roleName === ROLE_NAME_FINANCE && (
                            <Button
                                className={classes.button}
                                variant="outlined"
                                color="primary"
                                onClick={() => setShowAdjustment(true)}
                                disabled={!Boolean(selectedInstitutionId)}
                            >
                                Add Adjustment
                            </Button>
                        )}
                    </Box>
                </Grid>

                {user.roleName === ROLE_NAME_FINANCE && (
                    <Grid item xs={5} xl={4}>
                        <RefundsList data={refunds} loading={loading.refunds} onShowRefundReceived={handleShowRefundReceived} />
                    </Grid>
                )}
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <TransactionsList
                        rows={transactions}
                        rowCount={transactionsRowCount}
                        loading={loading.transactions}
                        loadingTransactionId={loading.transactionId}
                        onViewTransaction={handleViewTransaction}
                        initialSortModel={sortModel}
                        pagination={transactionsPagination}
                        onPageChange={(params) => setTransactionPagination((prev) => ({ ...prev, page: params }))}
                        onSortModelChange={(params) => setSortModel(params)}
                        onPageSizeChange={(params) => setTransactionPagination((prev) => ({ ...prev, pageSize: params }))}
                    />
                </Grid>
            </Grid>
            {showSubmitPaymentOrRefund && (
                <SubmitDialog
                    open={showSubmitPaymentOrRefund}
                    onClose={handleCloseSubmitPaymentOrRefund}
                    onSave={handleSubmitPaymentOrRefund}
                    type={getSubmitDialogType()}
                    selectedInstitution={institutionBalances.find((i) => i.id === selectedInstitutionId)}
                    selectedYear={selectedYear}
                    transaction={transactionToView}
                    refund={refundToReceive}
                    isSaving={saving}
                />
            )}
            <AdjustmentDialog
                open={showAdjustment}
                balance={institutionBalances.find((i) => i.id === selectedInstitutionId)?.amount}
                isSaving={saving}
                onSave={handleAddAdjustment}
                onClose={() => setShowAdjustment(false)}
            />
        </SinglePaperLayout>
    );
};

export default FinancialsPage;
