import axios from 'axios';
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import * as TransactionApi from '../../../../api/transactions';
import { TransactionType } from '../../../../common/logic/enums';

const useFinancials = ({ selectedInstitutionId, selectedYear, transactionsSorting, transactionsPageNumber, transactionsResultsPerPage }) => {
    const [loading, setLoading] = useState({ institutions: false, refunds: false, transactions: false });
    const [saving, setSaving] = useState(false);
    const [reloadData, setReloadData] = useState(true);
    const [transactions, setTransactions] = useState([]);
    const [transactionsRowCount, setTransactionsRowCount] = useState(0);
    const [institutionBalances, setInstitutionBalances] = useState([]);
    const [refunds, setRefunds] = useState([]);

    const { enqueueSnackbar } = useSnackbar();

    //console.log(transactionsSorting, transactionsPageNumber);

    //get institution balances
    useEffect(() => {
        const institutionTokenSource = axios.CancelToken.source();

        (async () => {
            try {
                if (selectedYear) {
                    setLoading((prev) => ({ ...prev, institutions: true }));
                    const institutionsResponse = await TransactionApi.getInstitutionBalances(selectedYear, institutionTokenSource.token);
                    setInstitutionBalances(institutionsResponse.data.result);
                }
            } catch {
            } finally {
                setLoading((prev) => ({ ...prev, institutions: false }));
            }
        })();

        return () => {
            institutionTokenSource.cancel();
        };
    }, [reloadData, selectedYear]);

    //get refunds
    useEffect(() => {
        const refundTokenSource = axios.CancelToken.source();

        (async () => {
            try {
                if (selectedYear) {
                    if (selectedInstitutionId) {
                        setLoading((prev) => ({ ...prev, refunds: true }));
                        const refundsResponse = await TransactionApi.getPendingRefunds(selectedInstitutionId, selectedYear, refundTokenSource.token);
                        setRefunds(refundsResponse.data.result);
                    } else {
                        setRefunds([]);
                    }
                }
            } catch {
            } finally {
                setLoading((prev) => ({ ...prev, refunds: false }));
            }
        })();

        return () => {
            refundTokenSource.cancel();
        };
    }, [reloadData, selectedYear, selectedInstitutionId]);

    //get transactions
    useEffect(() => {
        const transactionTokenSource = axios.CancelToken.source();

        (async () => {
            try {
                if (selectedYear) {
                    if (selectedInstitutionId) {
                        setLoading((prev) => ({ ...prev, transactions: true }));
                        const transactionsResponse = await TransactionApi.getAll(
                            selectedInstitutionId,
                            selectedYear,
                            null,
                            transactionTokenSource.token,
                            transactionsSorting,
                            transactionsPageNumber,
                            transactionsResultsPerPage
                        );

                        setTransactionsRowCount(transactionsResponse.data.result.totalCount);
                        setTransactions(transactionsResponse.data.result.items);
                    } else {
                        setTransactionsRowCount(0);
                        setTransactions([]);
                    }
                }
            } catch {
            } finally {
                setLoading((prev) => ({ ...prev, transactions: false }));
            }
        })();

        return () => {
            transactionTokenSource.cancel();
        };
    }, [reloadData, selectedYear, selectedInstitutionId, transactionsPageNumber, transactionsResultsPerPage, transactionsSorting]);

    const reload = () => {
        setReloadData((prev) => !prev);
    };

    const getParentTransaction = async (id) => {
        try {
            setLoading((prev) => ({ ...prev, transactionId: id }));
            const response = await TransactionApi.getParentTransactionWithChildTransactions(id);
            return response.data.result;
        } catch {
            return null;
        } finally {
            setLoading((prev) => ({ ...prev, transactionId: undefined }));
        }
    };

    const createOrUpdatePaymentRefund = async (data) => {
        try {
            setSaving(true);
            if (data.type === TransactionType.Update) {
                await TransactionApi.updateNotes(data.id, data.paymentDate, data.notes);
                enqueueSnackbar(`Changes saved successfully`, { variant: 'success' });
            } else if (data.type === TransactionType.Refund) {
                await TransactionApi.receiveRefund(data.id, data.paymentDate, data.notes);
                enqueueSnackbar(`Refund updated and saved successfully`, { variant: 'success' });
            } else {
                await TransactionApi.addPaymentRefundOrAdjustment(data);
                const type = data.type === TransactionType.PendingRefund ? 'Refund' : 'Payment';
                enqueueSnackbar(`${type} saved successfully`, { variant: 'success' });
            }
        } catch (ex) {
            enqueueSnackbar('Failed to save transaction', { variant: 'error' });
            throw new Error();
        } finally {
            setSaving(false);
        }
    };

    return {
        loading,
        saving,
        reload,
        getParentTransaction,
        createOrUpdatePaymentRefund,
        data: {
            transactions,
            institutionBalances,
            refunds,
            transactionsRowCount
        }
    };
};

export default useFinancials;
