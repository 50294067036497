import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import FileSaver from 'file-saver';
import { useSnackbar } from 'notistack';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';

import SocialSecurityToggle from '../../../../../common/socialSecurityToggle';
import * as ApplicationApi from '../../../../../api/applications';
import { useAuth } from '../../../../../auth/auth-context';
import b64toBlob from '../../../../../common/logic/b64toBlob';
import enumHelper from '../../../../../common/logic/enumHelper';
import * as DocumentApi from '../../../../../api/document';
import { APP_SETTING_NAME_AGREEMENTS } from '../../../../../common/logic/consts';
import { Grade, AgreementType } from '../../../../../common/logic/enums';
import ViewProfileDialog from './viewProfileDialog';

const useStyles = makeStyles((theme) => ({
    summaryCard: {
        alignItems: 'center',
        display: 'inline-flex',
        alignSelf: 'flex-start',
        justifyContent: 'space-between',
        //width: 450,
        padding: theme.spacing(1)
    },
    agreementLink: {
        cursor: 'pointer'
    },
    name: {
        color: theme.palette.header.main
    },
    inputMarginDense: {
        padding: theme.spacing(0.5, 0.6)
    },
    sizeSmallEdit: {
        width: 24,
        height: 24,
        marginLeft: theme.spacing(0.5)
    }
}));

const SummaryCard = ({ applicationId }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [showProfile, setShowProfile] = useState(false);
    const [editingCollegeId, setEditingCollegeId] = useState(false);
    const [collegeIdValue, setCollegeIdValue] = useState('');
    const [canEditCollegeId, setCanEditCollegeId] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { user, settings } = useAuth();
    const agreements = settings.find((s) => s.name === APP_SETTING_NAME_AGREEMENTS)?.value;

    useEffect(() => {
        const load = async () => {
            try {
                setLoading(true);
                const result = (await ApplicationApi.getApplicationProfileSummary(applicationId)).data.result;
                setData(result);
                setCanEditCollegeId(result.institutionId === user.institution?.id);
                setCollegeIdValue(result.collegeId === null ? '' : result.data);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        load();
    }, [applicationId]);

    const handleDownloadAgreement = async (type) => {
        let agreement;
        switch (type) {
            case AgreementType.Funds:
                agreement = agreements.funds;
                break;
            case AgreementType.Records:
                agreement = agreements.records;
                break;
            case AgreementType.FAQS:
                agreement = agreements.faqs;
                break;
            default:
                console.error('unsupported agreement type');
                break;
        }

        try {
            const file = (await DocumentApi.getData(agreement.id)).data.result;
            const blob = b64toBlob(file.data, file.mimeType);
            FileSaver.saveAs(blob, file.fileName);
        } catch (ex) {
            enqueueSnackbar('Error downloading document', { variant: 'error' });
            console.error(ex);
        }
    };

    const downloadWardOfCourt = async () => {
        try {
            const file = (await DocumentApi.getData(data.wardOfCourtLetter)).data.result;
            const blob = b64toBlob(file.data, file.mimeType);
            FileSaver.saveAs(blob, file.fileName);
        } catch (ex) {
            enqueueSnackbar('Error downloading document', { variant: 'error' });
            console.error(ex);
        }
    };

    const handleSaveCollegeId = async () => {
        try {
            await ApplicationApi.updateCollegeId(applicationId, collegeIdValue === '' ? null : collegeIdValue);
            // setReloadData((prev) => !prev);
            setData((prev) => ({ ...prev, collegeId: collegeIdValue }));
            setEditingCollegeId(false);
        } catch (ex) {
            console.error(ex);
        }
    };

    return (
        <Paper className={classes.summaryCard}>
            <Box>
                {loading && (
                    <>
                        <Skeleton variant="text" width={200} height={30} />
                        <Skeleton variant="text" width={150} />
                        <Skeleton variant="text" width={150} />
                        <Skeleton variant="text" width={100} />
                        <Skeleton variant="text" width={175} />
                    </>
                )}
                {data && (
                    <>
                        <Typography className={classes.name} variant="h6">
                            {data.name}
                        </Typography>
                        <SocialSecurityToggle value={data.ssn} />
                        <Typography variant="body1">DOB: {data.birthDate ? moment(data.birthDate).utc().format('M/D/YYYY') : ''}</Typography>
                        <Typography variant="body1">{enumHelper.getDisplayName(Grade, data.currentGrade)}</Typography>
                        <Typography variant="body1">{data.institution}</Typography>
                        {data.collegeId && !editingCollegeId ? (
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1">
                                    College Id: <strong>{data.collegeId}</strong>
                                </Typography>
                                {canEditCollegeId && (
                                    <IconButton
                                        aria-label="edit-collegeid"
                                        onClick={() => setEditingCollegeId(true)}
                                        size="small"
                                        color="primary"
                                        classes={{ sizeSmall: classes.sizeSmallEdit }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                )}
                            </Box>
                        ) : editingCollegeId ? (
                            <Box display="flex">
                                <OutlinedInput
                                    style={{ width: 150 }}
                                    classes={{ inputMarginDense: classes.inputMarginDense }}
                                    placeholder="College Id"
                                    margin="dense"
                                    onChange={(e) => setCollegeIdValue(e.target.value)}
                                    value={collegeIdValue}
                                    inputProps={{ maxLength: 20 }}
                                />
                                <IconButton aria-label="save-collegeid" onClick={handleSaveCollegeId} size="small" color="primary">
                                    <CheckIcon />
                                </IconButton>
                            </Box>
                        ) : canEditCollegeId ? (
                            <Button color="primary" variant="text" onClick={() => setEditingCollegeId(true)} size="small">
                                Add College ID
                            </Button>
                        ) : null}
                    </>
                )}
            </Box>
            <Box pl={1} pr={1} alignSelf="stretch">
                <Divider orientation="vertical" />
            </Box>
            <Box alignSelf="stretch" display="flex" flexDirection="column" alignItems="flex-end" justifyContent="center">
                <Box display="flex" alignSelf="center" mb={1}>
                    <Button variant="outlined" size="small" color="primary" onClick={() => setShowProfile(true)}>
                        View Profile
                    </Button>
                </Box>
                <Box>
                    <Link className={classes.agreementLink} onClick={() => handleDownloadAgreement(AgreementType.Funds)}>
                        <Box display="flex" alignItems="center">
                            <GetAppIcon fontSize="small" /> Use of Funds
                        </Box>
                    </Link>
                    <Link className={classes.agreementLink} onClick={() => handleDownloadAgreement(AgreementType.Records)}>
                        <Box display="flex" alignItems="center">
                            <GetAppIcon fontSize="small" /> Access to Records
                        </Box>
                    </Link>
                    <Link className={classes.agreementLink} onClick={() => handleDownloadAgreement(AgreementType.FAQS)}>
                        <Box display="flex" alignItems="center">
                            <GetAppIcon fontSize="small" /> FAQS
                        </Box>
                    </Link>
                    <Link className={classes.agreementLink} onClick={downloadWardOfCourt}>
                        <Box display="flex" alignItems="center">
                            <GetAppIcon fontSize="small" /> Ward of Court Letter
                        </Box>
                    </Link>
                </Box>
            </Box>
            <ViewProfileDialog userId={data?.userId} open={showProfile} onClose={() => setShowProfile(false)} />
        </Paper>
    );
};

export default SummaryCard;
