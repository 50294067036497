import React from 'react';
import ThemeEditor from './editor';
import LeftNavLayout from '../../leftNavLayout';

export default function ThemePage() {
    return (
        <LeftNavLayout>
            <ThemeEditor />
        </LeftNavLayout>
    );
}
