import React, { useState, useEffect } from 'react';
import axios from 'axios';
import makeStyles from '@material-ui/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Form } from 'react-final-form';
import { useSnackbar } from 'notistack';

import * as SettingApi from '../../../../api/setting';
import Contact, { ContactSkeleton } from './contact';
import EditContact from './editContact';
import LoadingButton from '../../../../common/loadingButton';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    contacts: {
        display: 'flex',
        flexDirection: 'column'
    },
    content: {
        padding: theme.spacing(0, 2, 2),
        flex: 1
    },
    cancelButton: {
        marginRight: theme.spacing(1)
    }
}));

const Contacts = () => {
    const classes = useStyles();
    const [contacts, setContacts] = useState({});
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [editing, setEditing] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const tokenSource = axios.CancelToken.source();
        (async () => {
            try {
                setLoading(true);
                const data = (await SettingApi.getContacts(tokenSource.token)).data.result;
                setContacts(data);
            } catch (ex) {
                enqueueSnackbar('Error fetching contacts', { variant: 'error' });
                console.error(ex);
            } finally {
                setLoading(false);
            }
        })();

        return () => {
            tokenSource.cancel();
        };
    }, []);

    const handleSubmit = async (values) => {
        if (editing) {
            try {
                setSaving(true);
                await SettingApi.updateContacts(values);
            } catch (ex) {
            } finally {
                setEditing(false);
                setSaving(false);
            }
        } else {
            setEditing(true);
        }
    };

    const handleCancel = (form) => {
        setEditing(false);
        form.reset();
    };

    return (
        <Paper className={classes.contacts}>
            <Form onSubmit={(values) => handleSubmit(values)} initialValues={contacts}>
                {(props) => (
                    <form className={classes.form} noValidate onSubmit={props.handleSubmit}>
                        <Box display="flex" alignItems="center" p={2} justifyContent="space-between">
                            <Typography variant="h6">Contacts</Typography>
                            <Box display="flex">
                                {editing && (
                                    <Button
                                        className={classes.cancelButton}
                                        color="primary"
                                        variant="outlined"
                                        disabled={saving}
                                        onClick={() => handleCancel(props.form)}
                                    >
                                        Cancel
                                    </Button>
                                )}
                                <LoadingButton color="primary" variant="contained" type="submit" loading={saving}>
                                    {editing ? 'Save' : 'Edit'}
                                </LoadingButton>
                            </Box>
                        </Box>
                        <div className={classes.content}>
                            {loading ? (
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <ContactSkeleton />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ContactSkeleton />
                                    </Grid>
                                </Grid>
                            ) : !editing ? (
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Contact label="primary" contact={props.values.primary} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Contact label="secondary" contact={props.values.secondary} />
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <EditContact label="primary" isRequired />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <EditContact label="secondary" />
                                    </Grid>
                                </Grid>
                            )}
                        </div>
                    </form>
                )}
            </Form>
        </Paper>
    );
};

export default Contacts;
