import React, { useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import LeftNavLayout from '../../leftNavLayout';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import FileSaver from 'file-saver';
import InfoIcon from '@material-ui/icons/Info';

import b64toBlob from '../../../common/logic/b64toBlob';
import AcademicYearSelector from '../../../common/academicYearSelector';
import * as ReportApi from '../../../api/report';
import BackdropLoader from '../../../common/backdropLoader';
import ExcelIcon from '../../../common/icons/excelIcon';
import { conformedCurrency } from '../../../common/masks';

const useStyles = makeStyles((theme) => ({
    grantSummary: {},
    leftMargin: {
        marginLeft: theme.spacing(1)
    },
    data: {
        border: '1px solid rgba(224, 224, 224)',
        borderRadius: theme.shape.borderRadius
    },
    bold: {
        fontWeight: 'bold'
    }
}));

const GrantSummary = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [academicYear, setAcademicYear] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    const getData = async () => {
        try {
            setLoading(true);
            const result = (await ReportApi.getGrantSummaryData(academicYear)).data.result;
            setData(result);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.error(err);
            enqueueSnackbar('There was an error retrieving report data. Please try again later.', { variant: 'error' });
        }
    };

    const getExcel = async () => {
        try {
            setLoading(true);
            const file = (await ReportApi.runGrantSummaryReport()).data.result;
            const blob = b64toBlob(file.data, file.mimeType);
            FileSaver.saveAs(blob, file.fileName);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.error(err);
            enqueueSnackbar('There was an error retrieving report data. Please try again later.', { variant: 'error' });
        }
    };

    const renderDataItem = (label, value) => {
        return (
            <Box mb={1}>
                <FormLabel>{label}</FormLabel>
                <Typography variant="body2" className={classes.bold}>
                    {value}
                </Typography>
            </Box>
        );
    };

    const renderDataTable = () => {
        if (!data) {
            return (
                <Box minHeight={150} className={classes.data} p={1} display="flex" justifyContent="center" alignItems="center">
                    <InfoIcon color="primary" fontSize="small" />
                    <Box className={classes.leftMargin}>Run Report To See Data</Box>
                </Box>
            );
        }

        return (
            <Box className={classes.data} p={1} display="flex">
                <Box flex={1} mr={1}>
                    {renderDataItem('Application Year', data.applicationYear)}
                    {renderDataItem('# of Students applied', data.applied)}
                    {renderDataItem('# of Students ineligible', data.ineligible)}
                    {renderDataItem('# of Students eligible', data.eligible)}
                </Box>
                <Box flex={1} mr={1}>
                    {renderDataItem('# of Students not certified', data.notCertified)}
                    {renderDataItem('# of Students awarded', data.awarded)}
                </Box>
                <Box flex={1}>
                    {renderDataItem('# of Students awarded State grants', data.stateGrantsAwarded)}
                    {renderDataItem('# of Students awarded Federal grants', data.federalGrantsAwarded)}
                    {renderDataItem('Total Award Amount - State', conformedCurrency(data.stateTotal.toString()))}
                    {renderDataItem('Total Award Amount - Federal', conformedCurrency(data.federalTotal.toString()))}
                </Box>
            </Box>
        );
    };

    return (
        <LeftNavLayout>
            <div className={classes.grantSummary}>
                <BackdropLoader open={loading} />
                <Box
                    paddingTop={1}
                    paddingBottom={1}
                    paddingLeft={4}
                    paddingRight={4}
                    color="secondary.contrastText"
                    bgcolor="secondary.main"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box display="flex" alignItems="center">
                        <Typography variant="h6">Grant Summary</Typography>
                        <AcademicYearSelector
                            inTableHeader
                            value={academicYear}
                            onChange={(val) => setAcademicYear(val)}
                            className={classes.leftMargin}
                        />
                        <Button variant="contained" color="primary" className={classes.leftMargin} onClick={getData}>
                            Run Report
                        </Button>
                    </Box>
                    <Button variant="contained" color="primary" startIcon={<ExcelIcon />} onClick={getExcel}>
                        Export All Years to Excel
                    </Button>
                </Box>
                {renderDataTable()}
            </div>
        </LeftNavLayout>
    );
};

export default GrantSummary;
