import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { useAuth } from '../../../../auth/auth-context';
import SinglePaperLayout from '../../../singlePaperLayout';
import BackButton from '../../../../common/backButton';
import SummaryCard from './components/summaryCard';
import CertifyStudent from './components/certifyStudent';

const CertifyPage = ({ applicationId }) => {
    const { user } = useAuth();

    return (
        <SinglePaperLayout>
            <Box mb={2}>
                <BackButton location="/scholarship/certify" label="Back to Students List" />
            </Box>
            <Box mb={1}>
                <Typography variant="h6">Certify Eligibility{user.institution ? ` (${user.institution.name})` : undefined}</Typography>
            </Box>
            <SummaryCard applicationId={applicationId} />
            <Box mt={2}>
                <Divider />
            </Box>
            <CertifyStudent applicationId={applicationId} />
        </SinglePaperLayout>
    );
};

export default CertifyPage;
