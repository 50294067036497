import axios from 'axios';

export function getAll(sorting = '', skipCount = 0, maxResultCount = 100, cancelToken) {
    return axios.get('/api/services/app/Institution/GetAll', { params: { sorting, skipCount, maxResultCount }, cancelToken });
}

export function get(id) {
    return axios.get('/api/services/app/Institution/Get', { params: { id } });
}

export function create(input) {
    return axios.post('/api/services/app/Institution/Create', input);
}

export function update(input) {
    return axios.put('/api/services/app/Institution/Update', input);
}

export function remove(id) {
    return axios.delete('/api/services/app/Institution/Delete', { params: { id } });
}
