import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/styles/makeStyles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { navigate } from '@gatsbyjs/reach-router';
import clsx from 'clsx';
import FilledInput from '@material-ui/core/FilledInput';
import FileSaver from 'file-saver';

import * as ApplicationApi from '../../../api/applications';
import AcademicYearSelector from '../../../common/academicYearSelector';
import YearSummary from './yearSummary';
import { fade } from '@material-ui/core';
import CustomDataGrid from '../../../common/customDataGrid';
import { dateFormatter, dateFormatterUtc } from '../../../common/logic/grid';
import ExcelIcon from '../../../common/icons/excelIcon';
import b64toBlob from '../../../common/logic/b64toBlob';

const useStyles = makeStyles((theme) => ({
    title: {
        marginRight: theme.spacing(3)
    },
    selectFilter: {
        marginLeft: theme.spacing(2),
        minWidth: 200
    },
    formControl: {
        minWidth: 120,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25)
        }
    },
    underline: {
        '&:after': {
            borderBottom: `2px solid ${theme.palette.secondary.contrastText}`
        }
    },
    selectInput: {
        color: theme.palette.secondary.contrastText
    },
    selectLabel: {
        color: theme.palette.secondary.contrastText,
        '&.Mui-focused': {
            color: theme.palette.secondary.contrastText,
            fontWeight: 700
        }
    }
}));

const sortModel = [{ field: 'status', sort: 'desc' }];

const ApplicantList = () => {
    const classes = useStyles();
    const [selectedYear, setSelectedYear] = useState('');
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [status, setStatus] = useState('All');
    const [summary, setSummary] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const tokenSource = axios.CancelToken.source();

        const load = async () => {
            try {
                setLoading(true);
                const data = (await ApplicationApi.getSummary(tokenSource.token)).data.result;
                setSummary(data);
            } catch {
            } finally {
                setLoading(false);
            }
        };

        load();

        return () => {
            tokenSource.cancel();
        };
    }, []);

    useEffect(() => {
        const tokenSource = axios.CancelToken.source();
        const load = async () => {
            try {
                setLoading(true);
                const data = (await ApplicationApi.getByAcademicYear(selectedYear, tokenSource.token)).data.result;
                setRows(data);
            } catch {
            } finally {
                setLoading(false);
            }
        };

        if (selectedYear !== '') {
            load();
        }

        return () => {
            tokenSource.cancel();
        };
    }, [selectedYear]);

    useEffect(() => {
        setFilteredRows(rows.filter((i) => status === 'All' || i.status === status));
    }, [rows, status]);

    const columns = [
        { field: 'lastName', headerName: 'Last Name', flex: 1 },
        { field: 'firstName', headerName: 'First Name', flex: 1 },
        { field: 'birthDate', headerName: 'DOB', type: 'date', flex: 1, valueFormatter: dateFormatterUtc },
        { field: 'selectedInstitution', headerName: 'Selected Institution', flex: 2 },
        { field: 'status', headerName: 'Status', flex: 1 },
        { field: 'statusUpdated', headerName: 'Status Updated', flex: 1, valueFormatter: dateFormatter },
        { field: 'eligibleGrants', headerName: 'Eligible Grants', flex: 1 },
        {
            field: 'view',
            headerName: 'View',
            width: 100,
            sortable: false,
            renderCell: (params) => (
                <Button variant="contained" color="primary" size="small" onClick={() => navigate(`/applicants/${params.row['id']}`)}>
                    View
                </Button>
            )
        }
    ];

    const handleExportToExcel = async () => {
        try {
            setLoading(true);
            const file = (await ApplicationApi.exportApplicants(selectedYear, null)).data.result;
            const blob = b64toBlob(file.data, file.mimeType);
            FileSaver.saveAs(blob, file.fileName);
        } catch {
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Box p={1} display="flex" justifyContent="space-between" color="secondary.contrastText" bgcolor="secondary.main">
                <Toolbar variant="dense" style={{ flex: 1 }}>
                    <Box display="flex" flex={1} alignItems="center" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                            <Typography className={classes.title} variant="h6">
                                Applicants
                            </Typography>
                            <AcademicYearSelector value={selectedYear} onChange={(val) => setSelectedYear(val)} inTableHeader />
                            <FormControl className={clsx(classes.selectFilter, classes.formControl)} margin="dense">
                                <InputLabel variant="filled" id="status-filter" className={classes.selectLabel}>
                                    Filter Status
                                </InputLabel>
                                <Select
                                    value={status}
                                    onChange={(evt) => setStatus(evt.target.value)}
                                    id="status-filter"
                                    input={<FilledInput classes={{ underline: classes.underline, input: classes.selectInput }} />}
                                >
                                    <MenuItem value="All">All Statuses</MenuItem>
                                    <MenuItem value="In Progress">In Progress</MenuItem>
                                    <MenuItem value="Submitted">Submitted</MenuItem>
                                    <MenuItem value="Accepted">Accepted</MenuItem>
                                    <MenuItem value="Rejected">Rejected</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Tooltip arrow title={filteredRows.length === 0 ? 'No Data' : ''}>
                            <div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleExportToExcel}
                                    startIcon={<ExcelIcon />}
                                    disabled={loading || filteredRows.length === 0}
                                >
                                    Export to Excel
                                </Button>
                            </div>
                        </Tooltip>
                    </Box>
                </Toolbar>
                <YearSummary summary={summary} />
            </Box>
            <CustomDataGrid
                disableColumnMenu
                disableColumnFilter
                initialSortModel={sortModel}
                disableSelectionOnClick
                rows={filteredRows}
                columns={columns}
                initialPageSize={25}
                loading={loading}
                rowsPerPageOptions={[25, 50, 100]}
            />
        </div>
    );
};

export default ApplicantList;
