import axios from 'axios';

export function updateNotes(transactionId, paymentDate, notes, cancelToken) {
    return axios.put('/api/services/app/Transaction/UpdateNotes', { transactionId, paymentDate, notes }, cancelToken);
}

export function addPaymentRefundOrAdjustment(input, cancelToken) {
    return axios.post('/api/services/app/Transaction/AddPaymentRefundOrAdjustment', input, cancelToken);
}

export function getAll(
    institutionId,
    academicYearId,
    hideCleared,
    cancelToken,
    transactionsSorting = 'paymentDate DESC',
    transactionsPageNumber = 0,
    transactionsResultsPerPage = 9999
) {
    return axios.get('/api/services/app/Transaction/GetAll', {
        params: {
            institutionId,
            academicYearId,
            hideCleared,
            sorting: transactionsSorting,
            skipCount: transactionsPageNumber * transactionsResultsPerPage,
            maxResultCount: transactionsResultsPerPage
        },
        cancelToken
    });
}

export function getPendingRefunds(institutionId, academicYearId, cancelToken) {
    return axios.get('/api/services/app/Transaction/GetPendingRefunds', { params: { institutionId, academicYearId }, cancelToken });
}

export function getParentTransactionWithChildTransactions(id) {
    return axios.get('/api/services/app/Transaction/GetParentTransactionWithChildTransactions', { params: { id } });
}

export function getInstitutionBalances(id, cancelToken) {
    return axios.get('/api/services/app/Transaction/GetInstitutionBalances', { params: { id }, cancelToken });
}

export function receiveRefund(transactionId, receivedDate, notes, cancelToken) {
    return axios.post('/api/services/app/Transaction/ReceiveRefund', { transactionId, receivedDate, notes }, cancelToken);
}
