import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import useTheme from '@material-ui/styles/useTheme';
import { ColorPickerType } from './colorPickerType';
import ThemeControl from './control';
import ThemePreview from './preview';
import { getDefaultThemeWithOptions } from '../../../theme/theme-context';

import './editor.css';

export const ThemeType = {
    Light: 'light',
    Dark: 'dark'
};

const ThemeEditor = () => {
    const theme = useTheme();
    const [headerColor, setHeaderColor] = useState(theme.palette.header.main);
    const [primaryColor, setPrimaryColor] = useState(theme.palette.primary.main);
    const [secondaryColor, setSecondaryColor] = useState(theme.palette.secondary.main);
    const [colorType, setColorType] = useState(ColorPickerType.none);
    const [themeType, setThemeType] = useState(theme.palette.type);
    const [anchorEl, setAnchorEl] = useState(null);
    function handleColorClick(type, e) {
        setAnchorEl(e.currentTarget);
        setColorType(type);
    }

    function handleClose() {
        setAnchorEl(null);
        setColorType(ColorPickerType.none);
    }

    function handleChangeColor(color) {
        switch (colorType) {
            case ColorPickerType.primaryColor:
                setPrimaryColor(color.hex);
                break;
            case ColorPickerType.secondaryColor:
                setSecondaryColor(color.hex);
                break;
            case ColorPickerType.headerColor:
                setHeaderColor(color.hex);
                break;
            default:
                break;
        }
    }

    function handleChangeThemeType(type) {
        setThemeType(type);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <Paper>
                    <ThemeControl
                        colorType={colorType}
                        themeType={themeType}
                        primaryColor={primaryColor}
                        secondaryColor={secondaryColor}
                        headerColor={headerColor}
                        onColorClick={handleColorClick}
                        onChangeColor={handleChangeColor}
                        onChangeThemeType={handleChangeThemeType}
                        onColorClose={handleClose}
                        colorPickerAnchor={anchorEl}
                    />
                </Paper>
            </Grid>
            <Grid item xs={8}>
                <ThemeProvider theme={getDefaultThemeWithOptions({ themeType, headerColor, primaryColor, secondaryColor })}>
                    <Paper>
                        <ThemePreview />
                    </Paper>
                </ThemeProvider>
            </Grid>
        </Grid>
    );
};

export default ThemeEditor;
