export const shortenStringWithEllipsis = (string, length) => {
    const str = string.substr(0, length);
    const indexOfLastSpace = str.lastIndexOf(' ');
    return str.substr(0, indexOfLastSpace) + '...';
};

export const maskedSsn = (val) => {
    const numbers = val.replace(/[^0-9]/g, '');
    if (numbers.length === 9) {
        return `***-**-${numbers.substr(5, 4)}`;
    }
    return val;
};
