import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import ContentWrapper from '../studentApplications/contentWrapper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ReadOnlyCheckbox from './readOnlyCheckbox';
import ApplicationStatusChip from '../../../common/applicationStatusChip';
import { OTHER_INSTITUTION_VALUE } from '../../../common/logic/consts';
import enumHelper from '../../../common/logic/enumHelper';
import { Grade, EnrollmentStatus } from '../../../common/logic/enums';

const useStyles = makeStyles((theme) => ({
    readOnlyApplication: {},
    value: {
        fontWeight: 700
    },
    margin: {
        margin: theme.spacing(1, 0)
    },
    subtext: {
        fontSize: '0.75rem',
        marginBottom: theme.spacing(0.5)
    }
}));

const ReadOnlyApplication = ({ application, institutions }) => {
    const classes = useStyles();
    const institution = institutions.find((i) => i.value === application.selectedInstitutionId);

    return (
        <ContentWrapper applicationToEdit={application}>
            <Grid container spacing={1} className={classes.margin}>
                <Grid item sm={4} xs={12}>
                    <Typography variant="body1">Current Grade</Typography>
                    <Typography variant="body1" className={classes.value}>
                        {enumHelper.getDisplayName(Grade, application.currentGrade)}
                    </Typography>
                </Grid>
                <Grid item sm={4} xs={12}>
                    <Typography variant="body1">Selected Institution</Typography>
                    <Typography variant="body1" className={classes.value}>
                        {institution ? institution.label : ''}
                    </Typography>
                </Grid>
                <Grid item sm={4} xs={12}>
                    {application.selectedInstitutionId === OTHER_INSTITUTION_VALUE && (
                        <>
                            <Typography variant="body1">Other</Typography>
                            <Typography variant="body1" className={classes.value}>
                                {application.otherInstitution}
                            </Typography>
                        </>
                    )}
                </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.margin}>
                <Grid item sm={6} xs={12}>
                    <Typography variant="body1">School ID Number</Typography>
                    <Typography variant="body1" className={classes.value}>
                        {application.schoolIdentificationNumber}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.margin}>
                <Grid item sm={6} xs={12}>
                    <Typography variant="body1">Enrollment Status</Typography>
                    <Typography variant="body1" className={classes.value}>
                        {enumHelper.getDisplayName(EnrollmentStatus, application.enrollmentStatus)}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.margin}>
                <Grid item sm={12} xs={12}>
                    <Typography variant="body2">
                        <strong>I agree to the following:</strong>
                    </Typography>
                    <Typography className={classes.subtext} variant="body2">
                        (Note: You must download and review each agreement before checkbox is enabled.)
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.margin}>
                <Grid item sm={4} xs={12}>
                    <ReadOnlyCheckbox checked={application.useOfFunds} label="Use of Funds Agreement" />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <ReadOnlyCheckbox checked={application.accessToRecords} label="Access to Records Agreement" />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <ReadOnlyCheckbox checked={application.hasReadFAQs} label="Reading the FAQs" />
                </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.margin}>
                <Grid item xs={12}>
                    <ApplicationStatusChip status={application.applicationStatus} />
                </Grid>
            </Grid>
        </ContentWrapper>
    );
};

export default ReadOnlyApplication;
