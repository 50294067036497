import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    root: {
        color: '#ffffff',
        fontWeight: 300,
        fontSize: 16
    }
}));

export default function Copyright() {
    const classes = useStyles();
    const year = new Date().getFullYear();

    return <Typography className={classes.root}>Copyright © Trilix {year}</Typography>;
}
