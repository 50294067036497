import React from 'react';
import HostLayout from '../../hostLayout';
import CommonChangePasswordPage from '../../../common/changePassword';

export default function ChangePasswordPage() {
    return (
        <HostLayout>
            <CommonChangePasswordPage />
        </HostLayout>
    );
}
