import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useAuth } from '../../../auth/auth-context';
import { conformedCurrency } from '../../../common/masks';
import enumHelper from '../../../common/logic/enumHelper';
import { ReviewCondition } from '../../../common/logic/enums';

const useStyles = makeStyles((theme) => ({
    payoutsCard: {
        margin: theme.spacing(0, 0, 2),
        padding: theme.spacing(1),
        alignSelf: 'stretch',
        backgroundColor: '#EFEFEF'
    },
    payout: {
        display: 'flex',
        flexDirection: 'column'
    },
    payoutMeta: {
        display: 'flex'
    },
    conditions: {}
}));

const PayoutsCard = ({ payouts }) => {
    const classes = useStyles();
    const { getPeriodName } = useAuth();

    return payouts.length !== 0 ? (
        <Paper className={classes.payoutsCard}>
            {payouts.map((payout, index) => {
                return (
                    <div key={payout.period} className={classes.payout}>
                        <Typography variant="subtitle2">{getPeriodName(payout.institutionId, payout.period)}</Typography>
                        <div className={classes.payoutMeta}>
                            <Typography variant="body2">
                                Federal Eligible: {payout.federalEligible ? 'Yes' : 'No'} - {conformedCurrency(payout.federalAward.toString())}
                            </Typography>
                        </div>

                        {payout.reviewConditions !== 0 && (
                            <>
                                <Typography variant="body2">Conditions:</Typography>
                                <Typography variant="body2" component="ul" className={classes.conditions}>
                                    {enumHelper.getBitwiseDisplayNames(ReviewCondition, payout.reviewConditions).map((condition, index) => (
                                        <Typography variant="caption" component="li" key={index}>
                                            {condition}
                                        </Typography>
                                    ))}
                                </Typography>
                            </>
                        )}

                        <div className={classes.payoutMeta}>
                            <Typography variant="body2">
                                State Eligible: {payout.federalEligible ? 'Yes' : 'No'} - {conformedCurrency(payout.stateAward.toString())}
                            </Typography>
                        </div>
                        {index !== payouts.length - 1 && <Box mb={1} />}
                    </div>
                );
            })}
        </Paper>
    ) : null;
};

export default PayoutsCard;
