import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import makeStyles from '@material-ui/styles/makeStyles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import clsx from 'clsx';
import { Tooltip, Zoom } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    margin: {
        marginBottom: theme.spacing(1)
    },
    label: {
        marginBottom: theme.spacing(1)
    },
    required: {
        color: 'red'
    },
    checkboxContainer: {
        alignSelf: 'flex-start',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    cbLabel: {
        // flex: '0 0 50%'
    },
    checkbox: {
        padding: theme.spacing(1)
    }
}));

const FinalFormCheckbox = ({
    className,
    containerClassName,
    checkboxClassName,
    tooltipPlacement,
    tooltipContent = '',
    label,
    labelClassName,
    caption,
    required,
    input: { name, onChange, value, ...restInput },
    fullWidth,
    meta,
    ...rest
}) => {
    const classes = useStyles();
    const [showTooltip, setShowTooltip] = React.useState(false);

    return (
        <FormControl className={clsx(classes.margin, className)} fullWidth={fullWidth} error={meta.error && meta.touched}>
            {label && (
                <FormLabel required={required} className={clsx(classes.label, labelClassName)} classes={{ asterisk: classes.required }}>
                    {label}
                </FormLabel>
            )}
            <div
                className={clsx(classes.checkboxContainer, containerClassName)}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                <FormControlLabel
                    value={name}
                    label={caption}
                    control={
                        <Checkbox
                            icon={
                                <Tooltip arrow title={tooltipContent} open={showTooltip} placement={tooltipPlacement} TransitionComponent={Zoom}>
                                    <CheckBoxOutlineBlankIcon />
                                </Tooltip>
                            }
                            classes={{ root: clsx(classes.checkbox, checkboxClassName) }}
                            {...rest}
                            name={name}
                            onChange={(event) => onChange(event.target.checked)}
                            value={name}
                            checked={!!value}
                            required={required}
                        />
                    }
                />
            </div>
            {meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
    );
};

export default FinalFormCheckbox;
