import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    loader: { background: 'transparent', boxShadow: 'none' },
    progress: { color: '#ffffff' }
}));

const PageLoader = () => {
    const classes = useStyles();
    return (
        <Dialog open={true} classes={{ paper: classes.loader }}>
            <DialogContent>
                <Container>
                    <CircularProgress classes={{ colorPrimary: classes.progress }} />
                </Container>
            </DialogContent>
        </Dialog>
    );
};

export default PageLoader;
