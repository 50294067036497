import axios from 'axios';

export function addOrUpdate(values) {
    return axios.post('/api/services/app/Award/AddOrUpdate', values);
}

export function get(academicYearId) {
    return axios.get('/api/services/app/Award/Get', { params: { id: academicYearId } });
}

export function getAwardBalance(academicYearId) {
    return axios.get('/api/services/app/Award/GetAwardBalance', { params: { academicYearId } });
}

export function calculateAward(values) {
    return axios.post('/api/services/app/Award/CalculateAward', values);
}

export function applyAwards(values) {
    return axios.post('/api/services/app/Award/ApplyAwards', values);
}
