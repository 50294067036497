import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

import * as InstitutionApi from '../../../api/institution';
import EditDialog from './editDialog';
import enumHelper from '../../../common/logic/enumHelper';
import { TermStructure, SchoolType } from '../../../common/logic/enums';
import CustomDataGrid from '../../../common/customDataGrid';

const useStyles = makeStyles((theme) => ({
    institutions: {
        padding: theme.spacing(0, 2)
    },
    tableTitle: {
        flexGrow: 1
    },
    keyContact: {
        listStyleType: 'none',
        padding: 0,
        margin: 0,
        lineHeight: 1.3
    }
}));

const sortModel = [{ field: 'name', sort: 'asc' }];

export default function InstitutionList() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const classes = useStyles();

    useEffect(() => {
        const tokenSourceInstitutions = axios.CancelToken.source();

        async function load() {
            setIsLoading(true);
            try {
                const items = (await InstitutionApi.getAll('name', 0, 100, tokenSourceInstitutions.token)).data.result.items;
                setData(items);
            } catch {
            } finally {
                setIsLoading(false);
            }
        }

        load();

        return () => {
            tokenSourceInstitutions.cancel();
        };
    }, [showEdit]);

    const columns = [
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'mainPhone', headerName: 'Main Phone', flex: 1 },
        {
            field: 'keyContact',
            headerName: 'Key Contact',
            flex: 1,
            renderCell: ({ row }) => (
                <ul className={classes.keyContact}>
                    <li>{row.keyContact}</li>
                    <li>{row.keyContactEmail && <Link href={`mailto:${row.keyContactEmail}`}>{row.keyContactEmail}</Link>}</li>
                </ul>
            )
        },
        { field: 'isActive', headerName: 'Active', width: 100, renderCell: ({ row }) => (row.isActive ? <DoneIcon color="primary" /> : ' ') },
        { field: 'schoolType', headerName: 'School Type', flex: 1, valueFormatter: ({ value }) => enumHelper.getDisplayName(SchoolType, value) },
        {
            field: 'termStructure',
            headerName: 'Term Structure',
            flex: 1,
            valueFormatter: ({ value }) => enumHelper.getDisplayName(TermStructure, value)
        },
        {
            field: 'actions',
            headerName: 'Action',
            width: 100,
            sortable: false,
            renderCell: ({ row }) => (
                <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => {
                        setSelectedItem(row);
                        setShowEdit(true);
                    }}
                    startIcon={<CreateIcon className={classes.leftIcon} fontSize="small" />}
                >
                    Edit
                </Button>
            )
        }
    ];

    return (
        <div className={classes.institutions}>
            <Box p={1} color="secondary.contrastText" bgcolor="secondary.main">
                <Toolbar variant="dense">
                    <Typography className={classes.tableTitle} variant="h6">
                        Institutions
                    </Typography>
                    <IconButton
                        color="inherit"
                        onClick={() => {
                            setSelectedItem(null);
                            setShowEdit(true);
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                </Toolbar>
            </Box>
            <CustomDataGrid
                disableColumnMenu
                disableColumnFilter
                initialSortModel={sortModel}
                disableSelectionOnClick
                rows={data}
                rowHeight={75}
                columns={columns}
                initialPageSize={25}
                loading={isLoading}
                rowsPerPageOptions={[25, 50, 100]}
            />
            <EditDialog open={showEdit} onClose={() => setShowEdit(false)} institution={selectedItem} />
        </div>
    );
}
