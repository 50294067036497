import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
    contact: {
        display: 'flex',
        flexDirection: 'column',
        '&:first-child': {
            marginBottom: theme.spacing(2)
        }
    },
    label: {
        textTransform: 'capitalize',
        fontSize: 15,
        fontWeight: 700,
        marginBottom: theme.spacing(0.5)
    },
    formControl: {
        '& p': {
            marginBottom: theme.spacing(1)
        }
    },
    email: {
        wordBreak: 'break-word'
    }
}));

export const ContactSkeleton = () => {
    const classes = useStyles();

    return (
        <div className={classes.contact}>
            <Skeleton variant="text" width={75} height={20} style={{ marginBottom: 5 }} />
            <Skeleton variant="text" width={75} height={15} />
            <Skeleton variant="text" width={115} height={25} />
            <Skeleton variant="text" width={75} height={15} />
            <Skeleton variant="text" width={115} height={25} />
            <Skeleton variant="text" width={75} height={15} />
            <Skeleton variant="text" width={115} height={25} />
            <Skeleton variant="text" width={75} height={15} />
            <Skeleton variant="text" width={135} height={25} />
            <Skeleton variant="text" width={75} height={15} />
            <Skeleton variant="text" width={155} height={25} />
        </div>
    );
};

const Contact = ({ label, contact }) => {
    const classes = useStyles();

    return (
        <div className={classes.contact}>
            <FormControl className={classes.formControl}>
                <FormLabel className={classes.label}>{label} Contact</FormLabel>
                <FormLabel>Name</FormLabel>
                <Typography variant="body1">{contact && contact.name ? contact.name : 'Name Not specified'}</Typography>
                <FormLabel>Degree</FormLabel>
                <Typography variant="body1">{contact && contact.degree ? contact.degree : 'Degree Not specified'}</Typography>
                <FormLabel>Phone Number</FormLabel>
                <Typography variant="body1">{contact && contact.phoneNumber ? contact.phoneNumber : 'Phone Not specified'}</Typography>
                <FormLabel>Email Address</FormLabel>
                <Typography className={classes.email} variant="body1">
                    {contact && contact.emailAddress ? contact.emailAddress : 'Email Not specified'}
                </Typography>
                <FormLabel>Job Title</FormLabel>
                <Typography variant="body1">{contact && contact.jobTitle ? contact.jobTitle : 'Job Title Not specified'}</Typography>
            </FormControl>
        </div>
    );
};

export default Contact;
